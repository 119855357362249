import { Pipe, PipeTransform } from '@angular/core';
declare var require: any;
const moment = require('moment-timezone');

@Pipe({
  name: 'dateFormatPipe',
})
export class dateFormatPipe implements PipeTransform {
  transform(value: string) {
    value = moment(value).utcOffset(0).format('YYYY-MM-DD HH:mm:ss');
    return value;
  }
}
