import {Component, OnInit, ViewChild,Input ,Inject,LOCALE_ID} from '@angular/core';
import {UserService} from "../../services/user.service";
import {NgxSpinnerService} from "ngx-spinner";
import {HttpClient} from "@angular/common/http";
import {ModalDirective} from "ngx-bootstrap";
import {DataTableDirective} from 'angular-datatables';
import {Subject} from "rxjs/Rx";
import * as env from '../../globals/env';
import * as moment from "moment";
import {UtilityService} from '../../globals/utilityService';
import { PlacebetService } from 'src/app/services/placebet.service';
import { CommonService } from 'src/app/services/common.service';
import { MarketService } from 'src/app/services/market.service';
declare let $: any;
declare let _: any;


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-profit-loss',
  templateUrl: './profit-loss.component.html',
  styleUrls: ['./profit-loss.component.scss']
})
export class ProfitLossComponent implements OnInit {
  minDate: any;
  @Input() dateChanged: any;

  @ViewChild('transactionModel', {static: false}) transactionModel: ModalDirective;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  dtTrigger = new Subject();
  resData: any;
  adminServer_url: any = env.adminServer_url();
  constructor(  private userService: UserService ,
                private utilityService: UtilityService ,
                private http: HttpClient,
                private marketService:MarketService,
                private placebetService: PlacebetService ,
                private commonService:CommonService,
                @Inject(LOCALE_ID) private locale: string,
                private spinner : NgxSpinnerService) {
  }

  filter = {
    id: this.utilityService.returnLocalStorageData('userId'),
    page: 1,
    limit: 10,
    startDate: null,
    endDate: null,
    matchName:null,
    type: 'GAME'

  };
  matchListSerchdata:any;
  matchNameVaule = '';
  fromDate: any;
  toDate: any;
  total = 0;
  sDate:any;
  eDate:any;
  betlistFind:any;
  chipsTransactionData: any;
  userTransactionRes: any;
  disabled:boolean = true;
  preBal:any = null;
  OpeningBalace:any= null;
  OpeningBalaceDate:any= null;
  rawNO:any = 0;
  responseData:any;
  jsonData:any = [];

  ngOnInit(): void {
    let pre =  moment().format("YYYY-MM-DD");
    let endDate =  moment().subtract(3,'d').format("YYYY-MM-DD");

    this.filter.startDate = endDate;
    this.filter.endDate = pre;

    let ePreShow = moment().format("DD/MM/YYYY");
    let sPreShow = moment().subtract(3,'d').format("DD/MM/YYYY");
    this.sDate = sPreShow;
    this.eDate = ePreShow;

    // this.getOpeningBalanceData();
    this.getAcSummaryReport();
    this.matchListSerch();
  }




  /**
   * @author TR
   * @date 23-06-2020
   * GetAll AcSummaryReport
   * @param data
   */
  getAcSummaryReport(){
    this.rerender();
    this.preBal = null;
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50, // -- trying to set to 5 records only
      paging: true,
      deferLoading: 0,
      serverSide: true,
      processing: false,
      responsive: true,
      lengthChange: true,
      order: [[ 0, "" ]],
      lengthMenu: [50, 75, 100, 200],
      language: {
        lengthMenu: '_MENU_',
        zeroRecords: '',
        searchPlaceholder: 'search',
        search: "",
        emptyTable: 'No record found',
        paginate: {
          first: 'first', last: 'last',
          next: 'next',
          previous: 'prev'
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        let url = this.adminServer_url + 'chipscash/getplSummary';
        that.http
          .post<DataTablesResponse>(
            url,
            Object.assign(dataTablesParameters, {data : this.filter}),
            {}
          ).subscribe(resp => {
          this.responseData = resp;
          this.responseData = this.utilityService.gsk(this.responseData.auth);
          this.responseData = JSON.parse(this.responseData);
          if(this.responseData && this.responseData.status){
            this.chipsTransactionData = this.responseData.data.data;
            this.findsum(this.chipsTransactionData);
          }
          this.spinner.hide();
          this.dtTrigger.next();
          callback({
            recordsTotal: this.responseData.data.totalCount,
            recordsFiltered: this.responseData.data.totalCount,
            data: []
          });
        });
      },

      columns: [ { data: '' }, { data: 'createdAt' }, { data: 'debit' }, { data: 'credit' }],
      columnDefs: [ { orderable: false, targets: [0] },{ orderable: false, targets: [1] }, { orderable: false, targets: [2] }, { orderable: false, targets: [3] }]
    };
  }


  rerender(): void {
    $('.dataTable').dataTable().fnClearTable();
  }

  clearDate(e){
    if(e.target.value === ''){
      this.getAcSummaryReport()
    }
  }

  searchFilterData() {
    this.OpeningBalaceDate = null;
    this.rawNO = 0;
    if (this.fromDate){
      this.filter.startDate = (this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day);
    }
    if (this.toDate){
      this.filter.endDate = (this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day);
    }
    this.fromDate = this.fromDate;
    this.toDate = this.toDate;
    if(this.matchNameVaule){
      this.filter.matchName = this.matchNameVaule
    }
    this.getAcSummaryReport();
  }

  clearFilter(){
    this.OpeningBalaceDate = null;
    this.rawNO = 0;
    this.disabled = true;
    this.sDate = '';
    this.eDate = '';
    this.filter.startDate = null;
    this.filter.endDate = null;
    this.fromDate =null;
    this.toDate =null;
    this.matchNameVaule = '';

    let pre =  moment().format("YYYY-MM-DD");
    let endDate =  moment().subtract(3,'d').format("YYYY-MM-DD");

    this.filter.startDate = endDate;
    this.filter.endDate = pre;

    let ePreShow = moment().format("DD/MM/YYYY");
    let sPreShow = moment().subtract(3,'d').format("DD/MM/YYYY");
    this.sDate = sPreShow;
    this.eDate = ePreShow;

    this.filter = {
      id: this.utilityService.returnLocalStorageData('userId'),
      page: 1,
      limit: 10,
      startDate: this.filter.startDate,
      endDate: this.filter.endDate,
      matchName:this.matchNameVaule,
      type: 'GAME'
    };
    this.getAcSummaryReport();
  }


  /**
   * @author kc
   * @date 30-04-2019
   * changed page based on current page
   * @param p
   */
  pageChange(p): void {
    this.filter.page = p.page;
    this.getAcSummaryReport();
  }

   /**
   * @author subhash
   * @date 21-08-2019
   * date bulding
   */
  onDateSelect(e) {
    if (e.year) {
      this.toDate = null;
      this.disabled = false;
      this.minDate = {
        year: e.year,
        month: e.month ,
        day: e.day + 1
      };
    } else {
      this.disabled = true;
    }
  }




   /**
   * get all match list
   */
  matchListSerch() {
    let data = {
      startDate: this.filter.startDate,
      endDate:this.filter.endDate
    };
    this.marketService.getMatchListSerch(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      // response.data = _.uniq(response.data , 'id');
      response.data = _.uniqBy(response.data, function (e) {
        return e.id;
      });
      this.matchListSerchdata = response.data;
    }, error => {
      console.error('error');
    });
  }

  findsum(data){
    this.total = 0;
    for(let j=0; j < data.length; j++){
      this.total+=data[j].total;
    }
  }
}

