import {Inject, Injectable } from '@angular/core';
import {DOCUMENT} from '@angular/common';

import * as io from 'socket.io-client';
import { Observable} from 'rxjs';

declare let _: any;
declare var $: any;
@Injectable()

export class ScoreCard{
  private url =  'https://score.getlivefeed.xyz/';

  private socket;
  constructor(@Inject(DOCUMENT) private document: any,

  ) {
    this.socket = io(this.url,{
        autoConnect: true,
        forceNew: false,
        reconnection: true,
        reconnectionDelay: 500,
        reconnectionDelayMax: 1000,
        reconnectionAttempts: Infinity,
        transports: ['websocket','flashsocket','htmlfile','xhr-polling','jsonp-polling','polling']
      }
    );

    this.socket.on('disconnect', () => {

    });


    this.socket.on('reconnect', () => {
      this.reconnections();
      let socket = this.socket;
      let roomIs = localStorage.getItem('scorecardIds');
      let room = JSON.parse(roomIs);
      if (roomIs !== null) {
        socket.emit('join-room', room);
        // _.map(room, function(res) {
        //   socket.emit('join-room', res.toString());
        // }, this);
      }
    });

    document.addEventListener("visibilitychange", function() {
    }, false);
  }



  //Join room function
  joinRoom(roomId){
    this.socket.emit('join-room', roomId);
  }


  //leave the room
  leaveRoom(roomId){
    // console.log(roomId);
    this.socket.emit('leave-room', roomId);
  }

  disconnect(){
    this.socket.disconnect(0);
  }

  connect(){
    this.socket.connect(0);
  }



  public getScoreCard = () => {
    return Observable.create((observer) => {
      this.socket.on('score-card', (message) => {
        observer.next(message);
      });
    });
  }

  public getOtherCard = () => {
    return Observable.create((observer) => {
      this.socket.on('other-card', (message) => {
        observer.next(message);
      });
    });
  }

  public getScoreCardRadar = () => {
    return Observable.create((observer) => {
      this.socket.on('score-radar', (message) => {
        observer.next(message);
      });
    });
  }

  public reconnections = () => {
    return Observable.create((observer) => {
      this.socket.on('reconnect', (message) => {
        observer.next('reconnect');
      });
    });
  }
}
