import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CommonService } from "../services/common.service";
import { UserService } from '../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import { SocketService } from "../globals/socketService";
declare let _: any;
import { CurrencyService } from "../services/currency.service";
import { ModalDirective } from 'ngx-bootstrap';
import { SocketServiceClient } from "../globals/socketServiceClient";
import { SocketServiceRedis } from "../globals/socketServiceRedis";



import {UtilityService} from '../globals/utilityService';
import { PlacebetService } from '../services/placebet.service';
//import {SocketcasinoRedis} from '../globals/socketcasinoRedis';
import {NgxSpinnerService} from 'ngx-spinner';
import {SocketServiceRedisMarket} from "../globals/socketServiceRedisMarket";
import {ScoreCard} from "../globals/scoreCard";
import {MarketService} from '../services/market.service';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild(ModalDirective, { static: false }) currencyModal: ModalDirective;
  @ViewChild('changePassModal', { static: false }) changePassModal: ModalDirective;
  @ViewChild('exposureDetails', { static: false }) exposureDetails: ModalDirective;
  @ViewChild('newUserchangePassModal', { static: false }) newUserchangePassModal: ModalDirective;

  userBalance = 0;
  userExposer = 0;
  userPl = 0;
  userAvaBalance = 0;
  userName = 0;
  loginTime: any;
  confNewPassword: any;
  isCasino = false;
  userData:any;
  changePassword:any;
  getAllMarkets:any;
  cricketLen = 0;
  soccerLen = 0;
  tennisLen = 0;
  mycurrentRoute = '';
  masterId:any;
  massage:any = '';
  userLoginId:any;
  userId = this.utilityService.returnLocalStorageData('userId');
  messages: any;
  public BalanceHideShow: boolean = true;
  public AvailableHideShow: boolean = true;
  public ExposureHideShow: boolean = true;
  public PlHideShow : boolean = true;
  constructor(
    private socketServiceClient: SocketServiceClient,
    private socketServiceRedis: SocketServiceRedis,
    private socketServiceRedisMarket: SocketServiceRedisMarket,
    private scoreCard: ScoreCard,
    private commonService: CommonService,
    private currencyService: CurrencyService,
    private utilityService: UtilityService,
    private placebetService: PlacebetService,
    private  spinner: NgxSpinnerService ,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private marketService: MarketService,
    private socketService: SocketService
  ) {
    const source = interval(15000); // interval is in milliseconds

    // Execute the function for a specific number of times (optional)
    source.pipe().subscribe(() => {
      // this.getbalance(); // Replace with your actual function
    });
  }
  /**
   * @author TR
   * @date : 05-06-2020
   * esc click to close modal with reset form
   */
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      //this.currencyModal.hide();
    }
  }

  ngOnInit() {

    let datauser = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
    let tkn = localStorage.getItem('token');
    this.getCasinoLock(datauser);
    if(datauser.userType == 'CLIENT'){

    }else{
      this.router.navigate(['/login']);
    }

    this.route.params.subscribe(params => {

      if (params.type) {
        $('.menu-box-a').removeClass('active');
        $('#'+params.type).addClass('active');
      }else{
        $('.menu-box-a').removeClass('active');
        $('#home').addClass('active');
      }
    });
    this.spinner.show();
    let data = localStorage.getItem('theme');
    if(data){
    $('#mode').attr("href", data);
      this.spinner.hide();
    }

    this.changePassword = datauser.changePassword;
    this.route.params.subscribe(params => {
      let checkStorage = this.utilityService.returnLocalStorageData('userId');
      if(checkStorage) {
      }else {
        this.router.navigate(['/login']);
      }
    });

    this.userData = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
    this.masterId = this.userData.masterId;

    let items = sessionStorage.getItem('balance');
    this.BalanceHideShow = JSON.parse(items);

    let available = sessionStorage.getItem('available');
    this.AvailableHideShow = JSON.parse(available);

    let exposure = sessionStorage.getItem('exposure');
    this.ExposureHideShow = JSON.parse(exposure);

    let pl = sessionStorage.getItem('pl');
    this.PlHideShow = JSON.parse(pl);

    let users = this.commonService.getLocalStorage();
    let userId = users.userId;
    let balanceObj = {
      ids: userId
    };
    this.userLoginId = userId;

    this.getbalance();
    this.socketServiceClient.connect();
    this.socketService.connect();
    this.socketServiceRedis.connect();
    this.socketServiceRedisMarket.connect();
    this.scoreCard.connect();

    this.userLoginId = users.userId;
    this.socketService.joinRoom(this.userLoginId);
    this.socketServiceClient.joinRoom( this.userLoginId);

    this.socketServiceClient.balaceSettel().subscribe((res) => {
      if (res) {
            this.userBalance = 0;
            this.userExposer = 0;
            this.userAvaBalance = 0;
            this.userBalance = res[0].balance;
            this.userExposer = res[0].exposer;
            this.userPl = res[0].p_and_l + res[0].cash;
            this.userAvaBalance = res[0].available_balance;
            this.userName = res[0].userName;
      }
    });


    this.socketServiceClient.updateBalancebyAdmin().subscribe((res) => {
      if (res) {
        let users = this.commonService.getLocalStorage();
        let userId = users.userId;
        let avbl ;
        if(res.length > 0){
          _.map(res, function (e) {
            if(e.userId == userId){
              avbl = e.balance;
            }

          });
          this.userAvaBalance = avbl;
        }
        // this.userService.getBalanceUpdated(res[1].receiverId).subscribe(res => {
        //   this.userBalance = 0;
        //   this.userExposer = 0;
        //   this.userAvaBalance = 0;
        //   if (res.status == true) {
        //     this.userBalance = res.data[0].balance;
        //     this.userExposer = res.data[0].exposure;
        //     this.userPl = res.data[0].p_and_l + res.data[0].cash;
        //     this.userAvaBalance = res.data[0].available_balance;
        //     this.userName = res.data[0].userName;
        //   }
        // });
      }

    });

    this.socketServiceClient.welcomeMassage().subscribe((res) => {
      if (res) {
        this.massage = res.massage;
      }
    });

    this.socketServiceRedis
      .reconnections()
      .subscribe((response) => {
        this.getbalance();
      });


    this.socketServiceClient
      .updateBalance()
      .subscribe((response) => {
        if (response) {
          this.userBalance = response.balance;
          this.userExposer = response.casino_exposer ? response.exposer + response.casino_exposer : response.exposer;
          this.userPl = response.p_and_l + response.cash;
          this.userAvaBalance = response.available_balance
        }
      });

    //Get latest transaction from socket
    this.socketServiceClient
      .cancelMarket()
      .subscribe((response) => {
        this.getbalance();
      });

    this.socketServiceClient.logoutByAdmin().subscribe((response) => {
      if (response) {
        if (datauser.user_id == response._id) {
          if (response.isLogin == false && response.isActive == true) {
            this.commonService.popToast('success', 'Success', 1500, 'Your credential login another device therefor you logout.');
          }
          if (response.isActive == false) {
            this.commonService.popToast('success', 'Success', 1500, 'Your Account is InActive By Admin.');
          }
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(['/login']);
        }
      }
    });

    this.welcomeMassages();

    this.socketService.logoutByAdminInctive().subscribe((response) => {
      if (response) {
        if (_.includes(this.masterId, response._id)) {
          if (response.isActive == false) {
            $("#mode").attr("href", 'assets/scss/custome-dark.css');
            localStorage.clear();
            sessionStorage.clear();
            this.router.navigate(['/login']);
            this.commonService.popToast('success', 'Success', 1500, 'Logout successfully By Admin.');
          }
        }
      }
    });
    this.getInplayData();
  }

  welcomeMassages() {
    this.userService.welcomeMassage().subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if(response && response.data){
        this.massage = response.data.massage;
      }else{
        this.massage = '';
      }
    }, error => {
      this.commonService.popToast('error', 'Error', 1500, error.message);
      console.error("error");
    });
  }


  userLogout() {
    this.userService.logOut().subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      $("#mode").attr("href", 'assets/scss/custome-dark.css');
      this.commonService.popToast('success', 'Success', 1500, 'Logout successfully.');
      localStorage.clear();
      let lastJoinRoom = sessionStorage.getItem("lastJoinRoom");

      let userJoinRoom = this.utilityService.returnSessionStorageData('userId');
      this.socketService.disconnect();
      this.socketServiceClient.disconnect();
      this.socketServiceRedis.disconnect();
      this.socketServiceRedisMarket.disconnect();
      this.scoreCard.disconnect();
      const oldEventId = sessionStorage.getItem('casinoId');
      if(oldEventId){
      //this.socketcasinoRedis.leaveRoom('casino_' + oldEventId);
      }
      if (lastJoinRoom) {
        this.socketService.leaveRoom(lastJoinRoom);
      }
      if (userJoinRoom) {
        this.socketServiceClient.leaveRoom(userJoinRoom);
      }
      sessionStorage.clear();
      this.router.navigate(['']);
    }, error => {
      this.commonService.popToast('error', 'Error', 1500, error.message);
      console.error("error in logout");
    });
  }

  Pl(pl) {
    sessionStorage.setItem('pl', pl);
    let item = sessionStorage.getItem('pl');
    this.PlHideShow = JSON.parse(item);
  }

  reload(){
    this.route.params.subscribe(params => {
      this.mycurrentRoute = this.router.url;
      if(this.mycurrentRoute === '/dashboard') {
        location.reload();
      }else {

      }
    });
  }

  getbalance(){
    let balanceObj = {
      ids: this.userLoginId
    };
    this.userService.getBalanceClient(balanceObj).subscribe(res => {
      res = this.utilityService.gsk(res.auth);
      res = JSON.parse(res);
      if(res.status == false){
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      if (res.status == true) {
        this.userBalance = res.data[0].balance;
        this.userExposer = res.data[0].exposure;
        this.userPl = res.data[0].p_and_l;
        this.userAvaBalance = res.data[0].available_balance;
        this.userName = res.data[0].userName;
        this.loginTime = res.data[0].updatedAt;
      }
    });
  }

  getCasinoLock(data){
    let datas = {
      masterId : data.masterId,
      sportId: '990',
      userId: data.user_id
    }
    this.marketService.casinoLockCheck(datas).subscribe(response => {
      if(response.status == true){
        this.isCasino = response.data.isCasino
      }else{
        this.isCasino = false
      }
    });
  }


  getInplayData(){
    let cricketLen = 0;
    let tennisLen = 0;
    let soccerLen = 0;
    let items = localStorage.getItem("inplayMatch");
    let inplay = JSON.parse(items);
    _.map(inplay, function (e) {
      if(e._id == 4 ){
        cricketLen =  (cricketLen + e.doc.length);
      }
      if(e._id == 1 ){
        let length = e.doc.length;
        tennisLen =  (tennisLen + length);
      }
      if(e._id == 2 ){
        let length = e.doc.length;
        soccerLen = (soccerLen + length);
      }
    })
    this.cricketLen = cricketLen;
    this.tennisLen = tennisLen;
    this.soccerLen = soccerLen;
  }


  activeBtn(e){
    $('.menu-box-a').removeClass('active');
    $(e.target).addClass('active')
  }
  changeCSS(cssFile){
    $("#mode").attr("href", cssFile);
    sessionStorage.setItem('theme' , cssFile);
    localStorage.setItem('theme' , cssFile);
    let data = {
      themeName: cssFile,
      userId: this.userLoginId
    }
    this.userService.updateTheme(data).subscribe(res => {

    });
  }
  inplaycl(){
    $('.tab-menu-sport nav .nav-link').removeClass('active');
  }
  openMenu(){
    $('side-bar').addClass('is-open');
    $('#mainBody').css('overflow', 'hidden');
  }
  closeMenu(){
    $('side-bar').removeClass('is-open');
  }
  close(){
    // $('.nev-box-man .amount-main-box .dropdown-box:hover .dropdown-content').css('display', 'none');
  }

  open(){
      $('.dropdown-content').toggleClass('open');
  }




}


