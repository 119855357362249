import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ToasterConfig} from 'angular2-toaster';
import {NavigationStart, Router} from '@angular/router';
import {UtilityService} from './globals/utilityService';
import {environment} from '../environments/environment';
import {VersionCheckService} from './services/version-check.service';
import {isUndefined} from 'util';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {
  changePassword:any;
  @HostListener('window:keyup', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (window) {
      window.console.log = function () { };
    }
    if (event.key === 'f12' || event.key === 'F12') {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['']);
    }
  }
  @HostListener('window:keydown', ['$event']) onKeyDown(e) {
    if (window) {
      window.console.log = function () { };
    }
    if ((e.shiftKey == true && e.keyCode == 73 && e.ctrlKey == true) || (e.key == 'f12' || e.key == 'F12') ) {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['']);
      return false;
    }
  }
  @HostListener('click', ['$event']) onRightClick(event) {
    let datauser = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
    this.changePassword = datauser.changePassword;
  }
  title = 'MYFANCY';

  sidebarHeader = false;
  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-right',
    limit: 2
  });
  constructor(private router: Router, private versionCheckService: VersionCheckService , private utilityService: UtilityService) {
    if(window.console) {
        window.console.log = function(){};
        window.console.warn = function(){};
        window.console.error = function(){};
        window.console.time = function(){};
        window.console.timeEnd = function(){};
        window.console.info = function(){};
        window.console.trace= function(){};
        console.clear = function(){};
        console.clear();
     }


    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        const url = event.url;
        if (event['url'] === '/' || event['url'] === '/login'|| url.startsWith('/tv/')) {
          this.sidebarHeader = false;
        } else {
          if (!this.utilityService.returnLocalStorageData('userId')) {
            this.navigation();
          }else {
            this.sidebarHeader = true;
          }
        }
      }
    });
  }
  navigation(){
    this.router.navigate(['/login']);
  }

  ngOnInit() {
    this.createOnline$().subscribe(isOnline => {
      if(isOnline == false){
        this.utilityService.popToast('error','Error',5000,'Make sure you are connected with internet!');
      }
    });
    let  hello = this.versionCheckService.initVersionCheck(environment.appVersion);
    if(isUndefined(hello)){

    }else{
      location.reload();
    }
    let datauser = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
    this.changePassword = datauser.changePassword;
    if(this.changePassword === false){
      // this.router.navigate(['/login']);
    }
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

}
