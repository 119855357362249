import {AfterViewInit, Component, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {UtilityService} from '../../globals/utilityService';
import { UserService } from '../../services/user.service';
import _ from "lodash";
import * as $ from 'jquery';
import {Observable, Subscription, timer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MarketService} from '../../services/market.service';
import {CurrencyService} from '../../services/currency.service';
import {PlacebetService} from '../../services/placebet.service';
import {SocketServiceClient} from '../../globals/socketServiceClient';

//import {SocketcasinoRedis} from '../../globals/socketcasinoRedis';
import {CommonService} from '../../services/common.service';
import {ModalDirective} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
declare var T20RTCPlayer;
@Component({
  selector: 'app-casino-view',
  templateUrl: './casino-view.component.html',
  styleUrls: ['./casino-view.component.scss']
})
export class CasinoViewComponent implements OnInit  {
  @ViewChild('betviewall', { static: false }) betviewall: ModalDirective;
  countDown: Subscription;
  counter = 0;
  tick = 1000;

  url : any;
  eventId : any;
  eventType : any;
  resultArray = [];
  mycurrentRoute : any;
  resultData : any;
  showContent = false;
  selectedrunnerData : any;
  marketData : any;
  roundId : any;
  eventName : any;
  revCounter :any = 0;
  marketId : any;
  preData : any;
  Toast = {
    type: '',
    title: '',
    timeout: 0,
    body: ''
  };
  currencyAll : any;
  cardArray : any = [];
  cardCount : any = [];
  cardScan : any = [];
  placeType : any;
  allTransactions : any;
  endSubmit = false;
  casinoexp : any;
  eventData : any;
  showing : any;
  userId = this.utilityService.returnLocalStorageData('userId');
  token = this.utilityService.getRandomString(20);
  constructor(private utilityService: UtilityService,
              private userService: UserService,
              private marketService: MarketService,
              private commonService: CommonService,
              private socketServiceClient: SocketServiceClient,
              //private socketcasinoRedis: SocketcasinoRedis,
              private currencyService: CurrencyService,
              private placebetService: PlacebetService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.eventId = params.id;
      const oldEventId = sessionStorage.getItem('casinoId');
      //this.socketcasinoRedis.leaveRoom('casino_' + oldEventId);
      sessionStorage.setItem('casinoId', this.eventId);
      //this.socketcasinoRedis.joinRoom('casino_' + this.eventId);
      this.getEventData(this.eventId);
      this.getSettleDetails(this.eventId);
      this.eventSreaming();
    });

    this.getUserCurrency();



    // this.socketcasinoRedis
    //   .casinoRates()
    //   .subscribe((response) => {
    //       if(response){
    //         this.cardArray = response.cardsArr;
    //          if(response.eventId === '2020230163340196'){
    //            this.cardScan = [this.cardArray.card];
    //          }else{
    //            if(this.cardScan && this.cardScan.length > 0){
    //            this.cardCount = response.cardCount;
    //            this.cardScan = response.cardScan;
    //            this.cardScan = Object.values(this.cardScan);
    //            this.cardScan = _.without(this.cardScan , "0");
    //            }else {
    //              let runnerArray = [];
    //              for (var key in this.cardArray) {
    //                runnerArray.push({key: key, value: this.cardArray[key]});
    //              }
    //              this.cardArray = runnerArray;
    //              this.showing = null;
    //            }
    //          }

    //       if(response && response.roundId && this.eventData && this.eventData.roundId &&  this.eventData.roundId === response.roundId){
    //       this.eventData.status = response.status;
    //         this.eventName = response.eventName;
    //         this.roundId = response.roundId;
    //         if(this.preData !== response.status && response.status === "ONLINE"){
    //           this.counter = response.seconds;
    //           $('svg circle').css('animation' , 'countdown ' +response.seconds +'s linear infinite forwards')
    //           this.tick = 1000;
    //           this.countDown = timer(0, this.tick).subscribe((e) =>{
    //             if(this.counter != 0){
    //               --this.counter;
    //             }else{
    //               $('svg circle').css('animation' , 'none');
    //               this.countDown.unsubscribe();
    //               this.counter = 0;
    //               $('.bet-runs-main').removeClass('open-bet-run');
    //             }
    //           });
    //         }
    //         if(response.status === "SUSPEND"){
    //           $('.bet-runs-main').removeClass('open-bet-run');
    //           $('svg circle').css('animation' , 'none');
    //           this.counter = 0;
    //         }
    //         this.preData = response.status;
    //       _.map(response.marketArr, function (e) {
    //         if(e.marketId){
    //          _.map(e.runners, function (c) {
    //            // if(c.status === 'SUSPEND'){
    //            //   $('#' + c.selectionId + e.marketId + '_suspend').addClass('bookmaker_ballstart');
    //            // }else {
    //            //   $('#' + c.selectionId + e.marketId + '_suspend').removeClass('bookmaker_ballstart');
    //            // }
    //            let sizeBack = abbrNum(c.price.back[0].size, 0);
    //            let priceBack = String(c.price.back[0].price);
    //            let sizeLay;
    //            $('#' + c.selectionId + e.marketId + '_rates_back').text(priceBack);
    //            $('#' + c.selectionId + e.marketId + '_size_back').text(sizeBack);
    //            if(c.price && c.price.lay){
    //              let priceLay = String(c.price.lay[0].price);
    //             sizeLay = abbrNum(c.price.lay[0].size, 0);
    //              $('#' + c.selectionId + e.marketId + '_rates_lay').text(priceLay);
    //              $('#' + c.selectionId + e.marketId + '_size_lay').text(sizeLay);
    //            }
    //          })
    //         }
    //       })
    //       }else {
    //          this.eventData = response;
    //       }
    //     }else {
    //         this.eventData = [];
    //       }
    //   });

    this.socketServiceClient
      .updateTransaction()
      .subscribe((response) => {
        if (response) {
          const transactionObj = {
            eventId: this.eventId,
            userId: this.userId,
            roundId: this.eventData.roundId
          };
          this.getAllTransaction(transactionObj);
          $('.expo_event').text(0);
        }
      });

    this.socketServiceClient
      .casinoLive()
      .subscribe((response) => {
        if (response) {
          this.getSettleDetails(this.eventId);
        }
      });

    this.socketServiceClient
      .updateCasinoResults()
      .subscribe((response) => {
        if (response) {


            if(String(response.data.eventId) === this.eventId){
              let e = response.data;
              let res;
              _.map(e.resultArray, function (p) {
                if(p.marketName === 'RACE WINNER' || p.marketName === 'WINNER' ) {
                  _.map(p.runner, function (s) {
                    if (s.result === 'WINNER') {
                      if(e.eventId === '2020310988715'){
                      res = (s.winnerName === 'CLUB KING') ? 'CK' : (s.winnerName === 'HEART KING') ? 'HK' : (s.winnerName === 'SPADE KING') ? 'SK' : 'DK';
                      }
                      if(e.eventId === '2020230163340196') {
                        res = (s.winnerName === 'LOW CARD ( A to 6 )') ? 'LOW' : (s.winnerName === 'HIGH CARD ( 8 to K )') ? 'HIGH' : 'TIE';
                      }
                      if(e.eventId === '030820190613') {
                        res = s.winnerName === 'PLAYER A' ? 'PLAYER_A' : 'PLAYER_B';
                      }
                      if(e.eventId === '20201013161936177') {
                        res = s.winnerName;
                      }
                      if(e.eventId === '2020528144333331') {
                        res = s.winnerName === 'Player 8' ? 'PLAYER_8' : s.winnerName === 'Player 9' ? 'PLAYER_9' : s.winnerName === 'Player 10' ? 'PLAYER_10' : 'PLAYER_11';
                      }
                      if(e.eventId === '202101234450584') {
                        res = (s.winnerName === '1st BET A' || s.winnerName === '2nd BET A') ? 'PLAYER_A' : (s.winnerName === '1st BET B' || s.winnerName === '2nd BET B') ? 'PLAYER_B' : 'TIE';
                      }
                      if(e.eventId === '20204410722520') {
                        res = s.winnerName === 'DRAGON' ? 'DRAGON' : s.winnerName === 'TIGER' ? 'TIGER' : 'TIE';
                      }
                      if(e.eventId === '2020814123240967') {
                        res = s.winnerName === 'DRAGON' ? 'DRAGON' : s.winnerName === 'TIGER' ? 'TIGER' : 'TIE';
                      }
                    }
                  });
                }
              });
              let obj = {
                result: res,
                roundId: e.roundId,
                data: e
              };
              this.resultArray.unshift(obj);
              this.resultArray.pop();
            }
        }
      });
  }
  ngOnDestroy(){
    this.countDown=null;
  }


  getEventData(ite){
      this.marketService.getCasinoData().subscribe(response => {
        if(response.data.length > 0){
          let datas = _.map(response.data, function(e) {
            if(e.eventId === ite){
              return e;
            }
          });
          if(datas.length > 0){
          let ites = _.without(datas , undefined);
          this.eventData =  ites[0];
          }else {
            this.eventData = [];
          }
          this.eventName = this.eventData && this.eventData.eventName;
          this.roundId = this.eventData && this.eventData.roundId;
        }else {
          this.eventData = [];
        }
        if(this.eventData  && this.eventData.roundId ){
        this.getcasinoExposure();
        const transactionObj = {
          eventId: this.eventId,
          userId: this.userId,
          roundId: this.eventData.roundId
        };
        this.getAllTransaction(transactionObj);
        }
      });
  }


  getcasinoExposure(){
    let obj = {
      eventId : this.eventId,
      userId: this.userId,
      roundId: this.eventData.roundId
    };
    this.marketService.getCasinoexp(obj).subscribe(response => {
      this.casinoexp = response.data;
      this.getLatestExposer(response.data);
    });
  }

  eventSreaming(){
    let obj = {
      eventId : this.eventId,
    };
    this.marketService.getStreamData(obj).subscribe(response => {
      this.casinoexp = response.data;
      myFuncCasinoFrame(this.casinoexp);
    });
  }

  getKeyByValue(array , val){
    for (var key in array) {
      if(key == val){
        return array[key];
      }
    }
    return false;
  }

  getAllTransaction(transactionObj) {
    transactionObj.limitData = 50;
    this.marketService.getAllTransactionsCasino(transactionObj).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if (response.status == true) {
        this.allTransactions = response.data;
      }
    });
  }

  /**
   * @author TR
   * @date : 04-06-2020
   * get Currency
   */
  getUserCurrency() {
    const data = {
      userId : this.utilityService.returnLocalStorageData('userId')
    };
    this.currencyService.getUserCurrency(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      this.currencyAll = response.data[0];
    });
  }

  /**
   * @author TR
   * @date : 04-06-2020
   * get Currency
   */
  getSettleDetails(id) {

    const data = {
      marketId : id
    };
    this.currencyService.getSettleData(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
       this.resultArray = [];
       let results = [];
      let res;
      _.map(response.data, function(e) {
        if(String(e.eventId) === '2020310988715'){
          _.map(e.resultArray, function (p) {
            if(p.marketName === 'RACE WINNER') {
              _.map(p.runner, function (s) {
                if (s.result === 'WINNER') {
                  res = (s.winnerName === 'CLUB KING') ? 'CK' : (s.winnerName === 'HEART KING') ? 'HK' : (s.winnerName === 'SPADE KING') ? 'SK' : 'DK';
                }
              });
            }
          })
        }
        if(String(e.eventId) == '2020230163340196'){
          _.map(e.resultArray, function (p) {
            if(p.marketName === 'WINNER'){
            _.map(p.runner, function (s) {
            if (s.result === 'WINNER') {
              res = (s.winnerName === 'LOW CARD ( A to 6 )') ? 'LOW' : (s.winnerName === 'HIGH CARD ( 8 to K )') ? 'HIGH' : 'TIE';
            }
          });
            }
          });

        }
        if(String(e.eventId) == '030820190613') {
          _.map(e.resultArray, function (p) {
            if(p.marketName === 'WINNER') {
              _.map(p.runner, function (s) {
                if (s.result === 'WINNER') {
                  res = s.winnerName === 'PLAYER A' ? 'PLAYER_A' : 'PLAYER_B';
                }
              });
            }
              });
        }

        if(String(e.eventId) == '20201013161936177') {
          _.map(e.resultArray, function (p) {
            if(p.marketName === 'WINNER') {
              _.map(p.runner, function (s) {
                if (s.result === 'WINNER') {
                  res = s.winnerName;
                }
              });
            }
          });
        }

        if(String(e.eventId) == '2020528144333331') {
          _.map(e.resultArray, function (p) {
            if(p.marketName === 'WINNER') {
              _.map(p.runner, function (s) {
                if (s.result === 'WINNER') {
                  res = s.winnerName === 'Player 8' ? 'PLAYER_8' : s.winnerName === 'Player 9' ? 'PLAYER_9' : s.winnerName === 'Player 10' ? 'PLAYER_10' : 'PLAYER_11';
                }
              });
            }
              });
        }

        if(String(e.eventId) == '202101234450584') {
          _.map(e.resultArray, function (p) {
            if(p.marketName === 'WINNER') {
              _.map(p.runner, function (s) {
                if (s.result === 'WINNER') {
                  res = (s.winnerName === '1st BET A' || s.winnerName === '2nd BET A') ? 'PLAYER_A' : (s.winnerName === '1st BET B' || s.winnerName === '2nd BET B') ? 'PLAYER_B' : 'TIE';
                }
              });
            }
              });
        }

        if(String(e.eventId) == '20204410722520') {
          _.map(e.resultArray, function (p) {
            if(p.marketName === 'WINNER') {
              _.map(p.runner, function (s) {
                if (s.result === 'WINNER') {
                  res = s.winnerName === 'DRAGON' ? 'DRAGON' : s.winnerName === 'TIGER' ? 'TIGER' : 'TIE';
                }
              });
            }
              });
        }
        if(String(e.eventId) == '2020814123240967') {
          _.map(e.resultArray, function (p) {
            if(p.marketName === 'WINNER') {
              _.map(p.runner, function (s) {
                if (s.result === 'WINNER') {
                  res = s.winnerName === 'DRAGON' ? 'DRAGON' : s.winnerName === 'TIGER' ? 'TIGER' : 'TIE';
                }
              });
            }
              });
        }
        let obj = {
          result: res,
          roundId: e.roundId,
          data: e
        };
        results.push(obj);

      });
      this.resultArray = results;
    });
  }

  openPlaceModal(type , data , ite){
    $('.tempborder').removeClass('lay back');
    this.selectedrunnerData = data;
   let marketId = Number(type.marketId);
    let nameEvent = $('#' + this.selectedrunnerData.selectionId + '_name').text();
    let types = ite == 'back' ? 'BACK' : 'LAY';
    $('.tempborder').addClass(ite);
    this.placeType = ite;
    let stack = ite == 'back' ? $('#'+ this.selectedrunnerData.selectionId + marketId + '_rates_back' ).text() : $('#'+ this.selectedrunnerData.selectionId + marketId + '_rates_lay' ).text();
    this.marketId = type.marketId;
    this.marketData = type;
     $('.bet-runs-main').removeClass('open-bet-run');
    $('#' + this.selectedrunnerData.selectionId + marketId).addClass('open-bet-run');
    $('#' + this.selectedrunnerData.selectionId + marketId + '_price').val(stack);
    $('#' + marketId + '_runner').text(nameEvent);
    $('#' + marketId + '_type').text(types);
    $('#' + this.selectedrunnerData.selectionId + marketId + '_rate').val('');
    types == 'BACK' ? $('.price-btn-padding button').addClass('back-color') : $('.price-btn-padding button').addClass('lay-color');
  }

  closePlaceModal(){
     $('.bet-runs-main').removeClass('open-bet-run');
  }
  placeRateValue(btnval , i){
    $('#' + this.selectedrunnerData.selectionId + this.marketId + '_rate').val(btnval);
    $('.btn-amount').removeClass('activeBtn');
    $('#btn'+ i).addClass('activeBtn');
  }

  placeBet(roundData) {
    this.spinner.show();
    if(this.endSubmit) {
      return;
    }
    this.endSubmit = true;
    let nameEvent = $('#' + this.selectedrunnerData.selectionId + '_name').text();
    let price = $('#' + this.selectedrunnerData.selectionId + this.marketId + '_price').val();
    let rate = $('#' + this.selectedrunnerData.selectionId + this.marketId + '_rate').val();
    if(this.marketData.runners.length == 1){
      let dummyRunn = {
        "selectionId": "079",
        "status": "ONLINE",
        "price" : {
          "back": [{
            "price": 0,
            "size": 0
          }]
        }
      }
      this.marketData.runners.push(dummyRunn);
    }
    let objectPlaceBet = {
      "userId": this.userId,
      "marketId": this.marketId,
      "exposure": 0,
      "marketExposure": 0,
      "runnerId": this.selectedrunnerData.selectionId,
      "marketObject": this.marketData,
      "marketType": "casinoLive",
      "eventId": roundData.eventId,
      "roundId": roundData.roundId,
      "betDelay": roundData.betDelay,
      "sport": {
        id: roundData.sportId,
        name: 'casinoLive'
      },
      "bet": {
        "odds": price,
        "rate": rate,
        "volume": this.selectedrunnerData.price.back[0].size,
        "runner_type": this.placeType,
        "runnerName": nameEvent,
        "marketName": roundData.eventName,
        "marketId": this.marketId,
        "roundId": roundData.roundId,
        "eventId": roundData.eventId,
        "runnerId": this.selectedrunnerData.selectionId
      },
    }

    $('.bet-runs-main').removeClass('open-bet-run');
    this.placebetService.placeBetCasinoLive(objectPlaceBet).subscribe(response => {
      this.spinner.hide();
      this.marketData.runners = _.map(this.marketData.runners ,function (e) {
        if(e.selectionId !== '079'){
          return e;
        }
      });
      this.marketData.runners = _.without(this.marketData.runners, undefined);
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if(response.status === true){
        this.showToster('Success', 'success', response.message);
        // this.playAudio();
      const transactionObj = {
        eventId: this.eventId,
        userId: this.userId,
        roundId: this.eventData.roundId
      };
      this.getAllTransaction(transactionObj);
       _.map(response.placebetCasino.sharing, function(e) {
        if(e.userId === objectPlaceBet.userId && String(e.runners.selectionId) !== '079'){
          let item =  $('#' + e.runners.selectionId + response.placebetCasino.marketId + '_exposer');
          item.html(e.amount);
          e.amount > 0 ? item.addClass('credit-color') : item.addClass('debit-color');
        }
      });
        this.endSubmit = false;
      }else {
        this.endSubmit = false;
        $('.bet-runs-main').removeClass('open-bet-run');
        this.showToster('Error','error', response.message);
      }
    });
  }

  /*
      Developer: RK
      Date: 26-04-2020
      title: get latest exposure
      Use: This function is use to get latest exposure
    */
  getLatestExposer(data) {
    if(data && data.length > 0){
      _.map(data , function (els) {
        _.map(els.runnerExp, function (exp) {
          if(String(exp.runners.selectionId) !== '079'){
          let item =  $('#' + exp.runners.selectionId + els.marketId + '_exposer');
          item.html(exp.amount);
          exp.amount > 0 ? item.addClass('credit-color') : item.addClass('debit-color');
          }
          });
      });
    }
  }

  /*
    Developer: Ravi
    Date: 05-mar-2020
    title: Toster message
    Use: This function is use to throgh toster message
  */

  showToster(title,type,message){
    this.Toast.title = title;
    this.Toast.type = type;
    this.Toast.body = message;
    this.commonService.popToast(type, title, 1500, message);
  }

  playAudio() {
    const audio = new Audio();
    audio.src = '../../../assets/audio/notification-1.ogg';
    audio.load();
    audio.play();
  }



  abbrNum(num, decPlaces) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    var abbrev = ["k", "m", "b", "t"];
    let str : string;
    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {

      // Convert array index to "1000", "1000000", etc
      var size = Math.pow(10, (i + 1) * 3);

      // If the number is bigger or equal do the abbreviation
      if (size <= num) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        var number : number = Math.round(num * decPlaces / size) / decPlaces;


        // Handle special case where we round up to the next abbreviation
        if((number == 1000) && (i < abbrev.length - 1)) {
          number = 1;
          i++;
        }
        // number + abbrev[i];
        str  = number + abbrev[i];
        break;
      }
    }
    return str;
  }


/*
    Developer: RT
    Date: 16-jun-2021
    title: detalis
    Use: This function is use to details of winner
  */

  openDetalModal(item){
    this.betviewall.show();
    this.resultData = item;
  }
  /*
    Developer: RT
    Date: 16-jun-2021
    title: detalis
    Use: This function is use to details of winner
  */

  closeModal(){
    this.betviewall.hide();
  }

}



function abbrNum(num, decPlaces) {

  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ["K", "M", "B", "T"];
  let str : string;
  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {

    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= num) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      var number : number = Math.round(num * decPlaces / size) / decPlaces;


      // Handle special case where we round up to the next abbreviation
      if((number == 1000) && (i < abbrev.length - 1)) {
        number = 1;
        i++;
      }
      // number + abbrev[i];
       str  = number + abbrev[i];
      break;
    }
  }
  return str;
}

function myFuncCasinoFrame(data){
    let name = data && data.name ? data.name : 'DT1';
    let url = data && data.url ? data.url : 'icexchange.live';
  let webrtcPlayer = new T20RTCPlayer("remoteVideo", name, "", url, "", true, true, "tcp");
  webrtcPlayer.Play();
}




// abbrNum(999995, 4); //       => 999.995k
// abbrNum(999995, 2); //       => 1m
// abbrNum(999900000,0); //     => 1b
// abbrNum(999999900000000,0);//=> 1000t
// abbrNum(12 , 1); //          => 12
// abbrNum(0 , 2); //           => 0
// abbrNum(1234 , 0); //        => 1k
// abbrNum(34567 , 2); //       => 34.57k
// abbrNum(918395 , 1); //      => 918.4k
// abbrNum(2134124 , 2); //     => 2.13m
// abbrNum(47475782130 , 2); // => 47.48b
