import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {commonDerectivenModule} from "../auth-gaurd/commonDerective.module";
import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { AccountStatementComponent } from './account-statement/account-statement.component';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule} from "@angular/forms";
import {ModalModule} from "ngx-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {DataTablesModule} from "angular-datatables";
import { OpenBetComponent } from './open-bet/open-bet.component';
import { NgbDateMomentParserFormatter } from '../auth-gaurd/date_format';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ChipsSettingComponent } from './chips-setting/chips-setting.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { RulesSettingComponent } from './rules-setting/rules-setting.component';
import { ExposerDetailsComponent } from './exposer-details/exposer-details.component';
import { GenerateBillComponent } from './generate-bill/generate-bill.component';
import { HistoricalAcStatementComponent } from './historical-ac-statement/historical-ac-statement.component';
import { ProfileComponent } from './profile/profile.component';
import { MatchWiseAcStatementComponent } from './match-wise-ac-statement/match-wise-ac-statement.component';


@NgModule({
  declarations: [ReportsComponent, AccountStatementComponent, ProfitLossComponent, OpenBetComponent, ChipsSettingComponent, PasswordResetComponent, RulesSettingComponent, ExposerDetailsComponent,  GenerateBillComponent, HistoricalAcStatementComponent, ProfileComponent, MatchWiseAcStatementComponent],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ModalModule.forRoot(),
    HttpClientModule,
    DataTablesModule,
    NgbModule,
    commonDerectivenModule
  ],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useFactory: () => { return new NgbDateMomentParserFormatter('DD/MM/YYYY') }
    }
  ],
})
export class ReportsModule { }
