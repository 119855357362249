import { Component, OnInit } from '@angular/core';
import {CurrencyService} from '../../services/currency.service';
import {UtilityService} from '../../globals/utilityService';
import {CommonService} from '../../services/common.service';

@Component({
  selector: 'app-rules-setting',
  templateUrl: './rules-setting.component.html',
  styleUrls: ['./rules-setting.component.scss']
})
export class RulesSettingComponent implements OnInit {
  marketRules: any;
  constructor(
    private currencyService: CurrencyService,
    private utilityService: UtilityService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.getRules();
  }

  getRules() {
    this.currencyService.getRestriction().subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if (response.status === true) {
        this.marketRules = response.data[0];
      } else {
        // this.commonService.popToast('error','Error', 3000 , 'not found market rules.');
      }
    }, error => {
      this.commonService.popToast('error', 'Error', 1500, 'not found market rules.');
    });
  }
}
