import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {tap} from 'rxjs/internal/operators';
import {isUndefined} from 'util';
import * as env from '../globals/env';
import {UtilityService} from '../globals/utilityService';

@Injectable({
  providedIn: 'root'
})
export class MarketService {
  adminServer_url: any = env.adminServer_url();
  server_url: any = env.server_url();

  constructor(private http: HttpClient, private utilityService: UtilityService
  ) { }

  getBannerImage(): Observable<any> {
    return this.http.get(this.adminServer_url + 'market/getImages')
      .pipe(tap(_ => this.log(`get images successfully`)));
  }


  /**
   *@author kc
   * @date 31-01-2020
   * @param filter
   * @returns {Observable<any>}
   * get all Market
   */
  getAllMarket(filter , userId): Observable<any> {
    let data = '';
    let query = '?page=' + filter.page +   '&limit=' + filter.limit + '&userId=' + userId;

    if (!isUndefined(filter.search) && filter.search !== null) {
      query += '&search=' + filter.search;
    }
    if (filter.data !== null) {
      query += '&ite=' + filter.data;
    } else{
      query += '&ite= 4';
    }
    console.log('this.adminServer_url + market + query',this.adminServer_url + 'market' + query);
    return this.http.get(this.adminServer_url + 'market' + query)
      .pipe(tap(_ => this.log(`get Market successfully`)));
  }

  /**
   *@author kc
   * @date 26-02-2020
   * @param filter
   * @returns {Observable<any>}
   * get getMarketById
   */
  getCommentry(filter): Observable<any> {
    console.log(filter)
    return this.http.get(this.adminServer_url + 'market/commentryRd/'+ filter)
      .pipe(tap(_ => this.log(`get By MarketId successfully`)));
  }


  /**
   *@author kc
   * @date 26-02-2020
   * @param filter
   * @returns {Observable<any>}
   * get getMarketById
   */
  getLocalRt(filter): Observable<any> {
    console.log(filter)
    return this.http.get(this.adminServer_url + 'market/localRt/'+ filter)
      .pipe(tap(_ => this.log(`get By MarketId successfully`)));
  }

  /**
   *@author TR
   * @date 31-01-2020
   * @param filter
   * @returns {Observable<any>}
   * get all Match Odds for Sidebar
   */
  getAllMarketForDashboard(filter , userId): Observable<any> {

    let query = '?page=' + filter.page +   '&limit=' + filter.limit + '&userId=' + userId;

    return this.http.get(this.adminServer_url + 'market/creative/dashboard-client' + query)
      .pipe(tap(_ => this.log(`get Market successfully`)));
  }
  getAllMarketForDashboardClient(filter , userId): Observable<any> {

    let query = '?page=' + filter.page +   '&limit=' + filter.limit + '&userId=' + userId;

    return this.http.get(this.adminServer_url + 'market/creative/dashboard-clientDelay' + query)
      .pipe(tap(_ => this.log(`get Market successfully`)));
  }

  getSideBarData(filter , userId): Observable<any> {
    let query = '?page=' + filter.page +   '&limit=' + filter.limit + '&userId=' + userId;
    return this.http.get(this.adminServer_url + 'market/creative/sidebar-client' + query)
    .pipe(tap(_ => this.log(`get sidebar successfully`)));
  }

  /**
   *@author TR
   * @date 31-01-2020
   * @param filter
   * @returns {Observable<any>}
   * get all Match Odds for Sidebar
   */
  getAllMarketForSideCreative(filter , userId): Observable<any> {

    let query = '?page=' + filter.page +   '&limit=' + filter.limit + '&userId=' + userId;

    return this.http.get(this.adminServer_url + 'market/creative/sidebar-client' + query)
      .pipe(tap(_ => this.log(`get Market successfully`)));
  }

  getEventWiseTransaction(data):Observable<any>{
    return this.http.post(this.adminServer_url + 'placebet/getEventTran', data)
      .pipe(tap(_ => this.log(`get match successfully`)));
  }

  getExposer(data): Observable<any> {
    return this.http.post(this.server_url + 'placebet/get-market-expo', data)
      .pipe(tap());
  }
  casinoLockCheck(data): Observable<any> {
    return this.http.post(this.server_url + 'placebet/get-casino-lock', data)
      .pipe(tap());
  }

  getAllTransactionsCount(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'placebet/get-all-client-transactions-count', data)
      .pipe(tap());
  }

  /**
   * @author TR
   * @date : 11/06
   *  get round data result
   */

  getRedisData(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/redisDataFancy', data)
      .pipe(tap(_ => this.log(`Get redis data successfully`)));
  }

  /**
   * @author TR
   * @date : 11/06
   *  get round data result
   */

  getMultiMarketIds(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/getMultiMarketId', data)
      .pipe(tap(_ => this.log(`Get redis data successfully`)));
  }

  /**
   * @author TR
   * @date : 11/06
   *  get round data result
   */

  getMultiMarket(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/getMultiMarket', data)
      .pipe(tap(_ => this.log(`Get multimarket data successfully`)));
  }
  /**
   * @author TR
   * @date : 11/06
   *  multiMarketAdd
   */

  multiMarketAdd(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/addMultiByUser', data)
      .pipe(tap(_ => this.log(`Add multi market data`)));
  }
  /**
   *@author TR
   * @date 11-03-2021
   * @param filter
   * @returns {Observable<any>}
   * get all live tv feed
   */
  liveTvFeed(filter,ip): Observable<any> {
    return this.http.get(this.adminServer_url + 'market/gettv/' + filter + "/" + ip)
    // return this.http.get('https://tv.rdsconn.com/tv.php?id=' + filter)
      .pipe(tap(_ => this.log(`get live tv`)));
  }

  /**
   *@author TR
   * @date 11-03-2021
   * @param filter
   * @returns {Observable<any>}
   * get all live tv feed
   */
  getCasinoexp(filter): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/getExposerCasinoLive' , filter)
      .pipe(tap(_ => this.log(`get exposure sccessfully.`)));
  }


  /**
   *@author TR
   * @date 11-03-2021
   * @param filter
   * @returns {Observable<any>}
   * get all live tv feed
   */
  getStreamData(filter): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/event_streaming' , filter)
      .pipe(tap(_ => this.log(`get event Data sccessfully.`)));
  }


  liveTvIp(filter): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.get('https://api.ipify.org/?format=json',{ headers: headers })
    // return this.http.get('https://tv.rdsconn.com/tv.php?id=' + filter)
      .pipe(tap(_ => this.log(`get live tv`)));
  }

  /**
   *@author TR
   * @date 31-01-2020
   * @param filter
   * @returns {Observable<any>}
   * get all Match Odds for Sidebar
   */
  getAllMarketForSidebar(filter): Observable<any> {

    let query = '?page=' + filter.page +   '&limit=' + filter.limit + '&userId=' + filter.userId;

    return this.http.get(this.adminServer_url + 'market/sidebar' + query)
      .pipe(tap(_ => this.log(`get Market successfully`)));
  }

  /**
   *@author kc
   * @date 26-02-2020
   * @param filter
   * @returns {Observable<any>}
   * get getMarketById
   */
  getMarketById(filter ,userId): Observable<any> {
    let query =  filter +  '?userId=' + userId;
    return this.http.get(this.adminServer_url + 'market/creative/'+ query)
      .pipe(tap(_ => this.log(`get By MarketId successfully`)));
  }


  /**
   *@author rk
   * @date 26-02-2020
   * @param filter
   * @returns {Observable<any>}
   * get all transaction
   */

  getAllTransactions(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'placebet/get-all-transactions-creative', data)
      .pipe(tap());
  }

  /**
   *@author rk
   * @date 26-02-2020
   * @param filter
   * @returns {Observable<any>}
   * get all transaction
   */

  getAllTransactionsCasino(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'placebet/transactionsCasino', data)
      .pipe(tap());
  }


  /**
   *@author subhash
   * @date 12-02-2020
   * @param filter
   * @returns {Observable<any>}
   * get all transaction
   */

  getAllTransactionsAsMatch(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'placebet/get-all-transactions/fancy', data)
      .pipe(tap());
  }

  getAllTransactionsAsLine(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'placebet/get-all-transactions/line', data)
      .pipe(tap());
  }

  getAllTransactionsAsMatchOdds(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'placebet/get-all-transactions/matchdds', data)
      .pipe(tap());
  }


  /**
   *@author kc
   * @date 26-02-2020
   * @param filter
   * @returns {Observable<any>}
   * get getMarketById
   */
  getAllSport(): Observable<any> {
    return this.http.get(this.adminServer_url + 'sport')
      .pipe(tap(_ => this.log(`get All Sport successfully`)));
  }
  /**
   *@author kc
   * @date 26-02-2020
   * @param filter
   * @returns {Observable<any>}
   * get getMarketById
   */
  getAllMatch(filter): Observable<any> {
    return this.http.get(this.adminServer_url + 'match/sport/' + filter)
      .pipe(tap(_ => this.log(`get All Sport successfully`)));
  }

  /**
   *@author kc
   * @date 26-02-2020
   * @param filter
   * @returns {Observable<any>}
   * get getMarketById
   */
  getAllMatchInMarket(): Observable<any> {
    return this.http.get(this.adminServer_url + 'market/getAllMatch')
      .pipe(tap(_ => this.log(`get All match successfully`)));
  }

  /**
   *@author kc
   * @date 26-02-2020
   * @param filter
   * @returns {Observable<any>}
   * get getMarketById
   */
  allimage(): Observable<any> {
    return this.http.get(this.adminServer_url + 'market/getAllimages')
      .pipe(tap(_ => this.log(`get All match successfully`)));
  }
  /**
   *@author subhash
   * @date 21-07-2020
   */

  getMarketApi(): Observable<any> {
    let headers = new HttpHeaders({ 'Authorization': this.utilityService.returnLocalStorageData('token') });
    return this.http.get(this.server_url + 'marketapi/get',{ headers: headers })
      . pipe(tap(_ => this.log(`get Market Api successfully`)));
  }

  /**
   *@author subhash
   * @date 21-07-2020
   */
  getMarketApicall(url,id): Observable<any> {
    return this.http.get(url + id)
      . pipe(tap(_ => this.log(`get Market Api successfully`)));
  }

  /**
   *@author TR
   * @date 04/01/2021
   */
  getLayoutSetting(): Observable<any> {
    return this.http.get(this.adminServer_url + 'webhook/layout')
      .pipe(tap(_ => this.log(`get layout Setting`)));
  }

  getBookmakerRateRedis(marketId): Observable<any> {
    var data = {
      marketId: marketId
    }
    return this.http.post(this.adminServer_url + 'market/get-bookmaker-redis',data)
      . pipe(tap(_ => this.log(`get Market Api successfully`)));
  }

  getMatchListSerch(data):Observable<any>{
    return this.http.post(this.adminServer_url + 'market/getMatch', data)
      .pipe(tap(_ => this.log(`get match successfully`)));
  }


  /**
   *@author TR
   * @date 24/05/2021
   */
  liveStrimEvent(data):Observable<any>{
    return this.http.post(this.adminServer_url + 'market/getEventData', data)
      .pipe(tap(_ => this.log(`get match successfully`)));
  }

  getCasinoData():Observable<any>{
    return this.http.get(this.adminServer_url + 'market/getCasinoData/')
      .pipe(tap(_ => this.log(`get match successfully`)));
  }

  getInitialRate(): Observable<any> {
    return this.http.get(this.adminServer_url + 'market/get-dummy-rate')
  }

  getInitialRateMarket(): Observable<any> {
    return this.http.get(this.adminServer_url + 'market/market-dummy-rate')
  }

  getQrCode(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/get-qr-code', data)
      .pipe(tap(_ => this.log(`get qr code successfully`)));
  }
  checkQrCode(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/check-qr-code', data)
      .pipe(tap(_ => this.log(`cehck qr code successfully`)));
  }
  verifyQrCode(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/verify-qr-code', data)
      .pipe(tap(_ => this.log(`get match successfully`)));
  }
  verifyQrCodeFirst(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/verify-qr-code-first', data)
      .pipe(tap(_ => this.log(`get match successfully`)));
  }
  verifyQrCodeLogin(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/verify-qr-code', data)
      .pipe(tap(_ => this.log(`get match successfully`)));
  }
  updateAuthSetting(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/update-auth-setting', data)
      .pipe(tap(_ => this.log(`update auth setting successfully`)));
  }
  updateVerifyUser(data): Observable<any> {
    return this.http.post(this.adminServer_url + 'market/update-verify-user', data)
      .pipe(tap(_ => this.log(`get match successfully`)));
  }

  log(message) {
    console.log(message);
  }


}
