import {Component, OnDestroy, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-aura-view',
  templateUrl: './aura-view.component.html',
  styleUrls: ['./aura-view.component.scss']
})
export class AuraViewComponent implements OnInit , OnDestroy {

  constructor( private route: ActivatedRoute,) { }

  ngOnInit() {
    $('.notice-main-new').css('display', 'none');

    this.route.params.subscribe(params => {
     if(params.id){
      let wdh = $(window).innerWidth();
      let ite = localStorage.getItem('token');
      ite = ite
      let operaterId =  9560 ;
      let url1;
      if(wdh > 992){
        url1 = 'https://d2.fawk.app/#/splash-screen/'+ ite + '/'+ operaterId +'?opentable=' + params.id + '&refresh=1234' ;
      }else{
        url1 = 'https://m2.fawk.app/#/splash-screen/'+ ite + '/'+ operaterId +'?opentable=' + params.id + '&refresh=1234' ;
      }
      $("#loadIframe1").html("<iframe src='" + url1 + "' width=100% height=1000px></iframe>");
     }else{
       $('.notice-main-new').css('display', 'none');
       let ite = localStorage.getItem('token');
       ite = ite
       let wdh = $(window).innerWidth();
       let url;
       if(wdh > 992){
         url = 'https://d2.fawk.app/#/splash-screen/'+ ite +'/'+9560;
       }else{
         url = 'https://m2.fawk.app/#/splash-screen/'+ ite +'/'+9560;
       }
       $("#loadIframe1").html("<iframe src='" + url + "' width=100% height=1000px></iframe>");
     }
    })

  }
  ngOnDestroy(){
    $('.notice-main-new').css('display', 'block');
  }

}

