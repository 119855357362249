import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if(!items) return [];

    if(!searchText) return items;

    return this.searchItems(items, searchText.toLowerCase());
  }

  private searchItems(items :any[], searchText): any[] {
    let results = [];
    items.forEach(it => {
      if (it.matchNm.toLowerCase().includes(searchText)) {
        results.push(it);
      }
      if(it.gameType){
      if ( it.gameType === 'market' ? it.team[0].runnerName.toLowerCase().includes(searchText) : it.gameType === 'line' ? it.gameDetail[0].lineName.toLowerCase().includes(searchText) : it.gameType === 'bookmaker' ? it.team[0].runnerName.toLowerCase().includes(searchText) : it.gameType === 'Winner' ? it.team[0].runnerName.toLowerCase().includes(searchText) : it.gameDetail[0].fancyName.toLowerCase().includes(searchText)) {
        results.push(it);
      }
      }
    });
    return results;
  }
}
