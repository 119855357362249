import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SportsComponent } from './sports.component';
import { ListViewComponent } from './list-view/list-view.component';
import { CardViewComponent } from './card-view/card-view.component';
import { AuraViewComponent } from './aura-view/aura-view.component';
import { GameViewComponent } from './game-view/game-view.component';
import {AuthGuard} from "../auth-gaurd/auth-guard.service";
import {CasinoViewComponent} from './casino-view/casino-view.component';
// import {MultiMarketComponent} from './multi-market/multi-market.component';
import {CasonoDashboardComponent} from './casono-dashboard/casono-dashboard.component';


const routes: Routes = [{
  path: 'sports',
  component: SportsComponent,
  children: [
      {
          path: 'view',
        canActivate: [AuthGuard],
          component: ListViewComponent,
      },
      {
        path: 'card-view',
        canActivate: [AuthGuard],
        component: CardViewComponent,
      },
    {
        path: 'aura-view',
        canActivate: [AuthGuard],
        component: AuraViewComponent,
      },
    {
        path: 'aura-view/:id',
        canActivate: [AuthGuard],
        component: AuraViewComponent,
      },
      {
        path: 'game-view/:id',
        canActivate: [AuthGuard],
        component: GameViewComponent,
    },
    // {
    //     path: 'multi-market',
    //     canActivate: [AuthGuard],
    //     component: MultiMarketComponent,
    // },
    {
        path: 'casino-dash',
        canActivate: [AuthGuard],
        component: CasonoDashboardComponent,
    },
    {
        path: 'casino-view/:id',
        canActivate: [AuthGuard],
        component: CasinoViewComponent,
    },
      {
        path: 'event-view',
        canActivate: [AuthGuard],
        component: ListViewComponent,
    }
  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SportsRoutingModule { }
