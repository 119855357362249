import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient,HttpHeaders} from '@angular/common/http';

import {tap} from 'rxjs/internal/operators';
import {isUndefined} from 'util';
import * as env from '../globals/env';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  server_url: any = env.adminServer_url();
  office_url: any = env.office_url();


  constructor(private http: HttpClient) { }

    /**
   *@author Ravi Kadia
   * @date 09-01-2020
   * @param filter
   * @returns {Observable<any>}
   * add new user
   */

  loginApi(data): Observable<any> {
    return this.http.post(this.server_url + 'user/creative/login' , data)
      .pipe(tap(_ => this.log(`user login successfully`)));
  }

  loginApiDemo(data): Observable<any> {
    return this.http.post(this.server_url + 'user/creative/login/demo' , data)
      .pipe(tap(_ => this.log(`user login successfully`)));
  }


  registerApi(data): Observable<any> {
    return this.http.post(this.server_url + 'user/register' , data)
      .pipe(tap(_ => this.log(`user register successfully`)));
  }

  depositeApi(data): Observable<any> {
    return this.http.post(this.server_url + 'user/deposite' , data)
      .pipe(tap(_ => this.log(`user register successfully`)));
  }
  getDepositeReport (data): Observable<any> {
    return this.http.post(this.server_url + 'user/depositeReport' , data)
    .pipe(tap(_ => this.log(`deposite report successfully`)));
  }
  getWithdrawReport (data): Observable<any> {
    return this.http.post(this.server_url + 'user/withdrawReport' , data)
    .pipe(tap(_ => this.log(`deposite report successfully`)));
  }

  withdrawApi(data): Observable<any> {
    return this.http.post(this.server_url + 'user/withdraw' , data)
      .pipe(tap(_ => this.log(`user register successfully`)));
  }

  verifyOtp(data): Observable<any> {
    return this.http.post(this.server_url + 'user/verifyOtp' , data)
      .pipe(tap(_ => this.log(`user register successfully`)));
  }

  resendOtp(data): Observable<any> {
    return this.http.post(this.server_url + 'user/resendOtp' , data)
      .pipe(tap(_ => this.log(`user register successfully`)));
  }

  newUser(data):Observable<any>{
    console.log("data",this.server_url + 'user/new-user/change/password',data);

    return this.http.post(this.server_url + 'user/new-user/change/password',data)
      .pipe(tap(_ => this.log(`new-user changepassword`)));
  }

      /**
   *@author Ravi Kadia
   * @date 09-01-2020
   * @param filter
   * @returns {Observable<any>}
   * get balance
   */

  getBalance(data): Observable<any> {
    let headers = new HttpHeaders({'Method-Name': "balance"});
    return this.http.post(this.server_url + 'user/getBalance/',data,{headers : headers})
      .pipe(tap(_ => this.log(`get balance`)));

  }

  /**
   *@author Rt
   * @date 09-11-2023
   * @param filter
   * @returns {Observable<any>}
   * get balance
   */

  getBalanceClient(data): Observable<any> {
    let headers = new HttpHeaders({'Method-Name': "balance"});
    return this.http.post(this.server_url + 'user/getBalanceClient/',data,{headers : headers})
      .pipe(tap(_ => this.log(`get balance`)));

  }

  /**
   *@author Ravi Kadia
   * @date 09-01-2020
   * @param filter
   * @returns {Observable<any>}
   * get balance
   */

  updateTheme(data): Observable<any> {
    return this.http.post(this.server_url + 'user/updateTheme', data)
      .pipe(tap(_ => this.log(`update theme`)));

  }

  /**
   * @author TR
   * @date : 28/04
   * user logout function
   */


  logOut() :Observable<any>{
    return this.http.get(this.server_url + 'user/logout')
      .pipe(tap(_ => this.log(` user logout successfully`)));
  }

  /**
   * @author TR
   * @date : 24/06
   * user password change
   */


  passwordChange(data) :Observable<any>{
    delete data.confNewPassword;
    return this.http.put(this.server_url + 'user/resetClientPassword/' + data.userId, data)
      .pipe(tap(_ => this.log(` user password update successfully`)));
  }




  /**
   *@author TR
   * @date 01-07-2020
   * @param filter
   * @returns {Observable<any>}
   * get account summary report
   */

  getAllTransactionByGame(filter): Observable<any> {
    filter.userType = 'CLIENT';
    console.log("filter",filter);
    return this.http.post(this.server_url + 'placebet/get-all-transactions/betlist' , filter)
      .pipe(tap(_ => this.log(`User transaction successfully`)));
  }

  /**
   *@author TR
   * @date 16-07-2020
   * @param filter
   * @returns {Observable<any>}
   * get account summary report
   */

  getAllTransaction(filter): Observable<any> {

    return this.http.post(this.server_url + 'placebet/get-all-transactions-sport' , filter)
      .pipe(tap(_ => this.log(`User transaction successfully`)));
  }

  getBalanceUpdated(id): Observable<any> {
    return this.http.get(this.server_url + 'user/getBalance-update/' + id)
      .pipe(tap(_ => this.log(`User transaction successfully`)));
  }

  getUserById(id): Observable<any> {
    return this.http.get(this.server_url + 'user/getUserData/' + id)
      .pipe(tap(_ => this.log(`User transaction successfully`)));
  }

  storeStudioData(id,token): Observable<any> {
    return this.http.get(this.server_url + 'user/store-user-data/' + id + "/" + token)
      .pipe(tap(_ => this.log(`User transaction successfully`)));
  }

  storeEzugiData(id,token): Observable<any> {
    return this.http.get(this.server_url + 'user/store-ezugi-data/' + id + "/" + token)
      .pipe(tap(_ => this.log(`User transaction successfully`)));
  }

  /**
   //subhash
   */

  getOpeningBalance(filter): Observable<any> {
    return this.http.post(this.server_url + 'chipscash/opening-balace' , filter)
      .pipe(tap(_ => this.log(`get User Opening Balace`)));
  }

  welcomeMassage(): Observable<any> {
    return this.http.get(this.server_url + 'white-lable-welcome-massage')
      .pipe(tap(_ => this.log(`White Lable Welcome Massage`)));
  }



  getPasswordOwn(data) :Observable<any>{
    return this.http.post(this.server_url + 'user/getPasswordOwn', data)
    .pipe(tap(_ => this.log(`check user successfully`)));
  }


  getLoginToken(data): Observable<any> {
    const headers = new HttpHeaders({
                            'Access-Control-Allow-Origin': '*'
                         });
      return this.http.post(this.server_url + 'bbMarket/token' , data,{headers : headers})
      .pipe(tap(_ => this.log(`User sport successfully`)));
  }

  getNewToken(): Observable<any> {
    let obj = {
      grant_type :  'refresh_token',
      refresh_token :  localStorage.getItem('refresh_token')
    }
    console.log(obj);

     const headers = new HttpHeaders({
                            'Access-Control-Allow-Origin': '*'
                         });
      return this.http.post(this.server_url + 'bbMarket/get-new-token' , obj,{headers : headers})
      .pipe(tap(_ => this.log(`User sport successfully`)));

  }


  stockLogin(data): Observable<any> {
    const headers = new HttpHeaders({
                            'Access-Control-Allow-Origin': '*'
                         });
        return this.http.post(this.server_url + 'bbMarket/stockLogin' , data,{headers : headers})
        .pipe(tap(_ => this.log(`User sport successfully`)));
  }

  log(message) {
    console.log(message);
  }
}
