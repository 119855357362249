import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { TvComponent } from './tv/tv.component';
import { AuthGuard } from './auth-gaurd/auth-guard.service';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthenticComponent} from './authentic/authentic.component';

const routes: Routes = [
  { path : '', component:LoginComponent },
  { path : 'login', component:LoginComponent },
  { path : 'tv/:id', component:TvComponent },
  { path : 'dashboard', component:DashboardComponent , canActivate: [AuthGuard] },
  { path : 'dashboard/:type', component:DashboardComponent , canActivate: [AuthGuard] }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
