import { Component, OnInit } from '@angular/core';
import {CurrencyService} from '../../services/currency.service';
import {UtilityService} from '../../globals/utilityService';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {CommonService} from '../../services/common.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  oldPassword: any;
  newPassword: any;
  confNewPassword: any;
  showPassword = false;
  showPassword1 = false;
  showPassword2 = false;
  constructor(
    private currencyService: CurrencyService,
    private utilityService: UtilityService,
    private userService: UserService,
    private router: Router,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
  }

  passChange() {
    let stockUserId = this.utilityService.returnLocalStorageData('stockUserId')
    if(stockUserId ==  false){
      let data = {
        oldPass: this.utilityService.returnEncrypt(this.oldPassword),
        newPass: this.utilityService.returnEncrypt(this.newPassword),
        cinfNewPass: this.utilityService.returnEncrypt(this.confNewPassword),
        userId: this.utilityService.returnLocalStorageData('userId'),
        stockUserId : stockUserId
      };
      this.userService.passwordChange(data).subscribe(response => {
        if (response.status === true) {
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(['/login']);
        } else {
          this.commonService.popToast('error', 'Error', 1500, 'password does not match.');
        }
      }, error => {
        this.commonService.popToast('error', 'Error', 1500, 'password does not match.');
        console.error("error in logout");
      });
    }else{
      this.userService.getNewToken().subscribe(responseToken => {
        if(responseToken.status == true){
          let refreshToken = responseToken.data.refresh_token;
          this.utilityService.storeRefreshToken(refreshToken);
          let data = {
            oldPass: this.utilityService.returnEncrypt(this.oldPassword),
            newPass: this.utilityService.returnEncrypt(this.newPassword),
            cinfNewPass: this.utilityService.returnEncrypt(this.confNewPassword),
            userId: this.utilityService.returnLocalStorageData('userId'),
            stockUserId : stockUserId,
            accessToken : responseToken.data.access_token
          };
          this.userService.passwordChange(data).subscribe(response => {
            if (response.status === true) {
              localStorage.clear();
              sessionStorage.clear();
              this.router.navigate(['/login']);
            } else {
              this.commonService.popToast('error', 'Error', 1500, 'password does not match.');
            }
          }, error => {
            this.commonService.popToast('error', 'Error', 1500, 'password does not match.');
            console.error("error in logout");
          });
        }
      })
    }

  }
  showPass(){
    this.showPassword = (this.showPassword === true) ?  false : true;
  }
  showPass1(){
    this.showPassword1 = (this.showPassword1 === true) ?  false : true;
  }
  showPass2(){
    this.showPassword2 = (this.showPassword2 === true) ?  false : true;
  }
}
