import {Component, OnInit, ViewChild} from '@angular/core';
import { StudioService } from '../services/studio.service';
import {UtilityService} from '../globals/utilityService';
import {ActivatedRoute, Router,NavigationEnd} from '@angular/router';
import {ModalDirective} from 'ngx-bootstrap';

import * as $ from 'jquery';
declare let _: any;
@Component({
  selector: 'app-dream-casino',
  templateUrl: './dream-casino.component.html',
  styleUrls: ['./dream-casino.component.scss']
})
export class DreamCasinoComponent implements OnInit {
  @ViewChild('instructionModal', { static: false }) instructionModal: ModalDirective;
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "autoplay": true,  "vertical": false , arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studioService: StudioService,
    private utilityService: UtilityService
  ) { }
  gameList;
  groupList;
  groupLists;
  product = ['Evolution Gaming', 'eBET' ,'Super Spade Games', 'Pragmatic Play Live', 'Asia Gaming', 'Evoplay Entertainment', 'Betsoft', 'Ezugi'];
  gameLists;
  urlAura;
  userId = this.utilityService.returnLocalStorageData('userId');
  finalList = [];
  ngOnInit() {
    let datauser = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
    if(datauser.userType == 'CLIENT'){

    }else{
      this.router.navigate(['/login']);
    }
    this.getCasinoList();
  }
  closeModal(){
    this.instructionModal.hide();
  }
  getCasinoList(){
    let data = {
      product : this.product
    }
    let items = localStorage.getItem("casinogames");
    items = JSON.parse(items)
    this.gameLists =  items
    this.gameList = items;
    this.groupList = _.filter(this.gameLists , function (e) {
      if(e.provider_name == 'EZUGI'){
        return e;
      }
    })
    this.finalList = this.gameList;
    // this.studioService.getGameList(data).subscribe(response => {
    //   if(response.status == true){

    //   }else{
    //     this.gameList = [];
    //   }
    // });
  }
  onSelect(item){
    if(item === 'all'){
      this.gameList = this.gameLists;
    }else {
      this.gameList = item.category;
    }
  }

  popular(data){
    this.groupLists = _.filter(data, function(p){
      let checktrue = _.includes([3884,3897,3900,3906,3920,2071,2072,2082,2093,2099,2113,2139,2140,2144,2149,2151,2152,2154, 2156,2157, 2899,3935, 3947, 8508, 9754, 628,629, 630, 631, 633, 771, 816, 833, 1677,8984,7185, 430, 434, 1832, 1839, 2643, 2648, 2652, 2659, 2662, 2663, 2667, 6506, 6507, 6508,6509,6510, 6511, 6512,6513, 6514, 6515, 7171, 7173,7175, 7177, 7179, 7181, 7183, 7187, 7189,7191, 7193, 9511,9648, 9708, 10641, 10643, 10645, 10729, 11548, 11564, 11598, 12332, 12333, 12834, 13216], p.game_id);
      if(checktrue === true){
        return p;
      }
    });
    this.gameList = this.groupLists;
  }

  gameUrl(data){
    this.router.navigateByUrl('/dc/game-lobby/' + data);
    // let game_id = data.game_id;
    // let params = {
    //   game_id : data.game_id,
    //   user : this.userId,
    //   platform : 'GPL_DESKTOP',
    //   lobby_url : "https://crown777.games/dashboard",
    // }
    // this.studioService.getUrl(params).subscribe(response => {
    //   console.log(response);
    // });
  }


  gameL(item){
    this.groupList = _.filter(this.gameList , function (e) {
      if(e.provider_name == item){
        return e;
      }
    })
    this.groupList = _.reverse(this.groupList)
  }

  searchgame(event){
    const lowerSearchTerm = event.toLowerCase(); // Convert search term to lowercase
    const searchResults = this.gameList.filter(item => item['game_name'].toString().toLowerCase().includes(lowerSearchTerm));
   // this.gameList.filter(item =>{
   //   if(item){
   //     // console.log('item++++++',item['game_name'].toString());
   //     console.log('item++++++++',item['game_name'].toString().toLowerCase());
   //   }
   // })
   //  console.log('searchResults+++++++',searchResults);
    if(event != ""){
     this.groupList =  searchResults;
    }else{
     this.groupList = _.filter(this.gameLists , function (e) {
       if(e.sub_provider_name == 'Ezugi'){
         return e;
       }
     })
    }
}

}
