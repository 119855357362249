import { Component, OnInit } from '@angular/core';
import {CurrencyService} from '../../services/currency.service';
import {CommonService} from '../../services/common.service';
import {UtilityService} from '../../globals/utilityService';
import {PlacebetService} from '../../services/placebet.service';
import { Router} from '@angular/router';

@Component({
  selector: 'app-exposer-details',
  templateUrl: './exposer-details.component.html',
  styleUrls: ['./exposer-details.component.scss']
})
export class ExposerDetailsComponent implements OnInit {
  exposureData: any;
  userLoginId:any;
  searchText: any;
  constructor(
    private currencyService: CurrencyService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private placebetService: PlacebetService,
    private router: Router,
  ) { }

  ngOnInit() {
    let users = this.commonService.getLocalStorage();
    this.userLoginId = users.userId;
    this.exploserData();
  }
  exploserData(){
    let data = {
      userId :this.userLoginId,
      userType : 'CLIENT'
    }
    this.placebetService.getExploserMatchData(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if (response.status === true) {
        console.log( response.data)
        this.exposureData = response.data;
        console.log('this.exposureData+++++',this.exposureData);
      } else {

      }
    }, error => {
      this.commonService.popToast('error', 'Error', 1500, 'not found.');
    });

  }
  redirection(data){
    if(data.marketType === 'casinoLive'){
      this.router.navigateByUrl('/sports/casino-view/' + data.eventId);
    } else {
      this.router.navigateByUrl('/sports/game-view/' + data.marketId);
    }
  }
}
