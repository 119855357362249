import { Component, OnInit } from '@angular/core';
import { StudioService } from '../../services/studio.service';
import {UtilityService} from '../../globals/utilityService';
import {ActivatedRoute, Router,NavigationEnd} from '@angular/router';
import filterjson from '../../globals/AllList.json'
declare let _: any;

@Component({
  selector: 'app-dream-casino-view',
  templateUrl: './dream-casino-view.component.html',
  styleUrls: ['./dream-casino-view.component.scss']
})
export class DreamCasinoViewComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studioService: StudioService,
    private utilityService: UtilityService
  ) { }
  userId = this.utilityService.returnLocalStorageData('userId');
   userData = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
  errLobby = false;
  url;
  betGames = ['6509','6510','6511','6512','6513','6514','6515','11598','12834'];

  onetuch = [
    '9511','9512','9513','9514','9515','9516','9765','10729','11421','11422','11423','11424','11425','11426','11427','12511','12512','12513','12514','12515','12516','12662','12663',
  ]

  ezugi = [
    "3897","3898","3899","3900","3901","3902","3905","3906","3907","3908","3909","3910","3911","3912","3913","3914","3915","3916",
    "3917","3918","3919","3920","3921","3922","3923","3924","3925","3926","3927","3928","3929","3997","3998","3999","4001","4002",
    "4089","4090", "6487","6602","6943","7310","8078","8625","8627","9748","9750","9752","10354","10356","10358","10365","10369","10677",
    "11010","11012","11640","12808","12812","13932"
    ]
  ngOnInit() {
    this.route.params.subscribe(params => {
      if(params){
        console.log('params.id',params.id);
        if(params.id == "200296"){

        }
        this.getUrl(params.id)
      }else{
        this.router.navigateByUrl('/login');
      }
    });
  }

  // getUrl(game_id){
  //   let partnerId;
  //   // if(_.includes(this.ezugi,String(game_id))){
  //   //     partnerId = "samudrap";
  //   // }else{
  //   //     partnerId = "samudrap";
  //   // }

  //   if(_.includes(this.ezugi,String(game_id))){
  //     partnerId = "samudra";
  // }else{
  //     partnerId = "samudrain";
  // }

  //   let params = {
  //     game_id : Number(game_id),
  //     user : this.userId,
  //     platform : 'GPL_DESKTOP',
  //     lobby_url : window.location.href,
  //     userName  : this.userData.userName,
  //     partnerId : partnerId
  //   }
  //   console.log(params);
  //   this.studioService.getUrl(params).subscribe(response => {
  //       this.errLobby  =false;
  //       this.url = response.url;
  //       $("#loadIframe").html("<iframe src='" + this.url + "' width=100% height=1000px></iframe>");
  //     },err => {
  //       this.errLobby = true;
  //   });
  // }
  getUrl(game_id){
    let jsonItm = this.getJsonData()
    let operatorId = "samudrad";
    let findGame = jsonItm.find(itm => itm.game_id == game_id);
    if(findGame){
      console.log('findGame',findGame);
      let params = {}
      if(findGame['casinoType'] && findGame['casinoType'] == "HKD"){
        params = {
          operatorId : 'samudrad',
          "providerName": findGame['provider_name'],
          "gameId": game_id,
          "userId": this.userId,
          "username":  this.userData.userName,
          "platformId": "desktop",
          "currency": "HKD",
          "lobby":true,
          "casinoType": "HKD"
       }
      }else{
        params = {
          operatorId : 'samudra',
          "providerName": findGame['provider_name'],
          "gameId": game_id,
          "userId": this.userId,
          "username":  this.userData.userName,
          "platformId": "desktop",
          "currency": "INR",
          "lobby":true,
          "casinoType": "INR"
       }
      }
      console.log('params',params);
      console.log(params);
      this.studioService.getUrl(params).subscribe(response => {
          this.errLobby  =false;
          this.url = response.url;
          $("#loadIframe").html("<iframe src='" + this.url + "' width=100% height=1000px></iframe>");
        },err => {
          this.errLobby = true;
      });
    }else{
      $("#loadIframe").html("<iframe srcdoc='<div>This is game not found.</div>' width=100% height=1000px></iframe>");
    }

  }

  getJsonData(): any {
    return filterjson;
  }

}
