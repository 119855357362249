import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-tv',
  templateUrl: './tv.component.html',
  styleUrls: ['./tv.component.scss']
})
export class TvComponent implements OnInit {

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) { }
  tvId:any;
  iframeUrl:SafeResourceUrl;
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.tvId = params['id'];
      // Sanitize the URL for the iframe to prevent security issues
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://5por-tt1.top/getscore.php?chid=${this.tvId}`);
    })
  }

}
