import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {tap} from 'rxjs/internal/operators';
import {isUndefined} from 'util';
import * as env from '../globals/env';
import {ToasterService} from "angular2-toaster";
import {UtilityService} from '../globals/utilityService';

@Injectable({
  providedIn: 'root'
})
export class BinaryService {
  private headers = new HttpHeaders({'Authorization' : 'Bearer ' + this.utilityService.returnLocalStorageData('token')});
  private toasterService: ToasterService;

  server_url: any = env.adminServer_url();
  stock_url: any = env.stockApi();

  constructor(private http: HttpClient, private utilityService:UtilityService
              ) { }

  /**
   *@author TR
   * @date 20-07-2020
   * @param filter
   * @returns {Observable<any>}
   * placebet market
   */
  placeBet(item): Observable<any> {
    return this.http.post(this.server_url + 'sharemarket/placeorder', item)
      .pipe(tap(_ => this.log(`place order successfully`)));
  }

  placeBetExternal (data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
      'stock' : 'true'
     });
     let accessToken = data.accessToken;
     let paramsData = {
        url : this.stock_url + 'api/MobileApp/PlaceOrder',
        Authorization :  'Bearer ' + accessToken,
        data : data
      }
      delete data.accessToken;

    return this.http.post(this.stock_url + 'api/MobileApp/PlaceOrder' ,data,{headers : headers})
    .pipe(tap(_ => this.log(`placebet successfully`)));

  }
  getGenerateBill (data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
      'stock' : 'true'
    });
    delete data.accessToken;
    return this.http.post(this.stock_url + 'api/User/GenerateUserRpt' ,data,{headers : headers})
      .pipe(tap(_ => this.log(`get assign successfully`)));
  }



  deleteTransactionById(filter): Observable<any> {
    let data = {
      transactionId: filter._id
    }
    return this.http.post(this.server_url + 'sharemarket/deleteTransaction' , data)
      .pipe(tap(_ => this.log(`delete portfolio successfully`)));
  }

  userList (data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
      'stock' : 'true'
     });

     let paramsData = {
        url : this.stock_url + 'api/MobileApp/UserList?UserId=' + data.UserId,
        Authorization :  'Bearer ' + data.accessToken,
        data : {}
      }
      delete data.accessToken;

    return this.http.post(this.stock_url + 'api/MobileApp/UserList?UserId=' + data.UserId ,{},{headers : headers})
    .pipe(tap(_ => this.log(`get assign successfully`)));
  }



  /**
   *@author TR
   * @date 04-08-2020
   * @param filter
   * @returns {Observable<any>}
   * Get all Open History
   */
  getAllOpenPosition(data): Observable<any> {
    return this.http.post(this.server_url + 'sharemarket/get/clientOpenPosition', data)
      .pipe(tap(_ => this.log(`Get net position successfully`)));
  }




  getPortfolios(data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
     });

     let paramsData = {
        url : this.stock_url + 'api/MobileApp/getPortfolios?UserId=' + data.stockUserId,
        Authorization :  'Bearer ' + data.accessToken
     }
    // dot net server url
     return this.http.get(this.stock_url + 'api/MobileApp/getPortfolios?UserId=' + data.stockUserId ,{headers : headers})
    .pipe(tap(_ => this.log(`get portfollio successfully`)));
  }


  getMyExchnage(data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
      'stock' : 'true'
     });

     let paramsData = {
      url : this.stock_url + 'api/MobileApp/ExchangeList?UserId=' + data.stockUserId,
      Authorization :  'Bearer ' + data.accessToken,
      data : {}
   }

    return this.http.post(this.stock_url + 'api/MobileApp/ExchangeList?UserId=' + data.stockUserId ,{},{headers : headers})
    .pipe(tap(_ => this.log(`get my exchnage successfully`)));
  }

  assignSingleScript (data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
      'stock' : 'true'
     });

     let paramsData = {
      url : this.stock_url + 'api/MobileApp/assignSingleScript',
      Authorization :  'Bearer ' + data.accessToken,
      data : data
   }
   delete data.accessToken;
    return this.http.post(this.stock_url + 'api/MobileApp/assignSingleScript' ,data,{headers : headers})
    .pipe(tap(_ => this.log(`get assign successfully`)));
  }

  orderTypeList (data): Observable<any> {
    console.log(data);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
      'stock' : 'true'
     });


     let paramsData = {
        url : this.stock_url + 'api/MobileApp/OrderTypeList?ScriptID=' + data.ScriptId,
        Authorization :  'Bearer ' + data.accessToken,
        data : {}
     }
       delete data.accessToken;
    return this.http.post(this.stock_url + 'api/MobileApp/OrderTypeList?ScriptID=' + data.ScriptId + '&UserId=' + data.UserId ,{},{headers : headers})
    .pipe(tap(_ => this.log(`get OrderTypeList successfully`)));
  }


  DeletePendingReport (data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
      'stock' : 'true'
     });

     let paramsData = {
        url : this.stock_url + 'api/MobileApp/DeletePendingReport?UserId=' + data.UserId + '&OrderId=' + data.OrderId + '&OrderStatus=' + data.OrderStatus,
        Authorization :  'Bearer ' + data.accessToken,
        data : {}
      }

     //delete data.accessToken;
    return this.http.post(this.stock_url + 'api/MobileApp/DeletePendingReport?UserId=' + data.UserId + '&OrderId=' + data.OrderId + '&OrderStatus=' + data.OrderStatus, {},{headers : headers})
    .pipe(tap(_ => this.log(`get OrderTypeList successfully`)));
  }


  marketWatchDetails (data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
      'stock' : 'true'
     });

     let paramsData = {
      url : this.stock_url + 'api/MobileApp/MarketWatchDetails?ScriptID=' + data.ScriptId + '&LoginUserId=' + data.userId,
      Authorization :  'Bearer ' + data.accessToken,
      data : {}
    }
    delete data.accessToken;


    return this.http.post(this.stock_url + 'api/MobileApp/MarketWatchDetails?ScriptID=' + data.ScriptId + '&LoginUserId=' + data.userId ,{},{headers : headers})
    .pipe(tap(_ => this.log(`get OrderTypeList successfully`)));
  }



  removeSingleScript (data): Observable<any> {
    console.log(data);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
      'stock' : 'true'
     });

    let paramsData = {
      url : this.stock_url + 'api/MobileApp/RemoveSingleScript',
      Authorization :  'Bearer ' + data.accessToken,
      data : data
    }
     delete data.accessToken;

    console.log(data);

    return this.http.post(this.stock_url + 'api/MobileApp/RemoveSingleScript' ,data,{headers : headers})
    .pipe(tap(_ => this.log(`get assign successfully`)));
  }

  scriptList(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
     });
     return this.http.post(this.stock_url + 'api/MobileApp/ScriptList?ExchangeID=0' ,{},{headers : headers})
    .pipe(tap(_ => this.log(`get exchange list successfully`)));
  }



  getCashSettlement (data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
      'stock' : 'true'
    });
    delete data.accessToken;
    return this.http.post(this.stock_url + 'api/MobileApp/GetUserCashSettlement' ,data,{headers : headers})
      .pipe(tap(_ => this.log(`get assign successfully`)));
  }
  getAccountStatementSummary (data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data.accessToken,
      'stock' : 'true'
    });
    delete data.accessToken;
    return this.http.post(this.stock_url + 'api/MobileApp/GetAccountStatementSummary' ,data,{headers : headers})
      .pipe(tap(_ => this.log(`get assign successfully`)));
  }



  log(message) {
    // console.log(message);
  }
}
