import { Component, OnInit, Inject, Injectable } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import * as io from 'socket.io-client';
import { Observable} from 'rxjs';
import * as env from '../globals/env';

declare var $: any;
declare var _: any;
@Injectable()

export class SocketService{
  private url =  env.socket_url();
  private prefix =  env.socketPrefix();

  private socket;

  constructor(@Inject(DOCUMENT)
              private document: any,
              private router: Router,
              private location: Location
  ) {
    this.socket = io(this.url,{
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 1000,
      reconnectionAttempts: Infinity,
      transports: ['websocket', 'polling', 'flashsocket']});

    this.socket.on('disconnect', () => {
      console.log("disconnect socket");
    })
    this.socket.on('reconnect', () => {
      // let roomIs = this.utilityService.returnLocalStorageData('userId');
      let roomIs = sessionStorage.getItem('lastJoinRoom');
      let room = JSON.parse(roomIs);
      if (roomIs !== null) {
        let socket = this.socket;

        _.map(room, function(res) {
          socket.emit('join-room', res.toString());
        }, this);
      }
    });
  }

  //Join room function
  joinRoom(roomId){
    this.socket.emit('join-room', roomId);
  }


  //leave the room
  leaveRoom(roomId){
    // console.log(roomId);
    this.socket.emit('leave-room', roomId);
  }

  disconnect(){
    this.socket.disconnect(0);
  }

  connect(){
    this.socket.connect(0);
  }


  rateBroadcast(apiObj){
    this.socket.emit('api-obj', apiObj);
  }

  public getRate = () => {
    return Observable.create((observer) => {
      this.socket.on('api-obj', (message) => {
        console.log(message);
        observer.next(message);
      });
    });
  }


  public getRateBookMakerMan = () => {
    return Observable.create((observer) => {
      this.socket.on('bookMaker-rate', (message) => {
        console.log(message);
        observer.next(message);
      });
    });
  }

  public getRateOddEvenMan = () => {
    return Observable.create((observer) => {
      this.socket.on('oddEven-rate', (message) => {
        console.log(message);
        observer.next(message);
      });
    });
  }

  setLineRate(marketId){
    this.socket.emit('line-rate', marketId);
    this.socket.emit('line-status', marketId);
  }

  public getLineRate = () => {
    return Observable.create((observer) => {
      this.socket.on('line-rate', (message) => {
        observer.next(message);
      });
    });
  }



  public getLineStatus = () => {
    return Observable.create((observer) => {
      this.socket.on('line-status', (message) => {
        observer.next(message);
      });
    });
  }

  public BetLock = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'betlock', (message) => {
        observer.next(message);
      });
    });
  }

  public changeMode = () => {
    return Observable.create((observer) => {
      this.socket.on('change-mode', (message) => {
        console.log(message)
        observer.next(message);
      });
    });
  }

  public changeFlag = () => {
    return Observable.create((observer) => {
      this.socket.on('getPlaceBet', (message) => {
        observer.next(message);
      });
    });
  };

  public getKey = (keyword) => {
    return Observable.create((observer) => {
      this.socket.on('getkey_' + keyword, (message) => {
        console.log(message);
        observer.next(message);
      });
    });
  }


  public changeStatus = () => {
    return Observable.create((observer) => {
      this.socket.on('change-status', (message) => {
        observer.next(message);
      });
    });
  }

  public updateRunners = () => {
    return Observable.create((observer) => {
      this.socket.on('updateRunners', (message) => {
        observer.next(message);
      });
    });
  }




  getLatestRate(roomId){
    this.socket.emit('get-latest-rate', roomId);
  }

//this function use for getting bet from socket emit event
  public getPlaceBet = () => {
    return Observable.create((observer) => {
      this.socket.on('placeBet', (message) => {
        console.log(message);
        observer.next(message);
      });
    });
  }



//this function use for getting bet from socket emit event
  public updateBalance = () => {
    return Observable.create((observer) => {
      this.socket.on('updateBalance', (message) => {
        console.log(message);
        observer.next(message);
      });
    });
  }


//this function use for getting bet from socket emit event
  public oddsRate = () => {
    return Observable.create((observer) => {
      this.socket.on('odds-rate', (message) => {
        observer.next(message);
      });
    });
  }

  public logoutByAdmin = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix + 'logoutByAdmin', (message) => {  console.log("logout");
        observer.next(message);
      });
    });
  }
  public logoutByAdminInctive = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'logoutByAdmin', (message) => {
        observer.next(message);
      });
    });
  }

  //this function use for getting bet from socket emit event
  public deleteBet = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'deleteBet', (message) => {
        observer.next(message);
      });
    });
  }

}
