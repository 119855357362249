import { Component, OnInit } from '@angular/core';
import {CurrencyService} from '../../services/currency.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilityService} from '../../globals/utilityService';
import {PlacebetService} from '../../services/placebet.service';
import {CommonService} from '../../services/common.service';

@Component({
  selector: 'app-chips-setting',
  templateUrl: './chips-setting.component.html',
  styleUrls: ['./chips-setting.component.scss']
})
export class ChipsSettingComponent implements OnInit {
  currencyAll: any;
  userData: any;
  constructor(
    private currencyService: CurrencyService,
    private utilityService: UtilityService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.userData = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
    this.getUserCurrency();
  }

  /**
   * @author TR
   * @date : 05-06-2020
   * get Currency
   */
  getUserCurrency() {
    let data = {
      userId: this.utilityService.returnLocalStorageData('userId')
    };
    this.currencyService.getUserCurrency(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      this.currencyAll = response.data;
    });
  }

  /**
   * @author subhash
   * @date : 28/10/2020
   * get master Currency
   */
  resetCurrencyModel() {
    let data = {
      userId: this.userData.parentId
    };
    this.currencyService.getUserCurrency(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      this.currencyAll[0].buttons = response.data[0].buttons;
      if(response){
        this.currencyService.updateCurrency(this.currencyAll[0]).subscribe(response => {
          response = this.utilityService.gsk(response.auth);
          response = JSON.parse(response);
          this.getUserCurrency();
          // this.currencyModal.hide();
        });
      }
    });
  }

  /**
   * @author TR
   * @date : 05-06-2020
   * update Currency
   */
  updateCurrency() {
    this.currencyService.updateCurrency(this.currencyAll[0]).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      this.commonService.popToast('success', 'success', 1500, 'update successfully.');
      this.getUserCurrency();
    });
  }

}
