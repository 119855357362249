declare var require: any;
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {decode} from 'punycode';
import {UtilityService} from '../globals/utilityService';
var aes256 = require('aes256');

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private message: string;

  constructor(private _router: Router, private utilityService: UtilityService) { }

  /**
   * this is used to clear anything that needs to be removed
   */
  clear(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(): boolean {
    return this.utilityService.returnLocalStorageData('token') != null && !this.isTokenExpired();
  }

  // simulate jwt token is valid
  // https://github.com/theo4u/angular4-auth/blob/master/src/app/helpers/jwt-helper.ts
  isTokenExpired(): boolean {
    return false;
  }


  decode() {
    return decode(this.utilityService.returnLocalStorageData('token'));
  }

  returnUserRoles(){
    return (this.utilityService.returnLocalStorageData('role'));
  }
  getEncKey(resObj){
    let encKey = module.exports.getRandomString(20);
    let appendEncKey = module.exports.getRandomString(5);
    let finalString = appendEncKey + reverseString(encKey) + dataRkEnc(encKey, JSON.stringify(resObj));
    return finalString;
  };
}

function reverseString(str){
  var splitString = str.split(""); // var splitString = "hello".split("");
  var reverseArray = splitString.reverse();
  var joinArray = reverseArray.join("");
  return joinArray;
}
function dataRkEnc(key, data){
  let encrypted = aes256.encrypt(key, data);
  return encrypted;
};

