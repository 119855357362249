import {Component, OnInit, ViewChild} from '@angular/core';
import { StudioService } from '../../services/studio.service';
import {UtilityService} from '../../globals/utilityService';
import {ActivatedRoute, Router,NavigationEnd} from '@angular/router';
import {ModalDirective} from 'ngx-bootstrap';
declare let _: any;
@Component({
  selector: 'app-indian-poker-view',
  templateUrl: './indian-poker-view.component.html',
  styleUrls: ['./indian-poker-view.component.scss']
})
export class IndianPokerViewComponent implements OnInit {

  @ViewChild('instructionModal', { static: false }) instructionModal: ModalDirective;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studioService: StudioService,
    private utilityService: UtilityService
  ) { }
  gameList;
  groupList;
  product = ['Evolution Gaming' ,'Super Spade Games', 'Pragmatic Play Live', 'Ezugi'];
  gameLists;
  userId = this.utilityService.returnLocalStorageData('userId');
  gameIds = [2152,2153,2154,2338,3072,3921,8042,8214,634,3072,3073,3919];
  ngOnInit() {
    this.getCasinoList();
  }
  closeModal(){
    this.instructionModal.hide();
  }
  getCasinoList(){
    let data = {
      product : this.product
    }
    this.studioService.getGameList(data).subscribe(response => {
      if(response.status == true){
        this.gameLists = response.data;
        this.gameList = response.data;
        this.groupList = [];
        // this.instructionModal.show();
        this.groupList = _.filter(this.gameList, function(p){
          let checktrue = _.includes([6943,3929,9754,2082,9748, 9750,3920,11564,2648,11556,3900,3898,2659,2663,2072,2073,3902,8078,3690,11548,3556,12764], p.game_id);
          if(checktrue === true){
          return p;
          }
        });
      //   this.groupList = _.filter(this.gameList)
      //   // Group the elements of Array based on `color` property
      //     .groupBy("product")
      //     // `key` is group's name (color), `value` is the array of objects
      //     .map((value, key) => ({ name: key, category: value }))
      //     .value();
      // }else{
      //   this.gameList = [];
       }
    });
  }
  onSelect(item){
    if(item === 'all'){
      this.gameList = this.gameLists;
    }else {
      this.gameList = item.category;
    }
  }

  gameUrl(data){
    this.router.navigateByUrl('/dc/game-lobby/' + data.game_id);
    // let game_id = data.game_id;
    // let params = {
    //   game_id : data.game_id,
    //   user : this.userId,
    //   platform : 'GPL_DESKTOP',
    //   lobby_url : "https://bettrader.live/dashboard",
    // }
    // this.studioService.getUrl(params).subscribe(response => {
    //   console.log(response);
    // });
  }


}
