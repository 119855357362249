import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { SportsRoutingModule } from './sports-routing.module';
import { SportsComponent } from './sports.component';
import { ListViewComponent } from './list-view/list-view.component';
import { CardViewComponent } from './card-view/card-view.component';
import { GameViewComponent } from './game-view/game-view.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import {ModalModule} from "ngx-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
// import { DragulaModule, DragulaService }   from 'ng2-dragula';
import {BrowserModule} from '@angular/platform-browser';
import {commonDerectivenModule} from '../auth-gaurd/commonDerective.module';
import { CasinoViewComponent } from './casino-view/casino-view.component';
import { MultiMarketComponent } from './multi-market/multi-market.component';
import { CasonoDashboardComponent } from './casono-dashboard/casono-dashboard.component';
import { AuraViewComponent } from './aura-view/aura-view.component';

@NgModule({
  declarations: [SportsComponent, ListViewComponent, CardViewComponent, GameViewComponent, CasinoViewComponent, MultiMarketComponent, CasonoDashboardComponent, AuraViewComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // DragulaModule,
    ModalModule,
    SportsRoutingModule,
    NgxSpinnerModule,
    BrowserModule,
    commonDerectivenModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    // DragulaService
  ],
})
export class SportsModule { }
