import { Component, OnInit, Inject, Injectable } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import * as io from 'socket.io-client';
import { Observable} from 'rxjs';
import * as env from './env';
import {UtilityService} from './utilityService';
declare var $ : any;
declare var _: any;
@Injectable()

export class SocketServiceClient{
  private url =  env.client_socket_url();
  private prefix =  env.socketPrefix();

  private socket;

  constructor(@Inject(DOCUMENT) private document: any,
              private router: Router,
              private location: Location,
              private utilityService: UtilityService
  ) {
    this.socket = io(this.url,{
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 1000,
      reconnectionAttempts: Infinity,
      transports: ['websocket', 'polling', 'flashsocket']});
    //this.socket.emit('join-room', 'room2');

    this.socket.on('disconnect', () => {

    });

    this.socket.on('reconnect', () => {
      let roomIs = this.utilityService.returnLocalStorageData('userId');
      if (roomIs !== null) {
        let socket = this.socket;
        socket.emit('join-room', roomIs.toString());
      }
    });
  }

  //Join room function
  joinRoom(roomId){
    this.socket.emit('join-room', roomId);
  }
  //leave the room
  leaveRoom(roomId){
    // console.log(roomId);
    this.socket.emit('leave-room', roomId);
  }

  disconnect(){
    this.socket.disconnect(0);
  }
  connect(){
    this.socket.connect(0);
  }


//this function use for getting bet from socket emit event
  public updateCasinoResults = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'updateCasinoResult', (message) => {
        observer.next(message);
      });
    });
  }


  public getKey = (keyword) => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'getkey_' + keyword, (message) => {
        observer.next(message);
      });
    });
  }

  public BetLock = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'betlock', (message) => {
        observer.next(message);
      });
    });
  }



//this function use for getting bet from socket emit event
  public getPlaceBetShareMarket = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'getPlaceBetShareMarket', (message) => {
        observer.next(message);
      });
    });
  }

//this function use for getting bet from socket emit event
  public getPlaceBetShareMarketBalance = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'getPlaceBetShareMarketBalance', (message) => {
        observer.next(message);
      });
    });
  }

  public cancelMarket = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'cancelMarket', (message) => {
        observer.next(message);
      });
    });
  }

  public casinoLive = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'getresultData', (message) => {
        observer.next(message);
      });
    });
  }

//this function use for getting bet from socket emit event
  public updateBalance = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'updateBalance', (message) => {
        console.log("message+++++++++++++",message);
        observer.next(message);
      });
    });
  }

//this function use for getting bet from socket emit event
  public updateTransaction = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'updateTransaction', (message) => {
        console.log("message+++++++++++++",message);
        observer.next(message);
      });
    });
  }

//this function use for getting bet from socket emit event
  public deleteBet = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'deleteBet', (message) => {
        observer.next(message);
      });
    });
  }

//this function use for getting bet from socket emit event
  public fancySettled = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'fancySettled', (message) => {
        observer.next(message);
      });
    });
  }

//this function use for getting bet from socket emit event
  public fancyAdd = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'fancyAdd', (message) => {
        observer.next(message);
      });
    });
  }

//this function use for getting bet from socket emit event
  public marketCreate = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'marketCreate', (message) => {
        observer.next(message);
      });
    });
  }

//this function use for getting bet from socket emit event
  public updateSport = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'updateSport', (message) => {
        observer.next(message);
      });
    });
  }

//this function use for getting bet from socket emit event
  public updateTournament = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'updateTournament', (message) => {
        observer.next(message);
      });
    });
  };

  //this function use for getting bet from socket emit event
  public updateMatch = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'updateMatch', (message) => {
        observer.next(message);
      });
    });
  };

//this function use for getting bet from socket emit event
  public suspendStatus = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'suspend-status', (message) => {
        observer.next(message);
      });
    });
  }

//this function use for getting bet from socket emit event
  public fancyAutoUpdate = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'auto-fancy-update', (message) => {
        observer.next(message);
      });
    });
  }

//this function use for getting bet from socket emit event
  public logoutUser = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'logutUser', (message) => {
        observer.next(message);
      });
    });
  }

  public updateBalancebyAdmin = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'updateBalancebyAdmin', (message) => {
        observer.next(message);
      });
    });
  }
  public balaceSettel = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'balaceSettel', (message) => {
        observer.next(message);
      });
    });
  }

  public welcomeMassage = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'welcomeMassage', (message) => {
        observer.next(message);
      });
    });
  }

  public bookMakerRateChange = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'bookMaker-rate', (message) => {
        observer.next(message);
      });
    });
  }

  public logoutByAdmin = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'logoutByAdmin', (message) => {  console.log("logout");
        observer.next(message);
      });
    });
  }


  //this function use for getting bet from socket emit event
  public casinoSettle = () => {
    return Observable.create((observer) => {
      this.socket.on(this.prefix +'casinoLiveSettled', (message) => {
        console.log(message);
        observer.next(message);
      });
    });
  }




}

