import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/index';
import {tap} from 'rxjs/internal/operators';
import {isUndefined} from 'util';
import * as env from '../globals/env';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class StudioService {

  server_url: any = env.adminServer_url();
  office_url: any = env.office_url();
  studio_url: any = env.studio_url();



  constructor(private http: HttpClient) { }

  /**
   *@author Ravi Kadia
  * @date 06-03-2020
  * @param filter
  * @returns {Observable<any>}
  * Add place bet
**/

  storeUserData(data): Observable<any> {
    return this.http.post(this.studio_url + '/auth/store-user-data', data)
      .pipe(tap(_ => this.log(`get all market successfully`)));
  }

  getGameList(data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'stock' : 'true'
    });
    return this.http.post(this.studio_url + '/wallet/game-redis-stag', data, {headers : headers})
    .pipe(tap(_ => this.log(`get game list`)));
  }
  //old dream casino
  // getUrl(data): Observable<any> {
  //   data.whtLblUrl = this.server_url;
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'stock' : 'true'
  //    });
  //   return this.http.post(this.studio_url + '/wallet/game-url',data,{headers : headers})
  //   .pipe(tap(_ => this.log(`get game list`)));
  // }

  getUrl(data): Observable<any> {
    data.whtLblUrl = "https://server.dgexch.com/api/v1/";
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'stock' : 'true'
     });
    return this.http.post(this.studio_url + '/wallet/login',data,{headers : headers})
    .pipe(tap(_ => this.log(`get game list`)));
  }

  log(message) {
    console.log(message);
  }
}
