'use strict';
import {SocketService} from "../globals/socketService";
import { DOCUMENT } from '@angular/common';

declare var require: any;
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Route, Router} from '@angular/router';
import * as env from '../globals/env';
import { CommonService } from '../services/common.service';
import { UserService } from '../services/user.service';
import {SocketServiceClient} from "../globals/socketServiceClient";
var aes256 = require('aes256');
import { UtilityService } from '../globals/utilityService';
import {environment} from '../../environments/environment';
import {ModalDirective} from 'ngx-bootstrap';
// import { BinaryService } from '../services/binary.service';
import * as $ from 'jquery';
import {MarketService} from '../services/market.service';
import {NgxSpinnerService} from "ngx-spinner";
import {SocketServiceRedis} from '../globals/socketServiceRedis';
import { NgForm } from '@angular/forms';
import { Observable } from "rxjs/Observable";
import { StudioService } from '../services/studio.service';
import {SocketServiceRedisMarket} from '../globals/socketServiceRedisMarket';
import "rxjs/add/observable/timer";
import "rxjs/add/operator/finally";
import "rxjs/add/operator/takeUntil";
import "rxjs/add/operator/map";

declare let _: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  currentApplicationVersion = environment.appVersion;
  @ViewChild("loginForm", { static: false }) loginForm;
  @ViewChild('loginModal', { static: false }) loginModal: ModalDirective;
  @ViewChild('passwordChange', { static: false }) passwordChange: ModalDirective;
  @ViewChild("registerForm", { static: false }) registerForm;
  @ViewChild('registerModal', { static: false }) registerModal: ModalDirective;
  @ViewChild('otpModal', { static: false }) otpModal: ModalDirective;
  @ViewChild('tokenModal', { static: false }) tokenModal: ModalDirective;
  @ViewChild('ask2fModal', { static: false }) ask2fModal: ModalDirective;
  @ViewChild('getQrCodeModal', { static: false }) getQrCodeModal: ModalDirective;
  slides = [
    { img: '../../assets/images/casino/56767.jpg' },
    { img: '../../assets/images/casino/56768.jpg' },
    { img: '../../assets/images/casino/56967.jpg' },
    { img: '../../assets/images/casino/56968.jpg' },
    { img: '../../assets/images/casino/67564.jpg' },
    { img: '../../assets/images/casino/67565.jpg' },
    { img: '../../assets/images/casino/67567.jpg' },
    { img: '../../assets/images/casino/67570.jpg' },
    { img: '../../assets/images/casino/67580.jpg' },
    { img: '../../assets/images/casino/67600.jpg' },
    { img: '../../assets/images/casino/67610.jpg' },
    { img: '../../assets/images/casino/67620.jpg' },
    { img: '../../assets/images/casino/67630.jpg' }
  ];

  slideConfig = {"slidesToShow": 9, "slidesToScroll": 1, "autoplay": true,  "vertical": false , arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 9
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };



  product = ['Evolution Gaming', 'eBET' ,'Super Spade Games', 'Pragmatic Play Live', 'Asia Gaming', 'Evoplay Entertainment', 'Betsoft', 'Ezugi'];
  gameLists;
  gameList;
  groupList;
  groupLists;
  initialSlide: 3;
  slideConfig1 = {"slidesToShow": 3, "slidesToScroll": 1, "autoplay": true,  "vertical": false , arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
  
    ]
  };
  logo: any = env.PROJECT_LOGO_URL;
  oldPassword: any;
  newPassword: any;
  bannerImages: any;
  casinoImages: any;
  confNewPassword: any;
  age18year = true;
  loginObject = {
    userName: null,
    password: null,
    deviceId : "",
    fcmToken : "",
    remember : false,
    channel : "WEB",
    userType : "CLIENT",
    code : env.PROJECT_CODE
  };
  tokenObj = {
    authToken : null,
    authSecret : null,
    userId : null,
    userData : null,
    respons1 : null,
    response : null,
    verifyToken:null
  }
  registerObject = {
    userName: null,
    fullName:null,
    password: null,
    channel : "WEB",
    userType : "CLIENT",
    dialCode : "91",
    countryName : "India (भारत)",
    mobileNo : '',
    emailId : ''
  };
  showPassword = false;
  showPassword1 = false;
  showPassword2 = false;
  Toast = {
    type: '',
    title: '',
    timeout: 0,
    body: ''
  };
  endSubmit = false;
  stockUsernamePrefix = env.stockUsernamePrefix();
  refralCode = false;
  countryCode = {
    dialCode: "91",
    iso2: "in",
    name: "India (भारत)"
  }
  brandImages = ['../../assets/images/evolution-gaming.png','../../assets/images/ezugi.png','../../assets/images/realtime-gaming.png','../../assets/images/super-spades.png']


  marketFilter = {
    page: 1,
    limit: 10,
    search: null,
    userId : this.utilityService.returnLocalStorageData('userId')
  };
  userId = this.utilityService.returnLocalStorageData('userId');
  otpuserId;
  loginPassword;
  loginUsername;
  countdown: number;
  stopCount  = false;
  qrCode;
  authSecret;
  tennisCount = 1;
  electionCount = 1;
  soccerCount = 1;
  cricketCount = 1;
  getAllMarkets = [];
  getAllSports = [];
  messages: any;
  inplayCount = 0;
  dummyRateResponse = localStorage.getItem('dummyRateResponse');
  cricketLen = 0;
  soccerLen = 0;
  tennisLen = 0;


  constructor( private router: Router,
               @Inject(DOCUMENT) private document: Document,
               private commonService: CommonService,
               private socketService: SocketService,
               private socketServiceClient: SocketServiceClient,
               private socketServiceRedis: SocketServiceRedis,
               private utilityService: UtilityService ,
               private userService:UserService,
               private marketService:MarketService,
               // private binaryService : BinaryService,
               private spinner : NgxSpinnerService,
               private studioService: StudioService,
               private socketServiceRedisMarket : SocketServiceRedisMarket
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    $('.loading').hide();
    this.socketService.disconnect();
    this.socketServiceClient.disconnect();
    this.socketServiceRedis.disconnect();
    let data = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
    // if (!this.utilityService.returnLocalStorageData('userId')) {
    // } else {
    //   if(data && data.userType === 'CLIENT'){
    //     localStorage.clear();
    //     // this.router.navigate(['/dashboard']);
    //   }
    // }
    
    this.getMarketOdds(false);
    this.getMarketOddsData('');
    this.dashboardData('ALL');
    this.getBannerImage();
    this.getMarketOddsItem();
    this.getCasinoList()
    setTimeout(() => {
      this.getInplayData();      
    }, 100);
    if (localStorage.checkbox && localStorage.checkbox !== "" && localStorage.password !== "") {
      this.loginObject.remember = true;
      this.loginObject.userName = localStorage.username;
      this.loginObject.password = localStorage.password;
    } else {
      this.loginObject.remember = false;
      this.loginObject.userName = "";
      this.loginObject.password = "";
    }
    let socketMatchOdds = this.socketServiceRedisMarket;
    socketMatchOdds.joinRoom('marketRateDm');

    this.socketServiceRedisMarket
      .oddsRate1()
      .subscribe((response1) => {
        if(response1 && response1.length > 0){
          response1 = JSON.parse(response1);
          _.map(response1, function (response){
            if (response) {
              if (response.status == 'OPEN') {
                let marketId = response.marketId.toString().replace('.', '');
                $('.' + marketId + '_markets').removeClass('market_suspends');
                let runtypes = response.runners;
                let runners = 0;
                if (response.numberOfActiveRunners) {
                  runners = response.numberOfActiveRunners;
                } else {
                  runners = response.runners.length;
                }
                if(runners == 3){
                  $("#" + marketId + "_back_2_odds_hiddne").val((runtypes[2].ex.availableToBack[0]) ? runtypes[2].ex.availableToBack[0].price : '-');
                  $("#" + marketId + "_lay_2_odds_hiddne").val((runtypes[2].ex.availableToLay[0]) ? runtypes[2].ex.availableToLay[0].price : '-');
                  const currOddsRate2 =  $('#' + marketId +'_back_2_odds').text();
                  const prevOddsRate2 = $('#' + marketId +'_back_2_odds_hiddne').val();
                  const laycurrOddsRate2 =  $('#' + marketId +'_lay_2_odds').text();
                  const layprevOddsRate2 = $('#' + marketId +'_lay_2_odds_hiddne').val();
                  if (currOddsRate2 === prevOddsRate2) {
                    $('#' + marketId +'_back_2_odds').css('background', '');
                  } else {
                    $('#' + marketId +'_back_2_odds').css('background', '#FFD700');
                  }
                  if (laycurrOddsRate2 === layprevOddsRate2) {
                    $('#' + marketId +'_lay_2_odds').css('background', '');
                  } else {
                    $('#' + marketId +'_lay_2_odds').css('background', '#FFD700');
                  }
                }
                $("#" + marketId + "_back_1_odds_hiddne").val((runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToBack[0]) ? runtypes[0].ex.availableToBack[0].price : '-');
                $("#" + marketId + "_lay_1_odds_hiddne").val((runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToLay[0]) ? runtypes[0].ex.availableToLay[0].price : '-');
                $("#" + marketId + "_back_3_odds_hiddne").val((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
                $("#" + marketId + "_lay_3_odds_hiddne").val((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');

                // Start Blinking Rate change Odds
                const currOddsRate =  $('#' + marketId +'_back_1_odds').text();
                const prevOddsRate = $('#' + marketId +'_back_1_odds_hiddne').val();
                const currOddsRate1 =  $('#' + marketId +'_back_3_odds').text();
                const prevOddsRate1 = $('#' + marketId +'_back_3_odds_hiddne').val();


                const laycurrOddsRate =  $('#' + marketId +'_lay_1_odds').text();
                const layprevOddsRate = $('#' + marketId +'_lay_1_odds_hiddne').val();
                const laycurrOddsRate1 =  $('#' + marketId +'_lay_3_odds').text();
                const layprevOddsRate1 = $('#' + marketId +'_lay_3_odds_hiddne').val();

                if (currOddsRate === prevOddsRate) {
                  $('#' + marketId +'_back_1_odds').css('background', '');
                } else {
                  $('#' + marketId +'_back_1_odds').css('background', '#FFD700');
                }
                if (currOddsRate1 === prevOddsRate1) {
                  $('#' + marketId +'_back_3_odds').css('background', '');
                } else {
                  $('#' + marketId +'_back_3_odds').css('background', '#FFD700');
                }

                if (laycurrOddsRate === layprevOddsRate) {
                  $('#' + marketId +'_lay_1_odds').css('background', '');
                } else {
                  $('#' + marketId +'_lay_1_odds').css('background', '#FFD700');
                }
                if (laycurrOddsRate1 === layprevOddsRate1) {
                  $('#' + marketId +'_lay_3_odds').css('background', '');
                } else {
                  $('#' + marketId +'_lay_3_odds').css('background', '#FFD700');
                }

                $("#" + marketId + "_back_1_odds").html((runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToBack[0]) ? runtypes[0].ex.availableToBack[0].price : '-');
                $("#" + marketId + "_lay_1_odds").html(( runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToLay[0]) ? runtypes[0].ex.availableToLay[0].price : '-');
                if (runners == 3) {
                  $("#" + marketId + "_back_3_odds").html(( runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
                  $("#" + marketId + "_lay_3_odds").html(( runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');
                  $("#" + marketId + "_back_2_odds").html((runtypes[2] && runtypes[2].ex && runtypes[2].ex.availableToBack[0]) ? runtypes[2].ex.availableToBack[0].price : '-');
                  $("#" + marketId + "_lay_2_odds").html(( runtypes[2] && runtypes[2].ex && runtypes[2].ex.availableToLay[0]) ? runtypes[2].ex.availableToLay[0].price : '-');
                } else {
                  if (runtypes[1] && runtypes[1].ex) {
                    $("#" + marketId + "_back_3_odds").html((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
                    $("#" + marketId + "_lay_3_odds").html((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');
                  }
                }
              }else{
                let marketId = response.marketId.toString().replace('.', '');
                $('.' + marketId + '_markets').addClass('market_suspends');
              }
            }
          })
        }

      });
  }
  // ngAfterViewInit(){
  //   this.getMarketOdds();
  //   this.getInitialRate();
  // }

  getBannerImage(){
    let prefix = "99sport";
    this.marketService.getBannerImage().subscribe(response => {
      if (response.status == true) {
        localStorage.setItem('bannerImages', JSON.stringify(response.data));
        this.bannerImages = _.filter(response.data , function (e) {
          let items = _.includes(e.whitelableTypes , prefix);
          if(e.type === 'Banner' && items){
            return e;
          }
        });
        this.casinoImages = _.filter(response.data , function (e) {
          let items1 = _.includes(e.whitelableTypes , prefix);
          if(e.type === 'Casino' && items1){
            return e;
          }
        });
        setTimeout(function(){
          $('.carousel-indicators > li').first().addClass('active');
          $('#img0').addClass('active');
        }, 200);

      } else {
        // this.commonService.popToast('error','Error', 3000 , 'not found market rules.');
      }
    }, error => {
      // this.commonService.popToast('error', 'Error', 3000, 'not found.');
    });
  }
  getAllMarket(){
    console.log('done chnages');
    this.marketService.getBannerImage().subscribe(response => {
    });
  }

  getCasinoList(){
    let data = {
      product : this.product
    }
    this.studioService.getGameList(data).subscribe(response => {
      if(response.status == true){
        this.gameLists = response.data;
        this.gameList = response.data;
        localStorage.setItem('casinogames', JSON.stringify(this.gameList));
      }else{
        this.gameList = [];
      }
    });
  }
  getInplayData(){
    let cricketLen = 0;
    let tennisLen = 0;
    let soccerLen = 0;
    let items = localStorage.getItem("inplayMatch");
    let inplay = JSON.parse(items);
    console.log('inplay',inplay);
    _.map(inplay, function (e) {
      if(e._id == 4 ){
        cricketLen =  (cricketLen + e.doc.length);
      }
      if(e._id == 1 ){
        let length = e.doc.length;
        tennisLen =  (tennisLen + length);
      }
      if(e._id == 2 ){
        let length = e.doc.length;
        soccerLen = (soccerLen + length);
      }
    })
    console.log('inplay',inplay);
    this.cricketLen = cricketLen;
    this.tennisLen = tennisLen;
    this.soccerLen = soccerLen;
  }

/*************  ✨ Codeium Command ⭐  *************/
  /**
   * @description get all market for dashboard and sidebar
   * @param type type of data inplay or upcoming
   * @returns void
   */
/******  59a7362e-37a0-42d8-8f1d-b158ab4639cb  *******/
  getMarketOdds(type) {
    const userId = this.utilityService.returnLocalStorageData('userId');
    this.marketService.getAllMarketForDashboardClient(this.marketFilter, userId).subscribe(response => {
      localStorage.setItem('dashboardData', JSON.stringify(response.data));
      if(response.data){
        if(type == 'inplay' ){
        this.dashboardData('inplay');
        }
        let cricket;
        let soccer;
        let tennis;
        let election;
        let allData = [];
        _.map(response.data, function (e) {
          allData.push(e);
          if(e._id == 4){
             cricket =  e.doc.length;
            localStorage.setItem('cricketData', JSON.stringify(e));
          } else if(e._id == 1){
            soccer =  e.doc.length;
            localStorage.setItem('tennisData', JSON.stringify(e));
          }else if(e._id == 2){
            tennis =  e.doc.length;
            localStorage.setItem('soccerData', JSON.stringify(e));
          }else{
            election =  e.doc.length;
            localStorage.setItem('otherSport', JSON.stringify(e));
          }
        });
        let mergedArray  = allData;
        const customOrder = ["4", "1", "2", "5"];
        mergedArray.sort((a, b) => customOrder.indexOf(a._id) - customOrder.indexOf(b._id));

        console.log('mergedArray', mergedArray);
        localStorage.setItem('allSport', JSON.stringify(mergedArray));
        this.cricketCount = cricket;
        this.soccerCount = soccer;
        this.tennisCount = tennis;
        this.electionCount = election;
      }
    });

    this.marketService.getAllMarketForSideCreative(this.marketFilter, userId).subscribe(response => {
      localStorage.setItem('sidebarData', JSON.stringify(response.data));
    });

  }

  dashboardData(type){
    console.log('type',type);
    this.getInitialRate();

    let mymatchOddsId = [];
    if(type == 'inplay'){

      $('#all-tab').addClass('active')
      this.getMarketOddsItem();
        let items = localStorage.getItem("inplayMatch");
      let data = JSON.parse(items)
      console.log('data',data);

      this.getAllMarkets = _.toArray(data);
      if(this.getAllMarkets.length > 0 && this.getAllMarkets[0] != null ){
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            firstLoop.marketIdDec = firstLoop.marketId.toString().replace('.', '');
            // firstLoop.marketDetails.filter(secondLoop => {
            //   if (secondLoop.displayName == "Match Odds") {
            //     firstLoop.marketIdDec = secondLoop.marketId.toString().replace('.', '');
            //     // mymatchOddsId.push(firstLoop.marketId[0]);
            //     // matchOdds.joinRoom(firstLoop.marketId[0]);
            //     // return secondLoop;
            //   }
            //   return secondLoop;
            // });
            return firstLoop;
          });
          return marketLoop;
        });
      }else{
        this.messages = "has no live event found.."
      }  
      
    }else if(type == 'CRICKET'){
      this.getMarketOddsData('');
      let items = localStorage.getItem("cricketData");
      items = JSON.parse(items)
      this.getAllMarkets =  [items];
      console.log('this.getAllMarkets',this.getAllMarkets);
      if(this.getAllMarkets.length > 0 && this.getAllMarkets[0] != null ) {
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            firstLoop.marketIdDec = firstLoop.marketId.toString().replace('.', '');
            // firstLoop.marketDetails.map(secondLoop => {
            //   if (secondLoop.displayName == "Match Odds") {
            //     firstLoop.marketIdDec = secondLoop.marketId.toString().replace('.', '');
            //     // mymatchOddsId.push(firstLoop.marketId[0]);
            //     // matchOdds.joinRoom(firstLoop.marketId[0]);
            //   }
            //   return secondLoop;
            // });
            return firstLoop;
          });
          return marketLoop;
        });
      }

    }else if(type == 'SOCCER'){
      this.getMarketOddsData('');
      let items = localStorage.getItem("tennisData");
      items = JSON.parse(items)
      this.getAllMarkets =  [items];
      if(this.getAllMarkets.length > 0 && this.getAllMarkets[0] != null ){
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            firstLoop.marketIdDec = firstLoop.marketId.toString().replace('.', '');
            // firstLoop.marketDetails.map(secondLoop => {
            //   if (secondLoop.displayName == "Match Odds") {
            //     firstLoop.marketIdDec = secondLoop.marketId.toString().replace('.', '');
            //     // mymatchOddsId.push(firstLoop.marketId[0]);
            //     // matchOdds.joinRoom(firstLoop.marketId[0]);
            //   }
            //   return secondLoop;
            // });
            return firstLoop;
          });
          return marketLoop;
        });
      }

    }else if(type == 'Tennis'){
      this.getMarketOddsData('');
      let items = localStorage.getItem("soccerData");
      items = JSON.parse(items)
      this.getAllMarkets =  [items];
      if(this.getAllMarkets.length > 0 && this.getAllMarkets[0] != null ){
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            firstLoop.marketIdDec = firstLoop.marketId.toString().replace('.', '');
            // firstLoop.marketDetails.map(secondLoop => {
            //   if (secondLoop.displayName == "Match Odds") {
            //     firstLoop.marketIdDec = secondLoop.marketId.toString().replace('.', '');
            //     // mymatchOddsId.push(firstLoop.marketId[0]);
            //     // matchOdds.joinRoom(firstLoop.marketId[0]);
            //   }
            //   return secondLoop;
            // });
            return firstLoop;
          });
          return marketLoop;
        });
      }

    }else if(type == "Other"){
      this.getMarketOddsData(''); 
      let items = localStorage.getItem("otherSport");
      items = JSON.parse(items)
      this.getAllMarkets =  [items];
      if(this.getAllMarkets.length > 0 && this.getAllMarkets[0] != null ){
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            firstLoop.marketIdDec = firstLoop.marketId.toString().replace('.', '');
            // firstLoop.marketDetails.map(secondLoop => {
            //   if (secondLoop.displayName == "Match Odds") {
            //     firstLoop.marketIdDec = secondLoop.marketId.toString().replace('.', '');
            //     // mymatchOddsId.push(secondLoop.marketId);
            //     // matchOdds.joinRoom(secondLoop.marketId);
            //   }
            //   return secondLoop;
            // });
            return firstLoop;
          });
          return marketLoop;
        });
      }
    }else{
      this.getMarketOddsData(''); 
      let items = localStorage.getItem("allSport");
      let data = JSON.parse(items)
      this.getAllMarkets = _.toArray(data);
      if(this.getAllMarkets.length > 0 && this.getAllMarkets[0] != null ){
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            if(firstLoop.marketId.length > 0){
              firstLoop.marketIdDec = firstLoop.marketId.toString().replace('.', '');
              // firstLoop.marketDetails.map(secondLoop => {
              //   if (secondLoop.displayName == "Match Odds") {
              //     firstLoop.marketIdDec = secondLoop.marketId.toString().replace('.', '');
              //     // mymatchOddsId.push(secondLoop.marketId);
              //     // matchOdds.joinRoom(secondLoop.marketId);
              //   }
              //   return secondLoop;
              // });
            }
            return firstLoop;
          });
          return marketLoop;
        });
      }
    }
    setTimeout(() => {
      if(this.dummyRateResponse){
        this.appendInitialData(JSON.parse(this.dummyRateResponse));
      }
    }, 1);
    let reply = localStorage.getItem("dashboardData");
    if(reply){
      reply = JSON.parse(reply);
    }

  }

  getInitialRate(){
    this.marketService.getInitialRate().subscribe(response => {
      localStorage.setItem( "dummyRateResponse", JSON.stringify(response.data));
    });
    this.marketService.getInitialRateMarket().subscribe(response => {
      localStorage.setItem( "dummyMarketRateResponse", JSON.stringify(response.data));
    });

  }

  appendInitialData(dummyRateResponse){
    // dummyRateResponse = dummyRateResponse[0].concat(dummyRateResponse[1], dummyRateResponse[2]);
    if(dummyRateResponse){
      _.map(dummyRateResponse, function (upendData) {
        upendData = JSON.parse(upendData);
        if(upendData != null){
          let obj = {
            "marketId": upendData.marketId,
            "status": "OPEN",
            "numberOfRunners": 2,
          }
          obj['runners'] = upendData.runners.length;
          let marketId = obj.marketId.toString().replace('.', '');
          let runtypes = upendData.runners;
          let runners = 0;
          if (obj['numberOfActiveRunners']) {
            runners = upendData.runners.length;
          } else {
            runners = upendData.runners.length;
          }
          if(runtypes.length > 0){
            if(runners == 3){
              $("#" + marketId + "_back_2_odds_hiddne").val((runtypes[2].ex.availableToBack[0]) ? runtypes[2].ex.availableToBack[0].price : '-');
              $("#" + marketId + "_lay_2_odds_hiddne").val((runtypes[2].ex.availableToLay[0]) ? runtypes[2].ex.availableToLay[0].price : '-');
              const currOddsRate2 =  $('#' + marketId +'_back_2_odds').text();
              const prevOddsRate2 = $('#' + marketId +'_back_2_odds_hiddne').val();
              const laycurrOddsRate2 =  $('#' + marketId +'_lay_2_odds').text();
              const layprevOddsRate2 = $('#' + marketId +'_lay_2_odds_hiddne').val();
              if (currOddsRate2 === prevOddsRate2) {
                $('#' + marketId +'_back_2_odds').removeClass('yellow');
              } else {
                $('#' + marketId +'_back_2_odds').addClass('yellow');
              }
              if (laycurrOddsRate2 === layprevOddsRate2) {
                $('#' + marketId +'_lay_2_odds').removeClass('yellow');
              } else {
                $('#' + marketId +'_lay_2_odds').addClass('yellow');
              }
            }
            $("#" + marketId + "_back_1_odds_hiddne").val("3.05");
            $("#" + marketId + "_lay_1_odds_hiddne").val((runtypes && runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToLay[0]) ? runtypes[0].ex.availableToLay[0].price : '-');
            $("#" + marketId + "_back_3_odds_hiddne").val((runtypes && runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
            $("#" + marketId + "_lay_3_odds_hiddne").val((runtypes && runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');

            // Start Blinking Rate change Odds
            const currOddsRate =  $('#' + marketId +'_back_1_odds').text();
            const prevOddsRate = $('#' + marketId +'_back_1_odds_hiddne').val();
            const currOddsRate1 =  $('#' + marketId +'_back_3_odds').text();
            const prevOddsRate1 = $('#' + marketId +'_back_3_odds_hiddne').val();


            const laycurrOddsRate =  $('#' + marketId +'_lay_1_odds').text();
            const layprevOddsRate = $('#' + marketId +'_lay_1_odds_hiddne').val();
            const laycurrOddsRate1 =  $('#' + marketId +'_lay_3_odds').text();
            const layprevOddsRate1 = $('#' + marketId +'_lay_3_odds_hiddne').val();

            // console.log(prevOddsRate)
            if (currOddsRate === prevOddsRate) {
              $('#' + marketId +'_back_1_odds').removeClass('yellow');
            } else {
              $('#' + marketId +'_back_1_odds').addClass('yellow');
            }
            if (currOddsRate1 === prevOddsRate1) {
              $('#' + marketId +'_back_3_odds').removeClass('yellow');
            } else {
              $('#' + marketId +'_back_3_odds').addClass('yellow');
            }

            if (laycurrOddsRate === layprevOddsRate) {
              $('#' + marketId +'_lay_1_odds').removeClass('yellow');
            } else {
              $('#' + marketId +'_lay_1_odds').addClass('yellow');
            }
            if (laycurrOddsRate1 === layprevOddsRate1) {
              $('#' + marketId +'_lay_3_odds').removeClass('yellow');
            } else {
              $('#' + marketId +'_lay_3_odds').addClass('yellow');
            }

            $("#" + marketId + "_back_1_odds").html((runtypes && runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToBack[0]) ? runtypes[0].ex.availableToBack[0].price : '-');
            $("#" + marketId + "_lay_1_odds").html(( runtypes && runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToLay[0]) ? runtypes[0].ex.availableToLay[0].price : '-');
            if (runners == 3) {
              $("#" + marketId + "_back_3_odds").html(( runtypes && runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
              $("#" + marketId + "_lay_3_odds").html((runtypes &&  runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');
              $("#" + marketId + "_back_2_odds").html((runtypes && runtypes[2] && runtypes[2].ex && runtypes[2].ex.availableToBack[0]) ? runtypes[2].ex.availableToBack[0].price : '-');
              $("#" + marketId + "_lay_2_odds").html(( runtypes && runtypes[2] && runtypes[2].ex && runtypes[2].ex.availableToLay[0]) ? runtypes[2].ex.availableToLay[0].price : '-');
            } else {
              if (runtypes[1] && runtypes[1].ex) {
                $("#" + marketId + "_back_3_odds").html((runtypes && runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
                $("#" + marketId + "_lay_3_odds").html((runtypes && runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');
              }
            }
          }
        }
      });
    }
  }

  getMarketOddsItem() {
    let reply = localStorage.getItem("dashboardData")
    console.log('reply',reply);
    if(reply){
      reply = JSON.parse(reply);
      reply =    _.filter(reply, function (e) {
        e.doc = _.filter(e.doc, function (i) {
          // i.data = _.filter(i.data, function (p) {
          if(i.isPlay == true){
            return i;
          }
          // })
          // if(i.data.length > 0){
          //   return i;
          // }

        })
        if(e.doc.length > 0) {
          return e;
        }
      })
      let gfg = _.sortBy(reply, ['doc[0].sportUrl.displayOrder']);
      let count = 0;
      _.map(gfg, function (e) {
          count = count + e.doc.length;

      })
      this.inplayCount = count;
      console.log('inplayMatch',gfg);
      localStorage.setItem('inplayMatch',JSON.stringify(gfg));

    }
  }
  getMarketOddsData(type) {
    this.marketService.getAllMarketForDashboardClient(this.marketFilter, false).subscribe(response => {
      console.log('response',response);
      localStorage.setItem('dashboardData', JSON.stringify(response.data));
      if(response.data){
        if(type == 'inplay' ){
        this.dashboardData('inplay');
        }
        let cricket;
        let soccer;
        let tennis;
        let election;
        _.map(response.data, function (e) {
          if(e._id == 4){
             cricket =  e.doc.length;
            localStorage.setItem('cricketData', JSON.stringify(e));
          } else if(e._id == 1){
            soccer =  e.doc.length;
            localStorage.setItem('tennisData', JSON.stringify(e));
          }else if(e._id == 2){
            tennis =  e.doc.length;
            localStorage.setItem('soccerData', JSON.stringify(e));
          }else{
            election =  e.doc.length;
            localStorage.setItem('otherSport', JSON.stringify(e));
          }
        });
        this.cricketCount = cricket;
        this.soccerCount = soccer;
        this.tennisCount = tennis;
        this.electionCount = election;
      }
    });   
  }

  
  /*
    Developer: Ravi
    Date: 09-jan-2020
    title: Login method from backend
    Use: This function is use login from backend
  */

  login() {
    let urs = this.loginObject.userName.replaceAll(' ','');
    this.loginObject.userName = urs;
    // if(this.endSubmit) {
    //   return;
    // }
    // this.endSubmit = true;
    this.lsRememberMe();
    let key = env.constantKey();
    this.userService.loginApi(this.loginObject).subscribe(resposne =>{
      this.endSubmit = false;
      resposne = this.utilityService.gsk(resposne.auth);
      resposne = JSON.parse(resposne);
      if(resposne.status === true){
        var descryptJson = aes256.decrypt(key, resposne.data);
        let resposne1 = JSON.parse(descryptJson);
        let userData = JSON.stringify(resposne1);
        console.log('resposne.isAuthEnable',resposne1.isAuthEnable);
        console.log('resposne.isAuthVerify',resposne1.isAuthVerify);
        
        console.log('resposne1.changePassword++++++++',resposne1.changePassword);
        // if(resposne1.changePassword == true){
        //   this.tokenObj.authSecret = resposne1.authSecret; 
        //   this.tokenObj.userId = resposne1.user_id;
        //   this.tokenObj.respons1 = resposne1;
        //   this.tokenObj.userData = userData;
        //   this.tokenObj.response = resposne;
        //   this.loginModal.hide();
        //   this.ask2fModal.show();
        // }else{
          if(resposne1.isAuthEnable == true && resposne1.isAuthVerify == true){
            this.tokenObj.authSecret = resposne1.authSecret; 
            this.tokenObj.userId = resposne1.user_id;
            this.tokenObj.respons1 = resposne1;
            this.tokenObj.userData = userData;
            this.tokenObj.response = resposne;
            this.loginModal.hide();
            this.tokenModal.show();
          }else{
            sessionStorage.setItem('balance', 'true');
            sessionStorage.setItem('available', 'true');
            sessionStorage.setItem('exposure', 'true');
            sessionStorage.setItem('pl', 'true');
            let userId = resposne1.user_id;
            let userType = resposne1.userType;
            let ip = resposne1.ipAddress;
            let dateTime = resposne1.dateTime;
            var userData1 = aes256.encrypt(key, userData);
            var userId1 = aes256.encrypt(key, userId);
            var ip1 = aes256.encrypt(key, ip);
            var dateTime1 = aes256.encrypt(key, dateTime);
            localStorage.setItem('userId', userId1);
            sessionStorage.setItem('userId', userId1);
            localStorage.setItem('token', resposne.token);
            sessionStorage.setItem('token', resposne.token);
            // this.closeLogin();
            // if(resposne1.changePassword === true){
              localStorage.setItem('userData', userData1);
              localStorage.setItem('ip', ip1);
              localStorage.setItem('dateTime', dateTime1);
  
              if(resposne1.allowStock != true){
                sessionStorage.setItem('theme', resposne1.themeName);
                localStorage.setItem('theme', resposne1.themeName);
                $('#mode').attr("href", resposne1.themeName);
                if(userType == 'CLIENT'){
                  // this.getMarketOdds(userId);
                  this.getInitialRate();
                this.router.navigate(['/dashboard']);
                }else{
                  this.showToster('Error','error', 'invalid userName and password!');
                }
              }
  
            // }else{
            //   this.passwordChange.show();
            // }
  
            if(resposne1.changePassword === true){
              if(userType == 'CLIENT'){
                // this.getMarketOdds(userId);
                this.getInitialRate();
                this.router.navigate(['/dashboard']);
              }else{
                this.showToster('Error','error', 'invalid userName and password!');
              }
            }
          }
        //}
        
      }else{

        // this.loginObject.password = '';
        this.showToster('Error','error', resposne.message);
      }
    }, error =>{

      let input = document.getElementById('userName');
      input.focus();
      // this.loginObject.password = '';
      this.loginForm.resetForm();
      this.showToster('Error','error', error.error.message);
    });
  }

  demologin(){
   let loginObj = {
      userName: 'demologin',
      password: 'Pass@200',
      deviceId : "",
      fcmToken : "",
      remember : false,
      channel : "WEB",
      userType : "CLIENT",
      code : env.PROJECT_CODE
    };
    console.log('loginObj',loginObj);
    let key = env.constantKey();
    this.userService.loginApiDemo(loginObj).subscribe(resposne =>{
      resposne = this.utilityService.gsk(resposne.auth);
      console.log('resposne',resposne);
      resposne = JSON.parse(resposne);
      
      if(resposne.status == true){
        localStorage.setItem('demoLogin', 'true');
        var descryptJson = aes256.decrypt(key, resposne.data);
        let resposne1 = JSON.parse(descryptJson);
        let userData = JSON.stringify(resposne1);
        console.log('resposne.isAuthEnable',resposne1.isAuthEnable);
        console.log('resposne.isAuthVerify',resposne1.isAuthVerify);
        
        console.log('resposne1.changePassword++++++++',resposne1.changePassword);
        sessionStorage.setItem('balance', 'true');
        sessionStorage.setItem('available', 'true');
        sessionStorage.setItem('exposure', 'true');
        sessionStorage.setItem('pl', 'true');

        let userId = resposne1.user_id;
        let userType = resposne1.userType;
        let ip = resposne1.ipAddress;
        let dateTime = resposne1.dateTime;
        var userData1 = aes256.encrypt(key, userData);
        var userId1 = aes256.encrypt(key, userId);
        var ip1 = aes256.encrypt(key, ip);
        var dateTime1 = aes256.encrypt(key, dateTime);
        localStorage.setItem('userId', userId1);
        sessionStorage.setItem('userId', userId1);
        localStorage.setItem('token', resposne.token);
        sessionStorage.setItem('token', resposne.token);
        // this.closeLogin();
        // if(resposne1.changePassword === true){
          localStorage.setItem('userData', userData1);
          localStorage.setItem('ip', ip1);
          localStorage.setItem('dateTime', dateTime1);

          if(resposne1.allowStock != true){
            sessionStorage.setItem('theme', resposne1.themeName);
            localStorage.setItem('theme', resposne1.themeName);
            $('#mode').attr("href", resposne1.themeName);
            if(userType == 'CLIENT'){
              // this.getMarketOdds(userId);
              this.getInitialRate();
            this.router.navigate(['/dashboard']);
            }else{
              this.showToster('Error','error', 'invalid userName and password!');
            }
          }

        // }else{
        //   this.passwordChange.show();
        // }

        if(resposne1.changePassword === true){
          if(userType == 'CLIENT'){
            // this.getMarketOdds(userId);
            this.getInitialRate();
            this.router.navigate(['/dashboard']);
          }else{
            this.showToster('Error','error', 'invalid userName and password!');
          }
        }

      }
    });
  }
  

  register(form: NgForm){

    if(this.endSubmit) {
      return;
    }
    this.endSubmit = true;
    this.registerObject.dialCode = this.countryCode.dialCode;
    this.registerObject.countryName = this.countryCode.name;
    this.userService.registerApi(this.registerObject).subscribe(resposne =>{
      this.endSubmit = false;
      this.spinner.hide();
      resposne = this.utilityService.gsk(resposne.auth);
      resposne = JSON.parse(resposne);
      if(resposne.status == true){
        this.otpuserId = resposne.data._id;
        this.closeRegister();
        // this.loginObject = {
        //   channel: "WEB",
        //   deviceId: "",
        //   fcmToken: "",
        //   password: this.registerObject.password,
        //   userName:  this.registerObject.userName,
        //   userType: "CLIENT"
        // }
        this.loginPassword = this.registerObject.password;
        this.loginUsername = this.registerObject.userName;
        form.resetForm();
        this.otpModal.show();
        this.startCountdownTimer();
        this.showToster('Success','success', resposne.message);
      }else{
        this.showToster('Error','error', resposne.message);
      }
    }, error =>{
      this.showToster('Error','error', error.error.message);
    })
  }


  /*
      Developer: Ravi
      Date: 05-mar-2020
      title: Toster message
      Use: This function is use to throgh toster message
    */

  showToster(title,type,message){
    this.Toast.title = title;
    this.Toast.type = type;
    this.Toast.body = message;
    this.commonService.popToast(type, title, 1500, message)
  }

  showPass(){
    this.showPassword = (this.showPassword === true) ?  false : true;
  }
  showPass1(){
    this.showPassword1 = (this.showPassword1 === true) ?  false : true;
  }
  showPass2(){
    this.showPassword2 = (this.showPassword2 === true) ?  false : true;
  }


  // closeLogin(){
  //   this.loginModal.hide();
  // }
  closeRegister(){
    this.registerModal.hide();
  }
  closeModal(){
    this.passwordChange.hide();
  }
  closeOtp(){
    this.otpModal.hide();
  }
  loginModalData(){
    this.loginModal.show();
  }
  closeLogin(){
    this.loginModal.hide();
  }
  closeToken(){
    this.tokenModal.hide();
  }
  registerModalData(){
    this.registerModal.show();
  }
  passChange() {
    let stockUserId = this.utilityService.returnLocalStorageData('stockUserId');
    if(stockUserId ==  false){
      let data = {
        oldPass: this.utilityService.returnEncrypt(this.oldPassword),
        newPass: this.utilityService.returnEncrypt(this.newPassword),
        cinfNewPass: this.utilityService.returnEncrypt(this.confNewPassword),
        userId: this.utilityService.returnLocalStorageData('userId'),
        stockUserId : stockUserId
      };
      this.userService.passwordChange(data).subscribe(response => {
        this.closeModal();
        if (response.status === true) {
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(['/login']);
        } else {
          this.commonService.popToast('error', 'Error', 1500, 'password does not match.');
          this.router.navigate(['/login']);
        }
      }, error => {
        this.closeModal();

        this.commonService.popToast('error', 'Error', 1500, 'password does not match.');
        this.router.navigate(['/login']);
        console.error("error in logout");
      });
    }else{
      this.userService.getNewToken().subscribe(responseToken => {
        if(responseToken.status == true){
          let refreshToken = responseToken.data.refresh_token;
          this.utilityService.storeRefreshToken(refreshToken);
          let data = {
            oldPass: this.utilityService.returnEncrypt(this.oldPassword),
            newPass: this.utilityService.returnEncrypt(this.newPassword),
            cinfNewPass: this.utilityService.returnEncrypt(this.confNewPassword),
            userId: this.utilityService.returnLocalStorageData('userId'),
            stockUserId : stockUserId,
            accessToken : responseToken.data.access_token
          };
          this.userService.passwordChange(data).subscribe(response => {
            this.closeModal();

            if (response.status === true) {
              localStorage.clear();
              sessionStorage.clear();
              this.router.navigate(['/login']);
            } else {
              this.commonService.popToast('error', 'Error', 1500, 'password does not match.');
              this.router.navigate(['/login']);
            }
          }, error => {
            this.closeModal();

            this.commonService.popToast('error', 'Error', 1500, 'password does not match.');
            this.router.navigate(['/login']);
            console.error("error in logout");
          });
        }
      });
    }
  }
  checkValue(event){
    this.age18year = event.target.checked;
  }

  getNumber(event){
    console.log(event);
  }

  telInputObject(event){
    console.log(event);
  }

  onCountryChange(event){
    let obj = {
      dialCode: event.dialCode,
      iso2: event.iso2,
      name: event.name
    }
    this.countryCode = obj;
  }

  verifyOtp(){
    this.spinner.show();
    if(this.endSubmit) {
      return;
    }
    this.endSubmit = true;
    let otp =$("#otp").val();
    let otpObj = {
      userId : this.otpuserId,
      otp : otp
    }
    this.userService.verifyOtp(otpObj).subscribe(resposne =>{
      this.endSubmit = false;
      this.spinner.hide();
      resposne = this.utilityService.gsk(resposne.auth);
      resposne = JSON.parse(resposne);
      if(resposne.status == true){
        this.loginObject = {
          channel: "WEB",
          deviceId: "",
          fcmToken: "",
          password: this.loginPassword,
          userName:  this.loginUsername,
          remember: false,
          userType: "CLIENT",
          code: '325'
        }
        this.otpModal.hide();
        this.login();
      }else{
        this.showToster('Error','error', resposne.message);
      }
    })
  }


  startCountdownTimer() {
    const interval = 1000;
    const duration = 120 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.stopCount = true;
      })
      .takeUntil(Observable.timer(duration + interval))
      .map(value => (duration - value * interval)/1000);
    stream$.subscribe(value => this.countdown = value);
  }

  resendOtp(){
    let otpObj = {
      userId : this.otpuserId
    }
    this.userService.resendOtp(otpObj).subscribe(resposne =>{
      resposne = this.utilityService.gsk(resposne.auth);
      resposne = JSON.parse(resposne);
      if(resposne.status == true){
        this.showToster('Success','success', resposne.message);
        this.stopCount = false;
        this.startCountdownTimer();
      }else{
        this.showToster('Error','error', resposne.message);
      }
    });
  }
  downloadApk(link){

  }
  lsRememberMe() {
    console.log(this.loginObject)
    if (this.loginObject.remember == true && this.loginObject.userName != "" && this.loginObject.password != "") {
      localStorage.username = this.loginObject.userName;
      localStorage.password = this.loginObject.password;
      localStorage.checkbox = this.loginObject.remember;
    } else {
      localStorage.username = "";
      localStorage.password = "";
      localStorage.checkbox = "";
    }
  }
  

  VerifyToken(){
    console.log('this.tokenObj',this.tokenObj);
    let obj = {
      userId : this.tokenObj.userId,
      authSecret : this.tokenObj.authSecret,
      token : this.tokenObj.authToken
    }
    this.marketService.verifyQrCode(obj).subscribe(response => { 
        response = this.utilityService.gsk(response.auth);
        response = JSON.parse(response);
        if(response['status']==true){
          this.loginVeriyUser()
        }else{
          this.commonService.popToast('error', 'Error', 1500, "Invalid Token. Please try again");
        }
    })
  }

  closeask2Fa(){
    this.loginVeriyUser()
    let userobj = { 
      userId : this.tokenObj.userId,
      isAuthEnable : false
    }
    this.marketService.updateVerifyUser(userobj).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
        response = JSON.parse(response);
        console.log('response code',response);
    });
  }

  update2Fa(){
    let  obj = {
      userId : this.tokenObj.userId
    }
    console.log('obj',obj);
    this.marketService.getQrCode(obj).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      console.log('response code',response);
      if(response.status == true){
        this.getQrCodeModal.show();
        this.qrCode = response.data;
        this.authSecret = response.authSecret;
        this.tokenObj.authSecret = response.authSecret;
      }else{
        this.showToster('Error', 'error', 'Unable to generate code');
      }
    })
    // this.ask2fModal.hide();
  }

  verify2Fa(){
    console.log('this.tokenObj.verifyToken',this.tokenObj.verifyToken);
    if(!this.tokenObj.verifyToken){
      this.showToster('Error', 'error', 'Invalid code');
    }else{
      let obj = {
        userId : this.tokenObj.userId,
        authSecret : this.tokenObj.authSecret,
        token : this.tokenObj.verifyToken
      }
      console.log('obj',obj);
      this.marketService.verifyQrCode(obj).subscribe(response => {
        response = this.utilityService.gsk(response.auth);
        response = JSON.parse(response);
        if(response.status == true){
          this.showToster('Success', 'success', 'Code Verified');
          this.loginVeriyUser();
          let userobj = { 
            userId : this.tokenObj.userId,
            isAuthEnable : true
          }
          this.marketService.updateVerifyUser(userobj).subscribe(response => {
            response = this.utilityService.gsk(response.auth);
              response = JSON.parse(response);
              console.log('response code',response);
          });
        }else{  
          this.showToster('Error', 'error', 'Invalid code');
        }
      })
    } 
  }

  loginVeriyUser(){
    let key = env.constantKey();
    let resposne1 = this.tokenObj.respons1;
    let userData = this.tokenObj.userData;
    let resposne = this.tokenObj.response;
    this.commonService.popToast('success', 'Success', 1500, "Login Successfully");          
    sessionStorage.setItem('balance', 'true');
    sessionStorage.setItem('available', 'true');
    sessionStorage.setItem('exposure', 'true');
    sessionStorage.setItem('pl', 'true');
    let userId = resposne1.user_id;
    let userType = resposne1.userType;
    let ip = resposne1.ipAddress;
    let dateTime = resposne1.dateTime;
    var userData1 = aes256.encrypt(key, userData);
    var userId1 = aes256.encrypt(key, userId);
    var ip1 = aes256.encrypt(key, ip);
    var dateTime1 = aes256.encrypt(key, dateTime);
    localStorage.setItem('userId', userId1);
    sessionStorage.setItem('userId', userId1);
    localStorage.setItem('token', resposne.token);
    sessionStorage.setItem('token', resposne.token);
    // this.closeLogin();
    // if(resposne1.changePassword === true){
      localStorage.setItem('userData', userData1);
      localStorage.setItem('ip', ip1);
      localStorage.setItem('dateTime', dateTime1);

      if(resposne1.allowStock != true){
        sessionStorage.setItem('theme', resposne1.themeName);
        localStorage.setItem('theme', resposne1.themeName);
        $('#mode').attr("href", resposne1.themeName);
        if(userType == 'CLIENT'){
          // this.getMarketOdds(userId);
          this.getInitialRate();
        this.router.navigate(['/dashboard']);
        }else{
          this.showToster('Error','error', 'invalid userName and password!');
        }
      }

    // }else{
    //   this.passwordChange.show();
    // }

    if(resposne1.changePassword === true){
      if(userType == 'CLIENT'){
        // this.getMarketOdds(userId);
        this.getInitialRate();
        this.router.navigate(['/dashboard']);
      }else{
        this.showToster('Error','error', 'invalid userName and password!');
      }
    }
  }
}


