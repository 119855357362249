import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SportsModule } from './sports/sports.module';
import {SocketService} from './globals/socketService';
import { SocketServiceClient } from './globals/socketServiceClient';
import { FancyService } from './services/fancy.service';
import { PlacebetService } from './services/placebet.service';
import { FormsModule }   from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxSpinnerService } from "ngx-spinner";
import {ToasterModule} from 'angular2-toaster';
import { ReportsModule } from './reports/reports.module';
import {commonDerectivenModule} from "./auth-gaurd/commonDerective.module";
import {AuthGuard} from "./auth-gaurd/auth-guard.service";
import {DefaultRequestOptions} from "./auth-gaurd/default-request-options.provider";
import {ModalModule} from 'ngx-bootstrap';
import {UtilityService} from "./globals/utilityService";
import { UserIdleModule } from 'angular-user-idle';
import {SocketServiceRedis} from './globals/socketServiceRedis';
import {SocketServiceRedisMarket} from './globals/socketServiceRedisMarket';
import { StudioService } from './services/studio.service';
import {DemoMaterialModule} from '../material-module';
import {VersionCheckService} from './services/version-check.service';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DreamCasinoModule } from './dream-casino/dream-casino.module';
import {Ng2TelInputModule} from 'ng2-tel-input';

import {SocketServiceFancyRedis} from "./globals/socketServiceFancyRedis";
import {ScoreCard} from "./globals/scoreCard";
import {DeviceDetectorService} from './globals/device-detector.service';
import { TvComponent } from './tv/tv.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    TvComponent,
    // NewUserComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ToasterModule.forRoot(),
    SportsModule,
    DreamCasinoModule,
    ReportsModule,
    SlickCarouselModule,
    ModalModule.forRoot(),
    NgxSpinnerModule,
    commonDerectivenModule,
    Ng2SearchPipeModule,
    UserIdleModule.forRoot({idle: 3600, timeout: 10, ping: 10}),
    Ng2TelInputModule,
    // DepositeModule,
    // WithdrawModule,
  ],

  providers: [SocketService,
    SocketServiceClient,
    SocketServiceRedis,
    SocketServiceFancyRedis,
    ScoreCard,
    FancyService,
    PlacebetService,
    StudioService,
    SocketServiceRedisMarket,
    NgxSpinnerService,
    VersionCheckService,
    UtilityService,
    DeviceDetectorService,
    AuthGuard ,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultRequestOptions,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
