import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/internal/operators';
import {isUndefined} from 'util';
import * as env from '../globals/env';


@Injectable({
  providedIn: 'root'
})
export class PlacebetService {

  server_url: any = env.adminServer_url();
  office_url: any = env.office_url();


  constructor(private http: HttpClient) { }

  /**
   *@author Ravi Kadia
  * @date 06-03-2020
  * @param filter
  * @returns {Observable<any>}
  * Add place bet
**/

  addPlaceBet(data): Observable<any> {
    return this.http.post(this.server_url + 'placebet', data)
      .pipe(tap(_ => this.log(`get all market successfully`)));
  }

  /**
   *@author Ravi Kadia
  * @date 26-05-2021
  * @param filter
  * @returns {Observable<any>}
  * Casino place bet
**/

  placeBetCasinoLive(data): Observable<any> {
    return this.http.post(this.server_url + 'placebet/placebetCasino', data)
      .pipe(tap(_ => this.log(`get all market successfully`)));
  }

    /**
   *@author Ravi Kadia
  * @date 12-03-2020
  * @param filter
  * @returns {Observable<any>}
  * Check latest rate
**/

checkLatestRate(data): Observable<any> {
  return this.http.post(this.office_url + 'market/check-rate', data)
    .pipe(tap(_ => this.log(`check current rate`)));
}

 /**
   *@author Ravi Kadia
  * @date 12-03-2020
  * @param filter
  * @returns {Observable<any>}
  * Get sharing
**/

getSharing(data): Observable<any> {
  return this.http.post(this.server_url + 'user/clientMasterPartnership', data)
    .pipe(tap(_ => this.log(`get sharing`)));
}


/**
   *@author Ravi Kadia
  * @date 12-03-2020
  * @param filter
  * @returns {Observable<any>}
  * Get fancy chart
**/

getFancyChart(data): Observable<any> {
  return this.http.post(this.server_url + 'placebet/get-fancy-chart', data)
    .pipe(tap(_ => this.log(`get fancy chart`)));
}


checkBasicValidation(data): Observable<any> {
  return this.http.post(this.server_url + 'placebet/check-basic-validations', data)
    .pipe(tap(_ => this.log(`get fancy chart`)));
}

getExploserMatchData (data): Observable<any> {
  return this.http.post(this.server_url + 'placebet/get-match-vise-exposers', data)
    .pipe(tap(_ => this.log(`get match vise exposers`)));
}

getAcSummaryReport(filter): Observable<any> {
  return this.http.post(this.server_url + 'chipscash/getAcSummary' , filter)
    .pipe(tap(_ => this.log(`get User Opening Balace`)));
}

getUserTransaction(filter): Observable<any> {
  return this.http.post(this.server_url + 'placebet/get-all-transactions-user' , filter)
    .pipe(tap(_ => this.log(`get User all transaction`)));
}
  log(message) {
    console.log(message);
  }
}
