import {Component, OnInit, ViewChild, Input, Inject, LOCALE_ID, AfterViewInit} from '@angular/core';
import * as moment from 'moment';
import {NgxSpinnerService} from "ngx-spinner";
import * as env from '../../globals/env';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ModalDirective} from 'ngx-bootstrap';
import {UtilityService} from '../../globals/utilityService';
import { CommonService } from 'src/app/services/common.service';
import { formatDate } from '@angular/common';
import { MarketService } from '../../services/market.service';
declare let $: any;
declare let _: any;


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-match-wise-ac-statement',
  templateUrl: './match-wise-ac-statement.component.html',
  styleUrls: ['./match-wise-ac-statement.component.scss']
})
export class MatchWiseAcStatementComponent implements OnInit {
  @ViewChild('transactionModel', {static: false}) transactionModel: ModalDirective;
  minDate: any;
  @Input() dateChanged: any;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  dtTrigger = new Subject();
  resData: any;
  server_url: any = env.server_url();

  constructor(private utilityService: UtilityService,
              private http: HttpClient,
              private commonService: CommonService,
              private marketService: MarketService,
              @Inject(LOCALE_ID) private locale: string,
              private spinner: NgxSpinnerService) {
  }

  filter = {
    id: this.utilityService.returnLocalStorageData('userId'),
    page: 1,
    limit: 10,
    startDate: null,
    endDate: null,
    sport: 4,
    type: 'GAME'

  };
  fromDate: any;
  total = 0;
  balance = 0;
  sport = 4;
  tournamentId: any;
  tournamentsList = [];
  selectedTournamentsItems: any;
  TournamentsSettings = {
    singleSelection: true,
    text: 'Select User',
    selectAllText: 'Select',
    unSelectAllText: 'UnSelect',
    enableSearchFilter: true,
  };
  tournamentFilter = {
    page: 1,
    limit: 5,
    search: null,
    id: null,
  };
  toDate: any;
  sDate: any;
  eDate: any;
  chipsTransactionData: any;
  chipsTransactionDataDetails: any;
  userTransactionRes: any;
  disabled: boolean = true;
  betlistFind: any;
  preBal: any = null;
  OpeningBalace: any = null;
  OpeningBalaceDate: any = null;
  rawNO: any = 0;
  responseData: any;
  jsonData: any = [];
  matchNameVaule = '';
  matchListSerchdata: any;

  ngOnInit(): void {
    // this.downTreeUser();
    let pre = moment().format("YYYY-MM-DD");
    let endDate = moment().subtract(1, 'd').format("YYYY-MM-DD");

    this.filter.startDate = endDate;
    this.filter.endDate = pre;

    let ePreShow = moment().format("DD/MM/YYYY");
    let sPreShow = moment().subtract(1, 'd').format("DD/MM/YYYY");
    this.sDate = sPreShow;
    this.eDate = ePreShow;

    this.getAcSummaryReport();
  }

  /**
   * @author kc
   * @date : 28-01-2020;
   * sport search with name
   */
  // onTornamentSearch(e) {
  //
  //   this.tournamentFilter.search = e.target.value;
  //   if (this.tournamentFilter.search.length > 2) {
  //     this.tournamentFilter.id = this.utilityService.returnLocalStorageData('userId');
  //     // this.downTreeUser();
  //   }
  // }

  // downTreeUser() {
  //   this.tournamentFilter.id = this.utilityService.returnLocalStorageData('userId');
  //   this.marketService.getDownTreeUser(this.tournamentFilter).subscribe(response => {
  //     this.tournamentsList = response.data.map(data => {
  //       return {id: data._id, itemName: data.userName, tournamentId: data._id};
  //     });
  //   });
  // }

  /**
   * @author kc
   * @date : 30-01-2019
   * on tournament select relavent match api call
   */
  onTornamentSelect(e) {
    this.filter.id = e.tournamentId;
    console.log(e)
    // this.getOpeningBalanceData();
    this.getAcSummaryReport();
  }

  /**
   * @author TR
   * @date 23-06-2020
   * GetAll AcSummaryReport
   * @param data
   */
  getAcSummaryReport() {
    this.tournamentsList = [];
    if (this.filter.startDate === null) {
      delete this.filter.startDate
    }
    if (this.filter.endDate === null) {
      delete this.filter.endDate
    }
    console.log(this.filter)
    this.rerender();
    this.preBal = null;
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,   // -- trying to set to 5 records only
      paging: true,
      deferLoading: 0,
      order: [[0, ""]],
      serverSide: true,
      processing: true,
      responsive: true,
      lengthChange: true,
      lengthMenu: [50, 75, 100, 200],
      language: {
        lengthMenu: '_MENU_',
        zeroRecords: '',
        searchPlaceholder: 'search',
        search: "",
        emptyTable: 'No record found',
        paginate: {
          first: '<i class="fa fa-chevron-double-left">', last: '<i class="fa fa-chevron-double-right">',
          next: '<i class="fa fa-chevron-right">',
          previous: '<i class="fa fa-chevron-left">'
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        let url = this.server_url + 'chipscash/getplSummaryByMatchWise';
        that.http
          .post<DataTablesResponse>(
            url,
            Object.assign(dataTablesParameters, {data: this.filter}),
            {}
          ).subscribe(resp => {
          this.responseData = resp;
          this.responseData = this.utilityService.gsk(this.responseData.auth);
          this.responseData = JSON.parse(this.responseData);
          if (this.responseData && this.responseData.status) {
            this.chipsTransactionData = this.responseData.data.data;
            $('.respo-scroll').addClass('displayBl');
            this.findsum(this.chipsTransactionData);
          }
          this.spinner.hide();
          this.dtTrigger.next();
          callback({
            recordsTotal: this.responseData.data.totalCount,
            recordsFiltered: this.responseData.data.totalCount,
            data: []
          });
        });
      },

      columns: [{data: ''}, {data: 'sport'}, {data: 'match'}, {data: 'debit'}, {data: 'credit'}],
      columnDefs: [{orderable: false, targets: [0]}, {orderable: false, targets: [1]}, {orderable: false, targets: [2]}, {
        orderable: false,
        targets: [3]
      }]
    };
  }

  rerender(): void {
    $('.dataTable').dataTable().fnClearTable();
  }

  clearDate(e) {
    if (e.target.value === '') {
      this.getAcSummaryReport()
    }
  }

  searchFilterData() {
    this.responseData = [];
    this.chipsTransactionData = [];
    this.chipsTransactionDataDetails = [];
    this.spinner.show();
    this.OpeningBalaceDate = null;
    this.rawNO = 0;
    if (this.fromDate) {
      this.filter.startDate = (this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day);

    }
    if (this.toDate) {
      this.filter.endDate = (this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day);
    }
    if (this.sport) {
      this.filter.sport = this.sport
    }
    this.getAcSummaryReport();
  }

  clearFilter() {
    this.spinner.show();
    this.selectedTournamentsItems = '';
    this.OpeningBalaceDate = null;
    this.rawNO = 0;
    this.sDate = '';
    this.eDate = '';
    this.filter.startDate = null;
    this.filter.endDate = null;
    this.fromDate = null;
    this.toDate = null;
    this.sport = 4;
    let pre = moment().format("YYYY-MM-DD");
    let endDate = moment().subtract(3, 'd').format("YYYY-MM-DD");

    this.filter.startDate = endDate;
    this.filter.endDate = pre;

    let ePreShow = moment().format("DD/MM/YYYY");
    let sPreShow = moment().subtract(3, 'd').format("DD/MM/YYYY");
    this.sDate = sPreShow;
    this.eDate = ePreShow;
    this.filter = {
      id: this.utilityService.returnLocalStorageData('userId'),
      page: 1,
      limit: 10,
      startDate: this.filter.startDate,
      endDate: this.filter.endDate,
      sport: this.sport,
      type: ''
    };
    this.getAcSummaryReport();
    // this.getOpeningBalanceData();
    this.matchListSerch();
  }


  /**
   * @author kc
   * @date 30-04-2019
   * changed page based on current page
   * @param p
   */
  pageChange(p): void {
    this.filter.page = p.page;
    this.getAcSummaryReport();
  }


  /**
   * @author subhash
   * @date 20-08-2019
   * date bulding
   */
  onDateSelect(e) {
    if (e.year) {
      this.toDate = null;
      this.disabled = false;
      this.minDate = {
        year: e.year,
        month: e.month,
        day: e.day + 1
      };
      var dt = new Date();

      let year = dt.getFullYear();
      let month = ('0' + ((dt.getMonth()) + 1)).slice(-2);
      let date = ('0' + (dt.getDate() - 7)).slice(-2);
      if (('0' + ((dt.getMonth() - 1) + 1)).slice(-2) === '00') {
        year = year - 1;
        month = '12'
      }
      let endDate = dt.getFullYear() + "-" + ('0' + (dt.getMonth() + 1)).slice(-2) + "-" + ('0' + (dt.getDate())).slice(-2);

      this.eDate = ('0' + (dt.getDate())).slice(-2) + "/" + ('0' + (dt.getMonth() + 1)).slice(-2) + "/" + dt.getFullYear();

    } else {
      this.disabled = true;
    }
  }


  /**
   * get all match list
   */
  matchListSerch() {
    let data = {
      startDate: this.filter.startDate,
      endDate: this.filter.endDate
    };
    this.marketService.getMatchListSerch(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      response.data = _.uniq(response.data, 'id');
      this.matchListSerchdata = response.data;
    }, error => {
      console.error('error');
    });
  }

  findsum(data) {
    this.total = 0;
    for (let j = 0; j < data.length; j++) {
      this.total += data[j].total;
    }
  }

  findsum1(data) {
    this.balance = 0;
    for (let j = 0; j < data.length; j++) {
      this.balance += data[j].balance;
    }
  }

  selectUser(user) {
    this.selectedTournamentsItems = user.itemName;
    this.filter.id = user.tournamentId;
    this.tournamentsList = [];
  }

  AllMarket(data) {
    this.chipsTransactionData = [];
    this.chipsTransactionDataDetails = data.Data;
    this.total = data.total;
  }

  AllMarketBets(data) {
    let datas = {
      gameId: data.gameId,
      userId: data.userId
    };

    this.marketService.getEventWiseTransaction(datas).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      this.userTransactionRes = response.data;
      this.transactionModel.show();
    }, error => {
      console.error('error');
    });
  }

  closeTransactionModel() {
    this.transactionModel.hide();
  }
}
