import { Component, OnInit } from '@angular/core';
import {CommonService} from '../../services/common.service';
import {UserService} from '../../services/user.service';
import {UtilityService} from '../../globals/utilityService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  userExposer: any;
  userBalance: any;
  userPl: any;
  userAvaBalance: any;
  userName: any;
  constructor( private commonService: CommonService, private userService: UserService,private utilityService: UtilityService,private router: Router) { }

  ngOnInit() {
    let users = this.commonService.getLocalStorage();
    let userId = users.userId;
    this.getbalance(userId)
  }
  getbalance(id){
    let balanceObj = {
      ids: id
    };
    this.userService.getBalance(balanceObj).subscribe(res => {
      res = this.utilityService.gsk(res.auth);
      res = JSON.parse(res);
      if(res.status == false){
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      if (res.status == true) {
        this.userBalance = res.data[0].balance;
        this.userExposer = res.data[0].exposure;
        this.userPl = res.data[0].p_and_l;
        this.userAvaBalance = res.data[0].available_balance;
        this.userName = res.data[0].userName;
        // this.loginTime = res.data[0].updatedAt;
      }
    });
  }
}
