import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { UserService } from "../../services/user.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";
import {BinaryService} from '../../services/binary.service';
import {UtilityService} from '../../globals/utilityService';
import {formatDate} from '@angular/common';
import * as env from '../../globals/env';
declare let $: any;
declare let _: any;
@Component({
  selector: 'app-generate-bill',
  templateUrl: './generate-bill.component.html',
  styleUrls: ['./generate-bill.component.scss']
})
export class GenerateBillComponent implements OnInit {
  customeWeek = false;
  minDate: any;
  fromDate: any;
  toDate: any;
  @Input() dateChanged: any;
  disabled:boolean = true;
  server_url: any = env.adminServer_url();
  userId = this.utilityService.returnLocalStorageData('userId');
  adminServer_url: any = env.adminServer_url();
  stockUsernamePrefix = env.stockUsernamePrefix();


  constructor(private userService: UserService, private binaryService: BinaryService, private http: HttpClient, private spinner: NgxSpinnerService, private utilityService: UtilityService) {
  }

  betObjFilter = {
    user: Number(this.utilityService.returnLocalStorageData('stockUserId')),
    from: '',
    to: '',
    weekOption: 'thisweek',
  };
  localStorageData = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
  types: any;
  sDate: any;
  eDate: any;
  selectedUserItems: any;
  endSubmit = false;
  ngOnInit(): void {
    console.log(this.localStorageData)
    let pre = moment().format("YYYY-MM-DD");
    let endDate = moment().subtract(7, 'd').format("YYYY-MM-DD");

    this.betObjFilter.from = endDate;
    this.betObjFilter.to = pre;

    let ePreShow = moment().format("DD/MM/YYYY");
    let sPreShow = moment().subtract(1, 'd').format("DD/MM/YYYY");
    this.sDate = ePreShow;
    this.eDate = ePreShow;
    // this.getAllClient();
  }


  /**
   * @author TR
   * @date 23-06-2020
   * GetAll AcSummaryReport
   * @param data
   */


  searchFilterData() {
    if(this.endSubmit) {
      return;
    }
    this.endSubmit = true;
    let fromDate;
    let toDate;
    if(this.betObjFilter.weekOption == "thisweek"){
      let weekData = this.utilityService.getCurrentWeekSS();
      fromDate = weekData.fromDate;
      toDate = weekData.toDate;
    }else if(this.betObjFilter.weekOption == "previousweek"){
      let weekData = this.utilityService.getPreviousWeekDateSS();
      fromDate = weekData.fromDate;
      toDate = weekData.toDate;
    }else{
      if(this.fromDate && this.fromDate != ''){
        fromDate = this.fromDate.year + "/" + this.fromDate.month + "/" + this.fromDate.day;
        fromDate = formatDate(fromDate,'dd-MMM-yyyy', 'en')
      }else{
        fromDate = this.betObjFilter.from;
        fromDate = formatDate(fromDate,'dd-MMM-yyyy', 'en')
      }

      if(this.toDate && this.toDate != ''){
        toDate = this.toDate.year + "/" + this.toDate.month + "/" + this.toDate.day;
        toDate = formatDate(toDate,'dd-MMM-yyyy', 'en')
      }else{
        toDate = this.betObjFilter.to;
        toDate = formatDate(toDate,'dd-MMM-yyyy', 'en')
      }
    }
    this.userService.getNewToken().subscribe(responseToken => {
      if(responseToken.status == true){
        let refreshToken = responseToken.data.refresh_token;
        this.utilityService.storeRefreshToken(refreshToken);
        this.endSubmit = false;
        let obj = {
          UserId: this.betObjFilter.user,
          accessToken : responseToken.data.access_token
        }

        this.binaryService.getGenerateBill(obj).subscribe(userList => {
          if(userList){

            let url =  "https://reports.bettrader.live/" + "?UserId=" + userList.UserId + "&ToDate=" + toDate + "&FromDate=" + fromDate + "&UserName=" +  this.localStorageData.userName + this.stockUsernamePrefix + "&displayName="  + userList.DisplayName + this.stockUsernamePrefix;
            window.open(url, "_blank");
          }
        });
      }else{
        this.utilityService.userLogout();
      }
    });

  }


  /**
   * @author subhash
   * @date 20-08-2019
   * date bulding
   */

  onDateSelect(e) {
    if (e.year) {
      this.toDate = null;
      this.disabled = false;
      this.minDate = {
        year: e.year,
        month: e.month,
        day: e.day + 1
      };
      var dt = new Date();

      let year = dt.getFullYear();
      let month = ('0' + ((dt.getMonth()) + 1)).slice(-2);
      let date = ('0' + (dt.getDate() - 7)).slice(-2);
      if (('0' + ((dt.getMonth() - 1) + 1)).slice(-2) === '00') {
        year = year - 1;
        month = '12'
      }
      let endDate = dt.getFullYear() + "-" + ('0' + (dt.getMonth() + 1)).slice(-2) + "-" + ('0' + (dt.getDate())).slice(-2);

      // this.eDate = ('0' + (dt.getDate())).slice(-2)+ "/" + ('0' + (dt.getMonth() + 1)).slice(-2) + "/" + dt.getFullYear();
    } else {
      this.disabled = true;
    }
  }

  chnageWeek(e){
    if(e == "custome"){
      this.customeWeek = true;
    }else{
      this.customeWeek = false;
    }
    this.betObjFilter.weekOption = e;
  }


  getAllTransactionForMarket() {
    let userId = Number(this.utilityService.returnLocalStorageData('stockUserId'))
    let today = new Date();
    let fromDate = (this.fromDate) ? formatDate(this.fromDate, 'dd-MMM-yyyy', 'en') : formatDate(today, 'dd-MMM-yyyy', 'en');
    let toDate = (this.toDate) ? formatDate(this.toDate, 'dd-MMM-yyyy', 'en') : formatDate(today, 'dd-MMM-yyyy', 'en');
    this.sDate = moment(fromDate).format('DD/MM/YYYY');
    this.eDate = moment(toDate).format('DD/MM/YYYY');

  }

  clearDate(e) {
    if (e.target.value === '') {
      this.getAllTransactionForMarket();
    }
  }
}
