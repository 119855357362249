'use strict';
declare var require: any;
import {Component, OnInit, Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Toast, ToasterConfig, ToasterService} from 'angular2-toaster';
import { UserService } from '../services/user.service';
import { CommonService } from '../services/common.service';
import { SocketService } from './socketService';
import * as env from './env';
import {formatDate} from '@angular/common';

declare var $: any;
var aes256 = require('aes256');
@Injectable()

export class UtilityService {

  private toasterService: ToasterService;
  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-right',
    limit:2
  });
  roles:any;
  constructor(@Inject(DOCUMENT) private document: any,
              private router: Router,
              toasterService : ToasterService,
              private userService :UserService,
              private commonService : CommonService,
              private socketService : SocketService,
              private location: Location) {
    this.toasterService = toasterService;
  }

  popToast(type,title ,timeout, body ) {
    const toast: Toast = {
      type: type,
      title: title,
      timeout: timeout,
      body: body
    };
    this.toasterService.pop(toast);
  }

  returnLocalStorageData(pwd){

    let data =  localStorage.getItem(pwd);
    let key = env.constantKey();
    if(data){
      let dnzepto = aes256.decrypt(key, data);
      return dnzepto;
    }else{
      return false;
    }
  }

  gsk(auth){
    var firstTF = auth.substring(0, 25);
    firstTF = firstTF.substr(firstTF.length - 20);
    firstTF = this.reverseString(firstTF);
    let lastTF = auth.substring(25);
    let data = aes256.decrypt(firstTF,lastTF);
    return data;
  }

  reverseString(str){
    var splitString = str.split(""); // var splitString = "hello".split("");
    var reverseArray = splitString.reverse();
    var joinArray = reverseArray.join("");
    return joinArray;
  }

  userLogout() {
    this.userService.logOut().subscribe(response => {
      $("#mode").attr("href", 'assets/scss/custome-dark.css');
      this.commonService.popToast('success', 'Success', 1500, 'Logout successfully.');
      localStorage.clear();
      let lastJoinRoom = sessionStorage.getItem("lastJoinRoom");
      if (lastJoinRoom) {
        this.socketService.leaveRoom(lastJoinRoom);
      }
      this.socketService.disconnect();
      sessionStorage.clear();
      this.router.navigate(['']);
    }, error => {
      this.commonService.popToast('error', 'Error', 1500, error.message);
      console.error("error in logout");
    });
  }
  returnSessionStorageData(pwd){
    let data =  sessionStorage.getItem(pwd);
    let key = env.constantKey();
    if(data){
      let dnzepto = aes256.decrypt(key, data);
      return dnzepto;
    }else{
      return false;
    }
  }

  returnEncrypt(pwd){
    let key = env.constantKey();
    if(pwd){
      let enpozito = aes256.encrypt(key, pwd);
      return enpozito;
    }else{
      return false;
    }
  }


  getRandomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  storeRefreshToken(token){
    localStorage.setItem('refresh_token',token);
  }

  getCurrentWeek(){
    var now = new Date;
    var startDay = 1; //0=sunday, 1=monday etc.
    var d = now.getDay(); //get the current day
    var weekStart = new Date(now.valueOf() - (d<=0 ? 7-startDay:d-startDay)*86400000); //rewind to start day
    var weekEnd = new Date(weekStart.valueOf() + 4*86400000); //add 6 days to get last day
    let result = {
      toDate :  formatDate(weekEnd, 'dd-MMM-yyyy', 'en'),
      fromDate : formatDate(weekStart, 'dd-MMM-yyyy', 'en')
    }
    return result;
  }


  getCurrentWeekSS(){
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay() + 1; // First day is the  day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    var firstday = new Date(curr.setDate(first)).toUTCString();
    var lastday = new Date(curr.setDate(curr.getDate()+6)).toUTCString();

    let result = {
      toDate :  formatDate(lastday, 'dd-MMM-yyyy', 'en'),
      fromDate : formatDate(firstday, 'dd-MMM-yyyy', 'en')
    }
    return result;
  }


  getPreviousWeekDateSS(){
    var beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
      , day = beforeOneWeek.getDay()
      , diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
      , lastMonday = new Date(beforeOneWeek.setDate(diffToMonday))
      , lastSunday = new Date(beforeOneWeek.setDate(diffToMonday + 6));

    let result = {
      toDate : formatDate(lastSunday, 'dd-MMM-yyyy', 'en'),
      fromDate : formatDate(lastMonday, 'dd-MMM-yyyy', 'en')
    }
    return result;
  }

}
