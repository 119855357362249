import {Component, OnDestroy, OnInit, ViewChild,AfterViewInit} from '@angular/core';
import {MarketService} from "../services/market.service";
import {CurrencyService} from '../services/currency.service';
import {SocketService} from "../globals/socketService";

import {NgxSpinnerService} from "ngx-spinner";
import {UtilityService} from '../globals/utilityService';
import {SocketServiceClient} from '../globals/socketServiceClient';
import {SocketServiceRedis} from '../globals/socketServiceRedis';
import {SocketServiceRedisMarket} from '../globals/socketServiceRedisMarket';
import {ModalDirective} from 'ngx-bootstrap';

import { CommonService } from '../services/common.service';
import _ from "lodash";
import * as $ from "jquery";
import {ActivatedRoute, Router} from '@angular/router';
import * as env from '../globals/env';
import {ScoreCard} from "../globals/scoreCard";
import {StudioService} from '../services/studio.service';
// import firebase from "firebase";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit ,OnDestroy {
  // @ViewChild('otpModal', { static: false }) otpModal: ModalDirective;
  slideConfig = {"slidesToShow": 3, "slidesToScroll": 1, autoplaySpeed: 3000, speed: 1000, "autoplay": true,  "vertical": false , arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  };
  slideConfig1 = {"slidesToShow": 7, "slidesToScroll": 1, autoplaySpeed: 3000, speed: 1000, "autoplay": true,  "vertical": true , arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          infinite: true,
           dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
            slidesToShow: 1
        }
      }
    ]
  };
  constructor(private marketService: MarketService, private studioService: StudioService,  private scoreCard: ScoreCard,   private route: ActivatedRoute , private router: Router,private commonService: CommonService, private utilityService: UtilityService , private spinner: NgxSpinnerService, private socketService: SocketService, private socketServiceRedis: SocketServiceRedis, private socketServiceClient: SocketServiceClient,private socketServiceRedisMarket : SocketServiceRedisMarket,private currencyService :CurrencyService) {
  }



  marketFilter = {
    page: 1,
    limit: 10,
    search: null,
    data: null
  };
  contentHide = false;
  bannerImages: any;
  tennisCount = 1;
  electionCount = 1;
  soccerCount = 1;
  cricketCount = 1;
  inplayCount = 0;
  inplayAccess : any;
  messages: any;
  getAllMarkets = [];
  getAllSports = [];
  paramTypes = null;
  userId = this.utilityService.returnLocalStorageData('userId');
  selectedUniqIndx: any;
  runners: any;
  isCasino = false;
  casinoImages: any = [];
  Toast = {
    type: '',
    title: '',
    timeout: 0,
    body: ''
  };
  projectName: any = env.PROJECT_NAME;
  dummyRateResponse = localStorage.getItem('dummyRateResponse');
  gameList;
  groupList;
  groupLists;
  product = ['Evolution Gaming', 'eBET' ,'Super Spade Games', 'Pragmatic Play Live', 'Asia Gaming', 'Evoplay Entertainment', 'Betsoft', 'Ezugi'];
  gameLists;
  ngOnInit() {
    let datauser = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
    if(datauser.userType == 'CLIENT'){

    }else{
      this.router.navigate(['/login']);
    }

    this.getUserCurrency();
    this.getCasinoList();
    this.getCasinoLock(datauser);
    // this.casinoAuth();
    this.route.params.subscribe(params => {
      window.scrollTo(0, 0)
      if(datauser.userType == 'CLIENT'){

      }else{
        this.router.navigate(['/login']);
      }
     if(params.type){
      this.paramTypes = params.type;
       this.getMarketOddsData('');
       this.dashboardData(params.type);
     }else{
       this.paramTypes = null;
       this.getMarketOddsData('inplay');
     }
    });


    const oldMatch = JSON.parse(sessionStorage.getItem('matchOddsIds'));
    const oldfancy = JSON.parse(sessionStorage.getItem('lastJoinRoom'));
    let scoreIds =  localStorage.getItem('scorecardIds');
    this.scoreCard.leaveRoom(scoreIds);
    let socketFancy = this.socketServiceRedis;
    let socketMatchOdds = this.socketServiceRedisMarket;
    socketMatchOdds.joinRoom('marketRateDm');

    // _.map(oldfancy, function (e) {
    //   socketFancy.leaveRoom(e);
    // })
    // _.map(oldMatch, function (s) {
    //   socketMatchOdds.leaveRoom(s);
    // })


    this.socketServiceRedis
      .reconnections()
      .subscribe((response) => {
        this.getMarketOddsData('');
        this.route.params.subscribe(params => {
          if(params.type){
            this.paramTypes = params.type;
            this.dashboardData(params.type);

          }else{
            this.paramTypes = null;
          }
        });
        const oldMatch = JSON.parse(sessionStorage.getItem('matchOddsIds'));
        const oldfancy = JSON.parse(sessionStorage.getItem('lastJoinRoom'));
        let scoreIds =  localStorage.getItem('scorecardIds');
        this.scoreCard.leaveRoom(scoreIds);
        let socketFancy = this.socketServiceRedis;
        let socketMatchOdds = this.socketServiceRedisMarket;
        _.map(oldfancy, function (e) {
          socketFancy.leaveRoom(e);
        })
        _.map(oldMatch, function (s) {
          socketMatchOdds.leaveRoom(s);
        })
      });

    // this.selectedUniqIndx = 10;
    // this.getAllSportsData(this.selectedUniqIndx);



    this.socketService
      .changeFlag()
      .subscribe((response) => {
        if(response){
          // this.getAllSportsData('');
          // this.getMarketOddsData('');
        }
      });

    this.socketServiceClient
      .marketCreate()
      .subscribe((response) => {
        if(response){
          // this.getAllSportsData('');
          // this.getMarketOddsData('');
        }
      });

    this.socketServiceClient
      .updateSport()
      .subscribe((response) => {
        if(response){
          // this.getAllSportsData('');
          // this.getMarketOddsData('');
        }
      });

    this.socketServiceClient
      .updateTournament()
      .subscribe((response) => {
        if(response){
          // this.getAllSportsData('');
          // this.getMarketOddsData('');
        }
      });

    this.socketServiceClient
      .updateMatch()
      .subscribe((response) => {
        if(response){
          // this.getAllSportsData('');
          // this.getMarketOddsData('');
        }
      });

      this.socketServiceRedisMarket
      .oddsRate1()
      .subscribe((response1) => {
        if(response1 && response1.length > 0){
          response1 = JSON.parse(response1);
          _.map(response1, function (response){
            if (response) {
              if (response.status == 'OPEN') {
                let marketId = response.marketId.toString().replace('.', '');
                $('.' + marketId + '_markets').removeClass('market_suspends');
                let runtypes = response.runners;
                let runners = 0;
                if (response.numberOfActiveRunners) {
                  runners = response.numberOfActiveRunners;
                } else {
                  runners = response.runners.length;
                }
                if(runners == 3){
                  $("#" + marketId + "_back_2_odds_hiddne").val((runtypes[2].ex.availableToBack[0]) ? runtypes[2].ex.availableToBack[0].price : '-');
                  $("#" + marketId + "_lay_2_odds_hiddne").val((runtypes[2].ex.availableToLay[0]) ? runtypes[2].ex.availableToLay[0].price : '-');
                  const currOddsRate2 =  $('#' + marketId +'_back_2_odds').text();
                  const prevOddsRate2 = $('#' + marketId +'_back_2_odds_hiddne').val();
                  const laycurrOddsRate2 =  $('#' + marketId +'_lay_2_odds').text();
                  const layprevOddsRate2 = $('#' + marketId +'_lay_2_odds_hiddne').val();
                  if (currOddsRate2 === prevOddsRate2) {
                    $('#' + marketId +'_back_2_odds').css('background', '');
                  } else {
                    $('#' + marketId +'_back_2_odds').css('background', '#FFD700');
                  }
                  if (laycurrOddsRate2 === layprevOddsRate2) {
                    $('#' + marketId +'_lay_2_odds').css('background', '');
                  } else {
                    $('#' + marketId +'_lay_2_odds').css('background', '#FFD700');
                  }
                }
                $("#" + marketId + "_back_1_odds_hiddne").val((runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToBack[0]) ? runtypes[0].ex.availableToBack[0].price : '-');
                $("#" + marketId + "_lay_1_odds_hiddne").val((runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToLay[0]) ? runtypes[0].ex.availableToLay[0].price : '-');
                $("#" + marketId + "_back_3_odds_hiddne").val((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
                $("#" + marketId + "_lay_3_odds_hiddne").val((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');

                // Start Blinking Rate change Odds
                const currOddsRate =  $('#' + marketId +'_back_1_odds').text();
                const prevOddsRate = $('#' + marketId +'_back_1_odds_hiddne').val();
                const currOddsRate1 =  $('#' + marketId +'_back_3_odds').text();
                const prevOddsRate1 = $('#' + marketId +'_back_3_odds_hiddne').val();


                const laycurrOddsRate =  $('#' + marketId +'_lay_1_odds').text();
                const layprevOddsRate = $('#' + marketId +'_lay_1_odds_hiddne').val();
                const laycurrOddsRate1 =  $('#' + marketId +'_lay_3_odds').text();
                const layprevOddsRate1 = $('#' + marketId +'_lay_3_odds_hiddne').val();

                if (currOddsRate === prevOddsRate) {
                  $('#' + marketId +'_back_1_odds').css('background', '');
                } else {
                  $('#' + marketId +'_back_1_odds').css('background', '#FFD700');
                }
                if (currOddsRate1 === prevOddsRate1) {
                  $('#' + marketId +'_back_3_odds').css('background', '');
                } else {
                  $('#' + marketId +'_back_3_odds').css('background', '#FFD700');
                }

                if (laycurrOddsRate === layprevOddsRate) {
                  $('#' + marketId +'_lay_1_odds').css('background', '');
                } else {
                  $('#' + marketId +'_lay_1_odds').css('background', '#FFD700');
                }
                if (laycurrOddsRate1 === layprevOddsRate1) {
                  $('#' + marketId +'_lay_3_odds').css('background', '');
                } else {
                  $('#' + marketId +'_lay_3_odds').css('background', '#FFD700');
                }

                $("#" + marketId + "_back_1_odds").html((runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToBack[0]) ? runtypes[0].ex.availableToBack[0].price : '-');
                $("#" + marketId + "_lay_1_odds").html(( runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToLay[0]) ? runtypes[0].ex.availableToLay[0].price : '-');
                if (runners == 3) {
                  $("#" + marketId + "_back_3_odds").html(( runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
                  $("#" + marketId + "_lay_3_odds").html(( runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');
                  $("#" + marketId + "_back_2_odds").html((runtypes[2] && runtypes[2].ex && runtypes[2].ex.availableToBack[0]) ? runtypes[2].ex.availableToBack[0].price : '-');
                  $("#" + marketId + "_lay_2_odds").html(( runtypes[2] && runtypes[2].ex && runtypes[2].ex.availableToLay[0]) ? runtypes[2].ex.availableToLay[0].price : '-');
                } else {
                  if (runtypes[1] && runtypes[1].ex) {
                    $("#" + marketId + "_back_3_odds").html((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
                    $("#" + marketId + "_lay_3_odds").html((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');
                  }
                }
              }else{
                let marketId = response.marketId.toString().replace('.', '');
                $('.' + marketId + '_markets').addClass('market_suspends');
              }
            }
          })
        }

      });
    // this.socketServiceRedisMarket
    //   .oddsRate()
    //   .subscribe((response) => {
    //     if (response) {
    //       if (response.status == 'OPEN') {
    //         let marketId = response.marketId.toString().replace('.', '');
    //         $('.' + marketId + '_markets').removeClass('market_suspends');
    //         let runtypes = response.runners;
    //         let runners = 0;
    //         if (response.numberOfActiveRunners) {
    //           runners = response.numberOfActiveRunners;
    //         } else {
    //           runners = response.runners.length;
    //         }
    //         if(runners == 3){
    //           $("#" + marketId + "_back_2_odds_hiddne").val((runtypes[2].ex.availableToBack[0]) ? runtypes[2].ex.availableToBack[0].price : '-');
    //           $("#" + marketId + "_lay_2_odds_hiddne").val((runtypes[2].ex.availableToLay[0]) ? runtypes[2].ex.availableToLay[0].price : '-');
    //           const currOddsRate2 =  $('#' + marketId +'_back_2_odds').text();
    //           const prevOddsRate2 = $('#' + marketId +'_back_2_odds_hiddne').val();
    //           const laycurrOddsRate2 =  $('#' + marketId +'_lay_2_odds').text();
    //           const layprevOddsRate2 = $('#' + marketId +'_lay_2_odds_hiddne').val();
    //           if (currOddsRate2 === prevOddsRate2) {
    //             $('#' + marketId +'_back_2_odds').css('background', '');
    //           } else {
    //             $('#' + marketId +'_back_2_odds').css('background', '#FFD700');
    //           }
    //           if (laycurrOddsRate2 === layprevOddsRate2) {
    //             $('#' + marketId +'_lay_2_odds').css('background', '');
    //           } else {
    //             $('#' + marketId +'_lay_2_odds').css('background', '#FFD700');
    //           }
    //         }
    //         $("#" + marketId + "_back_1_odds_hiddne").val((runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToBack[0]) ? runtypes[0].ex.availableToBack[0].price : '-');
    //         $("#" + marketId + "_lay_1_odds_hiddne").val((runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToLay[0]) ? runtypes[0].ex.availableToLay[0].price : '-');
    //         $("#" + marketId + "_back_3_odds_hiddne").val((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
    //         $("#" + marketId + "_lay_3_odds_hiddne").val((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');

    //         // Start Blinking Rate change Odds
    //         const currOddsRate =  $('#' + marketId +'_back_1_odds').text();
    //         const prevOddsRate = $('#' + marketId +'_back_1_odds_hiddne').val();
    //         const currOddsRate1 =  $('#' + marketId +'_back_3_odds').text();
    //         const prevOddsRate1 = $('#' + marketId +'_back_3_odds_hiddne').val();


    //         const laycurrOddsRate =  $('#' + marketId +'_lay_1_odds').text();
    //         const layprevOddsRate = $('#' + marketId +'_lay_1_odds_hiddne').val();
    //         const laycurrOddsRate1 =  $('#' + marketId +'_lay_3_odds').text();
    //         const layprevOddsRate1 = $('#' + marketId +'_lay_3_odds_hiddne').val();

    //         if (currOddsRate === prevOddsRate) {
    //           $('#' + marketId +'_back_1_odds').css('background', '');
    //         } else {
    //           $('#' + marketId +'_back_1_odds').css('background', '#FFD700');
    //         }
    //         if (currOddsRate1 === prevOddsRate1) {
    //           $('#' + marketId +'_back_3_odds').css('background', '');
    //         } else {
    //           $('#' + marketId +'_back_3_odds').css('background', '#FFD700');
    //         }

    //         if (laycurrOddsRate === layprevOddsRate) {
    //           $('#' + marketId +'_lay_1_odds').css('background', '');
    //         } else {
    //           $('#' + marketId +'_lay_1_odds').css('background', '#FFD700');
    //         }
    //         if (laycurrOddsRate1 === layprevOddsRate1) {
    //           $('#' + marketId +'_lay_3_odds').css('background', '');
    //         } else {
    //           $('#' + marketId +'_lay_3_odds').css('background', '#FFD700');
    //         }

    //         $("#" + marketId + "_back_1_odds").html((runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToBack[0]) ? runtypes[0].ex.availableToBack[0].price : '-');
    //         $("#" + marketId + "_lay_1_odds").html(( runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToLay[0]) ? runtypes[0].ex.availableToLay[0].price : '-');
    //         if (runners == 3) {
    //           $("#" + marketId + "_back_3_odds").html(( runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
    //           $("#" + marketId + "_lay_3_odds").html(( runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');
    //           $("#" + marketId + "_back_2_odds").html((runtypes[2] && runtypes[2].ex && runtypes[2].ex.availableToBack[0]) ? runtypes[2].ex.availableToBack[0].price : '-');
    //           $("#" + marketId + "_lay_2_odds").html(( runtypes[2] && runtypes[2].ex && runtypes[2].ex.availableToLay[0]) ? runtypes[2].ex.availableToLay[0].price : '-');
    //         } else {
    //           if (runtypes[1] && runtypes[1].ex) {
    //             $("#" + marketId + "_back_3_odds").html((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
    //             $("#" + marketId + "_lay_3_odds").html((runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');
    //           }
    //         }
    //       }else{
    //         let marketId = response.marketId.toString().replace('.', '');
    //           $('.' + marketId + '_markets').addClass('market_suspends');
    //       }
    //     }
    //   });
    this.getBannerImage();
  }

  ngOnDestroy() {
    const oldMatch = JSON.parse(sessionStorage.getItem('matchOddsIds'));
    const oldfancy = JSON.parse(sessionStorage.getItem('lastJoinRoom'));
    let scoreIds =  localStorage.getItem('scorecardIds');
    this.scoreCard.leaveRoom(scoreIds);
    let socketFancy = this.socketServiceRedis;
    let socketMatchOdds = this.socketServiceRedisMarket;
    _.map(oldfancy, function (e) {
      socketFancy.leaveRoom(e);
    })
    _.map(oldMatch, function (s) {
      socketMatchOdds.leaveRoom(s);
    })
  }
  getBannerImage(){
    let bannerImage = localStorage.getItem('bannerImages');
    bannerImage = JSON.parse(bannerImage);
    let prefix = "99sport";
    if(bannerImage){
      this.bannerImages = _.filter(bannerImage , function (e) {
        let items = _.includes(e.whitelableTypes , prefix);
        if(e.type === 'Banner' && items){
          return e;
        }
      });
      this.casinoImages = _.filter(bannerImage , function (e) {
        let items1 = _.includes(e.whitelableTypes , prefix);
        if(e.type === 'Casino' && items1){
          return e;
        }
      });
      setTimeout(function(){
        $('.carousel-indicators > li').first().addClass('active');
        $('#img0').addClass('active');
      }, 50);
    }
    this.marketService.getBannerImage().subscribe(response => {
      if (response.status) {
        localStorage.setItem('bannerImages', JSON.stringify(response.data));
      } else {
        // this.commonService.popToast('error','Error', 3000 , 'not found market rules.');
      }
    }, error => {
      // this.commonService.popToast('error', 'Error', 3000, 'not found.');
    });
  }

  getInitialRate(){
    this.marketService.getInitialRate().subscribe(response => {
      localStorage.setItem( "dummyRateResponse", JSON.stringify(response.data));
    });
    this.marketService.getInitialRateMarket().subscribe(response => {
      localStorage.setItem( "dummyMarketRateResponse", JSON.stringify(response.data));
    });
  }

  getCasinoList(){
    let data = {
      product : this.product
    }
    this.studioService.getGameList(data).subscribe(response => {
      if(response.status == true){
        this.gameLists = response.data;
        this.gameList = response.data;
        // this.instructionModal.show();
        this.groupList = _.chain(this.gameLists)
        // Group the elements of Array based on `color` property
          .groupBy("product")
          // `key` is group's name (color), `value` is the array of objects
          .map((value, key) => ({ name: key, category: value }))
          .value();
      }else{
        this.gameList = [];
      }
    });
  }
  getCasinoLock(data){
    let datas = {
      masterId : data.masterId,
      sportId: '990',
      userId: data.user_id
    }
    this.marketService.casinoLockCheck(datas).subscribe(response => {
      if(response.status == true){
        this.isCasino = response.data.isCasino
      }else{
        this.isCasino = false
      }
    });
  }


  dashboardData(type){
    this.myExposer(this.userId);
    this.getInitialRate();

    let mymatchOddsId = [];
    let matchOdds = this.socketServiceRedisMarket;
    if(type == 'inplay'){

      $('#all-tab').addClass('active')
      this.getMarketOddsItem();
      let items = localStorage.getItem("inplayMatch");

      let data = JSON.parse(items)
      this.getAllMarkets = _.toArray(data);
      if(this.getAllMarkets.length > 0 && this.getAllMarkets[0] != null ){
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            firstLoop.marketDetails.filter(secondLoop => {
              if (secondLoop.displayName == "Match Odds") {
                firstLoop.marketIdDec = secondLoop.marketId.toString().replace('.', '');
                // mymatchOddsId.push(firstLoop.marketId[0]);
                // matchOdds.joinRoom(firstLoop.marketId[0]);
                // return secondLoop;
              }
              return secondLoop;
            });
            return firstLoop;
          });
          return marketLoop;
        });
      }else{
        this.messages = "has no live event found.."
      }
    }else if(type == 'CRICKET'){
      this.getMarketOddsData('');
      let items = localStorage.getItem("cricketData");
      items = JSON.parse(items)
      this.getAllMarkets =  [items];
      if(this.getAllMarkets.length > 0 && this.getAllMarkets[0] != null ) {
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            firstLoop.marketDetails.map(secondLoop => {
              if (secondLoop.displayName == "Match Odds") {
                firstLoop.marketIdDec = secondLoop.marketId.toString().replace('.', '');
                // mymatchOddsId.push(firstLoop.marketId[0]);
                // matchOdds.joinRoom(firstLoop.marketId[0]);
              }
              return secondLoop;
            });
            return firstLoop;
          });
          return marketLoop;
        });
      }

    }else if(type == 'SOCCER'){
      this.getMarketOddsData('');
      let items = localStorage.getItem("tennisData");
      items = JSON.parse(items)
      this.getAllMarkets =  [items];
      if(this.getAllMarkets.length > 0 && this.getAllMarkets[0] != null ){
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            firstLoop.marketDetails.map(secondLoop => {
              if (secondLoop.displayName == "Match Odds") {
                firstLoop.marketIdDec = secondLoop.marketId.toString().replace('.', '');
                // mymatchOddsId.push(firstLoop.marketId[0]);
                // matchOdds.joinRoom(firstLoop.marketId[0]);
              }
              return secondLoop;
            });
            return firstLoop;
          });
          return marketLoop;
        });
      }

    }else if(type == 'Tennis'){
      this.getMarketOddsData('');
      let items = localStorage.getItem("soccerData");
      items = JSON.parse(items)
      this.getAllMarkets =  [items];
      if(this.getAllMarkets.length > 0 && this.getAllMarkets[0] != null ){
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            firstLoop.marketDetails.map(secondLoop => {
              if (secondLoop.displayName == "Match Odds") {
                firstLoop.marketIdDec = secondLoop.marketId.toString().replace('.', '');
                // mymatchOddsId.push(firstLoop.marketId[0]);
                // matchOdds.joinRoom(firstLoop.marketId[0]);
              }
              return secondLoop;
            });
            return firstLoop;
          });
          return marketLoop;
        });
      }

    }else{
      this.getMarketOddsData('');
      let items = localStorage.getItem("otherSport");
      items = JSON.parse(items)
      this.getAllMarkets =  [items];
      if(this.getAllMarkets.length > 0 && this.getAllMarkets[0] != null ){
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            firstLoop.marketDetails.map(secondLoop => {
              if (secondLoop.displayName == "Match Odds") {
                firstLoop.marketIdDec = secondLoop.marketId.toString().replace('.', '');
                // mymatchOddsId.push(secondLoop.marketId);
                // matchOdds.joinRoom(secondLoop.marketId);
              }
              return secondLoop;
            });
            return firstLoop;
          });
          return marketLoop;
        });
      }
    }
    setTimeout(() => {
      if(this.dummyRateResponse){
        this.appendInitialData(JSON.parse(this.dummyRateResponse));
      }
    }, 1);
    let reply = localStorage.getItem("dashboardData");
    if(reply){
      reply = JSON.parse(reply);
    }

  }
  getMarketOddsData(type) {
    this.marketService.getAllMarketForDashboard(this.marketFilter, this.userId).subscribe(response => {
      localStorage.setItem('dashboardData', JSON.stringify(response.data));
      if(response.data){
        if(type == 'inplay' ){
        this.dashboardData('inplay');
        }
        let cricket;
        let soccer;
        let tennis;
        let election;
        _.map(response.data, function (e) {
          if(e._id == 4){
             cricket =  e.doc.length;
            localStorage.setItem('cricketData', JSON.stringify(e));
          } else if(e._id == 1){
            soccer =  e.doc.length;
            localStorage.setItem('tennisData', JSON.stringify(e));
          }else if(e._id == 2){
            tennis =  e.doc.length;
            localStorage.setItem('soccerData', JSON.stringify(e));
          }else{
            election =  e.doc.length;
            localStorage.setItem('otherSport', JSON.stringify(e));
          }
        });
        this.cricketCount = cricket;
        this.soccerCount = soccer;
        this.tennisCount = tennis;
        this.electionCount = election;
      }
    });
    setTimeout(() => {
      if(this.dummyRateResponse){
        this.appendInitialData(JSON.parse(this.dummyRateResponse));
      }
    }, 10);
  }

  getMarketOddsItem() {
    let reply = localStorage.getItem("dashboardData")
    if(reply){
      reply = JSON.parse(reply);
      reply =    _.filter(reply, function (e) {
        e.doc = _.filter(e.doc, function (i) {
          // i.data = _.filter(i.data, function (p) {
          if(i.isPlay == true){
            return i;
          }
          // })
          // if(i.data.length > 0){
          //   return i;
          // }

        })
        if(e.doc.length > 0) {
          return e;
        }
      })
      let gfg = _.sortBy(reply, ['doc[0].sportUrl.displayOrder']);
      let count = 0;
      _.map(gfg, function (e) {
          count = count + e.doc.length;

      })
      this.inplayCount = count;
      localStorage.setItem('inplayMatch',JSON.stringify(gfg));

    }
  }

  getMarketOdds(data, index) {
    let mymatchOddsId = [];
    let matchOdds = this.socketServiceRedisMarket;
    this.spinner.hide();
    let datas = localStorage.getItem(data.toString());
    if(datas !== null) {
      datas = this.utilityService.gsk(datas);
      this.getAllMarkets = JSON.parse(datas).data;
      this.getAllMarkets.map(marketLoop => {
        marketLoop.doc = marketLoop.doc.map(firstLoop => {
          // firstLoop.data.map(secondLoop => {
          if (firstLoop.marketId && firstLoop.marketId.length > 0) {
            firstLoop.marketIdDec = firstLoop.marketId[0].toString().replace('.', '');
            mymatchOddsId.push(firstLoop.marketId[0]);
            matchOdds.joinRoom(firstLoop.marketId[0]);
          }
          // });
          return firstLoop;
        });
        sessionStorage.setItem('matchOddsIds' , JSON.stringify(mymatchOddsId));
        return marketLoop;
      });
      $('#inplay').removeClass('active');
      this.contentHide = false;
      this.selectedUniqIndx = index;
      this.marketFilter.data = data;
      let userId = this.utilityService.returnLocalStorageData('userId');
      this.marketService.getAllMarketForDashboard(this.marketFilter, userId).subscribe(response => {
        localStorage.setItem(data, response.auth);
        this.spinner.hide();
      });
    }else{
      $('#inplay').removeClass('active');
      this.contentHide = false;
      this.selectedUniqIndx = index;
      this.marketFilter.data = data;
      let userId = this.utilityService.returnLocalStorageData('userId');
      this.marketService.getAllMarketForDashboard(this.marketFilter, userId).subscribe(response => {
        localStorage.setItem(data, response.auth);
        response = this.utilityService.gsk(response.auth);
        response = JSON.parse(response);
        this.getAllMarkets = response.data;
        this.spinner.hide();
        // this.socketServiceRedisMarket.connect();
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            // firstLoop.data.map(secondLoop => {
            if (firstLoop.marketId && firstLoop.marketId.length > 0) {
              firstLoop.marketIdDec = firstLoop.marketId[0].toString().replace('.', '');
              mymatchOddsId.push(firstLoop.marketId[0]);
              matchOdds.joinRoom(firstLoop.marketId[0]);
              sessionStorage.setItem('matchOddsIds' , JSON.stringify(mymatchOddsId));
            }
            // });
            return firstLoop;
          });
          return marketLoop;
        });
      });
    }
  }



  getAllSportsData(ite) {
    this.marketService.getAllSport().subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if(response) {
        this.getAllSports = response.data;
        if (this.getAllSports.length > 0 && ite !== 10) {
          let index = this.selectedUniqIndx ? this.selectedUniqIndx : 0;
          // this.getMarketOdds(this.getAllSports[index].id, index);
        } else {
          // this.getAllMatchData();
        }
      }
    });
  }

  getAllMatchData() {
    let mymatchOddsId = [];
    let matchOdds = this.socketServiceRedisMarket;
    this.spinner.hide();
    let datas = localStorage.getItem('inplay');
    if(datas !== null){
      datas = this.utilityService.gsk(datas);
      this.getAllMarkets = JSON.parse(datas).data;
      this.getAllMarkets.map(marketLoop => {
        marketLoop.doc = marketLoop.doc.map(firstLoop => {
          // firstLoop.data.map(secondLoop => {
          if (firstLoop.marketId && firstLoop.marketId.length > 0) {
            firstLoop.marketIdDec = firstLoop.marketId[0].toString().replace('.', '');
            matchOdds.joinRoom(firstLoop.marketId[0]);
            mymatchOddsId.push(firstLoop.marketId[0]);
            sessionStorage.setItem('matchOddsIds' , JSON.stringify(mymatchOddsId));
          }
          // });
          return firstLoop;
        });
        return marketLoop;
      });
      this.marketService.getAllMatchInMarket().subscribe(response => {
        localStorage.setItem('inplay', response.auth);
        this.spinner.hide();
      });
    }else{
      // this.socketServiceRedisMarket.disconnect();
      // this.contentHide = true;
      $('#mytab a').removeClass('active');
      $('#inplay').addClass('active');
      this.marketService.getAllMatchInMarket().subscribe(response => {
        localStorage.setItem('inplay', response.auth);
        response = this.utilityService.gsk(response.auth);
        response = JSON.parse(response);
        this.getAllMarkets = response.data;
        this.spinner.hide();
        // this.socketServiceRedisMarket.connect();
        this.getAllMarkets.map(marketLoop => {
          marketLoop.doc = marketLoop.doc.map(firstLoop => {
            // firstLoop.data.map(secondLoop => {
            if (firstLoop.marketId && firstLoop.marketId.length > 0) {
              firstLoop.marketIdDec = firstLoop.marketId[0].toString().replace('.', '');
              matchOdds.joinRoom(firstLoop.marketId[0]);
              mymatchOddsId.push(firstLoop.marketId[0]);
              sessionStorage.setItem('matchOddsIds' , JSON.stringify(mymatchOddsId));
            }
            // });
            return firstLoop;
          });
          return marketLoop;
        });
      });
    }
  }




  showToster(title,type,message){
    this.Toast.title = title;
    this.Toast.type = type;
    this.Toast.body = message;
    this.commonService.popToast('success','Success', 3000 , message);
  }




  appendInitialData(dummyRateResponse){
    // dummyRateResponse = dummyRateResponse[0].concat(dummyRateResponse[1], dummyRateResponse[2]);
    if(dummyRateResponse){
      _.map(dummyRateResponse, function (upendData) {
        upendData = JSON.parse(upendData);
        if(upendData != null){
          let obj = {
            "marketId": upendData.marketId,
            "status": "OPEN",
            "numberOfRunners": 2,
          }
          obj['runners'] = upendData.runners.length;
          let marketId = obj.marketId.toString().replace('.', '');
          let runtypes = upendData.runners;
          let runners = 0;
          if (obj['numberOfActiveRunners']) {
            runners = upendData.runners.length;
          } else {
            runners = upendData.runners.length;
          }
          if(runtypes.length > 0){
            if(runners == 3){
              $("#" + marketId + "_back_2_odds_hiddne").val((runtypes[2].ex.availableToBack[0]) ? runtypes[2].ex.availableToBack[0].price : '-');
              $("#" + marketId + "_lay_2_odds_hiddne").val((runtypes[2].ex.availableToLay[0]) ? runtypes[2].ex.availableToLay[0].price : '-');
              const currOddsRate2 =  $('#' + marketId +'_back_2_odds').text();
              const prevOddsRate2 = $('#' + marketId +'_back_2_odds_hiddne').val();
              const laycurrOddsRate2 =  $('#' + marketId +'_lay_2_odds').text();
              const layprevOddsRate2 = $('#' + marketId +'_lay_2_odds_hiddne').val();
              if (currOddsRate2 === prevOddsRate2) {
                $('#' + marketId +'_back_2_odds').removeClass('yellow');
              } else {
                $('#' + marketId +'_back_2_odds').addClass('yellow');
              }
              if (laycurrOddsRate2 === layprevOddsRate2) {
                $('#' + marketId +'_lay_2_odds').removeClass('yellow');
              } else {
                $('#' + marketId +'_lay_2_odds').addClass('yellow');
              }
            }
            $("#" + marketId + "_back_1_odds_hiddne").val("3.05");
            $("#" + marketId + "_lay_1_odds_hiddne").val((runtypes && runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToLay[0]) ? runtypes[0].ex.availableToLay[0].price : '-');
            $("#" + marketId + "_back_3_odds_hiddne").val((runtypes && runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
            $("#" + marketId + "_lay_3_odds_hiddne").val((runtypes && runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');

            // Start Blinking Rate change Odds
            const currOddsRate =  $('#' + marketId +'_back_1_odds').text();
            const prevOddsRate = $('#' + marketId +'_back_1_odds_hiddne').val();
            const currOddsRate1 =  $('#' + marketId +'_back_3_odds').text();
            const prevOddsRate1 = $('#' + marketId +'_back_3_odds_hiddne').val();


            const laycurrOddsRate =  $('#' + marketId +'_lay_1_odds').text();
            const layprevOddsRate = $('#' + marketId +'_lay_1_odds_hiddne').val();
            const laycurrOddsRate1 =  $('#' + marketId +'_lay_3_odds').text();
            const layprevOddsRate1 = $('#' + marketId +'_lay_3_odds_hiddne').val();

            // console.log(prevOddsRate)
            if (currOddsRate === prevOddsRate) {
              $('#' + marketId +'_back_1_odds').removeClass('yellow');
            } else {
              $('#' + marketId +'_back_1_odds').addClass('yellow');
            }
            if (currOddsRate1 === prevOddsRate1) {
              $('#' + marketId +'_back_3_odds').removeClass('yellow');
            } else {
              $('#' + marketId +'_back_3_odds').addClass('yellow');
            }

            if (laycurrOddsRate === layprevOddsRate) {
              $('#' + marketId +'_lay_1_odds').removeClass('yellow');
            } else {
              $('#' + marketId +'_lay_1_odds').addClass('yellow');
            }
            if (laycurrOddsRate1 === layprevOddsRate1) {
              $('#' + marketId +'_lay_3_odds').removeClass('yellow');
            } else {
              $('#' + marketId +'_lay_3_odds').addClass('yellow');
            }

            $("#" + marketId + "_back_1_odds").html((runtypes && runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToBack[0]) ? runtypes[0].ex.availableToBack[0].price : '-');
            $("#" + marketId + "_lay_1_odds").html(( runtypes && runtypes[0] && runtypes[0].ex && runtypes[0].ex.availableToLay[0]) ? runtypes[0].ex.availableToLay[0].price : '-');
            if (runners == 3) {
              $("#" + marketId + "_back_3_odds").html(( runtypes && runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
              $("#" + marketId + "_lay_3_odds").html((runtypes &&  runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');
              $("#" + marketId + "_back_2_odds").html((runtypes && runtypes[2] && runtypes[2].ex && runtypes[2].ex.availableToBack[0]) ? runtypes[2].ex.availableToBack[0].price : '-');
              $("#" + marketId + "_lay_2_odds").html(( runtypes && runtypes[2] && runtypes[2].ex && runtypes[2].ex.availableToLay[0]) ? runtypes[2].ex.availableToLay[0].price : '-');
            } else {
              if (runtypes[1] && runtypes[1].ex) {
                $("#" + marketId + "_back_3_odds").html((runtypes && runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToBack[0]) ? runtypes[1].ex.availableToBack[0].price : '-');
                $("#" + marketId + "_lay_3_odds").html((runtypes && runtypes[1] && runtypes[1].ex && runtypes[1].ex.availableToLay[0]) ? runtypes[1].ex.availableToLay[0].price : '-');
              }
            }
          }
        }
      });
    }
  }

  getUserCurrency(){
    const data = {
      userId : this.userId
    };
    this.currencyService.getUserCurrency(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if(response.status == true) {
        localStorage.setItem('currencyAll', JSON.stringify(response.data[0]))
        // this.currencyAll = response.data[0];
      }
    });
  }
  gameUrl(data){
    this.router.navigateByUrl('/dc/game-lobby/' + data);
  }

  myExposer(userId){
    let data = {
      userId: userId
    }
    this.marketService.getExposer(data).subscribe(resposne => {
      resposne = this.utilityService.gsk(resposne.auth);
      resposne = JSON.parse(resposne);
      if (resposne.status == true) {
        _.map(resposne.data , function (e) {
          let market = e.gameId.toString().replace('.', '');
          $('#'+ market +'_exp').html(e.amount);
          if(e.amount > 0){
            $('#'+ market +'_exp').removeClass('font-color-danger');
            $('#'+ market +'_exp').addClass('font-color-success');
          }else{
            $('#'+ market +'_exp').removeClass('font-color-success');
            $('#'+ market +'_exp').addClass('font-color-danger');
          }

        })
      }
    })
  }
  casinoAuth(){
    let ite = localStorage.getItem('token');
    let wdh = $(window).innerWidth();
    ite = ite
    let url;
    if(wdh > 992){
      url = 'https://d2.fawk.app/#/splash-screen/'+ ite +'/'+9560;
    }else{
      url = 'https://m2.fawk.app/#/splash-screen/'+ ite +'/'+9560;
    }
    $("#auraIframe").html("<iframe src='" + url + "' width=100% height=1000px></iframe>");
  }

}
