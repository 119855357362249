import {Component, OnInit, ViewChild,Input ,Inject,LOCALE_ID} from '@angular/core';
import {UserService} from "../../services/user.service";
import {NgxSpinnerService} from "ngx-spinner";
import {HttpClient} from "@angular/common/http";
import {ModalDirective} from "ngx-bootstrap";
import {DataTableDirective} from 'angular-datatables';
import {Subject} from "rxjs/Rx";
import * as env from '../../globals/env';
import {MarketService} from '../../services/market.service';
declare let $: any;
declare let _: any;
import * as moment from 'moment';
import {UtilityService} from '../../globals/utilityService';
import { CommonService } from 'src/app/services/common.service';
import { PlacebetService } from 'src/app/services/placebet.service';
import { formatDate } from '@angular/common';


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-open-bet',
  templateUrl: './open-bet.component.html',
  styleUrls: ['./open-bet.component.scss']
})
export class OpenBetComponent implements OnInit {
  minDate: any;
  @Input() dateChanged: any;
  @ViewChild('transactionModel', {static: false}) transactionModel: ModalDirective;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  dtTrigger = new Subject();
  resData: any;
  allSport: any;
  allMatch: any;
  adminServer_url: any = env.adminServer_url();
  matchListSerchdata:any;
  matchNameVaule = '';
  constructor( private userService: UserService ,
               private utilityService: UtilityService ,
               private marketService: MarketService ,
               private http: HttpClient,
               private placebetService: PlacebetService,
               private commonService:CommonService,
               @Inject(LOCALE_ID) private locale: string,
               private spinner : NgxSpinnerService) {
  }

  filter = {
    id: this.utilityService.returnLocalStorageData('userId'),
    page: 1,
    limit: 10,
    startDate: null,
    endDate: null,
    match: '',
    sport: '',
    matchName:null,
    status: 'open',

  };
  fromDate: any;
  toDate: any;
  sDate: any;
  eDate: any;
  chipsTransactionData: any;
  userTransactionRes: any;
  sportData: any;
  disabled:boolean = true;
  jsonData:any = [];
  ngOnInit(): void {
    let pre =  moment().format("YYYY-MM-DD");
    let endDate = moment().format("YYYY-MM-DD");

    // let pre =  moment().format("YYYY-MM-DD");
    // let endDate =  moment().subtract(7,'d').format("YYYY-MM-DD");

    this.filter.startDate = endDate;
    this.filter.endDate = pre;

    let ePreShow = moment().format("DD/MM/YYYY");
    let sPreShow = moment().format("DD/MM/YYYY");
    this.sDate = sPreShow;
    this.eDate = ePreShow;

    this.getAcSummaryReport();
    this.getAllSportList();
    this.matchListSerch();
  }


  /**
   * @author TR
   * @date 23-06-2020
   * GetAll AcSummaryReport
   * @param data
   */
  getAcSummaryReport(){
    this.rerender();
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50, // -- trying to set to 5 records only
      paging: true,
      deferLoading: 0,
      serverSide: true,
      processing: false,
      responsive: true,
      lengthChange: true,
      order: [[ 0, "" ]],
      lengthMenu: [50, 75, 100, 200],
      language: {
        lengthMenu: '_MENU_',
        searchPlaceholder: 'search',
        search: "",
        zeroRecords: '',
        infoFiltered: '',
        emptyTable: 'No record found',
        paginate: {
          first: 'first', last: 'last',
          next: 'next',
          previous: 'prev'
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        let url = this.adminServer_url + 'placebet/get-all-transactions-user';
        that.http
          .post<DataTablesResponse>(
            url,
            Object.assign(dataTablesParameters, {data : this.filter}),
            {}
          ).subscribe(resp => {
          this.chipsTransactionData = resp;
          this.chipsTransactionData = this.utilityService.gsk(this.chipsTransactionData.auth);
          this.chipsTransactionData = JSON.parse(this.chipsTransactionData);
          this.chipsTransactionData =this.chipsTransactionData.data;
          $('.respo-scroll').addClass('displayBl');
          this.spinner.hide();
          this.dtTrigger.next();
          callback({
            recordsTotal: this.chipsTransactionData.page,
            recordsFiltered: this.chipsTransactionData.total,
            data: []
          });
        });
        this.spinner.hide();
      },

      columns: [ { data: '' }, { data: 'time' }, { data: 'match' }, { data: 'marketType' },{ data: 'amount' }, { data: 'runnerName' },{ data: 'rate' },{ data: 'exposure' },{ data: 'placeDate' }],
      columnDefs: [ { orderable: false, targets: [0] },{ orderable: false, targets: [1] }, { orderable: false, targets: [2] }, { orderable: false, targets: [3] }, { orderable: false, targets: [4] }, { orderable: false, targets: [5] }, { orderable: false, targets: [6] }, { orderable: false, targets: [7] }, { orderable: false, targets: [8] }]
    };

  }


  rerender(): void {
    $('.dataTable').dataTable().fnClearTable();
  }

  clearDate(e){
    if(e.target.value === ''){
      this.getAcSummaryReport()
    }
  }

  searchFilterData() {
    if (this.fromDate){
      this.filter.startDate = (this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day);
    }
    if (this.toDate){
      this.filter.endDate = (this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day);
    }
    if(this.matchNameVaule){
      this.filter.matchName = this.matchNameVaule
    }
    this.getAcSummaryReport()
    this.matchListSerch();
  }
  onChange(e){
    alert(e)
    console.log(e);
  }

  clearFilter(){
    this.disabled = true;
    this.matchNameVaule = '';
    let pre =  moment().format("YYYY-MM-DD");
    let endDate = moment().format("YYYY-MM-DD");

    this.filter.startDate = endDate;
    this.filter.endDate = pre;

    let ePreShow = moment().format("DD/MM/YYYY");
    let sPreShow = moment().format("DD/MM/YYYY");
    this.sDate = sPreShow;
    this.eDate = ePreShow;

    this.filter = {
      id: this.utilityService.returnLocalStorageData('userId'),
      page: 1,
      limit: 10,
      startDate: this.filter.startDate,
      endDate: this.filter.endDate,
      matchName:this.matchNameVaule,
      match: '',
      sport: '',
      status: '',
    };
    this.getAcSummaryReport();
    this.matchListSerch();
  }

  /**
   * @author TR
   * @date 23-06-2020
   * GetAll Sport list
   * @param data
   */

  getAllSportList() {
    const item = {
      data: {
      userId : this.utilityService.returnLocalStorageData('userId'),
      }
    };
    this.userService.getAllTransaction(item).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      this.allSport = response.data;
      this.allSport = _.uniqBy(this.allSport, function (e) {
        return e.gameDetail[0].sport.id;
      });
    });

  }

  sportSelection(e){
    this.allMatch = [];
    this.filter.sport = e.target.value
    this.filter.startDate = (this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day);
    this.getAllMatchList(this.filter.sport);
    this.matchListSerch();
  }

  /**
   * @author TR
   * @date 23-06-2020
   * GetAll Tournament list
   * @param data
   */

    getAllMatchList(sportName) {
    const item = {
      data: {
        userId : this.utilityService.returnLocalStorageData('userId'),
        sportId : sportName
      }
    };
    this.userService.getAllTransaction(item).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      this.allMatch = response.data;
      this.allMatch = _.uniqBy(this.allMatch, function (e) {
        return e.matchId;
      });
    });
    }

  /**
   * @author subhash
   * @date 21-08-2019
   * date bulding
   */
  onDateSelect(e) {
    if (e.year) {
      this.toDate = null;
      this.disabled = false;
      this.minDate = {
        year: e.year,
        month: e.month ,
        day: e.day + 1
      };

    } else {
      this.disabled = true;
    }
  }

  download() {
    this.jsonData =[];
    let filter = {"draw":2,"columns": [
      {"data":"","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
      {"data":"time","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
      {"data":"match","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
      {"data":"marketType","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
      {"data":"amount","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
      {"data":"runnerName","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
      {"data":"rate","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
      {"data":"exposure","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
      {"data":"placeDate","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}}
    ],"order":[{"column":0,"dir":""}],"start":0,"length":this.chipsTransactionData.total,"search":{"value":"","regex":false},
    "data":{"id":this.filter.id,"page":1,"limit":10000000000,"startDate":this.filter.startDate,"endDate":this.filter.endDate,"match":this.filter.match,"sport":this.filter.sport,"status":this.filter.status}}


    let count = 0;
    let responseDatacsv;
      this.placebetService.getUserTransaction(filter).subscribe(response => {
        response = this.utilityService.gsk(response.auth);
        response = JSON.parse(response);
        responseDatacsv = response.data.docs;
        let dataTemps = responseDatacsv.map(transaction => {
          let dataArr ={
            RUNNER:(transaction.gameType == 'market') ? transaction.team[0].runnerName:
                    transaction.gameType == 'line'? transaction.gameDetail[0].lineName :
                    transaction.gameType == 'fancy' ? transaction.gameDetail[0].fancyName :
                    transaction.gameType == 'casinoLive' ? transaction.gameDetail[0].bet.runnerName:
                    transaction.gameType == 'Bookmaker' ? 'Bookmaker':'',
            RATE: transaction.odds,
            AMOUNT: transaction.rate,
            EXPOSURE: (transaction.runner_type === 'lay') ? transaction.back : transaction.lay,
            MARKET: transaction.gameType,
            MATCH: transaction.gameType === 'casinoLive' ? transaction.gameDetail[0].bet.marketName : transaction.gameDetail[0].match.name.substring(0, transaction.gameDetail[0].match.name.indexOf('(')),
            'PLACED TIME': formatDate(transaction.placeTime,'dd-MM-yyyy HH:MM:SS',this.locale),
            'MATCHED TIME': formatDate(transaction.betTime,'dd-MM-yyyy HH:MM:SS',this.locale),
          }
          count++;
          this.jsonData.push(dataArr);
        });
        let headerKey = ['RUNNER', 'RATE', 'AMOUNT', 'EXPOSURE', 'MARKET', 'MATCH','PLACED TIME' ,'MATCHED TIME'];
        let csvName = 'Bet History Report';
      if(responseDatacsv.length == count) {
        this.commonService.downloadFile(this.jsonData, headerKey,csvName);
      }
    }, error => {
      console.error('error in get Bet History report', error);
    })
  }

  /**
   * get all match list
   */
  matchListSerch() {
    let data = {
      startDate: this.filter.startDate,
      endDate: this.filter.endDate,
      sportId: this.filter.sport
    };

    this.marketService.getMatchListSerch(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      response.data = _.uniqBy(response.data, function (e) {
        return e.id;
      });
      this.matchListSerchdata = response.data;
    }, error => {
      console.error('error');
    });
  }
  selectTyp(deta){
    this.filter.status = deta;
  }
}
