import { HeaderComponent } from './../../header/header.component';
import {Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router,NavigationEnd} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import {PlacebetService} from '../../services/placebet.service';
import {CommonService} from '../../services/common.service';
import {MarketService} from '../../services/market.service';
import {FancyService} from '../../services/fancy.service';
import {SocketService} from '../../globals/socketService';
import {SocketServiceClient} from '../../globals/socketServiceClient';
import { SocketServiceRedisMarket } from '../../globals/socketServiceRedisMarket';
import {CurrencyService} from '../../services/currency.service';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';
import {isUndefined} from 'util';
import {SocketServiceRedis} from '../../globals/socketServiceRedis';
import {UtilityService} from '../../globals/utilityService';
import _ from "lodash";
import {interval, Subject} from 'rxjs';

var aes256 = require('aes256');

import * as CryptoJS from 'crypto-js';
import {ScoreCard} from "../../globals/scoreCard";
import {toInteger} from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'app-multi-market',
  templateUrl: './multi-market.component.html',
  styleUrls: ['./multi-market.component.scss']
})
export class MultiMarketComponent implements OnInit {
  @ViewChild('betview', { static: false }) betview: ModalDirective;
  @ViewChild('betviewLine', { static: false }) betviewLine: ModalDirective;
  @ViewChild('betviewall', { static: false }) betviewall: ModalDirective;
  @ViewChild('placeBetModel', { static: false }) placeBetModel: ModalDirective;
  @ViewChild('betviewMatchOdds', { static: false }) betviewMatchOdds: ModalDirective;
  @ViewChild('chart', { static: false }) chart: ModalDirective;
  @ViewChild('placeBet', { static: false }) placeBet: ModalDirective;
  @ViewChild('placeBetFm', { static: false }) placeBetFm: ModalDirective;
  @ViewChild('placeBetBms', { static: false }) placeBetBms: ModalDirective;

  safeSrc: SafeResourceUrl;
  safeSrcimg = '';

  constructor(
    private route: ActivatedRoute,
    private socketService: SocketService,
    private scoreCard: ScoreCard,
    private socketServiceClient: SocketServiceClient,
    private socketServiceRedis: SocketServiceRedis,
    // private socketServiceFancyRedis: SocketServiceFancyRedis,
    private socketServiceRedisMarket: SocketServiceRedisMarket,
    private fancyService: FancyService,
    private utilityService: UtilityService,
    private marketService: MarketService,
    // private lockGameService: LockGameService,
    private commonService: CommonService,
    private hostElement: ElementRef,
    private currencyService: CurrencyService,
    private placebetService: PlacebetService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
      }
    });
  }

  no2;
  no2Vol;
  yes2;
  yes2Vol;
  fancyData = [];
  FancyItem = [];
  lineMarketFancy = [];
  BallbyballFancy = [];
  stack: any;
  currencyAll: any;
  titalMatch: any;
  lineId: any;
  scores: any;
  countTransactions: any;
  getMarketByIds: any = [];
  getMarketById: any;
  getMarketCheckActiveBat: any;
  marketId: any;
  tvFeet= false;
  channelUrl: any;
  matchOddsData: any;
  matchOddsData1: any;
  bookmakerData: any;
  oddEvenData: any;
  oddEvenDataAp: any;
  modeSetting: any;
  lineMarketData: any;
  allMarketData: any;
  allTransactions = [];
  allTransactionsAsMatch: [];
  allTransactionsAsLine: [];
  usersPartnership: any;
  amountSelection: any;
  oneClick = false;
  lockGame = false;
  betLock = true;
  voiceOnOff = false;
  scoreId = null;
  apiObj: any;
  scoreDatas: any;
  scoreRadar: any;
  channelFeed: any;
  checkSport: any;
  lockGameObj: any;
  mycurrentRoute: any;
  chartAry = [];
  users = this.commonService.getLocalStorage();
  userId = this.users.userId;
  chartFancyName: any;
  lastJoinRoom: any = '';
  marketSel: any = '';
  bookmakerId: any = '';
  currentTranPro: any = 0;
  Toast = {
    type: '',
    title: '',
    timeout: 0,
    body: ''
  };
  fancyName: any;
  lineName: any;
  marketVolume = 1;
  marketStatus = '';
  allTransactionsAsMatchOdds: any;
  confirmRate: Boolean = false;
  confirmRateMob: Boolean = false;
  confirmRateBm : Boolean = false;
  inItDrate: Boolean = false;
  marketIdBooKmakerUsed = '';
  endSubmit = false;
  public myMath = Math;

  html = '';
  result = '';
  dummyRateResponse = localStorage.getItem('dummyRateResponse');
  dummyMarketRateResponse : any;
  paramsId;

  placeOdds : any;
  placeMarketType : any;
  placeGameId : any;
  placeVol : any;
  placeRow : any;
  placeTeam : any;
  placeRunnerType : any;
  selectedTm : any;
  selectInx :any;
  selectMarketId :any;
  selectMarket :any;

  ngOnInit() {
    let curAll = localStorage.getItem('currencyAll');
    if(curAll){
      let pCurAll = JSON.parse(curAll);
      this.currencyAll = pCurAll;
    }

    this.matchOddsData = [];
    this.matchOddsData1 = [];
    this.fancyData = [];


    this.mycurrentRoute = this.router.url;
    // this.route.params.subscribe(params => {
    //   this.renderLocalData(params.id)
    //   this.confirmRate = false;
    //   this.paramsId = params.id;
    //   this.confirmRateMob = false;
    //   this.getMarketByIds = [];
    //   this.lineMarketData = [];
    //   this.scoreRadar = null;
    //
    //   const oldMatch = JSON.parse(sessionStorage.getItem('matchOddsIds'));
    //   const oldfancy = JSON.parse(sessionStorage.getItem('lastJoinRoom'));
    //   let scoreIds =  localStorage.getItem('scorecardIds');
    //   this.scoreCard.leaveRoom(scoreIds);
    //   let socketFancy = this.socketServiceRedis;
    //   let socketMatchOdds = this.socketServiceRedisMarket;
    //   _.map(oldfancy, function (e) {
    //     socketFancy.leaveRoom(e);
    //   })
    //   _.map(oldMatch, function (s) {
    //     socketMatchOdds.leaveRoom(s);
    //   })
    //   const id = params.id;
    //   this.marketId = id;
    //   this.scoreDatas = null;
    //   const userId = this.utilityService.returnLocalStorageData('userId');
    //   this.getMarketIdData(userId);
    //
    //
    //   const findRecordObj = {
    //     userId: this.utilityService.returnLocalStorageData('userId'),
    //     matchId: Number(id),
    //   };
    //   this.lockGameObj = findRecordObj;
    //   // this.checkLockGame(this.lockGameObj);
    //   $('.scoreCard').html('');
    // });

    const userId = this.utilityService.returnLocalStorageData('userId');
    this.getMarketIdData(userId);

    // First join room by marketId
    this.socketService.joinRoom(this.userId);
    this.socketServiceClient.joinRoom(this.userId);

    this.socketServiceRedis
      .reconnections()
      .subscribe((response) => {
        this.getMarketByIds = [];
        this.fancyData = [];
        this.lineMarketData = [];
        this.bookmakerData = [];
        const oldfancy = sessionStorage.getItem('lastJoinRoom');
        let scoreIds =  localStorage.getItem('scorecardIds');
        this.scoreCard.leaveRoom(scoreIds);
        this.socketServiceRedis.leaveRoom(oldfancy);
        // this.renderLocalData(this.paramsId)
        this.getMarketIdData(this.userId);
        this.confirmRateMob = false;
        this.confirmRate =  false;
        const transactionObj = {
          marketId: this.marketId,
          userId: this.userId,
          gameId: this.marketId
        };
        let countObj = {
          userId: this.utilityService.returnLocalStorageData('userId'),
          matchId: Number(this.marketId),
        };
        this.getAllTransaction(transactionObj);
        this.getAllTransactionsCount(countObj);
        $('.scoreCard').html('');
      });

    this.socketServiceClient
      .cancelMarket()
      .subscribe((response) => {

        const transactionObj = {
          marketId: this.marketId,
          userId: this.userId,
          gameId: this.marketId
        };
        this.getAllTransactionsCount(transactionObj);
        if (response && response.fancyId) {
          this.fancyData = _.filter(this.fancyData, function(user) {
            return user.fancyId !== response.fancyId;
          });
          this.allTransactions = _.filter(this.allTransactions, function(tran) {
            return tran.gameId !== Number(response.fancyId);
          });

        }
        if (response && response.lineId) {
          this.lineMarketData = _.filter(this.lineMarketData, function(user) {
            return user.lineId !== response.lineId;
          });
          this.allTransactions = _.filter(this.allTransactions, function(tran) {
            return tran.gameId !== response.marketId;
          });
          this.getAllTransactionAsLine(transactionObj);
        }
        if (response && response.marketId) {
          this.matchOddsData = _.filter(this.matchOddsData, function(user) {
            return user.marketId !== response.marketId;
          });
          this.allTransactions = _.filter(this.allTransactions, function(tran) {
            return tran.gameId !== response.marketId;
          });
        }

        if (response && response.marketId && response.marketType === 'Bookmaker') {
          this.bookmakerData = _.filter(this.bookmakerData, function(user) {
            return user.marketId !== response.marketId;
          });
          this.allTransactions = _.filter(this.allTransactions, function(tran) {
            return tran.gameId !== response.marketId;
          });
        }
      });


    this.socketServiceRedisMarket
      .oddsRate()
      .subscribe((response) => {
        if (response) {
          if (response.status === 'OPEN') {
            const linerMarId = response.marketId;
            const marketId = response.marketId.toString().replace('.', '');
            let runners = 0;
            if (response.numberOfRunners) {
              runners = response.numberOfRunners;
            } else {
              runners = response.runners.length;
            }

            if(runners > 3){
              let marketobj = this.matchOddsData.find(o => o.marketId == linerMarId);

              if(marketobj){
                if (marketobj.marketStatus.id !== 'MS940896') {
                  $('.' + marketId + '_market').removeClass('market_suspend');
                  $('.' + marketId + '_market_mob').removeClass('market_suspend');
                } else {
                  $('.' + marketId + '_market').addClass('market_suspend');
                  $('.' + marketId + '_market_mob').addClass('market_suspend');
                }

                let matchRunners = this.matchOddsData[0].runners;
                var indexObject = _.reduce(response.runners, function(result, currentObject) {
                  result[currentObject.selectionId] = currentObject;
                  return result;
                }, {});
                response.runners = _.map(matchRunners, function(currentGUID) {
                  return indexObject[currentGUID.selectionId]
                });
                //this code is for only cup
                for (let i = 0; i < runners; i++) {
                  //if (marketId === this.marketSel) {
                  let marketSel = marketId.toString().replace('.', '');
                  let selectionId = response.runners[i].selectionId;
                  const volume = this.marketVolume;
                  if (marketobj.marketStatus.id === 'MS940896') {
                    $('.' + marketId + '_market').addClass('market_suspend');
                    $('.' + marketId + '_market_mob').addClass('market_suspend');
                  }
                  const availableBack = response.runners[i].ex.availableToBack;
                  // update bhav to market
                  $('#'+ selectionId + '_' + marketSel +'_back_0_odds').html((availableBack[0]) ? availableBack[0].price : '');
                  $('#'+ selectionId + '_back_0_odds').html((availableBack[0]) ? availableBack[0].price : '');

                  // Start Blinking Rate change Odds
                  const currOddsRate =  $('#'+ selectionId + '_'  + marketSel +'_back_0_odds').text();
                  const prevOddsRate = $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_hiddne').val();

                  if (currOddsRate === prevOddsRate) {
                    $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_blink').css('background', '');
                    $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_mob_blink').css('background', '');
                  } else {
                    $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_blink').css('background', '#FFD700');
                    $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_mob_blink').css('background', '#FFD700');
                  }

                  // End Blinking Rate change Odds

                  $('#'+ selectionId + '_back_0_vol').html((availableBack[0]) ? String(Math.round(availableBack[0].size * volume)) : '');
                  $('#'+ selectionId + '_'  + marketSel +'_back_0_vol').html((availableBack[0]) ? String(Math.round(availableBack[0].size * volume)) : '');
                  $('#'+ selectionId + '_'  + marketSel +'_back_1_odds').html((availableBack[1]) ? availableBack[1].price : '');
                  $('#'+ selectionId + '_'  + marketSel +'_back_1_vol').html((availableBack[1]) ? String(Math.round(availableBack[1].size * volume)) : '');
                  $('#'+ selectionId + '_'  + marketSel +'_back_2_odds').html((availableBack[2]) ? availableBack[2].price : '');
                  $('#'+ selectionId + '_'  + marketSel +'_back_2_vol').html((availableBack[2]) ? String(Math.round(availableBack[2].size * volume)) : '');
                  $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_hiddne').val((availableBack[0]) ? availableBack[0].price : '');

                  const availableLay = response.runners[i].ex.availableToLay;
                  $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds').html((availableLay[0]) ? availableLay[0].price : '');
                  $('#'+ selectionId + '_lay_0_odds').html((availableLay[0]) ? availableLay[0].price : '');

                  // Start Blinking Rate change Lays
                  const currLaysRate =  $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds').text();
                  const prevLaysRate =  $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_hiddne').val();
                  if (currLaysRate === prevLaysRate) {
                    $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_blink').css('background', '');
                    $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_mob_blink').css('background', '');
                  } else {
                    $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_blink').css('background', '#FFD700');
                    $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_mob_blink').css('background', '#FFD700');
                  }
                  // End Blinking Rate change Lays

                  $('#'+ selectionId + '_'  + marketSel +'_lay_0_vol').html((availableLay[0]) ? String(Math.round(availableLay[0].size * volume)) : '');
                  $('#'+ selectionId + '_lay_0_vol').html((availableLay[0]) ? String(Math.round(availableLay[0].size * volume)) : '');
                  $('#'+ selectionId + '_'  + marketSel +'_lay_1_odds').html((availableLay[1]) ? availableLay[1].price : '');
                  $('#'+ selectionId + '_'  + marketSel +'_lay_1_vol').html((availableLay[1]) ? String(Math.round(availableLay[1].size * volume)) : '');
                  $('#'+ selectionId + '_'  + marketSel +'_lay_2_odds').html((availableLay[2]) ? availableLay[2].price : '');
                  $('#'+ selectionId + '_'  + marketSel +'_lay_2_vol').html((availableLay[2]) ? String(Math.round(availableLay[2].size * volume)) : '');
                  $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_hiddne').val((availableLay[0]) ? availableLay[0].price : '');
                  //}
                  if (this.lineMarketData) {
                    const linerobj = this.lineMarketData.find(o => o.marketId === linerMarId);
                    if (linerobj) {
                      if (linerobj.lineMode == 'Auto') {
                        let lineMultipler = 0;
                        if (linerobj.lineSetting) {
                          lineMultipler = linerobj.lineSetting.MultiplierVolume;
                        }

                        // console.log("lineMultipler++++++++++++++++++++++",lineMultipler);
                        // In live market rate is reverse
                        const availableBack = response.runners[i].ex.availableToLay;
                        $('#' + linerobj.marketIdDec + '_back').html((availableBack[0]) ? String(Math.round(availableBack[0].price)) : '');
                        $('#' + linerobj.marketIdDec + '_back_vol').html((availableBack[0]) ? String(Math.round(availableBack[0].size * lineMultipler)) : '');

                        const availableLay = response.runners[i].ex.availableToBack;
                        $('#' + linerobj.marketIdDec + '_lay').html((availableLay[0]) ? String(Math.round(availableLay[0].price)) : '');
                        $('#' + linerobj.marketIdDec + '_lay_vol').html((availableLay[0]) ? String(Math.round(availableLay[0].size * lineMultipler)) : '');
                      }
                    }
                  }
                }
              }
            }

            //this code is for only market
            for (let i = 0; i < runners; i++) {
              let marketSel = marketId.toString().replace('.', '');
              let marketobj = this.matchOddsData.find(o => o.marketId == linerMarId);

              // if (marketId === this.marketSel) {
              let selectionId = response.runners[i].selectionId;
              const volume = this.marketVolume;
              if(marketobj){
                if (marketobj.marketStatus.id === 'MS940896') {
                  $('.' + marketId + '_market').addClass('market_suspend');
                  $('.' + marketId + '_market_mob').addClass('market_suspend');
                }else{
                  $('.' + marketId + '_market').removeClass('market_suspend');
                  $('.' + marketId + '_market_mob').removeClass('market_suspend');
                }
              }

              const availableBack = response.runners[i].ex.availableToBack;
              // update bhav to market
              $('#'+ selectionId + '_'  + marketSel +'_back_0_odds').html((availableBack[0]) ? availableBack[0].price : '');
              $('#'+ selectionId + '_back_0_odds').html((availableBack[0]) ? availableBack[0].price : '');

              // Start Blinking Rate change Odds
              const currOddsRate =  $('#'+ selectionId + '_'  + marketSel +'_back_0_odds').text();
              const prevOddsRate = $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_hiddne').val();

              if (currOddsRate === prevOddsRate) {
                $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_blink').css('background', '');
                $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_mob_blink').css('background', '');
              } else {
                $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_blink').css('background', '#FFD700');
                $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_mob_blink').css('background', '#FFD700');
              }

              // End Blinking Rate change Odds
              $('#'+ selectionId + '_'  + marketSel +'_back_0_vol').html((availableBack[0]) ? String(Math.round(availableBack[0].size * volume)) : '');
              $('#'+ selectionId + '_back_0_vol').html((availableBack[0]) ? String(Math.round(availableBack[0].size * volume)) : '');
              $('#'+ selectionId + '_'  + marketSel +'_back_1_odds').html((availableBack[1]) ? availableBack[1].price : '');
              $('#'+ selectionId + '_'  + marketSel +'_back_1_vol').html((availableBack[1]) ? String(Math.round(availableBack[1].size * volume)) : '');
              $('#'+ selectionId + '_'  + marketSel +'_back_2_odds').html((availableBack[2]) ? availableBack[2].price : '');
              $('#'+ selectionId + '_'  + marketSel +'_back_2_vol').html((availableBack[2]) ? String(Math.round(availableBack[2].size * volume)) : '');
              $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_hiddne').val((availableBack[0]) ? availableBack[0].price : '');

              const availableLay = response.runners[i].ex.availableToLay;
              $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds').html((availableLay[0]) ? availableLay[0].price : '');
              $('#'+ selectionId + '_lay_0_odds').html((availableLay[0]) ? availableLay[0].price : '');

              // Start Blinking Rate change Lays
              const currLaysRate =  $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds').text();
              const prevLaysRate =  $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_hiddne').val();
              if (currLaysRate === prevLaysRate) {
                $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_blink').css('background', '');
                $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_mob_blink').css('background', '');
              } else {
                $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_blink').css('background', '#FFD700');
                $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_mob_blink').css('background', '#FFD700');
              }
              // End Blinking Rate change Lays

              $('#'+ selectionId + '_'  + marketSel +'_lay_0_vol').html((availableLay[0]) ? String(Math.round(availableLay[0].size * volume)) : '');
              $('#'+ selectionId + '_lay_0_vol').html((availableLay[0]) ? String(Math.round(availableLay[0].size * volume)) : '');
              $('#'+ selectionId + '_'  + marketSel +'_lay_1_odds').html((availableLay[1]) ? availableLay[1].price : '');
              $('#'+ selectionId + '_'  + marketSel +'_lay_1_vol').html((availableLay[1]) ? String(Math.round(availableLay[1].size * volume)) : '');
              $('#'+ selectionId + '_'  + marketSel +'_lay_2_odds').html((availableLay[2]) ? availableLay[2].price : '');
              $('#'+ selectionId + '_'  + marketSel +'_lay_2_vol').html((availableLay[2]) ? String(Math.round(availableLay[2].size * volume)) : '');
              $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_hiddne').val((availableLay[0]) ? availableLay[0].price : '');
              //}
              if (this.lineMarketData) {
                const linerobj = this.lineMarketData.find(o => o.marketId === linerMarId);
                if (linerobj) {
                  if (linerobj.lineMode == 'Auto') {
                    let lineMultipler = 0;
                    if (linerobj.lineSetting) {
                      lineMultipler = linerobj.lineSetting.MultiplierVolume;
                    }

                    // In live market rate is reverse
                    const availableBack = response.runners[i].ex.availableToLay;
                    $('#' + linerobj.marketIdDec + '_back').html((availableBack[0]) ? String(Math.round(availableBack[0].price)) : '');
                    $('#' + linerobj.marketIdDec + '_back_vol').html((availableBack[0]) ? String(abbrNum(Math.round(availableBack[0].size * lineMultipler))) : '');

                    const availableLay = response.runners[i].ex.availableToBack;
                    $('#' + linerobj.marketIdDec + '_lay').html((availableLay[0]) ? String(Math.round(availableLay[0].price)) : '');
                    $('#' + linerobj.marketIdDec + '_lay_vol').html((availableLay[0]) ? String(abbrNum(Math.round(availableLay[0].size * lineMultipler))) : '');
                  }
                }
              }
            }

          } else {
            let market_id = response.marketId.toString().replace('.', '');
            $('.' + market_id + '_market').addClass('market_suspend');
            $('.' + market_id + '_market_mob').addClass('market_suspend');
          }
        }
      });


    this.socketServiceRedis
      .fancyRate()
      .subscribe((response) => {

        if (response) {
          //find fancy from auto response
          const fancyobj = this.fancyData.find(o => o.fancyId == response.srno);
          if (fancyobj) {
            if (fancyobj.fancyMode == 'Auto') {
              fancyobj.fancyIdDc =  fancyobj.fancyId.toString().replace('.', '');
              fancyobj.fancyIdDc =  fancyobj.fancyIdDc.toString().replace('-', '');
              appendStatus(fancyobj.fancyIdDc, 'active');
              this.appendFancyRateAuto(response);
            }
          }else{
            appendStatus(response.srno, 'active');
            this.appendFancyRateAuto(response);
          }

          //find fancy from auto response
          // if(this.bookmakerData && this.bookmakerData.length > 0) {
          //   if(this.bookmakerData[0].marketId  == response.srno){
          //     this.appendBMRateAuto(this.bookmakerData[0], response);
          //   }
          // }
        }
      }, this);


    this.socketServiceRedis
      .bmRate()
      .subscribe((response) => {
        if (response) {
          //find fancy from auto response
          if(this.bookmakerData && this.bookmakerData.length > 0) {
            _.map(this.bookmakerData, function (e) {

              e.marketId =  e.marketId.toString().replace('.', '');
              e.marketId =  e.marketId.toString().replace('-', '');
              if(e.marketId  == response.srno){
                appendBMRateAuto(e, response);
              }
            })

          }
        }
      }, this);


    this.socketService
      .getRate()
      .subscribe((response) => {
        if (response.type == 'line') {
          const marketId = response.id.toString().replace('.', '');
          const data = response.data;
          $('#' + marketId + '_lay').html(data.no2), $('#' + marketId + '_lay_mob').html(data.no2);
          $('#' + marketId + '_lay_vol').html(data.no2Vol), $('#' + marketId + '_lay_mob_vol').html(data.no2Vol);
          $('#' + marketId + '_back').html(data.yes2), $('#' + marketId + '_back_mob').html(data.yes2);
          $('#' + marketId + '_back_vol').html(data.yes2Vol), $('#' + marketId + '_back_mob_vol').html(data.yes2Vol);
          appendStatusLine(marketId, 'active');
        } else {
          const fancyId = response.data.fancyId;
          $('#no1_' + fancyId + ',#no1Vol_' + fancyId + ',#yes1_' + fancyId + ',#yes1Vol_' + fancyId + ',#no2_' + fancyId + ',#no2Vol_' + fancyId + ',#yes2_' + fancyId + ',#yes2Vol_' + fancyId + ',#no3_' + fancyId + ',#no3Vol_' + fancyId + ',#yes3_' + fancyId + ',#yes3Vol_' + fancyId + ',#no4_' + fancyId + ',#no4Vol_' + fancyId + ',#yes4_' + fancyId + ',#yes4Vol_' + fancyId + '').html('');
          $('#mob_no1_' + fancyId + ',#mob_no1Vol_' + fancyId + ',#mob_yes1_' + fancyId + ',#mob_yes1Vol_' + fancyId + ',#mob_no2_' + fancyId + ',#mob_no2Vol_' + fancyId + ',#mob_yes2_' + fancyId + ',#mob_yes2Vol_' + fancyId + ',#mob_no3_' + fancyId + ',#mob_no3Vol_' + fancyId + ',#mob_yes3_' + fancyId + ',#mob_yes3Vol_' + fancyId + + ',#mob_no4_' + fancyId + ',#mob_no4Vol_' + fancyId + ',#mob_yes4_' + fancyId + ',#mob_yes4Vol_' + fancyId + '').html('');

          $('#' + fancyId + '_first,#' + fancyId + '_third,#' + fancyId + '_forth').hide(), $('#mob_' + fancyId + '_first,#mob_' + fancyId + '_third,#' + fancyId + '_forth').hide();
          $('#' + fancyId + '_second').removeClass('fancy_ballstart'), $('#mob_' + fancyId + '_second').removeClass('fancy_ballstart');
          $('#' + fancyId + '_second').removeClass('fancy_suspend'), $('#mob_' + fancyId + '_second').removeClass('fancy_suspend');
          appendRate(response.data);
          appendStatus(fancyId, 'active');
        }
      });

    this.socketService
      .getRateBookMakerMan()
      .subscribe((response) => {
        // appendBMRateManual(response);
        this.appendBMRateManual(response);
      })

    this.socketService
      .getRateOddEvenMan()
      .subscribe((response) => {
        // appendBMRateManual(response);
        this.appendBMRateManual(response);
      })

    this.scoreCard
      .getScoreCard()
      .subscribe((response) => {
        if (response) {
          // response.l = response.l.split(' ');
          console.log(response)
          this.scoreRadar = response;
        }
      });

    this.scoreCard
      .getScoreCardRadar()
      .subscribe((response) => {
        if (response) {
          // this.scoreRadar = response;
        }
      });

    this.socketService
      .changeFlag()
      .subscribe((response) => {
        if (response) {
        }
      });

    this.socketService
      .updateRunners()
      .subscribe((response) => {
        if (response) {
          let matchOddsRunnerData = this.matchOddsData;
          _.map(response, function(item) {
            let marketDetail =  matchOddsRunnerData.find(o => o.marketId == item.marketId);
            if(marketDetail){
              marketDetail.runners = item.runners;
            }
          });
        }
      })

    this.socketServiceClient
      .BetLock()
      .subscribe((response) => {
        if (response) {
          if(response == this.marketId && this.mycurrentRoute === '/sports/game-view/' + response){
            this.router.navigateByUrl('/dashboard');
          }
        }
      });







    this.socketService
      .changeMode()
      .subscribe((response) => {
        if (response) {
          if (response.data.data.marketType == 'Fancy') {
            const modeSetting = response.data.data.fancyMode;
            const fancyId = response.data.data.fancyId;
            if (modeSetting == 'Auto') {
              this.socketServiceRedis.joinRoom(fancyId);
              // this.socketServiceFancyRedis.joinRoom(fancyId);
              appendStatus(fancyId, 'active');
            } else {
              appendStatus(fancyId, 'ballstart');
            }
            const fanobj =  this.fancyData.find(o => o.fancyId == response.data.data.fancyId);
            if (fanobj) {
              fanobj.fancyMode = response.data.data.fancyMode;
            }
          } else {
            this.modeSetting = response.data.data.lineMode;
            const marketId = response.data.data.marketId.toString().replace('.', '');
            $('#' + marketId + '_lay' + ',#' + marketId + '_back' + ',#' + marketId + '_lay_mob' + ',#' + marketId + '_back_mob').html('');
            $('#' + marketId + '_lay_vol' + ',#' + marketId + '_back_vol' + ',#' + marketId + '_lay_mob_vol' + ',#' + marketId + '_back_mob_vol' ).html('');
            $('#' + marketId + '_line').removeClass('fancy_suspend'), $('#' + marketId + '_mob_line').removeClass('fancy_suspend');
            $('#' + marketId + '_line').addClass('fancy_ballstart'), $('#' + marketId + '_mob_line').addClass('fancy_ballstart');
            if (this.modeSetting == 'Auto') {
              setTimeout(function() {
                $('#' + marketId + '_line').removeClass('fancy_ballstart');
              }, 2000);
            }
            const linerobj = this.lineMarketData.find(o => o.marketId == response.data.data.marketId);
            if (linerobj) {
              linerobj.lineMode = response.data.data.lineMode;
            }
          }
        }
      });


    this.socketServiceClient
      .deleteBet()
      .subscribe((response) => {
        if (response) {
          let countObj = {
            userId: this.utilityService.returnLocalStorageData('userId'),
            matchId: Number(this.marketId),
          };
          this.getAllTransactionsCount(countObj);
          this.allTransactions = _.filter(this.allTransactions, function(user) {
            return user._id !== response._id;
          });
        }
      });

    this.socketServiceClient
      .updateSport()
      .subscribe((response) => {
        if (response.isActive) {
        } else {
          const sportUnqId = String(response.id);
          if (this.checkSport[0].sport.id === sportUnqId) {
            this.router.navigateByUrl('dashboard');
          }
        }
      });

    this.socketServiceClient
      .updateTournament()
      .subscribe((response) => {
        if (response.isActive) {
        } else {
          const sportUnqId = String(response.id);
          if (this.checkSport[0].tournament.id === sportUnqId) {
            this.router.navigateByUrl('dashboard');
          }
        }
      });

    this.socketServiceClient
      .updateMatch()
      .subscribe((response) => {
        if (response.isActive) {
        } else {
          const sportUnqId = String(response.id);
          if (this.checkSport[0].match.id === sportUnqId) {
            this.router.navigateByUrl('dashboard');
          }
        }
        const matchIds = String(response.id);
        if (response.isPlay && matchIds === this.marketId) {
          $('#' + matchIds + '_inPlay').removeClass('noPlay');
          $('#' + matchIds + '_inPlay').addClass('inPlay');
          $('#' + matchIds + '_inPlayMob').removeClass('noPlay');
          $('#' + matchIds + '_inPlayMob').addClass('inPlay');
        } else {
          $('#' + matchIds + '_inPlay').removeClass('inPlay');
          $('#' + matchIds + '_inPlay').addClass('noPlay');
          $('#' + matchIds + '_inPlayMob').removeClass('inPlay');
          $('#' + matchIds + '_inPlayMob').addClass('noPlay');
        }
      });

    this.socketServiceClient
      .fancyAutoUpdate()
      .subscribe((response) => {
        if (response.isActive) {
          const count = _.filter(this.fancyData, function(user) {
            return user.fancyId === response.fancyId;
          });
          if (count.length > 0) {
            this.fancyData = _.filter(this.fancyData, function(user) {
              if (user.fancyId === response.fancyId) {
                return response;
              }
            });
          } else {
            if (this.marketId === response.match.id) {
              this.fancyData.push(response);
            }
          }
        } else {
          this.fancyData = _.filter(this.fancyData, function(user) {
            return user.fancyId !== response.fancyId;
          });
        }
      });


    this.socketServiceClient
      .suspendStatus()
      .subscribe((response) => {
        if (response && response.fancyId) {
          if (response.isActive) {
            if(response.fancyId.toString().replace('.', '')){
              response.fancyIdDc = response.fancyId.toString().replace('.', '');
              response.fancyIdDc = response.fancyIdDc.toString().replace('-', '');
            }else{
              response.fancyIdDc = response.fancyId;
            }
            if(response.marketType == 'Fancy') {
              const count = _.filter(this.fancyData, function (user) {
                if (user.fancyId == response.fancyId) {
                  user.fancySetting = response.fancySetting;
                }
                return user.fancyId === response.fancyId;
              });
              if (count.length > 0) {

              } else {
                if (this.marketId === response.match.id) {
                  response['minStackFancy'] = response.fancySetting.minStack;
                  response['maxStackFancy'] = response.fancySetting.maxStack;
                  this.fancyData.push(response);
                  const fancyObj = {
                    fancy: response.fancyId
                  };
                  this.fancyData = _.sortBy(this.fancyData, function (fancyObj) {
                    return fancyObj.displayOrder;
                  });
                  this.fancyService.getFancyByRedis(fancyObj).subscribe(resposne => {
                    if (resposne) {
                      resposne = this.utilityService.gsk(resposne.auth);
                      resposne = JSON.parse(resposne);
                      this.socketServiceRedis.joinRoom(response.fancyId);
                      if (resposne.status == false) {
                        appendStatus(response.fancyIdDc, 'suspend');
                      } else {
                        this.socketServiceRedis.joinRoom(response.fancyId);
                        appendRate(resposne.data);
                        if (resposne && resposne.data) {
                          appendStatus(response.fancyIdDc, resposne.data.statusValue[0].name);
                        }
                      }
                    } else {
                      appendStatus(response.fancyIdDc, 'suspend');
                    }
                  });

                }
              }
            }else{
              const count = _.filter(this.oddEvenData, function (user) {
                if (user.fancyId == response.fancyId) {
                  user.fancySetting = response.fancySetting;
                }
                return user.fancyId === response.fancyId;
              });
              if (count.length > 0) {

              } else {
                if (this.marketId === response.match.id) {
                  response['minStackOddEven'] = response.fancySetting.minStack;
                  response['maxStackOddEven'] = response.fancySetting.maxStack;
                  this.oddEvenData.push(response);
                  const fancyObj = {
                    fancy: response.fancyId
                  };
                  this.oddEvenData = _.sortBy(this.oddEvenData, function (fancyObj) {
                    return fancyObj.displayOrder;
                  });
                  this.fancyService.getFancyByRedis(fancyObj).subscribe(resposne => {
                    if (resposne) {
                      resposne = this.utilityService.gsk(resposne.auth);
                      resposne = JSON.parse(resposne);
                      this.socketServiceRedis.joinRoom(response.fancyId);
                      if (resposne.status == false) {
                        appendStatus(response.fancyIdDc, 'suspend');
                      } else {
                        this.socketServiceRedis.joinRoom(response.fancyId);
                        appendRate(resposne.data);
                        if (resposne && resposne.data) {
                          appendStatus(response.fancyIdDc, resposne.data.statusValue[0].name);
                        }
                      }
                    } else {
                      appendStatus(response.fancyIdDc, 'suspend');
                    }
                  });

                }
              }
            }
          } else {
            if(response.marketType === 'Fancy'){
              this.fancyData = _.filter(this.fancyData, function(user) {
                return user.fancyId !== response.fancyId;
              });
            }
            if(response.marketType === 'OddEven'){
              this.oddEvenData = _.filter(this.oddEvenData, function(user) {
                return user.fancyId !== response.fancyId;
              });
            }

          }
          if (response.marketStatus.id === 'MS940896') {
            appendStatus(response.fancyIdDc, 'suspend');
          }
          if (response.message) {
            $('#' + response.fancyIdDc +  '_message').html('<marquee>' + response.message + '</marquee>');
            $('#' + response.fancyIdDc +  '_messageMob').html('<marquee>' + response.message + '</marquee>');
          } else {
            $('#' + response.fancyIdDc +  '_message').html('');
            $('#' + response.fancyIdDc +  '_messageMob').html('');
          }
        }
        if (response && response.lineId) {
          this.socketServiceRedisMarket.joinRoom(response.marketId); // socket join room

          // update line setting
          _.map(this.lineMarketData , function(res) {
            if (res.marketId == response.marketId) {
              res.lineSetting = response.lineSetting;
            }
          });

          const markId = response.marketId.toString().replace('.', '');
          if (response.marketStatus.id === 'MS940896') {
            appendStatusLine(markId, 'suspend');
          }
          if (response.marketStatus.id === 'MS081893') {
            appendStatusLine(markId, 'Open');
            this.socketServiceRedisMarket.joinRoom(response.marketId); // socket join room
          }
          if (response.isActive) {
            const count = _.filter(this.lineMarketData, function(user) {
              if(user.lineId == response.lineId){
                user.lineSetting = response.lineSetting;
              }
              return user.lineId === response.lineId;
            });
            if (count.length > 0) {

            } else {
              if (this.marketId === response.match.id) {
                response.marketIdDec = markId;
                this.lineMarketData.push(response);
                if (response.marketStatus.id === 'MS940896') {
                  appendStatusLine(markId, 'suspend');
                }
                if (response.marketStatus.id === 'MS081893') {
                  appendStatusLine(markId, 'Open');
                  this.socketServiceRedisMarket.joinRoom(response.marketId); // socket join room
                }
              }
            }
          } else {
            this.lineMarketData = _.filter(this.lineMarketData, function(user) {
              return user.lineId !== response.lineId;
            });
            if (this.matchOddsData.length > 0 || this.lineMarketData.length > 0 || this.fancyData.length > 0) {

            } else {

              if (this.marketId === response.match.id && this.mycurrentRoute === '/sports/game-view/' + this.marketId) {
              }
            }
          }
          if (response.message) {
            $('#' + response.lineId +  '_message').html('<marquee>' + response.message + '</marquee>');
            $('#' + response.lineId +  '_messageMob').html('<marquee>' + response.message + '</marquee>');
          } else {
            $('#' + response.lineId +  '_message').html('');
            $('#' + response.lineId +  '_messageMob').html('');
          }
        }
        if ( response && response.marketId && response.marketType === 'Match Odds') {
          if (response.marketStatus.id === 'MS940896') {
            this.marketStatus = 'MS940896';
            const marketIds_str =  response.marketId.toString().replace('.', '');
            $('.' + marketIds_str + '_market').addClass('market_suspend');
            $('.' + marketIds_str + '_market_mob').addClass('market_suspend');
          }
          if (response.marketStatus.id === 'MS081893') {
            this.marketStatus = 'MS081893';
            const marketIds_str =  response.marketId.toString().replace('.', '');
            $('.' + marketIds_str + '_market').removeClass('market_suspend');
            $('.' + marketIds_str + '_market_mob').removeClass('market_suspend');
          }

          if (response.isActive) {
            const count = _.filter(this.matchOddsData, function(user) {
              if(user.marketId == response.marketId){
                user.gameSetting = response.gameSetting;
              }
              return user.marketId === response.marketId;
            });
            if (count.length > 0) {

            } else {
              if (this.marketId === response.match.id) {
                response.exposer = {
                  "AMOUNT": 0,
                  "RUNNERS": []
                };
                const marketIds_str =  response.marketId.toString().replace('.', '');
                response.marketIdDec = marketIds_str;
                this.matchOddsData.push(response);
                if (response.marketStatus.id === 'MS940896') {
                  $('.' + marketIds_str + '_market').addClass('market_suspend');
                  $('.' + marketIds_str + '_market_mob').addClass('market_suspend');
                }
              }
            }
          } else {
            this.matchOddsData = _.filter(this.matchOddsData, function(user) {
              return user.marketId !== response.marketId;
            });
            if (this.matchOddsData.length > 0 || this.bookmakerData.length > 0 || this.lineMarketData.length > 0 || this.fancyData.length > 0) {

            } else {

              if (this.marketId === response.match.id && this.mycurrentRoute === '/sports/game-view/' + this.marketId) {
                // this.router.navigateByUrl('/dashboard');
              }
            }
          }
          const markId = response.marketId.toString().replace('.', '');
          if (response.message) {

            $('#' + markId +  '_message').html('<marquee>' + response.message + '</marquee>');
            $('#' + markId +  '_messageMob').html('<marquee>' + response.message + '</marquee>');
          } else {
            $('#' + markId +  '_message').html('');
            $('#' + markId +  '_messageMob').html('');
          }
        }
        if ( response && response.marketId && response.marketTypeId === '5ebc1code68br4bik5b0814') {
          if (response.marketStatus.id === 'MS940896') {
            this.marketStatus = 'MS940896';
            const marketIds_str =  response.marketId.toString().replace('.', '');
            $('.' + marketIds_str + '_market').addClass('market_suspend');
            $('.' + marketIds_str + '_market_mob').addClass('market_suspend');
          }
          if (response.marketStatus.id === 'MS081893') {
            this.marketStatus = 'MS081893';
            const marketIds_str =  response.marketId.toString().replace('.', '');
            $('.' + marketIds_str + '_market').removeClass('market_suspend');
            $('.' + marketIds_str + '_market_mob').removeClass('market_suspend');
          }

          if (response.isActive) {
            const count = _.filter(this.matchOddsData, function(user) {
              return user.marketId === response.marketId;
            });
            if (count.length > 0) {

            } else {
              if (this.marketId === response.match.id) {
                response.exposer = {
                  "AMOUNT": 0,
                  "RUNNERS": []
                };
                this.matchOddsData.push(response);
                if (response.marketStatus.id === 'MS940896') {
                  const marketIds_str =  response.marketId.toString().replace('.', '');
                  $('.' + marketIds_str + '_market').addClass('market_suspend');
                  $('.' + marketIds_str + '_market_mob').addClass('market_suspend');
                }
              }
            }
          } else {
            this.matchOddsData = _.filter(this.matchOddsData, function(user) {
              return user.marketId !== response.marketId;
            });
            if (this.matchOddsData.length > 0 || this.bookmakerData.length > 0 || this.lineMarketData.length > 0 || this.fancyData.length > 0) {

            } else {

              if (this.marketId === response.match.id && this.mycurrentRoute === '/sports/game-view/' + this.marketId) {
              }
            }
          }
          const markId = response.marketId.toString().replace('.', '');
          if (response.message) {

            $('#' + markId +  '_message').html('<marquee>' + response.message + '</marquee>');
            $('#' + markId +  '_messageMob').html('<marquee>' + response.message + '</marquee>');
          } else {
            $('#' + markId +  '_message').html('');
            $('#' + markId +  '_messageMob').html('');
          }
        }
        if ( response && response.marketId && response.marketType === 'Bookmaker') {
          if (response.marketStatus.id === 'MS940896') {
            this.marketStatus = 'MS940896';
            const bookmakerId =  response.marketId.toString().replace('.', '');
            $('.' + bookmakerId + '_bookmaker').addClass('market_suspend');
            $('.' + bookmakerId + '_bookmaker_mob').addClass('market_suspend');
          }
          if (response.marketStatus.id === 'MS081893') {
            this.marketStatus = 'MS081893';
            const bookmakerId =  response.marketId.toString().replace('.', '');
            $('.' + bookmakerId + '_bookmaker').removeClass('market_suspend');
            $('.' + bookmakerId + '_bookmaker_mob').removeClass('market_suspend');
          }

          if (response.isActive) {
            const count = _.filter(this.bookmakerData, function(user) {
              if(user.marketId == response.marketId){
                user.bookmakerSetting = response.bookmakerSetting;
              }
              return user.marketId === response.marketId;
            });
            if (count.length > 0) {

            } else {
              if (this.marketId === response.match.id) {
                response.exposer = {
                  "AMOUNT": 0,
                  "RUNNERS": []
                };
                this.bookmakerData.push(response);
                if (response.marketStatus.id === 'MS940896') {
                  const bookmakerId =  response.marketId.toString().replace('.', '');
                  $('.' + bookmakerId + '_bookmaker').addClass('market_suspend');
                  $('.' + bookmakerId + '_bookmaker_mob').addClass('market_suspend');
                }
              }
            }
          } else {
            this.bookmakerData = _.filter(this.bookmakerData, function(user) {
              return user.marketId !== response.marketId;
            });
            if (this.matchOddsData.length > 0 || this.bookmakerData.length > 0 || this.lineMarketData.length > 0 || this.fancyData.length > 0) {

            } else {
              if (this.marketId === response.match.id && this.mycurrentRoute === '/sports/game-view/' + this.marketId) {
              }
            }
          }
          const markId = response.marketId.toString().replace('.', '');
          if (response.message) {

            $('#' + markId +  '_message').html('<marquee>' + response.message + '</marquee>');
            $('#' + markId +  '_messageMob').html('<marquee>' + response.message + '</marquee>');
          } else {
            $('#' + markId +  '_message').html('');
            $('#' + markId +  '_messageMob').html('');
          }
        }
      });


    this.socketService
      .changeStatus()
      .subscribe((response) => {

        if(response.type == 'fancy'){
          this.appendStatus(response.fancy_id, response.status);
        }
        if(response.marketType === 'Bookmaker'){
          _.map(response.runners , function(e) {
            appendStatusBM(e.selectionId, response.marketStatus.name);
          });

        }
        if (response.type == 'line') {
          const marketId = response.marketId.toString().replace('.', '');
          appendStatusLine(marketId, response.status);
        } else if (response.type === 'fancyStatusAll') {
          let status = response.status.name.toLowerCase();
          if (status === 'open') {
            status = 'active';
          }

          if (status === 'active' && response.fancyData.rate.length > 0) {
            appendRate(response.fancyData.rate[0]);
          }
          if (response.fancyData && response.fancyData.rate.length > 0) {
            appendStatus(response.fancyData.fancyId, status);
          }
        } else if (response.type === 'updateEvent') {

        } else if (response.type === 'updateEventTimer') {
          appendStatus(response.fancyId, 'ballstart');

        } else {
          const fancyId = response.fancy_id;
          appendStatus(fancyId, response.status);
          const fancyObj = {
            fancy : fancyId
          };
          this.fancyService.getFancyByRedis(fancyObj).subscribe(resposne => {

            if (resposne) {
              if (resposne.status == false) {
              } else {
                if(resposne.data){
                  if (resposne.data.statusValue[0]['id'] ===  'MS960523') {
                    appendRate(resposne.data);
                  }
                }
              }
            } else {
              appendStatus(response.fancyId, 'suspend');
            }
          });
        }
      });

    this.socketService
      .changeFlag()
      .subscribe((response) => {
        if (response) {
          let marketId = String(response)
          if(this.mycurrentRoute === '/sports/game-view/' + marketId && this.marketId === marketId){
            const userId = this.utilityService.returnLocalStorageData('userId');
            this.getMarketIdData(userId);

          }
        }
      });

    this.socketServiceClient.bookMakerRateChange().subscribe((response) => {
      if (response) {
        response.runners.map(res => {
          if (res && (res.back > 0 || res.lay > 0)) {
            $('#' + res.selectionId + '_bookmaker').removeClass('market_suspend');
            $('#' + res.selectionId + '_back_odds').html(res.back);
            $('#' + res.selectionId + '_lay_odds').html(res.lay);
          }else {
            $('#' + res.selectionId + '_bookmaker').addClass('market_suspend');
          }
        });
      }

    });

  }



  ngOnDestroy() {
    const oldMatch = JSON.parse(sessionStorage.getItem('matchOddsIds'));
    const oldfancy = JSON.parse(sessionStorage.getItem('lastJoinRoom'));
    let scoreIds =  localStorage.getItem('scorecardIds');
    this.scoreCard.leaveRoom(scoreIds);
    let socketFancy = this.socketServiceRedis;
    let socketMatchOdds = this.socketServiceRedisMarket;
    _.map(oldfancy, function (e) {
      socketFancy.leaveRoom(e);
    })
    _.map(oldMatch, function (s) {
      socketMatchOdds.leaveRoom(s);
    })
  }

  /**
   * @author kc
   * delete object client in db
   */
  getAllTransactionsCount(data) {
    this.marketService.getAllTransactionsCount(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      this.countTransactions = response.data;
    }, error => {

    });
  }

  getAllTransaction(transactionObj) {
    transactionObj.limitData = 50;
    this.marketService.getAllTransactions(transactionObj).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if (response.status == true) {
        this.allTransactions = response.data;
      }
    });
  }

  getAllTransactionAsMactch(transactionObj) {
    this.marketService.getAllTransactionsAsMatch(transactionObj).subscribe(response => {
      if (response.status == true) {
        this.allTransactionsAsMatch = response.data;
      }
    });
  }
  getAllTransactionAsLine(transactionObj) {
    // get all transaction
    this.marketService.getAllTransactionsAsLine(transactionObj).subscribe(response => {

      if (response.status == true) {
        this.allTransactionsAsLine = response.data;
      }
    });
  }

  upendFancyRate(fancyData) {
    fancyData.map(function(fancy) {
      if (fancy.fancyMode == 'Auto') {
        fancy.marketId = fancy.marketId.toString().replace('.', '');
        appendStatus(fancy.marketId, 'suspend');
      }
    },this);
  }

  upendBmRateManual(){
    this.bookmakerData.map(function(bookmaker) {
      if(bookmaker.bookmakerMode == "manual"){
        if(bookmaker.latestRate){
          // appendBMRateManual(bookmaker.latestRate);
          this.appendBMRateManual(bookmaker.latestRate);
        }
      }
    },this);
  }

  startRate() {
    // if (this.confirmRate == false) {
    let timeOuts =   setInterval(() => {
      console.log("hello")
      this.upendFancyRate(this.fancyData);
      clearInterval(timeOuts);
    }, 100);
    // }
    // this.confirmRate = true;
  }



  startRateBm() {
    if (this.confirmRateBm == false) {
      setTimeout(() => {
        this.upendBmRateManual();
      }, 100);
    }
    this.confirmRateBm = true;
  }



  appendStatus(fancyId, status) {
    if (status == 'ballstart') {
      $('#no1_' + fancyId + ',#no1Vol_' + fancyId + ',#yes1_' + fancyId + ',#yes1Vol_' + fancyId + ',#no2_' + fancyId + ',#no2Vol_' + fancyId + ',#yes2_' + fancyId + ',#yes2Vol_' + fancyId + ',#no3_' + fancyId + ',#no3Vol_' + fancyId + ',#yes3_' + fancyId + ',#yes3Vol_' + fancyId + + ',#no4_' + fancyId + ',#no4Vol_' + fancyId + ',#yes4_' + fancyId + ',#yes4Vol_' + fancyId + '').html('');
      $('#mob_no1_' + fancyId + ',#mob_no1Vol_' + fancyId + ',#mob_yes1_' + fancyId + ',#mob_yes1Vol_' + fancyId + ',#mob_no2_' + fancyId + ',#mob_no2Vol_' + fancyId + ',#mob_yes2_' + fancyId + ',#mob_yes2Vol_' + fancyId + ',#mob_no3_' + fancyId + ',#mob_no3Vol_' + fancyId + ',#mob_yes3_' + fancyId + ',#mob_yes3Vol_' + fancyId + ',#mob_no4_' + fancyId + ',#mob_no4Vol_' + fancyId + ',#mob_yes4_' + fancyId + ',#mob_yes4Vol_' + fancyId + '').html('');

      $('#' + fancyId +  '_first,#' + fancyId + '_third,#' + fancyId + '_forth').hide(), $('#mob_' + fancyId +  '_first,#mob_' + fancyId + '_third,#mob_' + fancyId + '_forth').hide();
      $('#' + fancyId + '_second').removeClass('fancy_suspend'), $('#mob_' + fancyId + '_second').removeClass('fancy_suspend');
      $('#' + fancyId + '_second').addClass('fancy_ballstart'), $('#mob_' + fancyId + '_second').addClass('fancy_ballstart');
    }
    if (status && status.name === 'Close') {
      $('#no1_' + fancyId + ',#no1Vol_' + fancyId + ',#yes1_' + fancyId + ',#yes1Vol_' + fancyId + ',#no2_' + fancyId + ',#no2Vol_' + fancyId + ',#yes2_' + fancyId + ',#yes2Vol_' + fancyId + ',#no3_' + fancyId + ',#no3Vol_' + fancyId + ',#yes3_' + fancyId + ',#yes3Vol_' + fancyId + ',#no4_' + fancyId + ',#no4Vol_' + fancyId + ',#yes4_' + fancyId + ',#yes4Vol_' + fancyId + '').html('');
      $('#mob_no1_' + fancyId + ',#mob_no1Vol_' + fancyId + ',#mob_yes1_' + fancyId + ',#mob_yes1Vol_' + fancyId + ',#mob_no2_' + fancyId + ',#mob_no2Vol_' + fancyId + ',#mob_yes2_' + fancyId + ',#mob_yes2Vol_' + fancyId + ',#mob_no3_' + fancyId + ',#mob_no3Vol_' + fancyId + ',#mob_yes3_' + fancyId + ',#mob_yes3Vol_' + fancyId  + ',#mob_no4_' + fancyId + ',#mob_no4Vol_' + fancyId + ',#mob_yes4_' + fancyId + ',#mob_yes4Vol_' + fancyId + '').html('');

      $('#' + fancyId +  '_first,#' + fancyId + '_third,#' + fancyId + '_forth').hide(), $('#mob_' + fancyId +  '_first,#mob_' + fancyId + '_third,#mob_' + fancyId + '_forth').hide();
      $('#' + fancyId + '_second').removeClass('fancy_suspend'), $('#mob_' + fancyId + '_second').removeClass('fancy_suspend');
      $('#' + fancyId + '_second').addClass('fancy_close'), $('#mob_' + fancyId + '_second').addClass('fancy_close');
    }
    if (status == 'suspend') {
      $('#no1_' + fancyId + ',#no1Vol_' + fancyId + ',#yes1_' + fancyId + ',#yes1Vol_' + fancyId + ',#no2_' + fancyId + ',#no2Vol_' + fancyId + ',#yes2_' + fancyId + ',#yes2Vol_' + fancyId + ',#no3_' + fancyId + ',#no3Vol_' + fancyId + ',#yes3_' + fancyId + ',#yes3Vol_' + fancyId + ',#no4_' + fancyId + ',#no4Vol_' + fancyId + ',#yes4_' + fancyId + ',#yes4Vol_' + fancyId + '').html('');
      $('#mob_no1_' + fancyId + ',#mob_no1Vol_' + fancyId + ',#mob_yes1_' + fancyId + ',#mob_yes1Vol_' + fancyId + ',#mob_no2_' + fancyId + ',#mob_no2Vol_' + fancyId + ',#mob_yes2_' + fancyId + ',#mob_yes2Vol_' + fancyId + ',#mob_no3_' + fancyId + ',#mob_no3Vol_' + fancyId + ',#mob_yes3_' + fancyId + ',#mob_yes3Vol_' + fancyId  + ',#mob_no4_' + fancyId + ',#mob_no4Vol_' + fancyId + ',#mob_yes4_' + fancyId + ',#mob_yes4Vol_' + fancyId + '').html('');

      $('#' + fancyId +  '_first,#' + fancyId + '_third,#' + fancyId + '_forth').hide(), $('#mob_' + fancyId +  '_first,#mob_' + fancyId + '_third,#mob_' + fancyId + '_forth').hide();        $('#' + fancyId + '_second').removeClass('fancy_ballstart'), $('#mob_' + fancyId + '_second').removeClass('fancy_ballstart');
      $('#' + fancyId + '_second').addClass('fancy_suspend'), $('#mob_' + fancyId + '_second').addClass('fancy_suspend');
    }
    if (status == 'settled') {
      $('#no1_' + fancyId + ',#no1Vol_' + fancyId + ',#yes1_' + fancyId + ',#yes1Vol_' + fancyId + ',#no2_' + fancyId + ',#no2Vol_' + fancyId + ',#yes2_' + fancyId + ',#yes2Vol_' + fancyId + ',#no3_' + fancyId + ',#no3Vol_' + fancyId + ',#yes3_' + fancyId + ',#yes3Vol_' + fancyId + ',#no4_' + fancyId + ',#no4Vol_' + fancyId + ',#yes4_' + fancyId + ',#yes4Vol_' + fancyId + '').html('');
      $('#mob_no1_' + fancyId + ',#mob_no1Vol_' + fancyId + ',#mob_yes1_' + fancyId + ',#mob_yes1Vol_' + fancyId + ',#mob_no2_' + fancyId + ',#mob_no2Vol_' + fancyId + ',#mob_yes2_' + fancyId + ',#mob_yes2Vol_' + fancyId + ',#mob_no3_' + fancyId + ',#mob_no3Vol_' + fancyId + ',#mob_yes3_' + fancyId + ',#mob_yes3Vol_' + fancyId  + ',#mob_no4_' + fancyId + ',#mob_no4Vol_' + fancyId + ',#mob_yes4_' + fancyId + ',#mob_yes4Vol_' + fancyId + '').html('');

      $('#' + fancyId +  '_first,#' + fancyId + '_third,#' + fancyId + '_forth').hide(), $('#mob_' + fancyId +  '_first,#mob_' + fancyId + '_third,#mob_' + fancyId + '_forth').hide();        $('#' + fancyId + '_second').removeClass('fancy_ballstart'), $('#mob_' + fancyId + '_second').removeClass('fancy_ballstart');
      $('#' + fancyId + '_second').removeClass('fancy_suspend'), $('#mob_' + fancyId + '_second').removeClass('fancy_suspend');

      $('#' + fancyId + '_second').addClass('fancy_settled'), $('#mob_' + fancyId + '_second').addClass('fancy_settled');
    }
    if (status == 'active') {
      $('#' + fancyId + '_second').removeClass('fancy_ballstart'), $('#mob_' + fancyId + '_second').removeClass('fancy_ballstart');
      $('#' + fancyId + '_second').removeClass('fancy_suspend'), $('#mob_' + fancyId + '_second').removeClass('fancy_suspend');
    }
  }

  getMarketIdData(filter) {
    let fancyRedis = [];
    const totalLineSct = [];
    const fancyRateId = [];
    const marketRateId = [];
    const bookmakerRateId = [];
    const oddEvenRateId = []
    this.getMarketByIds = [];
    this.marketService.getMultiMarket(filter).subscribe(response => {
      let marketServi = this.socketServiceRedisMarket;
      let fancyServi = this.socketServiceRedis;
        _.map(response.data, function (e) {

          _.map(e.data, function (s) {

            marketServi.joinRoom(s.marketId);
            if(s.marketType == 'Match Odds' || s.marketType == 'Bookmaker' || s.marketType == 'Winner'){
              s.marketIdDec = s.marketId.toString().replace('.', '');
            }else{
              fancyServi.joinRoom(s.fancyId);
              s.fancyIdDc = s.fancyId.toString().replace('.', '');
            }
          })
      })
      if(response.data.length > 0){
        this.myExposer(response.data[0]._id.sportId, filter);
      }
      if(response.data.length > 1){
        this.myExposer(response.data[1]._id.sportId, filter);
      }
      if(response.data.length > 2){
        this.myExposer(response.data[2]._id.sportId, filter);
      }
      if(response.data.length > 3){
        this.myExposer(response.data[3]._id.sportId, filter);
      }
      if(response.data.length > 4){
        this.myExposer(response.data[4]._id.sportId, filter);
      }
      if(response.data.length > 5){
        this.myExposer(response.data[5]._id.sportId, filter);
      }
      this.allMarketData = response.data;

      this.getUserCurrency();

      response.data.map(res => {
        this.getMarketByIds.push(res);
      });
      this.getMarketById = this.getMarketByIds;
      this.matchOddsData1 = this.getMarketById.filter(function(number) {
        if (number.marketTypeId === '5ebc1code68br4bik5b3035') {
          return number.marketTypeId === '5ebc1code68br4bik5b3035';
        } else if (number.marketTypeId === '5ebc1code68br4bik5b0814') {
          return number.marketTypeId === '5ebc1code68br4bik5b0814';
        }
      });
      // this.titalMatch = this.getMarketById[0].match.name.substring(0, this.getMarketById[0].match.name.indexOf('('))  + '-' + this.getMarketById[0].tournament.name.substring(0, this.getMarketById[0].tournament.name.indexOf('('));
      if (this.matchOddsData1.length > 0) {

        this.marketVolume =  this.matchOddsData1[0].volume ? this.matchOddsData1[0].volume : this.matchOddsData1[0].volume ;
        this.marketStatus = this.matchOddsData1[0].marketStatus.id;
        const marketId = this.matchOddsData1[0].marketId;
        this.marketSel = marketId.toString().replace('.', '');

        const lastJoinRoom =  sessionStorage.getItem('lastJoinRoom');
        if (lastJoinRoom) {
          let lastJoinRoomAry = [];
          lastJoinRoomAry = JSON.parse(lastJoinRoom);
          lastJoinRoomAry.map(res => {
            this.socketService.leaveRoom(res);
          });
        }

        totalLineSct.push(marketId);
        marketRateId.push(marketId);
      }

      this.bookmakerData = this.getMarketById.filter(function(number) {
        return number.marketTypeId === '5ebc1code68br4bik5b0810';
      });


      if (this.bookmakerData.length > 0) {
        const bookId = this.bookmakerData[0].marketId;
        this.marketIdBooKmakerUsed = this.bookmakerData[0].marketId;
        this.bookmakerId = bookId.toString().replace('.', '');
        if (this.bookmakerData.length > 1) {
          const bookId1 = this.bookmakerData[1].marketId;
          this.socketServiceRedis.joinRoom(bookId1);
        }
        this.socketServiceRedis.joinRoom(bookId);

        this.getbookMakerLatest(bookId , this.bookmakerData);
      }
      this.bookmakerData.map(res => {
        let bookId = this.bookmakerData[0].marketId;
        res.marketIdDec = bookId.toString().replace('.', '');
        totalLineSct.push(res.marketId);
        bookmakerRateId.push(res.marketId);

      });

      this.fancyData = this.getMarketById.filter(function(number) {
        return number.marketTypeId === '5ebc1code68br4bik5b1808';
      });
      this.fancyData.map(res => {
        if(res.fancyMode === 'Manual'){
          fancyRedis.push(res);
          this.socketServiceRedis.joinRoom(res.fancyId);
          appendStatus(res.fancyId, res.marketStatus.name);
        }

        if (res.fancyMode == 'Auto') {
          this.socketServiceRedis.joinRoom(res.fancyId);
          // this.socketServiceFancyRedis.joinRoom(res.fancyId);
        }
        totalLineSct.push(res.fancyId);
        fancyRateId.push(res.fancyId);
      });
      this.fancyData.filter(function(number) {
        if(number.fancyId.toString().replace('.', '')) {
          number.fancyIdDc = number.fancyId.toString().replace('.', '');
          number.fancyIdDc = number.fancyIdDc.toString().replace('-', '');
        }else{
          number.fancyIdDc = number.fancyId;
        }
        return number

      });
      this.startRate();

      this.marketService.getRedisData(fancyRedis).subscribe(response => {
        this.upendFancyRateRedis(response.data);
      });

      let matchOddsId = [];
      this.matchOddsData1.map(matchRes => {
        matchRes.marketIdDec = matchRes.marketId.toString().replace('.', '');
        this.socketServiceRedisMarket.joinRoom(matchRes.marketId);
        matchOddsId.push(matchRes.marketId);
      });
      sessionStorage.setItem('matchOddsIds' , JSON.stringify(matchOddsId));
      sessionStorage.setItem('lastJoinRoom', JSON.stringify(totalLineSct));
    });
  }

  upendFancyRateRedis(fancyData) {
    if(fancyData != undefined){
      fancyData.map(function(fancy) {
        fancy = JSON.parse(fancy);
        if(fancy && fancy.statusValue){
          appendRate(fancy);
          this.appendStatus(fancy.fancyId, fancy.statusValue[0].name);
        }else{
          this.appendStatus(fancy.fancyId, 'suspend');
        }


      },this);
    }
  }

  myExposer(matchId, userId){
    let data = {
      matchId: matchId,
      userId: userId
    }
    console.log(data)
    this.fancyService.getExposer(data).subscribe(resposne => {
      resposne = this.utilityService.gsk(resposne.auth);
      resposne = JSON.parse(resposne);
      if (resposne.status == true) {
        _.map(resposne.data, function (e) {
          if(e.marketType == 'fancy'){
            e.gameId =  e.gameId.toString().replace('.', '');
            e.gameId =  e.gameId.toString().replace('-', '');
            e.amount = e.amount.toFixed(0)
            $('#' + e.gameId + '_exposer').html(String(e.amount));
            $('#' + e.gameId + '_expr').html(String(e.amount));
          } else if(e.marketType == 'bookmaker') {
            _.map(e.runnerExp, function (s){
              if(s.amount < 0){
                s.amount = s.amount.toFixed(0)
                $('#' + s.runners.selectionId + '_bm_exposer').removeClass('text-success').addClass('text-danger');
                $('#' + s.runners.selectionId + '_bm_exposer').html(String(s.amount));
              }else{
                s.amount = s.amount.toFixed(0)
                $('#' + s.runners.selectionId + '_bm_exposer').removeClass('text-danger').addClass('text-success');
                $('#' + s.runners.selectionId + '_bm_exposer').html(String(s.amount));


              }
            });
          }else{
            _.map(e.runnerExp, function (p) {
              e.gameId  = e.gameId.toString().replace('.', '');
              if (p.amount < 0) {
                p.amount = p.amount.toFixed(0);
                $('#' + p.runners.selectionId + '_' + e.gameId + '_exposer').removeClass('text-success').addClass('text-danger');
                $('#' + p.runners.selectionId + '_' + e.gameId + '_exposer').html(String(p.amount));
                $('#' + p.runners.selectionId + '_' + e.gameId + '_exposer_m').html(String(p.amount));
              } else {
                p.amount = p.amount.toFixed(0);
                $('#' + p.runners.selectionId + '_' + e.gameId + '_exposer').removeClass('text-danger').addClass('text-success');
                $('#' + p.runners.selectionId + '_' + e.gameId + '_exposer').html(String(p.amount));
                $('#' + p.runners.selectionId + '_' + e.gameId + '_exposer_m').html(String(p.amount));
              }
            })
          }
        })
      }
    })
  }
  customeFancy(ittyp){
    if(ittyp = 'fancy'){
      this.FancyItem.map(res => {
        appendStatus(res.fancyId, res.marketStatus.name);
        this.socketServiceRedis.joinRoom(res.fancyId);
        // this.socketServiceFancyRedis.joinRoom(res.fancyId);
      });
    }
    if(ittyp = 'line'){
      this.lineMarketFancy.map(res => {
        appendStatus(res.fancyId, res.marketStatus.name);
        this.socketServiceRedis.joinRoom(res.fancyId);
      });
    }
    if(ittyp = 'ballbyball'){
      this.BallbyballFancy.map(res => {
        appendStatus(res.fancyId, res.marketStatus.name);
        this.socketServiceRedis.joinRoom(res.fancyId);
      });
    }

  }

  /*
    Developer: Ravi
    Date: 05-mar-2020
    title: Place rate function for market
    Use: This function is use append rate into place bet window
  */

  placeRateMarket(marketSel, index, marketType, row, event, marketDetail, team , inx) {
    this.placeBet.show();
    this.selectedTm = team;
    this.selectInx = inx;
    this.selectMarketId = marketSel;
    this.selectMarket = marketDetail;
    console.log(marketDetail)
    $('#'+ team.selectionId + inx +'_stackInput').val('');
    $('.activeColor').removeClass('activeColor');
    const marketId = team.selectionId + '_' +marketSel + '_' + marketType + '_' + row + '_';
    $('#' + marketId + 'odds_blink').addClass('activeColor');
    const odds = $('#' + marketId + 'odds').html();
    const vol = $('#' + marketId + 'vol').html();
    let interval = setInterval(() => {
      if( marketType == 'lay'){
        $('#'+ team.selectionId + '_betModal_market').removeClass('back');
        $('#'+ team.selectionId + '_betModal_market').addClass('lay') ;
        $('.price-btn').removeClass('backCl');
        $('.price-btn').addClass('layCl');

      }else{
        $('#'+ team.selectionId + '_betModal_market').removeClass('lay');
        $('#'+ team.selectionId + '_betModal_market').addClass('back')
        $('.price-btn').removeClass('layCl');
        $('.price-btn').addClass('backCl');
      }
      marketDetail.runners.forEach(async (e) => {
        let ite = $('#'+e.selectionId +'_'+ marketSel +'_exposer').text();
        let r1 = '#'+e.selectionId +'_'+marketSel+'_exposer_m';
        let r2 = '#'+e.selectionId +'_'+marketSel+'_exposer_mn';
        $(r1).text(ite);
        $(r2).text(ite);

        // if(Number(ite) > 0){
        //   $('#'+e.selectionId +'_'+marketSel+'_exposer_m').removeClass('text-danger');
        //   // $('#'+e.selectionId +'_'+marketSel+'_exposer_mn').removeClass('text-danger');
        //   $('#'+e.selectionId +'_'+marketSel+'_exposer_m').addClass('text-success');
        //   // $('#'+e.selectionId +'_'+marketSel+'_exposer_mn').addClass('text-success');
        // }else{
        //   $('#'+e.selectionId +'_'+marketSel+'_exposer_m').removeClass('text-success');
        //   // $('#'+e.selectionId +'_'+marketSel+'_exposer_mn').removeClass('text-success');
        //   $('#'+e.selectionId +'_'+marketSel+'_exposer_m').addClass('text-danger');
        //   // $('#'+e.selectionId +'_'+marketSel+'_exposer_mn').addClass('text-danger');
        // }
      });
      clearInterval(interval);
    }, 100);

    let intervalRt = setInterval(() => {
      let backRt = $('#'+team.selectionId +'_'+ marketSel +'_back_0_odds').text();
      let layRt = $('#'+team.selectionId +'_'+ marketSel +'_lay_0_odds').text();
      let backVl = $('#'+team.selectionId +'_'+ marketSel +'_back_0_vol').text();
      let layVl = $('#'+team.selectionId +'_'+ marketSel +'_lay_0_vol').text();
      $('#'+team.selectionId +'_back_0_odds').text(backRt);
      $('#'+team.selectionId +'_back_0_vol').text(backVl);
      $('#'+team.selectionId +'_lay_0_odds').text(layRt);
      $('#'+team.selectionId +'_lay_0_vol').text(layVl);
      clearInterval(intervalRt);
    }, 20);


    this.placeOdds = odds;
    this.placeMarketType = "market";
    this.placeRunnerType = marketType;
    this.placeGameId = marketDetail.marketId;
    this.placeVol = vol;
    this.placeRow = row;
    this.placeTeam = team;


    $('#placebet').data('data-odds', odds);
    $('.ite-types').html(marketType);
    $('#placebet').data('data-game-id', marketDetail.marketId);
    $('#placebet').data('data-volume', vol);
    $('#placebet').data('data-runner-type', marketType);
    $('#placebet').data('data-row', row);
    $('#placebet').data('data-market-type', 'market');
    $('#placebet').data('team', team);
    $('#placebet').data('marketDetails', marketDetail);
    this.resetTempExp();
  }

  placeRateMarketBM(marketSel, index, marketType, row, event, marketDetail, team) {
    this.placeBetBms.show();
    this.selectedTm = team;
    // this.selectInx = inx;
    this.selectMarketId = marketSel;
    this.selectMarket = marketDetail;
    let interval = setInterval(() => {
      if( marketType == 'lay'){
        $('#'+ team.selectionId + '_betModal_bm').removeClass('back');
        $('#'+ team.selectionId + '_betModal_bm').addClass('lay')
      }else{
        $('#'+ team.selectionId + '_betModal_bm').removeClass('lay');
        $('#'+ team.selectionId + '_betModal_bm').addClass('back')
      }
      marketDetail.runners.forEach(async (e) => {
        let ite = $('#'+e.selectionId +'_bm_exposer').text();
        let r1 = '#'+e.selectionId +'_Exp';
        $(r1).text(ite);
        // if(Number(ite) > 0){
        //   $('#'+e.selectionId +'_Exp').removeClass('text-danger');
        //   $('#'+e.selectionId +'_Exp').addClass('text-success');
        // }else{
        //   $('#'+e.selectionId +'_Exp').removeClass('text-success');
        //   $('#'+e.selectionId +'_Exp').addClass('text-danger');
        // }
      });
      clearInterval(interval);
    }, 100);

    let intervalRt = setInterval(() => {
      let backRt = $('#'+team.selectionId +'_back_odds').text();
      let layRt = $('#'+team.selectionId +'_lay_odds').text();
      let backVl = $('#'+team.selectionId +'_back_vol').text();
      let layVl = $('#'+team.selectionId +'_lay_vol').text();
      $('#'+team.selectionId +'_back_odds_b').text(backRt);
      $('#'+team.selectionId +'_back_vol_b').text(backVl);
      $('#'+team.selectionId +'_lay_odds_l').text(layRt);
      $('#'+team.selectionId +'_lay_vol_l').text(layVl);
      clearInterval(intervalRt);
    }, 20);

    $('.activeColor').removeClass('activeColor');
    let marketId = team.selectionId + '_' + marketType + '_';
    $('#' + marketId + 'odds_blink').addClass('activeColor');
    const odds = $('#' + marketId + 'odds').html();
    $('#placebetBm').data('data-odds', parseInt(odds));
    const vol = $('#' + marketId + 'vol').html();
    $('#'+ team.selectionId + '_bm_oddsInput').val(odds);
    $('.bet_for_name').html(team.runnerName);
    $('.ite-types').html(marketType);
    if(marketType === 'back'){
      $('#betting-slip-div').removeClass('betting-slip-lay').addClass('betting-slip-back');
    }else{
      $('#betting-slip-div').removeClass('betting-slip-back').addClass('betting-slip-lay');
    }
    this.placeOdds = odds;
    this.placeMarketType = "bookmaker";
    this.placeRunnerType = marketType;
    this.placeGameId = marketDetail.marketId;
    this.placeVol = vol;
    this.placeRow = row;
    this.placeTeam = team;

    $('#placebetBm').data('data-game-id', marketDetail.marketId);
    $('#placebetBm').data('data-volume', vol);
    $('#placebetBm').data('data-runner-type', marketType);
    $('#placebetBm').data('data-row', row);
    $('#placebetBm').data('data-market-type', 'bookmaker');
    $('#placebetBm').data('team', team);
    $('#'+ team.selectionId +'_bm_stackInput').val('');
    $('#placebet').data('marketDetails', marketDetail);

  }

  /*
    Developer: Ravi
    Date: 05-mar-2020
    title: Place rate function for fancy
    Use: This function is use append rate into place bet window
  */

  placeRate(type, runner_type, row, id, event, fancyName, marketId = '' , inx) {
    this.placeBetFm.show();
    this.selectedTm = fancyName;
    this.selectInx = inx;
    this.selectMarket = id;
    this.selectMarketId =  id.toString().replace('.', '');

    let interval = setInterval(() => {
      if( runner_type == 'lay'){
        $('#'+ this.selectMarketId + '_betModal_fm').removeClass('back');
        $('#'+ this.selectMarketId + '_betModal_fm').addClass('lay')
      }else{
        $('#'+ this.selectMarketId + '_betModal_fm').removeClass('lay');
        $('#'+ this.selectMarketId + '_betModal_fm').addClass('back')
      }
      let exp =  $('#'+ this.selectMarketId + '_exposer').text();
      $('#'+ this.selectMarketId + '_expr').text(exp)
      clearInterval(interval);
    }, 100);
    $('.activeColor').removeClass('activeColor');
    id = id.toString().replace('.', '');
    id = id.toString().replace('-', '');

    $('#'+row+ '_class' + id).addClass('activeColor');

    const odds = $('#'+row+ '_' + id).html();
    const vol = $('#'+row+ 'Vol_' + id).html();
    const oddsVal = odds + '/' + vol;
    $('#'+ id + inx+ '_oddsInput').val(oddsVal);
    $('#placebet-fm').data('data-odds', parseInt(odds));
    this.currentTranPro = 0;
    if (runner_type === 'lay') {
      $('.ite-types').html('No');
    } else {
      $('.ite-types').html('Yes');
    }
    $('#'+id+  inx+'_stackInput').val('');
    if (runner_type === 'back') {
      $('.betting-slip-div-class').removeClass('betting-slip-lay').addClass('betting-slip-back');
    } else {
      $('.betting-slip-div-class').removeClass('betting-slip-back').addClass('betting-slip-lay');
    }

    $('#placebet-fm').data('team', '');
    if (type == 'fancy') {
      // Rate value from html (condition check span is exits or not)
      const rateVal = ($(event.target).closest('td').find( '#' + row + '_' + id ).children().length > 0) ? $(event.target).closest('td').find( '#' + row + '_' + id ).children().html() : $(event.target).closest('td').find( '#' + row + '_' + id ).html() ;
      $('#oddsInput').val(parseInt(rateVal));
      // Rate value from html (condition check span is exits or not)
      const rateVol = ($(event.target).closest('td').find( '#' + row + 'Vol_' + id ).children().length > 0) ? $(event.target).closest('td').find( '#' + row + 'Vol_' + id ).children().html() : $(event.target).closest('td').find( '#' + row + 'Vol_' + id ).html();
      // $('.ite-volume').html('/' + rateVol);
      $('.bet_for_name').html(fancyName + '/' + rateVol);
      $('#placebet-fm').data('data-volume', vol);
      $('#placebet-fm').data('data-game-id', id);
      this.placeOdds = odds;
      this.placeMarketType = type;
      this.placeRunnerType = runner_type;
      this.placeGameId = id;
      this.placeVol = vol;
      this.placeRow = row;
    } else {
      $('.bet_for_name').html(fancyName);
      // Rate value from html (condition check span is exits or not)
      const rateVal = ($(event.target).closest('td').find('#' + id + '_' + runner_type).length > 0) ? $(event.target).closest('td').find('#' + id + '_' + runner_type).html() : $(event.target).closest('td').find('#' + id + '_' + runner_type).html() ;
      $('#oddsInput').val(parseInt(rateVal));
      // Rate value from html (condition check span is exits or not)
      const rateVol = ($(event.target).closest('td').find('#' + id + '_' + runner_type + '_vol').length > 0) ? $(event.target).closest('td').find('#' + id + '_' + runner_type + '_vol').html() : $(event.target).closest('td').find('#' + id + '_' + runner_type + '_vol').html();
      $('#placebet-fm').data('data-volume', parseInt(rateVol));
      $('#placebet-fm').data('data-game-id', marketId);
      this.placeOdds = odds;
      this.placeMarketType = type;
      this.placeRunnerType = runner_type;
      this.placeGameId = marketId;
      this.placeVol = vol;
      this.placeRow = row;
    }

    $('#placebet-fm').data('data-runner-type', runner_type);
    $('#placebet-fm').data('data-row', row);
    $('#placebet-fm').data('data-market-type', type);
    $('#placebet-fm').data('marketDetails', fancyName);
  }


  /*
    Developer: Ravi
    Date: 05-mar-2020
    title: Place rate function for fancy
    Use: This function is use append rate into place bet window
  */

  placeRateOddEven(type, runner_type, row, id, event, fancyName, marketId = '' , inx) {

    // scrollSmoothToBottom(id);
    $('.price-btn-padding a').removeClass(runner_type == 'lay' ? 'back' : 'lay');
    $('.activeColor').removeClass('activeColor');
    $('.price-btn-padding a').addClass(runner_type);
    $('.bet-runs-main').removeClass('open-bet-run');
    id = id.toString().replace('.', '');
    id = id.toString().replace('-', '');
    $('#'+ id + '_open').addClass('open');
    $('#'+ id).addClass('open-bet-run');
    //
    $('#'+row+ '_class' + id).addClass('activeColor');

    const odds = $('#'+row+ '_' + id).html();
    const vol = $('#'+row+ 'Vol_' + id).html();
    const oddsVal = odds + '/' + vol;
    $('#'+ id + inx+ '_oddsInput').val(oddsVal);
    $('#placebet').data('data-odds', parseFloat(odds));
    this.currentTranPro = 0;
    if (runner_type === 'lay') {
      $('.ite-types').html('No');
    } else {
      $('.ite-types').html('Yes');
    }
    $('#'+id+  inx+'_stackInput').val('');
    if (runner_type === 'back') {
      $('.betting-slip-div-class').removeClass('betting-slip-lay').addClass('betting-slip-back');
    } else {
      $('.betting-slip-div-class').removeClass('betting-slip-back').addClass('betting-slip-lay');
    }

    $('#placebet').data('team', '');
    if (type == 'oddEven') {
      // Rate value from html (condition check span is exits or not)
      const rateVal = ($(event.target).closest('td').find( '#' + row + '_' + id ).children().length > 0) ? $(event.target).closest('td').find( '#' + row + '_' + id ).children().html() : $(event.target).closest('td').find( '#' + row + '_' + id ).html() ;
      $('#oddsInput').val(parseInt(rateVal));
      // Rate value from html (condition check span is exits or not)
      const rateVol = ($(event.target).closest('td').find( '#' + row + 'Vol_' + id ).children().length > 0) ? $(event.target).closest('td').find( '#' + row + 'Vol_' + id ).children().html() : $(event.target).closest('td').find( '#' + row + 'Vol_' + id ).html();
      // $('.ite-volume').html('/' + rateVol);
      $('.bet_for_name').html(fancyName + '/' + rateVol);
      $('#placebet').data('data-volume', vol);
      $('#placebet').data('data-game-id', id);
      this.placeOdds = odds;
      this.placeMarketType = type;
      this.placeRunnerType = runner_type;
      this.placeGameId = id;
      this.placeVol = vol;
      this.placeRow = row;
    } else {
      $('.bet_for_name').html(fancyName);
      // Rate value from html (condition check span is exits or not)
      const rateVal = ($(event.target).closest('td').find('#' + id + '_' + runner_type).length > 0) ? $(event.target).closest('td').find('#' + id + '_' + runner_type).html() : $(event.target).closest('td').find('#' + id + '_' + runner_type).html() ;
      $('#oddsInput').val(parseInt(rateVal));
      // Rate value from html (condition check span is exits or not)
      const rateVol = ($(event.target).closest('td').find('#' + id + '_' + runner_type + '_vol').length > 0) ? $(event.target).closest('td').find('#' + id + '_' + runner_type + '_vol').html() : $(event.target).closest('td').find('#' + id + '_' + runner_type + '_vol').html();
      $('#placebet').data('data-volume', parseInt(rateVol));
      $('#placebet').data('data-game-id', marketId);
      this.placeOdds = odds;
      this.placeMarketType = type;
      this.placeRunnerType = runner_type;
      this.placeGameId = marketId;
      this.placeVol = vol;
      this.placeRow = row;
    }

    $('#placebet').data('data-runner-type', runner_type);
    $('#placebet').data('data-row', row);
    $('#placebet').data('data-market-type', type);
  }


  /*
    Developer: Ravi
    Date: 05-mar-2020
    title: Rate append in place bet
    Use: This function is use rate calculation
  */

  placeRateValue(event , id , inx, type) {

    $('button').removeClass('btn-danger5');
    $('#'+ event.target.id).addClass('btn-danger5');
    let rateStack = event.target.getAttribute('data-val');
    this.amountSelection = Number(rateStack);
    const marketType = type;
    const stackInput = $('#'+ id + inx +'_stackInput').val();

    if (stackInput != '') {
      rateStack = Number(rateStack) + Number(stackInput);
    }
    $('#'+id+ inx +'_stackInput').val(Number(rateStack));

    if (marketType == 'market') {
      $('#placebet').attr('data-amount', Number(rateStack));
      this.getLatestExposer(id , inx);
    }
    if (marketType == 'fancy' ||  marketType == 'line') {
      $('#placebet-fm').attr('data-amount', Number(rateStack));
      this.getLatestExposerFancy('', marketType);
    }
    let oneCl = this.oneClick;
    if(oneCl == true) {
      // this.placeBet(event , 'web',  id , inx)
    }

  }

  /*
     Developer: Ravi
     Date: 05-mar-2020
     title: Rate append in place bet
     Use: This function is use rate calculation
   */

  placeRateValueBm(event , id) {
    $('button').removeClass('btn-danger5');
    $('#'+ event.target.id).addClass('btn-danger5');
    let rateStack = event.target.getAttribute('data-val');
    const marketType = $('#placebet').data('data-market-type');
    const stackInput = $('#'+ id +'_bm_stackInput').val();
    this.amountSelection = Number(rateStack);
    if (stackInput != '') {
      rateStack = Number(rateStack);
    }
    $('#'+id+'_bm_stackInput').val(Number(rateStack));
    $('#placebet').attr('data-amount', Number(rateStack));
    if (marketType == 'bookmaker') {
      this.getLatestExposerBm(id);
    }

    let oneCl = this.oneClick;
    if(oneCl == true) {
      this.placeBetBm(event, 'web', id)
    }

  }


  /*
    Developer: Ravi
    Date: 05-mar-2020
    title: Place bet function
    Use: This function is use place bet
  */

  placeBets(event, device , id , inx, marketId , type) {
    let items;
    $('button').removeClass('btn-danger5');
    if(this.endSubmit) {
      return;
    }
    this.endSubmit = true;
    let postfix = '';
    if (device == 'mobile') {
      postfix = 'Mob';
    }
    let market_type = type;

    this.spinner.show();
    // const odds = $('#'+ id + inx +'_oddsInput').val();
    let game_id
    let marketDetailsData;
    let team = $('#placebet' + postfix).data('team');
    if(market_type == 'market' || market_type == 'bookmaker'){
      game_id = this.selectMarket.marketId;
      marketDetailsData = $('#placebet').data('marketDetails');
    }
    if(market_type == 'fancy'){
      game_id = this.selectMarket;
      marketDetailsData = $('#placebet-fm').data('marketDetails');
      console.log(marketDetailsData)
    }

    id = id.toString().replace('.', '');
    id = id.toString().replace('-', '');
    let rate = $('#'+ id + inx +'_stackInput').val();
    let volume = $('#placebet' + postfix).data('data-volume');
    let odds = $('#placebet' + postfix).data('data-odds');
    let runner_type = $('#placebet' + postfix).data('data-runner-type');
    let row = $('#placebet' + postfix).data('data-row');

    volume = (volume) ? volume : this.placeVol;
    odds = (odds) ? odds : this.placeOdds;
    runner_type = (runner_type) ? runner_type : this.placeRunnerType;
    row = (row) ? row : this.placeRow;
    team = (team) ? team : this.placeTeam;
    market_type = (market_type)?market_type : this.placeMarketType;

    if (game_id == '' || volume == '' || odds == '' || rate == '' || runner_type == '') { // This function check if any parameter is missing
      this.spinner.hide();
      this.endSubmit = false;
      this.showToster('Error', 'error', 'Invalid rate');
    } else if (Number.isInteger(Number(rate)) == false) {
      this.spinner.hide();
      this.endSubmit = false;
      this.showToster('Error', 'error', 'Invalid stake');
    }else {

      const users = this.commonService.getLocalStorage();
      this.usersPartnership = this.commonService.getLocalStorage();
      this.usersPartnership = JSON.parse(this.usersPartnership.userData);
      let sportId = '';
      let matchId = '';
      if (market_type == 'market') {
        sportId = this.selectMarket.sport.id;
        matchId = this.selectMarket.match.id;
      } else {
        matchId = this.selectedTm.match.id;
        sportId = '5ebc1code68br4bik5b1808';
      }

      console.log(matchId,this.selectMarket)

      this.apiObj = {
        odds,
        rate,
        game_id,
        volume,
        runner_type,
        market_type,
        row,
        marketId: matchId,
        team: (typeof team != 'undefined') ? team : '',
        sportId : (marketDetailsData.sport.id) ? Number(marketDetailsData.sport.id) : 0
      };
      console.log('this.apiObj++++++++++',this.apiObj);
      // this.resetTempExp();
      this.placebetService.addPlaceBet(this.apiObj).subscribe(resPlacebet => {

        resPlacebet = this.utilityService.gsk(resPlacebet.auth);
        resPlacebet = JSON.parse(resPlacebet);
        console.log(resPlacebet)
        $('.bet-runs-main').removeClass('open-bet-run');
        this.endSubmit = false;
        this.currentTranPro = 0;
        this.cancelBtn();
        this.closeBetModal();
        if (resPlacebet.status == true) {
          $('#oddsInput').val('');
          $('#stackInput').val('');
          this.spinner.hide();
          this.countTransactions = (this.countTransactions + 1);
          this.upendPlacebetResponse(resPlacebet.placebet , resPlacebet.placebet.user);
          this.showToster('Success', 'success', resPlacebet.message);
          // this.playAudio();
        } else {
          $('#oddsInput').val('');
          $('#stackInput').val('');
          this.spinner.hide();
          this.showToster('Failed', 'error', resPlacebet.message);
        }
      });
    }
  }

  /*
      Developer: Ravi
      Date: 05-mar-2020
      title: Place bet function
      Use: This function is use place bet
    */

  placeBetBm(event, device , id) {
    $('button').removeClass('btn-danger5');
    if(this.endSubmit) {
      return;
    }
    this.endSubmit = true;
    let postfix = '';
    if (device == 'mobile') {
      postfix = 'Mob';
    }
    let items;
    const market_type = $('#placebet' + postfix).data('data-market-type');
    let myid = $('#placebet' + postfix).data('data-game-id');
    if(myid.includes("B") == true ){
      if(market_type == 'bookmaker' ){

        let fancyId = myid.slice(1);
        let last = fancyId.slice(-2);
        fancyId = fancyId.slice(0,-2);
        let first = myid.charAt(0);
        items = first + '.' + fancyId + '-' + last;
      }else{
        items = $('#placebet' + postfix).data('data-game-id');
      }
    }else{
      items = $('#placebet' + postfix).data('data-game-id');
    }
    this.spinner.show();
    const odds = $('#'+ id +'_bm_oddsInput').val();
    const rate = $('#'+ id +'_bm_stackInput').val();
    const team = $('#placebet' + postfix).data('team');
    const game_id = items;
    const volume = $('#placebet' + postfix).data('data-volume');
    const runner_type = $('#placebet' + postfix).data('data-runner-type');
    const row = $('#placebet' + postfix).data('data-row');

    console.log(odds,rate,team,game_id,volume,runner_type,row,market_type)
    if (game_id == '' || volume == '' || odds == '' || rate == '' || runner_type == '') { // This function check if any parameter is missing
      this.spinner.hide();
      this.endSubmit = false;
      this.showToster('Error', 'error', 'Invalid rate');
    } else if (Number.isInteger(Number(rate)) == false) {
      this.spinner.hide();
      this.endSubmit = false;
      this.showToster('Error', 'error', 'Invalid stake');
    } else {

      const users = this.commonService.getLocalStorage();
      this.usersPartnership = this.commonService.getLocalStorage();
      this.usersPartnership = JSON.parse(this.usersPartnership.userData);
      let sportId = '';
      if (market_type == 'market') {
        sportId = this.matchOddsData[0].sport.id;
      } else {
        sportId = '5ebc1code68br4bik5b1808';
      }


      this.apiObj = {
        odds,
        rate,
        game_id,
        volume,
        runner_type,
        market_type,
        row,
        marketId: this.marketId,
        team: (typeof team != 'undefined') ? team : '',
        sportId : (this.getMarketById[0].sport.id) ? Number(this.getMarketById[0].sport.id) : 0
      };
      const sharReq = {
        masterIds: this.usersPartnership.masterId,
        sportId : (this.getMarketById[0].sport.id) ? Number(this.getMarketById[0].sport.id) : 0,
        parentId : this.usersPartnership.parentId
      };
      this.resetTempExp();
      this.placebetService.addPlaceBet(this.apiObj).subscribe(resPlacebet => {
        resPlacebet = this.utilityService.gsk(resPlacebet.auth);
        resPlacebet = JSON.parse(resPlacebet);
        $('.bet-runs-main').removeClass('open-bet-run');
        this.endSubmit = false;
        this.currentTranPro = 0
        this.cancelBtn();
        this.closeBetModal();
        if (resPlacebet.status == true) {
          $('#oddsInput').val('');
          $('#stackInput').val('');

          this.spinner.hide();
          // console.log("resPlacebet.placebet.user++++",resPlacebet.placebet.user);
          this.countTransactions = (this.countTransactions + 1);
          this.upendPlacebetResponse(resPlacebet.placebet , resPlacebet.placebet.user);
          this.showToster('Success', 'success', resPlacebet.message);
          // this.playAudio();
        } else {
          $('#oddsInput').val('');
          $('#stackInput').val('');
          this.spinner.hide();
          this.showToster('Failed', 'error', resPlacebet.message);
        }
      });
    }
  }


  upendPlacebetResponse(res , response){
    if(response){
      if (response.gameType == 'market' || response.gameType == "Winner") {
        let gameIdDec = response.gameId.toString().replace('.', '');
        if (response.runnerExp) {
          let newExp = {
            amount : response.game_exposer,
            runners : response.runnerExp
          }
          this.matchOddsData.map(function(detail) {
            if(detail.marketId == response.gameId){
              detail.exposer = newExp;
            }
          });
          response.runnerExp.map(res => {
            const amountstr = res.amount;
            const className = (res.amount < 0) ? 'text-danger' : 'text-success';
            if (res.amount < 0) {
              $('#' + res.runners.selectionId + "_" + gameIdDec +  '_exposer').removeClass('text-success').addClass('text-danger');
              $('#' + res.runners.selectionId + "_" + gameIdDec +  '_exposer').html(amountstr);
              $('#' + res.runners.selectionId + "_" + gameIdDec +  '_exposer_mob' ).removeClass('text-success').addClass('text-danger');
              $('#' + res.runners.selectionId + "_" + gameIdDec +  '_exposer_mob').html(amountstr);
            } else {
              $('#' + res.runners.selectionId + "_" + gameIdDec +  '_exposer' ).removeClass('text-danger').addClass('text-success');
              $('#' + res.runners.selectionId + "_" + gameIdDec +  '_exposer').html(amountstr);
              $('#' + res.runners.selectionId + "_" + gameIdDec +  '_exposer_mob' ).removeClass('text-danger').addClass('text-success');
              $('#' + res.runners.selectionId + "_" + gameIdDec +  '_exposer_mob').html(amountstr);
            }
          });
        }
      }else if(response.gameType == 'bookmaker'){
        if (response.runnerExp) {
          let newExp = {
            amount : response.game_exposer,
            runners : response.runnerExp
          }
          this.bookmakerData.map(function(detail) {
            if(detail.marketId == response.gameId){
              detail.exposer = newExp;
            }
          });

          response.runnerExp.map(res => {
            const amountstr = res.amount;
            const className = (res.amount < 0) ? 'text-danger' : 'text-success';
            if (res.amount < 0) {
              $('#' + res.runners.selectionId + '_bm_exposer').removeClass('text-success').addClass('text-danger');
              $('#' + res.runners.selectionId + '_bm_exposer').html(amountstr);
              $('#' + res.runners.selectionId + '_bm_exposer_mob' ).removeClass('text-success').addClass('text-danger');
              $('#' + res.runners.selectionId + '_bm_exposer_mob').html(amountstr);
            } else {
              $('#' + res.runners.selectionId + '_bm_exposer' ).removeClass('text-danger').addClass('text-success');
              $('#' + res.runners.selectionId + '_bm_exposer').html(amountstr);
              $('#' + res.runners.selectionId + '_bm_exposer_mob' ).removeClass('text-danger').addClass('text-success');
              $('#' + res.runners.selectionId + '_bm_exposer_mob').html(amountstr);
            }
          });
        }
      } else if (response.gameType == 'line') {
        const lineDec = response.gameId.toString().replace('.', '');
        $('#' + lineDec + '_exposer').html(String(-1 * Math.abs(response.game_exposer)));
        $('#' + lineDec + '_exposer_mob').html(String(-1 * Math.abs(response.game_exposer)));
      } else {
        if(response.gameId.toString().replace('.', '')){
          response.gameId = response.gameId.toString().replace('.', '');
          response.gameId = response.gameId.toString().replace('-', '');
        }
        $('#' + response.gameId + '_exposer').html(String(-1 * Math.abs(response.game_exposer)));
        $('#' + response.gameId + '_exposer_mob').html(String(-1 * Math.abs(response.game_exposer)));
      }
      response['_id'] = res.lastInsId;
      if(response.gameType == 'fancy'){
        const transactionObj = {
          marketId: this.marketId,
          userId: this.userId,
          gameId: this.marketId
        };

        this.getAllTransaction(transactionObj);
      }else{
        if(this.allTransactions.length > 0){
          this.allTransactions.unshift(response); // AVAILABLE_BALANCE
        }else {
          this.allTransactions.push(response); // AVAILABLE_BALANCE
        }
      }
    }
  }

  cancelBtn() {
    $('.activeColor').removeClass('activeColor');
    $('.bet-runs-main').removeClass('open-bet-run');
    this.resetTempExp();
    this.placeBet.hide();
    this.placeBetFm.hide();
    this.placeBetBms.hide();
  }

  closeBetModal() {
    this.cancelBtn();
  }

  resetTempExp() {
    if (this.matchOddsData[0]) {
      let gameId = $('#placebet').data('data-game-id');
      gameId = (gameId)?gameId:$('#placebetMob').data('data-game-id')
      let marketDetail = this.matchOddsData.find(o => o.marketId == gameId);
      if(marketDetail){
        const oldExpo = marketDetail.runners;

        oldExpo.forEach( (obj, index) => {
          $('#' + obj.selectionId +  "_" + marketDetail.marketIdDec).html('');
          $('#' + obj.selectionId + '_Exp').html('');
        });
      }
    }
    if(this.bookmakerData){
      this.bookmakerData.forEach( (obj, index) => {
        const runner = obj.runners;
        runner.forEach( (run, index) => {
          $('#' + run.selectionId + '_bm').html('');
          $('#' + run.selectionId + '_bm_mob').html('');
        });
      });
    }
  }

  /*
    Developer: Ravi
    Date: 05-mar-2020
    title: Toster message
    Use: This function is use to throgh toster message
  */

  showToster(title, type, message) {
    this.Toast.title = title;
    this.Toast.type = type;
    this.Toast.body = message;
    this.commonService.popToast(type, title, 1500, message);
  }

  getFancyChart(fancyId, marketId, fancyName) {
    this.chartFancyName = fancyName;
    const users = this.commonService.getLocalStorage();
    const fancyObj = {
      gameId : fancyId,
      marketId,
      userId : users.userId
    };
    this.placebetService.getFancyChart(fancyObj).subscribe(resPlacebet => {
      this.chart.show();
      if (resPlacebet.status == true) {
        if (resPlacebet.data.length > 0) {
          const dataAry = resPlacebet.data;
          let firstIndex = 0;
          let lastIndex = 0;
          let minOdds = _.minBy(dataAry, 'odds');
          let maxOdds = _.maxBy(dataAry, 'odds');

          firstIndex =  minOdds.odds - 10;
          lastIndex =  maxOdds.odds + 10;

          firstIndex = (firstIndex<0)?0:firstIndex
          this.chartAry = [];
          for (let i = firstIndex; i <= lastIndex; i++) {
            let plusAmt = 0;
            let minusAmt = 0;
            let finalAmt = 0;
            dataAry.forEach( (obj, index) => {
              if (obj.runner_type == 'lay') { // client says no
                if (i < obj.odds) {
                  plusAmt = plusAmt + obj.lay;
                } else {
                  minusAmt = minusAmt + obj.back;
                }
              } else {
                if (i >= obj.odds) {
                  plusAmt = plusAmt + obj.back;
                } else {
                  minusAmt = minusAmt + obj.lay;
                }
              }
            });
            finalAmt = plusAmt + minusAmt;
            const finalChart = {
              number : i,
              amount : finalAmt.toFixed(2)
            };
            this.chartAry.push(finalChart);
          }
          $('.fancy-main-chart-div').toggleClass('active');
          this.spinner.hide();
        } else {
          this.chartAry = [];

          $('.fancy-main-chart-div').toggleClass('active');
          this.spinner.hide();
        }
      } else {
        this.chartAry = [];
        $('.fancy-main-chart-div').toggleClass('active');
        this.spinner.hide();
      }
    });
  }


  getLineChart(lineId, marketId, lineName) {
    this.chartFancyName = lineName;
    const users = this.commonService.getLocalStorage();
    const fancyObj = {
      gameId : lineId,
      marketId,
      userId : users.userId
    };
    this.placebetService.getFancyChart(fancyObj).subscribe(resPlacebet => {
      this.chart.show();
      if (resPlacebet.status == true) {
        if (resPlacebet.data.length > 0) {
          const dataAry = resPlacebet.data;
          let firstIndex = 0;
          let lastIndex = 0;

          dataAry.forEach( (obj, index) => {
            if (index == 0) {
              firstIndex  = obj.odds - 5;
              lastIndex = obj.odds + 5;
            } else {
              if (firstIndex > obj.odds) {
                firstIndex = obj.odds - 5;
              }
              if (lastIndex < obj.odds) {
                lastIndex = obj.odds + 5;
              }
            }
          });
          this.chartAry = [];
          for (let i = firstIndex; i <= lastIndex; i++) {
            let plusAmt = 0;
            let minusAmt = 0;
            let finalAmt = 0;
            dataAry.forEach( (obj, index) => {
              if (obj.runner_type == 'lay') { // client says no
                if (i < obj.odds) {
                  plusAmt = plusAmt + obj.lay;
                } else {
                  minusAmt = minusAmt + obj.back;
                }
              } else {
                if (i >= obj.odds) {
                  plusAmt = plusAmt + obj.back;
                } else {
                  minusAmt = minusAmt + obj.lay;
                }
              }
            });
            finalAmt = plusAmt + minusAmt;
            const finalChart = {
              number : i,
              amount : finalAmt.toFixed(2)
            };
            this.chartAry.push(finalChart);
          }
          $('.fancy-main-chart-div').toggleClass('active');
          this.spinner.hide();
        } else {
          this.chartAry = [];
          $('.fancy-main-chart-div').toggleClass('active');
          this.spinner.hide();
        }
      } else {
        this.chartAry = [];
        $('.fancy-main-chart-div').toggleClass('active');
        this.spinner.hide();
      }
    });
  }

  /*
    Developer: RK
    Date: 26-04-2020
    title: get latest exposure
    Use: This function is use to get latest exposure
  */
  getLatestExposer(id , inx) {
    let device = '';
    let odds = $('#'+id+ inx +'_oddsInput').val();
    let rate = $('#'+id+ inx +'_stackInput').val();
    let team = $('#placebet').data('team');
    let runner_type = $('#placebet').data('data-runner-type');
    let gameId = $('#placebet').data('data-game-id');
    let marketDetail = $('#placebet').data('marketDetails');


    if (device) {
      odds = $('#oddsInputMob').val();
      rate = $('#stackInputMob').val();
      team = $('#placebetMob').data('team');
      runner_type = $('#placebetMob').data('data-runner-type');
      gameId = $('#placebetMob').data('data-game-id');
    }

    // let  marketDetail = this.matchOddsData1.find(o => o.marketId == gameId);
    // const oldExpo = marketDetail.exposer;
    let oldExpo = marketDetail.runners;
    let layVal = 0;
    let backVal = 0;
    let calExp = 0;
    if (runner_type == 'lay') {
      calExp =  (Number(rate) * (Number(odds) - 1));
      layVal =  Number(rate) * 1;
      backVal = calExp * -1;
    } else {
      calExp =  (Number(rate) * (Number(odds) - 1));
      layVal = Number(rate) * -1;
      backVal = calExp;
    }
    const  minProfit = (runner_type == 'lay') ? Math.round(backVal) : Math.round(layVal);
    const  maxProfit = (runner_type == 'lay') ? Math.round(layVal) : Math.round(backVal);
    this.currentTranPro = maxProfit;
    if (oldExpo) {
      if (oldExpo.length > 0) {
        oldExpo.forEach( (obj, index) => {
          let amount = Number($('#'+obj.selectionId + '_' + marketDetail.marketIdDec + '_exposer').text());
          if (obj.selectionId == team.selectionId) {
            if (runner_type == 'lay') { // client loss
              amount = amount + minProfit;
            } else { // client win
              amount = amount + maxProfit;
            }
          } else {
            if (runner_type == 'lay') { // client win
              amount = amount + maxProfit;
            } else { // client loss
              amount = amount + minProfit;
            }
          }
          if (amount < 0) {
            $( '#' + obj.selectionId + "_" + marketDetail.marketIdDec).removeClass(' text-success').addClass( ' text-danger' );
            $( '#' + obj.selectionId + "_Exp").removeClass(' text-success').addClass( ' text-danger' );
            $('#' + obj.selectionId + "_Exp").html(String(amount.toFixed(2)));
            $('#' + obj.selectionId + "_" + marketDetail.marketIdDec).html(String(amount.toFixed(0)));
          } else {
            $( '#' + obj.selectionId + "_Exp").removeClass(' text-danger').addClass( ' text-success' );
            $( '#' + obj.selectionId + "_" + marketDetail.marketIdDec).removeClass(' text-danger').addClass( ' text-success' );
            $('#' + obj.selectionId  + "_" + marketDetail.marketIdDec).html(String(amount.toFixed(0)));
            $('#' + obj.selectionId  + "_Exp").html(String(amount.toFixed(0)));
          }
        });
      } else {
        const gameRunner = marketDetail.runners;
        gameRunner.forEach( (obj, index) => {
          let amount = 0;
          const prevClassName = $('#' + obj.selectionId  + "_" + marketDetail.marketIdDec).attr('class').split(' ')[1];
          if (prevClassName) {
            $('#' + obj.selectionId + "_" + marketDetail.marketIdDec).removeClass('float-end ' + prevClassName);
          }
          if (obj.selectionId == team.selectionId) {
            if (runner_type == 'lay') { // client loss
              amount = minProfit;
            } else { // client win
              amount = maxProfit;
            }
          } else {
            if (runner_type == 'lay') { // client win
              amount =  maxProfit;
            } else { // client loss
              amount = minProfit;
            }
          }

          if (amount < 0) {
            $( '#' + obj.selectionId +  "_" + marketDetail.marketIdDec  ).removeClass(' text-success').addClass( ' text-danger' );
            $('#' + obj.selectionId  +  "_" + marketDetail.marketIdDec ).html(String(amount));
            $('#' + obj.selectionId  +  "_Exp").html(String(amount));
          } else {
            $( '#' + obj.selectionId +  "_" + marketDetail.marketIdDec ).removeClass(' text-danger').addClass( ' text-success' );
            $('#' + obj.selectionId +  "_Exp").html(String(amount));
          }

        });
      }
    }
  }

  /*
  Developer: RK
  Date: 26-04-2020
  title: get latest exposure fancy
  Use: This function is use to get latest exposure fancy
 */

  getLatestExposerFancy(device= '', marketType) {
    let odds = $('#oddsInput').val();
    let rate = Number($('#stackInput').val());
    let runner_type = $('#placebet').data('data-runner-type');
    let volume = Number($('#placebet').data('data-volume'));
    if (device) {
      odds = $('#oddsInputMob').val();
      rate = Number($('#stackInputMob').val());
      runner_type = $('#placebetMob').data('data-runner-type');
      volume = Number($('#placebetMob').data('data-volume'));
    }
    let backVal = 0;
    let layVal = 0;
    let calExp = 0;
    if (marketType == 'line') {
      volume = 100;
    }

    if (runner_type == 'lay') {
      calExp =  (rate * volume) / 100;
      backVal = calExp * -1;
      layVal = rate;
    } else {
      const calExp =  (rate * volume) / 100;
      layVal = rate * -1;
      backVal = calExp;
    }
    const tranMin = (runner_type == 'lay') ? backVal : layVal;
    const tranMax = (runner_type == 'lay') ? layVal : backVal;
    this.currentTranPro = tranMax;
  }

  /*
    Developer: RK
    Date: 26-04-2020
    title: get latest exposure
    Use: This function is use to get latest exposure
  */
  getLatestExposerBm(id) {
    let device = '';
    let odds = $('#'+id+'_bm_oddsInput').val();
    let rate = $('#'+id+'_bm_stackInput').val();
    let team = $('#placebet').data('team');
    let gameId = $('#placebet').data("data-game-id")
    let runner_type = $('#placebet').data('data-runner-type');

    if (device) {
      odds = $('#oddsInputMob').val();
      rate = $('#stackInputMob').val();
      team = $('#placebetMob').data('team');
      runner_type = $('#placebetMob').data('data-runner-type');
      gameId = $('#placebetMob').data("data-game-id")
    }

    let bookMakerDetail = this.bookmakerData.find(o => o.marketId == gameId);
    // const oldExpo = bookMakerDetail.exposer;
    const oldExpo = bookMakerDetail.runners;

    let layVal = 0;
    let backVal = 0;
    let calExp = 0;
    if (runner_type == 'lay') {
      calExp =  (Number(rate) * (Number(odds)/100));
      layVal =  Number(rate) * 1;
      backVal = calExp * -1;
    } else {
      calExp =  (Number(rate) * (Number(odds)/100));
      layVal = Number(rate) * -1;
      backVal = calExp;
    }

    const  minProfit = (runner_type == 'lay') ? Math.round(backVal) : Math.round(layVal);
    const  maxProfit = (runner_type == 'lay') ? Math.round(layVal) : Math.round(backVal);
    this.currentTranPro = maxProfit;
    if (oldExpo) {
      if (oldExpo) {
        oldExpo.forEach( (obj, index) => {
          let amount = Number($('#'+obj.selectionId + '_bm_exposer').text());
          if (obj.selectionId == team.selectionId) {
            if (runner_type == 'lay') { // client loss
              amount = amount + minProfit;
            } else { // client win
              amount = amount + maxProfit;
            }
          } else {
            if (runner_type == 'lay') { // client win
              amount = amount + maxProfit;
            } else { // client loss
              amount = amount + minProfit;
            }
          }
          if (amount < 0) {
            $( '#' + obj.selectionId + "_bm" ).removeClass(' text-success float-end').addClass( ' text-danger float-end' );
            $('#' + obj.selectionId + "_bm").html(String(amount.toFixed(2)));

          } else {
            $( '#' + obj.selectionId + "_bm"  ).removeClass(' text-danger float-end').addClass( ' text-success float-end' );
            $('#' + obj.selectionId  + "_bm").html(String(amount.toFixed(2)));
          }
        });
      } else {
        const gameRunner = bookMakerDetail.runners;
        gameRunner.forEach( (obj, index) => {
          let amount = 0;
          // const prevClassName = $('#' + obj.selectionId).attr('class').split(' ')[1];
          // if (prevClassName) {
          //   $('#' + obj.selectionId).removeClass('float-end ' + prevClassName);
          // }
          if (obj.selectionId == team.selectionId) {
            if (runner_type == 'lay') { // client loss
              amount = minProfit;
            } else { // client win
              amount = maxProfit;
            }
          } else {
            if (runner_type == 'lay') { // client win
              amount =  maxProfit;
            } else { // client loss
              amount = minProfit;
            }
          }

          if (amount < 0) {
            $( '#' + obj.selectionId + "_bm" ).removeClass(' text-success  float-end').addClass( ' text-danger  float-end' );
            $('#' + obj.selectionId + "_bm").html(String(amount));
          } else {
            $( '#' + obj.selectionId + "_bm" ).removeClass(' text-danger  float-end').addClass( ' text-success  float-end' );
            $('#' + obj.selectionId + "_bm").html(String(amount));
          }

        });
      }
    }
  }

  /*
 Developer: RK
 Date: 26-04-2020
 title: get latest exposure on key
 Use: This function is use to get latest exposure on key up
*/

  calculateExposerOnkey(value , id , inx) {

    let device = '';
    let marketType = $('#placebet').data('data-market-type');
    if(device == "mobile"){
      marketType = $('#placebetMob').data('data-market-type');
    }
    if (marketType == 'market') {
      this.getLatestExposer(id , inx);
    }
    if (marketType == 'fancy' ||  marketType == 'line') {
      this.getLatestExposerFancy(id, marketType);
    }
    if (marketType == 'bookmaker') {
      this.getLatestExposerBm(id);
    }
  }



  /*
    Developer: RT
    Date: 02-04-2020
    title: Lock Game Status
    Use: This function is use to Disable lock game
  */

  // checkLockGame(data) {
  //   this.lockGameService.findRecordExist(data)
  //     .subscribe(response => {
  //       response = this.utilityService.gsk(response.auth);
  //       response = JSON.parse(response);
  //       if (response && (response.data)) {
  //         this.lockGame = true;
  //       } else {
  //         this.lockGame = false;
  //       }
  //     }, error => {
  //       console.error('error in check lock game record');
  //     });
  // }

  /**
   * @author TR
   * @date : 04-06-2020
   * get Currency
   */
  getUserCurrency() {
    const data = {
      userId : this.utilityService.returnLocalStorageData('userId')
    };
    this.currencyService.getUserCurrency(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if(response.status == true) {
        this.currencyAll = response.data[0];
      }else{
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }

  minus() {
    this.stack--;
  }
  plus() {
    this.stack++;
  }

  openViewbestFancy(fancyId, name) {
    this.fancyName = name;
    this.betview.show();
    const userId = this.utilityService.returnLocalStorageData('userId');

    const Obj = {
      marketId: this.marketId,
      userId,
      fancyId,
      gameType: 'fancy'
    };
    this.getAllTransactionAsMactch(Obj);
  }

  openViewbestLine(lineId, name) {
    this.lineName = name;
    this.betviewLine.show();
    const userId = this.utilityService.returnLocalStorageData('userId');

    const Obj = {
      marketId: this.marketId,
      userId,
      lineId,
      gameType: 'Line'
    };
    this.getAllTransactionAsLine(Obj);
  }


  closeModal() {
    this.chart.hide();
  }
  playAudio() {
    const audio = new Audio();
    audio.src = '../../../assets/audio/notification-1.ogg';
    audio.load();
    audio.play();
  }

  allBet() {
    this.betviewall.show();
    const transactionObjData = {
      marketId: this.marketId,
      userId: this.userId,
      gameId: this.marketId,
      dataLimit: 'Allbet',
    };

    this.marketService.getAllTransactions(transactionObjData).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if (response.status == true) {
        this.allTransactions = response.data;
      }
    });
  }

  allMatchOdds() {
    this.betviewMatchOdds.show();

    const Obj = {
      marketId: this.marketId,
      userId: this.userId,
      gameType: 'market'
    };
    this.marketService.getAllTransactionsAsMatchOdds(Obj).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if (response.status) {
        this.allTransactionsAsMatchOdds = response.data;
      }
    });
  }

  appendFancyRateAuto(response) {
    response.srno =  response.srno.toString().replace('.', '');
    response.srno =  response.srno.toString().replace('-', '');
    const fancyId = response.srno;
    if (response.status == '1') {
      appendStatus(response.srno, 'ballstart');
    } else if (response.status == '2') {
      appendStatus(response.srno, 'suspend');
    } else {
      appendStatus(response.srno, 'active');
      if (response.rates) {
        $('#' + fancyId + '_third').hide(), $('#mob_' + fancyId + '_third').hide();
        $('#' + fancyId + '_first').hide(), $('#mob_' + fancyId + '_first').hide();
        $('#' + fancyId + '_forth').hide(), $('#mob_' + fancyId + '_forth').hide();

        for (const [key, value] of Object.entries(response.rates)) {
          if (key == '0') {
            $('#no2_42104465907F2').html("100")
            $('#no2_' + fancyId).html(value['rate_1']), $('#mob_no2_' + fancyId).html(value['rate_1']);
            $('#no2Vol_' + fancyId).html(value['value_1']), $('#mob_no2Vol_' + fancyId).html(value['value_1']);
            $('#yes2_' + fancyId).html(value['rate_2']), $('#mob_yes2_' + fancyId).html(value['rate_2']);
            $('#yes2Vol_' + fancyId).html(value['value_2']), $('#mob_yes2Vol_' + fancyId).html(value['value_2']);
            $('#' + fancyId + '_second').show(), $('#mob_' + fancyId + '_second').show();
          }
          if (key == '1') {
            $('#no1_' + fancyId).html(value['rate_1']), $('#mob_no1_' + fancyId).html(value['rate_1']);
            $('#no1Vol_' + fancyId).html(value['value_1']), $('#mob_no1Vol_' + fancyId).html(value['value_1']);
            $('#yes1_' + fancyId).html(value['rate_2']), $('#mob_yes1_' + fancyId).html(value['rate_2']);
            $('#yes1Vol_' + fancyId).html(value['value_2']), $('#mob_yes1Vol_' + fancyId).html(value['value_2']);
            $('#' + fancyId + '_first').css("display", "flex"), $('#mob_' + fancyId + '_first').css("display", "flex");
          }
          if (key == '2') {
            $('#no3_' + fancyId).html(value['rate_1']), $('#mob_no3_' + fancyId).html(value['rate_1']);
            $('#no3Vol_' + fancyId).html(value['value_1']), $('#mob_no3Vol_' + fancyId).html(value['value_1']);
            $('#yes3_' + fancyId).html(value['rate_2']), $('#mob_yes3_' + fancyId).html(value['rate_2']);
            $('#yes3Vol_' + fancyId).html(value['value_2']), $('#mob_yes3Vol_' + fancyId).html(value['value_2']);
            $('#' + fancyId + '_third').css("display", "flex"), $('#mob_' + fancyId + '_third').css("display", "flex");
          }
          if (key == '3') {
            $('#no4_' + fancyId).html(value['rate_1']), $('#mob_no4_' + fancyId).html(value['rate_1']);
            $('#no4Vol_' + fancyId).html(value['value_1']), $('#mob_no4Vol_' + fancyId).html(value['value_1']);
            $('#yes4_' + fancyId).html(value['rate_2']), $('#mob_yes4_' + fancyId).html(value['rate_2']);
            $('#yes4Vol_' + fancyId).html(value['value_2']), $('#mob_yes4Vol_' + fancyId).html(value['value_2']);
            $('#' + fancyId + '_forth').css("display", "flex"), $('#mob_' + fancyId + '_forth').css("display", "flex");
          }
        }
      } else {
        appendStatus(response.srno, 'suspend');
      }
    }
  }


  appendBMRateAuto(bookmakerObj,response) {

    const bookmakerId = response.srno;
    bookmakerObj.runners.map(res => {

      $("#" + res.selectionId + "_back_odds").html('0'),$("#" + res.selectionId + "_back_odds_mob").html('0');
      $("#" + res.selectionId + "_lay_odds").html('0'),$("#" +  res.selectionId + "_lay_odds_mob").html('0');
      $("#" +  res.selectionId + "_back_vol").html('0'),$("#" +  res.selectionId + "_back_vol_mob").html('0');
      $("#" +  res.selectionId + "_lay_vol").html('0'),$("#" +   res.selectionId + "_lay_vol_mob").html('0');

      if (response.status == '1') {
        if(this.result != 'ballstart' ) {
          this.result = 'ballstart';

        }
        appendStatusBM(res.selectionId, 'ballstart');
      } else if (response.status == '2') {
        if(response.selectid === res.selectionId) {
          if (this.result != res.runnerName + '  ' +  'suspended') {
            this.result = res.runnerName + '  ' +  'suspended';

          }
        }
        appendStatusBM(res.selectionId, 'suspend');
        $("#" + res.selectionId + "_back_odds").html(''),$("#" + res.selectionId + "_back_odds_mob").html('');              ;
        $("#" + res.selectionId + "_lay_odds").html(''),$("#" + res.selectionId + "_lay_odds_mob").html('');
        $("#" + res.selectionId + "_back_vol").html(''),$("#" + res.selectionId + "_back_vol_mob").html('');
        $("#" + res.selectionId + "_lay_vol").html(''),$("#" + res.selectionId + "_lay_vol_mob").html('');
      } else {

        appendStatusBM(res.selectionId, 'active');
        if(response['team'] == "D"){
          appendStatusBM(res.selectionId, 'suspend');
          let splitAry = response['selectid'].split(',');
          splitAry.map(selId => {
            appendStatusBM(selId, 'active');
            //this code for parker type BM
            //this code for samudra type BM
            if(response.selectid === res.selectionId) {
              if (this.result != res.runnerName + '  ' +   response['runnerRateAry'][selId]['rate_1'].toString() + '   ' + response['runnerRateAry'][selId]['rate_2'].toString()) {
                this.result = res.runnerName + '  ' +   response['runnerRateAry'][selId]['rate_1'].toString() + '   ' + response['runnerRateAry'][selId]['rate_2'].toString();

              }
            }
            if(response['rates'] && response['runnerRateAry'][selId]){
              $("#" + selId + "_back_odds").html(response['runnerRateAry'][selId]['rate_1']),$("#" + selId + "_back_odds_mob").html(response['runnerRateAry'][selId]['rate_1']);
              $("#" + selId + "_lay_odds").html(response['runnerRateAry'][selId]['rate_2']),$("#" + selId + "_lay_odds_mob").html(response['runnerRateAry'][selId]['rate_2']);
              $("#" + selId + "_back_vol").html(bookmakerObj['bookmakerSetting']['maxStack']),$("#" + selId + "_back_vol_mob").html(bookmakerObj['bookmakerSetting']['maxStack']);
              $("#" + selId + "_lay_vol").html(bookmakerObj['bookmakerSetting']['maxStack']),$("#" + selId + "_lay_vol_mob").html(bookmakerObj['bookmakerSetting']['maxStack']);
            }
          });
        }else{
          if(res.selectionId == response['selectid']){
            if(response.selectid === res.selectionId) {
              if (this.result != res.runnerName + '  ' +   response['rates']['0']['rate_1'].toString() + '   ' + response['rates'][0]['rate_2'].toString()) {
                this.result = res.runnerName + '  ' +   response['rates']['0']['rate_1'].toString() + '   ' + response['rates'][0]['rate_2'].toString();


              }
            }

            if(response['rates']){
              $("#" + res.selectionId + "_back_odds").html(response['rates']['0']['rate_1']),$("#" + res.selectionId + "_back_odds_mob").html(response['rates']['0']['rate_1']);
              $("#" + res.selectionId + "_lay_odds").html(response['rates']['0']['rate_2']),$("#" + res.selectionId + "_lay_odds_mob").html(response['rates']['0']['rate_2']);
              $("#" + res.selectionId + "_back_vol").html(bookmakerObj['bookmakerSetting']['maxStack']),$("#" + res.selectionId + "_back_vol_mob").html(bookmakerObj['bookmakerSetting']['maxStack']);
              $("#" + res.selectionId + "_lay_vol").html(bookmakerObj['bookmakerSetting']['maxStack']),$("#" + res.selectionId + "_lay_vol_mob").html(bookmakerObj['bookmakerSetting']['maxStack']);
            }
          }else{
            $("#" + res.selectionId + "_back_odds").html(''),$("#" + res.selectionId + "_back_odds_mob").html('');
            $("#" + res.selectionId + "_lay_odds").html(''),$("#" + res.selectionId + "_lay_odds_mob").html('');
            $("#" + res.selectionId + "_back_vol").html(''),$("#" + res.selectionId + "_back_vol_mob").html('');
            $("#" + res.selectionId + "_lay_vol").html(''),$("#" + res.selectionId + "_lay_vol_mob").html('');
            // $("#" + res.selectionId + "_bookmaker").addClass('market_suspend');
            appendStatusBM(res.selectionId, 'suspend');
          }
        }
      }
    });
  }

  /**
   * @author TR
   * @date : 19-02-2021
   * getbookMaker Latest Rate
   */

  getbookMakerLatest(id ,data) {
    this.marketService.getBookmakerRateRedis(id).subscribe(response => {
      if (response.status == true) {
        response.data.runners.map(res => {
          if (res && res.selectionId) {
            $('#' + res.selectionId + '_bookmaker').removeClass('market_suspend');
            $('#' + res.selectionId + '_back_odds').html(res.backRate);
            $('#' + res.selectionId + '_lay_odds').html(res.layRate.toFixed(2));
            $('#' + res.selectionId + '_back_vol').html(res.backVol);
            $('#' + res.selectionId + '_lay_vol').html(res.layVol);
          }else {
            // $('#' + res.selectionId + '_bookmaker').addClass('market_suspend');
          }
          if(data[0].marketStatus.name === 'ballstart') {
            appendStatusBM(res.selectionId, 'ballstart');
          }
        });
      }
    });

  }

  /**
   * @author TR
   * @date : 19-02-2021
   * close placebet model
   */

  closePlacebetModel(){
    this.resetTempExp();
    this.placeBetModel.hide();
  }

  /**
   * @author TR
   * @date : 12-03-2021
   * open live tv
   */

  liveTvClick(){
    this.tvFeet = (this.tvFeet == true) ? false : true;
    if(this.tvFeet == true){
      let liveTv_feed = (typeof this.channelUrl === "undefined" || this.channelUrl.substring(0, this.channelUrl.indexOf('//'))) ? this.channelUrl :  _.isNumber(Number(this.channelUrl));
      if(liveTv_feed && !isUndefined(liveTv_feed)){
        this.marketService.liveTvIp(this.channelUrl).subscribe(response => {
          if(response){
            let encryptedIp = aes256.encrypt("codebrik#321", response.ip);
            encryptedIp = encryptedIp.replace(/\//g,'*');
            this.marketService.liveTvFeed(this.channelUrl,encryptedIp).subscribe(response => {
              if(response){
                this.channelFeed = response.data;
                $('.LiveTvFeed').html( this.channelFeed);
              }
            });
          }else{
            let encryptedIp = aes256.encrypt("codebrik#321", '127.0.0.1');
            encryptedIp = encryptedIp.replace(/\//g,'*');

            this.marketService.liveTvFeed(this.channelUrl,encryptedIp).subscribe(response => {
              if(response){
                this.channelFeed = response.data;
                $('.LiveTvFeed').html( this.channelFeed);
              }
            });
          }
        })
      }else {
        if (this.channelUrl !== '' && !isUndefined(this.channelUrl)) {
          this.safeSrcimg = 'not';
          this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.channelUrl);
        } else {
          this.safeSrc = 'not';
          this.safeSrcimg = 'http://img.youtube.com/vi/YOURVIDEOID/maxresdefault.jpg';
        }
      }
    }else{
      let liveTv_feed = 5000000;

      if(liveTv_feed && !isUndefined(liveTv_feed)){
        let encryptedIp = aes256.encrypt("codebrik#321", '127.0.0.1');
        encryptedIp = encryptedIp.replace(/\//g,'*');
        this.marketService.liveTvFeed(liveTv_feed,encryptedIp).subscribe(response => {
          if(response){
            this.channelFeed = response.data;
            $('.LiveTvFeed').html( this.channelFeed);
          }
        });
      }else {
        if (this.channelUrl !== '' && !isUndefined(this.channelUrl)) {
          this.safeSrcimg = 'not';
          this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.channelUrl);
        } else {
          this.safeSrc = 'not';
          this.safeSrcimg = 'http://img.youtube.com/vi/YOURVIDEOID/maxresdefault.jpg';
        }
      }
    }
  }

  openTvCls(){
    $('.mobileTv').toggleClass('show');
    if ( $(".mobileTv").hasClass("show") ) {
      if(this.channelUrl == null){
        $('.mobileTv').html('<img class="img-tv-ic" src="https://res.cloudinary.com/dci7ozxle/image/upload/v1638514546/tv%20no%20signal/no-signal___2x_qh1mos.gif">');
      }else{
        $('.mobileTv').html('<iframe src="' + this.channelUrl + '"></iframe>');
      }
    }else{
      $('.mobileTv').html('');
    }
  }

  showMe(){
    $('#tv-men').toggleClass('show');
    if ( $("#tv-men").hasClass("show") ) {
      if(this.channelUrl == null){
        $('#tv-men').html('<img class="img-tv-ic" src="https://res.cloudinary.com/dci7ozxle/image/upload/v1638514546/tv%20no%20signal/no-signal___2x_qh1mos.gif">');
      }else{
        $('#tv-men').html('<iframe src="' + this.channelUrl + '"></iframe>');
      }
    }else{
      $('#tv-men').html('');
    }
  }
  openClose(){
    $('#scope').toggleClass('active');
  }

  appendBMRateManual(response){
    if(response.marketStatus){
      let dataitems = 'null';
      // this.pause();
      if(response.marketStatus.id == "MS081893"){
        _.map(response.runners, function(runnData) {
          if(response.selectedRunner == 'both'){
            appendStatusBM(runnData.selectionId, 'open');
            $("#" + runnData.selectionId + "_back_odds").html(runnData.backRate),$("#" + runnData.selectionId + "_back_odds_mob").html(runnData.backRate);
            $("#" + runnData.selectionId + "_lay_odds").html(''),$("#" +  runnData.selectionId + "_lay_odds_mob").html('');
            $("#" + runnData.selectionId + "_back_vol").html(runnData.backVol),$("#" +  runnData.selectionId + "_back_vol_mob").html(runnData.backVol);
            $("#" + runnData.selectionId + "_lay_vol").html(''),$("#" +   runnData.selectionId + "_lay_vol_mob").html('');
            dataitems = 'both  ' +  ' ' + runnData.backRate.toString();
          }else{
            if(runnData.status.id == "MS081893"){
              appendStatusBM(runnData.selectionId, 'open');
              $("#" + runnData.selectionId + "_back_odds").html(runnData.backRate),$("#" + runnData.selectionId + "_back_odds_mob").html(runnData.backRate);
              $("#" + runnData.selectionId + "_lay_odds").html(runnData.layRate),$("#" +  runnData.selectionId + "_lay_odds_mob").html(runnData.layRate);
              $("#" + runnData.selectionId + "_back_vol").html(runnData.backVol),$("#" +  runnData.selectionId + "_back_vol_mob").html(runnData.backVol);
              $("#" + runnData.selectionId + "_lay_vol").html(runnData.layVol),$("#" +   runnData.selectionId + "_lay_vol_mob").html(runnData.layVol);

              dataitems = runnData.runnerName + ' ' + runnData.backRate.toString() +' '+ runnData.layRate.toString();

            }else{
              appendStatusBM(runnData.selectionId, 'suspend');
              $("#" + runnData.selectionId + "_back_odds").html(''),$("#" + runnData.selectionId + "_back_odds_mob").html('');
              $("#" + runnData.selectionId + "_lay_odds").html(''),$("#" +  runnData.selectionId + "_lay_odds_mob").html('');
              $("#" + runnData.selectionId + "_back_vol").html(''),$("#" +  runnData.selectionId + "_back_vol_mob").html('');
              $("#" + runnData.selectionId + "_lay_vol").html(''),$("#" +   runnData.selectionId + "_lay_vol_mob").html('');
            }
          }
        });
      }else{
        _.map(response.runners, function(runnData) {
          dataitems = response.marketStatus.name
          appendStatusBM(runnData.selectionId, response.marketStatus.name);
          $("#" + runnData.selectionId + "_back_odds").html(''),$("#" + runnData.selectionId + "_back_odds_mob").html('');              ;
          $("#" + runnData.selectionId + "_lay_odds").html(''),$("#" +  runnData.selectionId + "_lay_odds_mob").html('');
          $("#" + runnData.selectionId + "_back_vol").html(''),$("#" +  runnData.selectionId + "_back_vol_mob").html('');
          $("#" + runnData.selectionId + "_lay_vol").html(''),$("#" +   runnData.selectionId + "_lay_vol_mob").html('');
        });
      }
      if(dataitems != 'null'){
        this.result = dataitems;

      }
    }
  }

  getAverage(exp,selectionId){


  }
  myFunction(id) {
    $('.match-team-price .show-popup').removeClass('show-popup');
    let ids = 'minMax_' +id;
    $('#'+ids).toggleClass('show-popup');
  }
  substract(id , i , nm, marketType){
    let getVal =  $('#'+ id + i + nm).val();
    getVal = Number(getVal) - this.amountSelection;
    if(getVal < 0 ){
      $('#'+ id + i + nm).val(0);
    }else{
      $('#'+ id + i + nm).val(getVal);
    }
    if (marketType == 'market') {
      this.getLatestExposer(id , i);
    }
  }
  addition(id , i , nm, marketType){
    let getVal =  $('#'+ id + i + nm).val();
    getVal = Number(getVal) + this.amountSelection;
    $('#'+ id + i + nm).val(getVal);
    if (marketType == 'market') {
      this.getLatestExposer(id , i);
    }
  }

  appendDummyrate(marketId){
    if(this.dummyRateResponse){
      let itemsData = JSON.parse(this.dummyRateResponse);
      let filtered_array = _.filter(
        itemsData, function(o) {
          let ites = JSON.parse(o);
          return ites.marketId == marketId;
        }
      );
      console.log(filtered_array)


      if(filtered_array.length > 0){
        filtered_array = JSON.parse(filtered_array);
        let obj = {
          "marketId": filtered_array.marketId,
          "status": 'OPEN',
          "numberOfRunners": filtered_array.runners.length,
        }
        // let runnerAry = []
        // _.map(upendData.odds, function (runnerData) {
        //   let runnerObj = {
        //     selectionId : runnerData.selectionId,
        //     status : upendData['status'],
        //     ex : {
        //       availableToBack: [{
        //         price: runnerData.backPrice1,
        //         size: runnerData.backSize1
        //       }, {
        //         price: runnerData.backPrice2,
        //         size: runnerData.backSize2
        //       }, {
        //         price: runnerData.backPrice3,
        //         size: runnerData.backSize3
        //       }],
        //       availableToLay: [{
        //         price: runnerData.layPrice1,
        //         size: runnerData.laySize1
        //       }, {
        //         price: runnerData.layPrice2,
        //         size: runnerData.laySize2
        //       }, {
        //         price: runnerData.layPrice3,
        //         size: runnerData.laySize3
        //       }]
        //     }
        //   }
        //   runnerAry.push(runnerObj)
        // })
        obj['runners'] = filtered_array.runners;
        this.renderDummyMarketrate(obj)
      }
    }
  }


  renderDummyMarketrate(response){
    if (response.status === 'OPEN') {
      const linerMarId = response.marketId;
      const marketId = response.marketId.toString().replace('.', '');
      let runners = 0;
      if (response.numberOfRunners) {
        runners = response.numberOfRunners;
      } else {
        runners = response.runners.length;
      }
      if(runners > 3){
        let marketobj = this.matchOddsData.find(o => o.marketId == linerMarId);

        if(marketobj){
          if (marketobj.marketStatus.id !== 'MS940896') {
            $('.' + marketId + '_market').removeClass('market_suspend');
            $('.' + marketId + '_market_mob').removeClass('market_suspend');
          } else {
            $('.' + marketId + '_market').addClass('market_suspend');
            $('.' + marketId + '_market_mob').addClass('market_suspend');
          }

          let matchRunners = this.matchOddsData[0].runners;
          var indexObject = _.reduce(response.runners, function(result, currentObject) {
            result[currentObject.selectionId] = currentObject;
            return result;
          }, {});
          response.runners = _.map(matchRunners, function(currentGUID) {
            return indexObject[currentGUID.selectionId]
          });
          //this code is for only cup
          for (let i = 0; i < runners; i++) {
            //if (marketId === this.marketSel) {
            let marketSel = marketId.toString().replace('.', '');
            let selectionId = response.runners[i].selectionId;
            const volume = this.marketVolume;
            if (marketobj.marketStatus.id === 'MS940896') {
              $('.' + marketId + '_market').addClass('market_suspend');
              $('.' + marketId + '_market_mob').addClass('market_suspend');
            }
            const availableBack = response.runners[i].ex.availableToBack;
            // update bhav to market
            $('#'+ selectionId + '_' + marketSel +'_back_0_odds').html((availableBack[0]) ? availableBack[0].price : ''), $('#' + marketSel +'_back_0_odds_mob').html((availableBack[0]) ? availableBack[0].price : '');

            // Start Blinking Rate change Odds
            const currOddsRate =  $('#'+ selectionId + '_'  + marketSel +'_back_0_odds').text();
            const prevOddsRate = $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_hiddne').val();

            // if (currOddsRate === prevOddsRate) {
            //   $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_blink').css('background', '');
            //   $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_mob_blink').css('background', '');
            // } else {
            //   $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_blink').css('background', '#FFD700');
            //   $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_mob_blink').css('background', '#FFD700');
            // }

            // End Blinking Rate change Odds
            $('#'+ selectionId + '_'  + marketSel +'_back_0_vol').html((availableBack[0]) ? String(Math.round(availableBack[0].size * volume)) : '')
            $('#'+ selectionId + '_'  + marketSel +'_back_1_odds').html((availableBack[1]) ? availableBack[1].price : '');
            $('#'+ selectionId + '_'  + marketSel +'_back_1_vol').html((availableBack[1]) ? String(Math.round(availableBack[1].size * volume)) : '')
            $('#'+ selectionId + '_'  + marketSel +'_back_2_odds').html((availableBack[2]) ? availableBack[2].price : '');
            $('#'+ selectionId + '_'  + marketSel +'_back_2_vol').html((availableBack[2]) ? String(Math.round(availableBack[2].size * volume)) : '')
            $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_hiddne').val((availableBack[0]) ? availableBack[0].price : '');

            const availableLay = response.runners[i].ex.availableToLay;
            $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds').html((availableLay[0]) ? availableLay[0].price : '')

            // Start Blinking Rate change Lays
            const currLaysRate =  $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds').text();
            const prevLaysRate =  $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_hiddne').val();
            // if (currLaysRate === prevLaysRate) {
            //   $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_blink').css('background', '');
            //   $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_mob_blink').css('background', '');
            // } else {
            //   $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_blink').css('background', '#FFD700');
            //   $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_mob_blink').css('background', '#FFD700');
            // }
            // End Blinking Rate change Lays

            $('#'+ selectionId + '_'  + marketSel +'_lay_0_vol').html((availableLay[0]) ? String(Math.round(availableLay[0].size * volume)) : '')
            $('#'+ selectionId + '_'  + marketSel +'_lay_1_odds').html((availableLay[1]) ? availableLay[1].price : ''), $('#' + marketSel +  '_lay_1_odds_mob').html((availableLay[1]) ? availableLay[1].price : '');
            $('#'+ selectionId + '_'  + marketSel +'_lay_1_vol').html((availableLay[1]) ? String(Math.round(availableLay[1].size * volume)) : '')
            $('#'+ selectionId + '_'  + marketSel +'_lay_2_odds').html((availableLay[2]) ? availableLay[2].price : ''), $('#' + marketSel +  '_lay_2_odds_mob').html((availableLay[2]) ? availableLay[2].price : '');
            $('#'+ selectionId + '_'  + marketSel +'_lay_2_vol').html((availableLay[2]) ? String(Math.round(availableLay[2].size * volume)) : '')
            $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_hiddne').val((availableLay[0]) ? availableLay[0].price : '');
            //}
            if (this.lineMarketData) {
              const linerobj = this.lineMarketData.find(o => o.marketId === linerMarId);
              if (linerobj) {
                if (linerobj.lineMode == 'Auto') {
                  let lineMultipler = 0;
                  if (linerobj.lineSetting) {
                    lineMultipler = linerobj.lineSetting.MultiplierVolume;
                  }

                  // console.log("lineMultipler++++++++++++++++++++++",lineMultipler);
                  // In live market rate is reverse
                  const availableBack = response.runners[i].ex.availableToLay;
                  $('#' + linerobj.marketIdDec + '_back').html((availableBack[0]) ? String(Math.round(availableBack[0].price)) : '')
                  $('#' + linerobj.marketIdDec + '_back_vol').html((availableBack[0]) ? String(Math.round(availableBack[0].size * lineMultipler)) : '');

                  const availableLay = response.runners[i].ex.availableToBack;
                  $('#' + linerobj.marketIdDec + '_lay').html((availableLay[0]) ? String(Math.round(availableLay[0].price)) : '');
                  $('#' + linerobj.marketIdDec + '_lay_vol').html((availableLay[0]) ? String(Math.round(availableLay[0].size * lineMultipler)) : '');
                }
              }
            }
          }
        }
      }

      //this code is for only market
      for (let i = 0; i < runners; i++) {
        let marketSel = marketId;
        let marketobj = this.matchOddsData.find(o => o.marketId == linerMarId);
        // if (marketId === this.marketSel) {
        let selectionId = response.runners[i].selectionId;
        const volume = this.marketVolume;
        if(marketobj){
          if (marketobj.marketStatus.id === 'MS940896') {
            $('.' + marketId + '_market').addClass('market_suspend');
            $('.' + marketId + '_market_mob').addClass('market_suspend');
          }else{
            $('.' + marketId + '_market').removeClass('market_suspend');
            $('.' + marketId + '_market_mob').removeClass('market_suspend');
          }
        }

        const availableBack = response.runners[i].ex ? response.runners[i].ex.availableToBack : response.runners[i];
        // update bhav to market
        $('#'+ selectionId + '_'  + marketSel +'_back_0_odds').html((availableBack[0]) ? availableBack[0].price : '');

        // Start Blinking Rate change Odds
        const currOddsRate =  $('#'+ selectionId + '_'  + marketSel +'_back_0_odds').text();
        const prevOddsRate = $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_hiddne').val();

        // if (currOddsRate === prevOddsRate) {
        //   $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_blink').css('background', '');
        //   $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_mob_blink').css('background', '');
        // } else {
        //   $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_blink').css('background', '#FFD700');
        //   $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_mob_blink').css('background', '#FFD700');
        // }

        // End Blinking Rate change Odds
        $('#'+ selectionId + '_'  + marketSel +'_back_0_vol').html((availableBack[0]) ? String(Math.round(availableBack[0].size * volume)) : '');
        $('#'+ selectionId + '_'  + marketSel +'_back_1_odds').html((availableBack[1]) ? availableBack[1].price : ''), $('#' + marketSel +'_back_1_odds_mob').html((availableBack[1]) ? availableBack[1].price : '');
        $('#'+ selectionId + '_'  + marketSel +'_back_1_vol').html((availableBack[1]) ? String(Math.round(availableBack[1].size * volume)) : '');
        $('#'+ selectionId + '_'  + marketSel +'_back_2_odds').html((availableBack[2]) ? availableBack[2].price : ''), $('#' + marketSel +'_back_2_odds_mob').html((availableBack[2]) ? availableBack[2].price : '');
        $('#'+ selectionId + '_'  + marketSel +'_back_2_vol').html((availableBack[2]) ? String(Math.round(availableBack[2].size * volume)) : '');
        $('#'+ selectionId + '_'  + marketSel +'_back_0_odds_hiddne').val((availableBack[0]) ? availableBack[0].price : '');

        const availableLay = response.runners[i].ex ? response.runners[i].ex.availableToLay : response.runners[i];
        $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds').html((availableLay[0]) ? availableLay[0].price : ''), $('#' + marketSel +  '_lay_0_odds_mob').html((availableLay[0]) ? availableLay[0].price : '');

        // Start Blinking Rate change Lays
        const currLaysRate =  $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds').text();
        const prevLaysRate =  $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_hiddne').val();
        // if (currLaysRate === prevLaysRate) {
        //   $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_blink').css('background', '');
        //   $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_mob_blink').css('background', '');
        // } else {
        //   $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_blink').css('background', '#FFD700');
        //   $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_mob_blink').css('background', '#FFD700');
        // }
        // End Blinking Rate change Lays

        $('#'+ selectionId + '_'  + marketSel +'_lay_0_vol').html((availableLay[0]) ? String(Math.round(availableLay[0].size * volume)) : '');
        $('#'+ selectionId + '_'  + marketSel +'_lay_1_odds').html((availableLay[1]) ? availableLay[1].price : ''), $('#' + marketSel +  '_lay_1_odds_mob').html((availableLay[1]) ? availableLay[1].price : '');
        $('#'+ selectionId + '_'  + marketSel +'_lay_1_vol').html((availableLay[1]) ? String(Math.round(availableLay[1].size * volume)) : '');
        $('#'+ selectionId + '_'  + marketSel +'_lay_2_odds').html((availableLay[2]) ? availableLay[2].price : ''), $('#' + marketSel +  '_lay_2_odds_mob').html((availableLay[2]) ? availableLay[2].price : '');
        $('#'+ selectionId + '_'  + marketSel +'_lay_2_vol').html((availableLay[2]) ? String(Math.round(availableLay[2].size * volume)) : '');
        $('#'+ selectionId + '_'  + marketSel +'_lay_0_odds_hiddne').val((availableLay[0]) ? availableLay[0].price : '');
        //}
        if (this.lineMarketData) {
          const linerobj = this.lineMarketData.find(o => o.marketId === linerMarId);
          if (linerobj) {
            if (linerobj.lineMode == 'Auto') {
              let lineMultipler = 0;
              if (linerobj.lineSetting) {
                lineMultipler = linerobj.lineSetting.MultiplierVolume;
              }

              // In live market rate is reverse
              const availableBack = response.runners[i].ex.availableToLay;
              $('#' + linerobj.marketIdDec + '_back').html((availableBack[0]) ? String(Math.round(availableBack[0].price)) : '')
              $('#' + linerobj.marketIdDec + '_back_vol').html((availableBack[0]) ? String(Math.round(availableBack[0].size * lineMultipler)) : '')

              const availableLay = response.runners[i].ex.availableToBack;
              $('#' + linerobj.marketIdDec + '_lay').html((availableLay[0]) ? String(Math.round(availableLay[0].price)) : '')
              $('#' + linerobj.marketIdDec + '_lay_vol').html((availableLay[0]) ? String(Math.round(availableLay[0].size * lineMultipler)) : '')
            }
          }
        }
      }

    } else {
      let market_id = response.marketId.toString().replace('.', '');
      $('.' + market_id + '_market').addClass('market_suspend');
      $('.' + market_id + '_market_mob').addClass('market_suspend');
    }
  }

  renderLocalData(matchId){
    let items = localStorage.getItem("dashboardData");
    if(items){
      let itemsDataAry = JSON.parse(items)
      itemsDataAry.filter(function(itemsData) {
        let findMatch = itemsData.doc.find(o => o.id == matchId);
        if(findMatch){
          this.getMarketById = findMatch.marketDetails;
          this.matchOddsData =  findMatch.marketDetails.filter(function(number) {
            if (number.marketTypeId === '5ebc1code68br4bik5b3035') {
              const marketIds_str =  number.marketId.toString().replace('.', '');
              number.marketIdDec = marketIds_str;
              return number.marketTypeId === '5ebc1code68br4bik5b3035' && number.isActive == true;
            } else if (number.marketTypeId === '5ebc1code68br4bik5b0814') {
              const marketIds_str =  number.marketId.toString().replace('.', '');
              number.marketIdDec = marketIds_str;
              return number.marketTypeId === '5ebc1code68br4bik5b0814' && number.isActive == true;
            }
          });

          this.bookmakerData =  findMatch.marketDetails.filter(function(number) {
            return number.marketTypeId === '5ebc1code68br4bik5b0810' && number.isActive == true;
          });
          this.fancyData = this.getMarketById.filter(function(number) {
            number.minStackFancy = (number.fancySetting) ? number.fancySetting.minStack : '';
            number.maxStackFancy = (number.fancySetting) ? number.fancySetting.maxStack : '';
            return number.marketTypeId === '5ebc1code68br4bik5b1808';
          });

          this.oddEvenData = this.getMarketById.filter(function(number) {
            number.minStackFancy = (number.fancySetting) ? number.fancySetting.minStack : '';
            number.maxStackFancy = (number.fancySetting) ? number.fancySetting.maxStack : '';
            return number.marketTypeId === '5ebc1code68br4bik5b0812';
          });
        }
      },this)
    }
    this.matchOddsData.filter(function(marketData) {
      // setTimeout(() => {
      this.appendDummyrate(marketData.marketId)
      // }, 10);
    },this);

  }

  inItAppendingDummyRate(){
    if (this.inItDrate == false) {
      this.matchOddsData.filter(function(marketData) {
        setTimeout(() => {
          this.appendDummyrate(marketData.marketId)
        }, 10);
      },this);
    }
    this.inItDrate = true;
  }

  addAndRemoveMultiMarket(data, cls , event){
    $(event.target).closest('.'+cls).css('display', 'none');
    let market = data.marketType == 'Fancy' ? data.fancyId : data.marketId;

    let multiData = {
      userId: this.userId,
      marketIds : [market]
    }
    this.marketService.multiMarketAdd(multiData).subscribe(response => {
      if(response){
        const userId = this.utilityService.returnLocalStorageData('userId');
        // this.getMarketIdData(userId);
      }
    });
  }

  add(){
    this.placeOdds = (Number(this.placeOdds) + 0.01).toFixed(2)
  }
  sub(){
    this.placeOdds = (Number(this.placeOdds) - 0.01).toFixed(2)
  }

}

function abbrNum(num) {
  if(num > 0){
    // 2 decimal places => 100, 3 => 1000, etc
    let decPlaces = 0;
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    var abbrev = ["K", "M", "B", "T"];
    let str : string;
    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {

      // Convert array index to "1000", "1000000", etc
      var size = Math.pow(10, (i + 1) * 3);

      // If the number is bigger or equal do the abbreviation
      if (size <= num) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        var number : number = Math.round(num * decPlaces / size) / decPlaces;


        // Handle special case where we round up to the next abbreviation
        if((number == 100) && (i < abbrev.length - 1)) {
          number = 1;
          i++;
        }
        // number + abbrev[i];
        str  = number + abbrev[i];
        break;
      }
    }
    if(str === undefined){
      return 0;
    }else{
      return str;
    }
  } else {
    return 0;
  }
}

/**
 * @author TR
 * @date : 19-02-2021
 * append status for fancy manual and auto
 */

function appendStatus(fancyId, status) {
  if (status == 'ballstart') {
    $('#no1_' + fancyId + ',#no1Vol_' + fancyId + ',#yes1_' + fancyId + ',#yes1Vol_' + fancyId + ',#no2_' + fancyId + ',#no2Vol_' + fancyId + ',#yes2_' + fancyId + ',#yes2Vol_' + fancyId + ',#no3_' + fancyId + ',#no3Vol_' + fancyId + ',#yes3_' + fancyId + ',#yes3Vol_' + fancyId +  ',#no4_' + fancyId + ',#no4Vol_' + fancyId + ',#yes4_' + fancyId + ',#yes4Vol_' + fancyId + '').html('');
    $('#mob_no1_' + fancyId + ',#mob_no1Vol_' + fancyId + ',#mob_yes1_' + fancyId + ',#mob_yes1Vol_' + fancyId + ',#mob_no2_' + fancyId + ',#mob_no2Vol_' + fancyId + ',#mob_yes2_' + fancyId + ',#mob_yes2Vol_' + fancyId + ',#mob_no3_' + fancyId + ',#mob_no3Vol_' + fancyId + ',#mob_yes3_' + fancyId + ',#mob_yes3Vol_' + fancyId + ',#mob_no4_' + fancyId + ',#mob_no4Vol_' + fancyId + ',#mob_yes4_' + fancyId + ',#mob_yes4Vol_' + fancyId + '').html('');

    $('#' + fancyId +  '_first,#' + fancyId + '_third,#' + fancyId + '_forth').hide(), $('#mob_' + fancyId +  '_first,#mob_' + fancyId + '_third,#mob_' + fancyId + '_forth').hide();
    $('#' + fancyId + '_second').removeClass('fancy_suspend'), $('#' + fancyId + '_status').removeClass('fancy_suspend');
    $('#' + fancyId + '_second').addClass('fancy_ballstart'), $('#' + fancyId + '_status').addClass('fancy_ballstart');
  }
  if (status && status.name === 'Close') {
    $('#no1_' + fancyId + ',#no1Vol_' + fancyId + ',#yes1_' + fancyId + ',#yes1Vol_' + fancyId + ',#no2_' + fancyId + ',#no2Vol_' + fancyId + ',#yes2_' + fancyId + ',#yes2Vol_' + fancyId + ',#no3_' + fancyId + ',#no3Vol_' + fancyId + ',#yes3_' + fancyId + ',#yes3Vol_' + fancyId + ',#no4_' + fancyId + ',#no4Vol_' + fancyId + ',#yes4_' + fancyId + ',#yes4Vol_' + fancyId + '').html('');
    $('#mob_no1_' + fancyId + ',#mob_no1Vol_' + fancyId + ',#mob_yes1_' + fancyId + ',#mob_yes1Vol_' + fancyId + ',#mob_no2_' + fancyId + ',#mob_no2Vol_' + fancyId + ',#mob_yes2_' + fancyId + ',#mob_yes2Vol_' + fancyId + ',#mob_no3_' + fancyId + ',#mob_no3Vol_' + fancyId + ',#mob_yes3_' + fancyId + ',#mob_yes3Vol_' + fancyId  + ',#mob_no4_' + fancyId + ',#mob_no4Vol_' + fancyId + ',#mob_yes4_' + fancyId + ',#mob_yes4Vol_' + fancyId + '').html('');

    $('#' + fancyId +  '_first,#' + fancyId + '_third,#' + fancyId + '_forth').hide(), $('#mob_' + fancyId +  '_first,#mob_' + fancyId + '_third,#mob_' + fancyId + '_forth').hide();
    $('#' + fancyId + '_second').removeClass('fancy_suspend'), $('#' + fancyId + '_status').removeClass('fancy_suspend');
    $('#' + fancyId + '_second').addClass('fancy_close'), $('#' + fancyId + '_status').addClass('fancy_close');
  }
  if (status == 'suspend') {
    $('#no1_' + fancyId + ',#no1Vol_' + fancyId + ',#yes1_' + fancyId + ',#yes1Vol_' + fancyId + ',#no2_' + fancyId + ',#no2Vol_' + fancyId + ',#yes2_' + fancyId + ',#yes2Vol_' + fancyId + ',#no3_' + fancyId + ',#no3Vol_' + fancyId + ',#yes3_' + fancyId + ',#yes3Vol_' + fancyId + ',#no4_' + fancyId + ',#no4Vol_' + fancyId + ',#yes4_' + fancyId + ',#yes4Vol_' + fancyId + '').html('');
    $('#mob_no1_' + fancyId + ',#mob_no1Vol_' + fancyId + ',#mob_yes1_' + fancyId + ',#mob_yes1Vol_' + fancyId + ',#mob_no2_' + fancyId + ',#mob_no2Vol_' + fancyId + ',#mob_yes2_' + fancyId + ',#mob_yes2Vol_' + fancyId + ',#mob_no3_' + fancyId + ',#mob_no3Vol_' + fancyId + ',#mob_yes3_' + fancyId + ',#mob_yes3Vol_' + fancyId  + ',#mob_no4_' + fancyId + ',#mob_no4Vol_' + fancyId + ',#mob_yes4_' + fancyId + ',#mob_yes4Vol_' + fancyId + '').html('');

    $('#' + fancyId +  '_first,#' + fancyId + '_third,#' + fancyId + '_forth').hide(), $('#mob_' + fancyId +  '_first,#mob_' + fancyId + '_third,#mob_' + fancyId + '_forth').hide();        $('#' + fancyId + '_second').removeClass('fancy_ballstart'), $('#mob_' + fancyId + '_second').removeClass('fancy_ballstart');
    $('#' + fancyId + '_second').addClass('fancy_suspend'), $('#' + fancyId + '_status').addClass('fancy_suspend');
  }
  if (status == 'settled') {
    $('#no1_' + fancyId + ',#no1Vol_' + fancyId + ',#yes1_' + fancyId + ',#yes1Vol_' + fancyId + ',#no2_' + fancyId + ',#no2Vol_' + fancyId + ',#yes2_' + fancyId + ',#yes2Vol_' + fancyId + ',#no3_' + fancyId + ',#no3Vol_' + fancyId + ',#yes3_' + fancyId + ',#yes3Vol_' + fancyId + ',#no4_' + fancyId + ',#no4Vol_' + fancyId + ',#yes4_' + fancyId + ',#yes4Vol_' + fancyId + '').html('');
    $('#mob_no1_' + fancyId + ',#mob_no1Vol_' + fancyId + ',#mob_yes1_' + fancyId + ',#mob_yes1Vol_' + fancyId + ',#mob_no2_' + fancyId + ',#mob_no2Vol_' + fancyId + ',#mob_yes2_' + fancyId + ',#mob_yes2Vol_' + fancyId + ',#mob_no3_' + fancyId + ',#mob_no3Vol_' + fancyId + ',#mob_yes3_' + fancyId + ',#mob_yes3Vol_' + fancyId  + ',#mob_no4_' + fancyId + ',#mob_no4Vol_' + fancyId + ',#mob_yes4_' + fancyId + ',#mob_yes4Vol_' + fancyId + '').html('');

    $('#' + fancyId +  '_first,#' + fancyId + '_third,#' + fancyId + '_forth').hide(), $('#mob_' + fancyId +  '_first,#mob_' + fancyId + '_third,#mob_' + fancyId + '_forth').hide();        $('#' + fancyId + '_second').removeClass('fancy_ballstart'), $('#mob_' + fancyId + '_second').removeClass('fancy_ballstart');
    $('#' + fancyId + '_second').removeClass('fancy_suspend'), $('#' + fancyId + '_status').removeClass('fancy_suspend');

    $('#' + fancyId + '_second').addClass('fancy_settled'), $('#' + fancyId + '_status').addClass('fancy_settled');
  }
  if (status == 'active') {
    $('#' + fancyId + '_second').removeClass('fancy_ballstart'), $('#' + fancyId + '_status').removeClass('fancy_ballstart');
    $('#' + fancyId + '_second').removeClass('fancy_suspend'), $('#' + fancyId + '_status').removeClass('fancy_suspend');
  }
}

/**
 * @author TR
 * @date : 19-02-2021
 * append Rate for fancy manual and auto
 */

function appendRate(response) {
  if (response && response.type == 'line') {
    const lineId = response.lineId.toString().replace('.', '');
    if (response.no2 != '' && response.yes2 != '' && response.lineId ) {
      // Line market append live rate for manual
      $('#' + lineId + '_lay').html(response.no2), $('#mob_' + lineId + '_lay').html(response.no2);
      $('#' + lineId + '_back').html(response.yes2Vol), $('#mob_' + lineId + '_back').html(response.yes2Vol);
      $('#' + lineId + '_lay_vol').html(response.no2Vol), $('#mob_' + lineId + '_lay_vol').html(response.no2Vol);
      $('#' + lineId + '_back_vol').html(response.yes2), $('#mob_' + lineId + '_back_vol').html(response.yes2);
    }
  }

  const fancyId =  response && response.fancyId;
  $('#' + fancyId + '_third').hide(), $('#mob_' + fancyId + '_third').hide();
  $('#' + fancyId + '_first').hide(), $('#mob_' + fancyId + '_first').hide();
  $('#' + fancyId + '_forth').hide(), $('#mob_' + fancyId + '_forth').hide();

  if (response && response.no1 && response.no1 != '' && response.yes1 != '' ) {
    $('#' + fancyId + '_first').css("display", "flex"), $('#mob_' + fancyId + '_first').css("display", "flex");
    $('#no1_' + fancyId).html(response.no1), $('#mob_no1_' + fancyId).html(response.no1);
    $('#no1Vol_' + fancyId).html(response.no1Vol), $('#mob_no1Vol_' + fancyId).html(response.no1Vol);
    $('#yes1_' + fancyId).html(response.yes1), $('#mob_yes1_' + fancyId).html(response.yes1);
    $('#yes1Vol_' + fancyId).html(response.yes1Vol), $('#mob_yes1Vol_' + fancyId).html(response.yes1Vol);
  }
  if (response && response.no2 && response.no2 != '' && response.yes2 != '' ) {
    // Fancy market append live rate for manual
    $('#no2_' + fancyId).html(response.no2), $('#mob_no2_' + fancyId).html(response.no2);
    $('#no2Vol_' + fancyId).html(response.no2Vol), $('#mob_no2Vol_' + fancyId).html(response.no2Vol);
    $('#yes2_' + fancyId).html(response.yes2), $('#mob_yes2_' + fancyId).html(response.yes2);
    $('#yes2Vol_' + fancyId).html(response.yes2Vol), $('#mob_yes2Vol_' + fancyId).html(response.yes2Vol);
    $('#' + fancyId + '_second').show(), $('#mob_' + fancyId + '_second').show();
  }
  if ( response && response.no3 && response.no3 != '' && response.yes3 != '' ) {
    $('#' + fancyId + '_third').css("display", "flex"), $('#mob_' + fancyId + '_third').css("display", "flex");
    $('#no3_' + fancyId).html(response.no3), $('#mob_no3_' + fancyId).html(response.no3);
    $('#no3Vol_' + fancyId).html(response.no3Vol), $('#mob_no3Vol_' + fancyId).html(response.no3Vol);
    $('#yes3_' + fancyId).html(response.yes3), $('#mob_yes3_' + fancyId).html(response.yes3);
    $('#yes3Vol_' + fancyId).html(response.yes3Vol), $('#mob_yes3Vol_' + fancyId).html(response.yes3Vol);
  }
}

/**
 * @author TR
 * @date : 19-02-2021
 * appendStatusLine manual and auto
 */

function appendStatusLine(marketId, status) {

  if (status == 'ballstart') {
    $('#' + marketId + '_lay' + ',#' + marketId + '_back' + ',#' + marketId + '_lay_mob' + ',#' + marketId + '_back_mob').html('');
    $('#' + marketId + '_lay_vol' + ',#' + marketId + '_back_vol' + ',#' + marketId + '_lay_mob_vol' + ',#' + marketId + '_back_mob_vol' ).html('');
    $('#' + marketId + '_line').removeClass('fancy_suspend'), $('#' + marketId + '_mob_line').removeClass('fancy_suspend');
    $('#' + marketId + '_line').addClass('fancy_ballstart'), $('#' + marketId + '_mob_line').addClass('fancy_ballstart');
  }
  if (status == 'suspend') {
    // $('#' + marketId + '_lay' + ',#' + marketId + '_back' + ',#' + marketId + '_lay_mob' + ',#' + marketId + '_back_mob').html('');
    // $('#' + marketId + '_lay_vol' + ',#' + marketId + '_back_vol' + ',#' + marketId + '_lay_mob_vol' + ',#' + marketId + '_back_mob_vol' ).html('');
    $('#' + marketId + '_line').removeClass('fancy_ballstart'), $('#' + marketId + '_mob_line').removeClass('fancy_ballstart');
    $('#' + marketId + '_line').addClass('fancy_suspend'), $('#' + marketId + '_mob_line').addClass('fancy_suspend');
  }
  if (status == 'settled') {
    $('#' + marketId + '_lay' + ',#' + marketId + '_back' + ',#' + marketId + '_lay_mob' + ',#' + marketId + '_back_mob').html('');
    $('#' + marketId + '_lay_vol' + ',#' + marketId + '_back_vol' + ',#' + marketId + '_lay_mob_vol' + ',#' + marketId + '_back_mob_vol' ).html('');
    $('#' + marketId + '_line').addClass('fancy_settled'), $('#' + marketId + '_mob_line').addClass('fancy_settled');
  }
  if (status == 'active') {
    $('#' + marketId + '_line').removeClass('fancy_ballstart'), $('#' + marketId + '_line').removeClass('fancy_suspend');
    $('#' + marketId + '_mob_line').removeClass('fancy_ballstart'), $('#' + marketId + '_mob_line').removeClass('fancy_suspend');
  }
  if (status == 'Open') {
    $('#' + marketId + '_line').removeClass('fancy_ballstart'), $('#' + marketId + '_line').removeClass('fancy_suspend');
    $('#' + marketId + '_mob_line').removeClass('fancy_ballstart'), $('#' + marketId + '_mob_line').removeClass('fancy_suspend');
  }
}

/**
 * @author TR
 * @date : 19-02-2021
 * appendStatusBM manual and auto
 */

function appendStatusBM(teamID,status){
  $("#" + teamID + "_bookmaker").removeClass('bookmaker_ballstart'),$("#" + teamID + "_bookmaker_mob").removeClass('bookmaker_ballstart');
  $("#" + teamID + "_bookmaker").removeClass('market_suspend'),$("#" + teamID + "_bookmaker_mob").removeClass('market_suspend');

  if (status == 'ballstart') {
    $("#" + teamID + "_bookmaker").addClass('bookmaker_ballstart'),$("#" + teamID + "_bookmaker_mob").addClass('bookmaker_ballstart');
  }
  if (status == 'suspend') {
    $("#" + teamID + "_bookmaker").addClass('market_suspend'),$("#" + teamID + "_bookmaker_mob").addClass('market_suspend');
  }
}

function appendBMRateManual(response){
  if(response.marketStatus){
    let dataitems = 'null';
    if(response.marketStatus.id == "MS081893"){
      _.map(response.runners, function(runnData) {
        if(response.selectedRunner == 'both'){
          appendStatusBM(runnData.selectionId, 'open');
          $("#" + runnData.selectionId + "_back_odds").html(runnData.backRate);
          $("#" + runnData.selectionId + "_lay_odds").html('');
          $("#" + runnData.selectionId + "_back_vol").html(runnData.backVol);
          $("#" + runnData.selectionId + "_lay_vol").html('');
        }else{
          if(runnData.status.id == "MS081893"){
            appendStatusBM(runnData.selectionId, 'open');
            $("#" + runnData.selectionId + "_back_odds").html(runnData.backRate);
            $("#" + runnData.selectionId + "_lay_odds").html(runnData.layRate);
            $("#" + runnData.selectionId + "_back_vol").html(runnData.backVol);
            $("#" + runnData.selectionId + "_lay_vol").html(runnData.layVol);

            dataitems = runnData.runnerName + ' ' + runnData.backRate.toString() +' '+ runnData.layRate.toString();

          }else{
            appendStatusBM(runnData.selectionId, 'suspend');
            $("#" + runnData.selectionId + "_back_odds").html('');
            $("#" + runnData.selectionId + "_lay_odds").html('');
            $("#" + runnData.selectionId + "_back_vol").html('');
            $("#" + runnData.selectionId + "_lay_vol").html('');
          }
        }
      });
    }else{
      _.map(response.runners, function(runnData) {
        dataitems = response.marketStatus.name
        appendStatusBM(runnData.selectionId, response.marketStatus.name);
        $("#" + runnData.selectionId + "_back_odds").html('');              ;
        $("#" + runnData.selectionId + "_lay_odds").html('');
        $("#" + runnData.selectionId + "_back_vol").html('');
        $("#" + runnData.selectionId + "_lay_vol").html('');
      });
    }
    if(dataitems != 'null'){
      this.result = dataitems;

    }
  }
}

function appendBMRateAuto(bookmakerObj,response) {
  const bookmakerId = response.srno;
  bookmakerObj.runners.map(res => {
    $("#" + res.selectionId + "_back_odds").html('0');
    $("#" + res.selectionId + "_lay_odds").html('0');
    $("#" +  res.selectionId + "_back_vol").html('0');
    $("#" +  res.selectionId + "_lay_vol").html('0');
    // $("#" + res.selectionId + "_back_odds_b").html('0');
    // $("#" + res.selectionId + "_lay_odds_l").html('0');
    // $("#" +  res.selectionId + "_back_vol_b").html('0');
    // $("#" +  res.selectionId + "_lay_vol_l").html('0');

    if (response.status == '1') {

      appendStatusBM(res.selectionId, 'ballstart');
    } else if (response.status == '2') {

      appendStatusBM(res.selectionId, 'suspend');
      $("#" + res.selectionId + "_back_odds").html('');              ;
      $("#" + res.selectionId + "_lay_odds").html('');
      $("#" + res.selectionId + "_back_vol").html('');
      $("#" + res.selectionId + "_lay_vol").html('');
    } else {

      appendStatusBM(res.selectionId, 'active');
      if(response['team'] == "D"){
        appendStatusBM(res.selectionId, 'suspend');
        let splitAry = response['selectid'].split(',');
        splitAry.map(selId => {
          appendStatusBM(selId, 'active');

          if(response['rates'] && response['runnerRateAry'][selId]){
            $("#" + selId + "_back_odds").html(response['runnerRateAry'][selId]['rate_1']);
            $("#" + selId + "_lay_odds").html(response['runnerRateAry'][selId]['rate_2']);
            $("#" + selId + "_back_vol").html(bookmakerObj['maxStackMarket']);
            $("#" + selId + "_lay_vol").html(bookmakerObj['maxStackMarket']);
          }
        });
      }else{
        if(res.selectionId == response['selectid']){


          if(response['rates']){
            $("#" + res.selectionId + "_back_odds").html(response['rates']['0']['rate_1']);
            $("#" + res.selectionId + "_lay_odds").html(response['rates']['0']['rate_2']);
            $("#" + res.selectionId + "_back_vol").html(bookmakerObj['maxStackMarket']);
            $("#" + res.selectionId + "_lay_vol").html(bookmakerObj['maxStackMarket']);
          }
        }else{
          $("#" + res.selectionId + "_back_odds").html('');
          $("#" + res.selectionId + "_lay_odds").html('');
          $("#" + res.selectionId + "_back_vol").html('');
          $("#" + res.selectionId + "_lay_vol").html('');
          // $("#" + res.selectionId + "_bookmaker").addClass('market_suspend');
          appendStatusBM(res.selectionId, 'suspend');
        }
      }
    }
  });
}

function initFancyRate(response) {
  response.srno =  response.srno.toString().replace('.', '');
  response.srno =  response.srno.toString().replace('-', '');
  const fancyId = response.srno;
  if (response.status == '1') {
    appendStatus(response.srno, 'ballstart');
  } else if (response.status == '2') {
    appendStatus(response.srno, 'suspend');
  } else {
    appendStatus(response.srno, 'active');
    if (response.rates) {
      $('#' + fancyId + '_third').hide(), $('#mob_' + fancyId + '_third').hide();
      $('#' + fancyId + '_first').hide(), $('#mob_' + fancyId + '_first').hide();
      $('#' + fancyId + '_forth').hide(), $('#mob_' + fancyId + '_forth').hide();

      for (const [key, value] of Object.entries(response.rates)) {
        if (key == '0') {
          $('#no2_' + fancyId).html("1000");
          $('#no2_' + fancyId).html(value['rate_1']), $('#mob_no2_' + fancyId).html(value['rate_1']);
          $('#no2Vol_' + fancyId).html(value['value_1']), $('#mob_no2Vol_' + fancyId).html(value['value_1']);
          $('#yes2_' + fancyId).html(value['rate_2']), $('#mob_yes2_' + fancyId).html(value['rate_2']);
          $('#yes2Vol_' + fancyId).html(value['value_2']), $('#mob_yes2Vol_' + fancyId).html(value['value_2']);
          $('#' + fancyId + '_second').show(), $('#mob_' + fancyId + '_second').show();
        }
        if (key == '1') {
          $('#no1_' + fancyId).html(value['rate_1']), $('#mob_no1_' + fancyId).html(value['rate_1']);
          $('#no1Vol_' + fancyId).html(value['value_1']), $('#mob_no1Vol_' + fancyId).html(value['value_1']);
          $('#yes1_' + fancyId).html(value['rate_2']), $('#mob_yes1_' + fancyId).html(value['rate_2']);
          $('#yes1Vol_' + fancyId).html(value['value_2']), $('#mob_yes1Vol_' + fancyId).html(value['value_2']);
          $('#' + fancyId + '_first').css("display", "flex"), $('#mob_' + fancyId + '_first').css("display", "flex");
        }
        if (key == '2') {
          $('#no3_' + fancyId).html(value['rate_1']), $('#mob_no3_' + fancyId).html(value['rate_1']);
          $('#no3Vol_' + fancyId).html(value['value_1']), $('#mob_no3Vol_' + fancyId).html(value['value_1']);
          $('#yes3_' + fancyId).html(value['rate_2']), $('#mob_yes3_' + fancyId).html(value['rate_2']);
          $('#yes3Vol_' + fancyId).html(value['value_2']), $('#mob_yes3Vol_' + fancyId).html(value['value_2']);
          $('#' + fancyId + '_third').css("display", "flex"), $('#mob_' + fancyId + '_third').css("display", "flex");
        }
        if (key == '3') {
          $('#no4_' + fancyId).html(value['rate_1']), $('#mob_no4_' + fancyId).html(value['rate_1']);
          $('#no4Vol_' + fancyId).html(value['value_1']), $('#mob_no4Vol_' + fancyId).html(value['value_1']);
          $('#yes4_' + fancyId).html(value['rate_2']), $('#mob_yes4_' + fancyId).html(value['rate_2']);
          $('#yes4Vol_' + fancyId).html(value['value_2']), $('#mob_yes4Vol_' + fancyId).html(value['value_2']);
          $('#' + fancyId + '_forth').css("display", "flex"), $('#mob_' + fancyId + '_forth').css("display", "flex");
        }
      }
    } else {
      appendStatus(response.srno, 'suspend');
    }
  }

}
