import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as $ from 'jquery';
import {UtilityService} from '../../globals/utilityService';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-casono-dashboard',
  templateUrl: './casono-dashboard.component.html',
  styleUrls: ['./casono-dashboard.component.scss']
})
export class CasonoDashboardComponent implements OnInit {
  url : any;
  eventId : any;
  eventType : any;
  mycurrentRoute : any;
  userId = this.utilityService.returnLocalStorageData('userId');
  token = this.utilityService.getRandomString(20);
  constructor(private router: Router,
              private utilityService: UtilityService,
              private userService: UserService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.eventId = params.id;
      this.eventType = params.type;
      if(this.eventId == "007"){
        this.ezugiVerification(this.eventId,this.eventType);
      }else{
        this.getUserData(this.eventId,this.eventType);
      }

    });
  }
  gameUrl(data){
    this.router.navigateByUrl('/dc/game-lobby/' + data);
  }

  getUserData(eventId,eventType){
    this.userService.storeStudioData(this.userId,this.token).subscribe(response => {
      //this.url = "https://livegame.marutix.com/#/fs?operatorId=1357&token=" + this.token + "&stakes=100,200,300,400,500,600";
      this.url = response.data.data.launchURL; //this is live
      $("#loadIframe").html("<iframe src='" + this.url + "' width=100% height=1000px></iframe>");
      // if(eventType == "web"){
      //   //this.url = "https://livegame.marutix.com/#/fs/hm/game-market/" + eventId + "?token=" + this.token + "&operatorId=1357&stakes=100,200,300,400,500,600"+"&fromColors=1d184e,373269,524F95,e44a3f&toColors=002b3f,002b3f,ffc107,ffc107"; //this is staging
      //   this.url = "https://faas.sports999.in/#/fs/hm/game-market/" + eventId + "?token=" + this.token + "&operatorId=36&stakes=1000,5000,10000,25000,50000,100000"+ "&onclickstake=1000,5000,10000,25000,50000,100000" + "&fromColors=1d184e,373269,524F95,e44a3f&toColors=002b3f,002b3f,ffc107,ffc107"; //this is live
      //   $("#loadIframe").html("<iframe src='" + this.url + "' width=100% height=1000px></iframe>");
      // }else{
      //   //this.url = "https://livegame.marutix.com/#/fs/mhome/egmarket/" + eventId + "?token=" + this.token + "&operatorId=1357&stakes=100,200,300,400,500,600"+"&fromColors=1d184e,373269,524F95,e44a3f&toColors=002b3f,002b3f,ffc107,ffc107"; ////this is staging
      //   this.url = "https://faas.sports999.in/#/fs/mhome/egmarket/" + eventId + "?token=" + this.token + "&operatorId=36&stakes=1000,5000,10000,25000,50000,100000"+ "&onclickstake=1000,5000,10000,25000,50000,100000" + "&fromColors=1d184e,373269,524F95,e44a3f&toColors=002b3f,002b3f,ffc107,ffc107"; ////this is staging
      //   $("#loadIframe").html("<iframe src='" + this.url + "' width=100% height=1000px></iframe>");
      //   // $('.header.mb-header').
      // }
      // console.log(this.token);
    });
  }

  ezugiVerification(eventId,eventType){
    this.userService.storeEzugiData(this.userId,this.token).subscribe(response => {
      //this.url = "https://livegame.marutix.com/#/fs?operatorId=1357&token=" + this.token + "&stakes=100,200,300,400,500,600";
      this.url = "https://playint.tableslive.com/auth/?token=" + this.token + "&operatorId=10495001";
      $("#loadIframe").html("<iframe src='" + this.url + "' width=100% height=1000px></iframe>");
    });
  }

}
