import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DreamCasinoComponent } from './dream-casino.component';
import { DreamCasinoViewComponent } from './dream-casino-view/dream-casino-view.component';
import { LobbyCasinoComponent } from './lobby-casino/lobby-casino.component';
import {AuthGuard} from "../auth-gaurd/auth-guard.service";
import {IndianPokerViewComponent} from './indian-poker-view/indian-poker-view.component';
import {VirtualComponent} from './virtual/virtual.component';


const routes: Routes = [{
  path: 'dc',
  component: DreamCasinoComponent,
  children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: DreamCasinoComponent,
      }
  ]
},
{
  path: 'dc/game-lobby/:id',
  component: DreamCasinoViewComponent,
  children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: DreamCasinoViewComponent,
      }
  ]
},
{
  path: 'dc/lobby-casino/:name',
  component: LobbyCasinoComponent,
  children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: LobbyCasinoComponent,
      }
  ]
},
{
  path: 'indian-poker',
  component: IndianPokerViewComponent,
  children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: IndianPokerViewComponent,
      }
  ]
},
{
  path: 'virtual',
  component: VirtualComponent,
  children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: VirtualComponent,
      }
  ]
}
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DreamCasinoRoutingModule { }
