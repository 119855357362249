import {Component, OnInit} from '@angular/core';
import {MarketService} from "../../services/market.service";
import {SocketService} from "../../globals/socketService";
import {NgxSpinnerService} from "ngx-spinner";
import {SocketServiceRedis} from '../../globals/socketServiceRedis';
import {UtilityService} from '../../globals/utilityService';
import {SocketServiceClient} from '../../globals/socketServiceClient';
import {Router} from '@angular/router';
declare  let _: any;
@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit {

  constructor(private marketService: MarketService, private spinner: NgxSpinnerService, private utilityService: UtilityService, private socketService: SocketService, private socketServiceClient: SocketServiceClient, private router: Router) {
  }

  getAllEvent = [
    {
      "code": "NASTIYA",
      "username": "NASTIYA",
      "image": "../../assets/images/casino-a.jpg",
      "event_id": "2020310988715",
      "event_name": "CARD RACE",
      "room": "asia",
      "table_id": null,
      "sequence": 1,
    },
{
  "code": "YANA",
  "username": "YANA",
  "image": "../../assets/images/casino-b.jpg",
  "event_id": "2020230163340196",
  "event_name": "LUCKY 7",
  "room": "asia",
  "table_id": null,
  "sequence": 2,
},
{
  "code": "ALINA",
  "username": "ALINA",
  "image": "../../assets/images/casino-c.jpg",
  "event_id": "030820190613",
  "event_name": "TEEN PATTI",
  "room": "asia",
  "table_id": null,
  "sequence": 3,
},
{
  "code": "LALLY",
  "username": "LALLY",
  "image": "../../assets/images/casino-d.jpg",
  "event_id": "20201013161936177",
  "event_name": "1 DAY DRAGON TIGER",
  "room": "asia",
  "table_id": "123",
  "sequence": 10,
},
{
  "code": "MERRY",
  "username": "MERRY",
  "image": "../../assets/images/casino-e.jpg",
  "event_id": "2020528144333331",
  "event_name": "32 Cards",
  "room": "asia",
  "table_id": null,
  "sequence": 10,
},
{
  "code": "ELLEY",
  "username": "ELLEY",
  "image": "../../assets/images/casino-f.jpg",
  "event_id": "202101234450584",
  "event_name": "ANDAR BAHAR",
  "room": "asia",
  "table_id": null,
  "sequence": 10,
},
{
  "code": "SABINA",
  "username": "SABINA",
  "image": "../../assets/images/casino-g.jpg",
  "event_id": "20204410722520",
  "event_name": "DRAGON TIGER",
  "room": "asia",
  "table_id": null,
  "sequence": 10,
},
    {
      "code": "COSTIN",
      "username": "COSTIN",
      "image": "../../assets/images/casino-h.jpg",
      "event_id": "2020814123240967",
      "event_name": "EU DRAGON TIGER",
      "room": "eu",
      "table_id": null,
      "sequence": null,
      "__v": 0
    }
];


  ngOnInit() {
    // this.getAllCasinoEvent();
  }


  liveStreaming(eventId) {
    // let data = {
    //   event_id : eventId
    // }
    // console.log(data)
    // this.marketService.liveStrimEvent(data).subscribe(response => {
    //  if(response.status){
       this.router.navigateByUrl('/sports/casino-view/' + eventId);
    //  }
    // });
  }
}
