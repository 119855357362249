import {Component, OnInit, ViewChild,Input,Inject,LOCALE_ID} from '@angular/core';
import {UserService} from "../../services/user.service";
import {NgxSpinnerService} from "ngx-spinner";
import {HttpClient} from "@angular/common/http";
import {ModalDirective} from "ngx-bootstrap";
import {DataTableDirective} from 'angular-datatables';
import {Subject} from "rxjs/Rx";
import * as env from '../../globals/env';
import * as moment from "moment";
import {UtilityService} from '../../globals/utilityService';
import { CommonService } from 'src/app/services/common.service';
import { PlacebetService } from 'src/app/services/placebet.service';
import { formatDate } from '@angular/common';
import { MarketService } from '../../services/market.service';
declare let $: any;
declare let _: any;


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-historical-ac-statement',
  templateUrl: './historical-ac-statement.component.html',
  styleUrls: ['./historical-ac-statement.component.scss']
})
export class HistoricalAcStatementComponent implements OnInit  {
  minDate: any;
  maxDate : any;
  firstMaxdate : any;
  @Input() dateChanged: any;

  @ViewChild('transactionModel', {static: false}) transactionModel: ModalDirective;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  dtTrigger = new Subject();
  resData: any;
  adminServer_url: any = env.adminServer_url();
  constructor(  private userService: UserService ,
                private utilityService: UtilityService ,
                private placebetService: PlacebetService ,
                private http: HttpClient,
                private marketService:MarketService,
                private commonService:CommonService,
                @Inject(LOCALE_ID) private locale: string,
                private spinner : NgxSpinnerService) {
  }

  filter = {
    id: this.utilityService.returnLocalStorageData('userId'),
    page: 1,
    limit: 10,
    startDate: null,
    endDate: null,
    matchName:null,
    type: 'GAME',
    checkBoxValue : true,
  };
  matchListSerchdata:any;
  matchNameVaule = '';
  fromDate: any;
  toDate: any;
  sDate: any;
  eDate: any;
  chipsTransactionData: any;
  userTransactionRes: any;
  disabled:boolean = true;
  betlistFind:any;
  preBal:any = null;
  OpeningBalace:any= null;
  OpeningBalaceDate:any= null;
  rawNO:any = 0;
  responseData:any;
  selectedResult:any;
  jsonData:any = [];
  ngOnInit(): void {


    this.maxDate = {
      year: 2022,
      month: 3,
      day: 15
    };
    this.firstMaxdate = {
      year: 2022,
      month: 3 ,
      day: 15
    }

    let pre = moment('2022-03-15').format("YYYY-MM-DD");
    let endDate = moment('2022-03-15').subtract(6, 'd').format("YYYY-MM-DD");
    this.filter.startDate = endDate;
    this.filter.endDate = pre;

    let ePreShow = moment('2022-03-15').format("DD/MM/YYYY");
    let sPreShow = moment('2022-03-15').subtract(6, 'd').format("DD/MM/YYYY");
    this.sDate = sPreShow;
    this.eDate = ePreShow;

    // this.getOpeningBalanceData();
    this.getAcSummaryReport();
    // this.matchListSerch();
  }


  /**
   * @author TR
   * @date 23-06-2020
   * GetAll AcSummaryReport
   * @param data
   */
  getAcSummaryReport(){
    this.rerender();
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50, // -- trying to set to 5 records only
      paging: true,
      deferLoading: 0,
      serverSide: true,
      processing: false,
      responsive: true,
      lengthChange: true,
      order: [[ 0, "" ]],
      lengthMenu: [50, 75, 100, 200],
      language: {
        lengthMenu: '_MENU_',
        zeroRecords: '',
        searchPlaceholder: 'search',
        search: "",
        emptyTable: 'No record found',
        paginate: {
           first: 'first', last: 'last',
          next: 'next',
          previous: 'prev'
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        let url = this.adminServer_url + 'chipscash/getAcSummary';
        that.http
          .post<DataTablesResponse>(
            url,
            Object.assign(dataTablesParameters, {data : this.filter}),
            {}
          ).subscribe(resp => {
          this.responseData = resp;
          this.responseData = this.utilityService.gsk(this.responseData.auth);
          this.responseData = JSON.parse(this.responseData);
          if(this.responseData && this.responseData.status){
            this.OpeningBalace = (this.responseData.data.data.length > 0) ? this.responseData.data.data[0].openingBalance : this.responseData.data.openingBalance  ;
            this.preBal = (this.responseData.data.data.length > 0) ? this.responseData.data.data[0].openingBalance : this.responseData.data.openingBalance  ;
            this.OpeningBalaceDate = (this.responseData.data.data.length > 0) ? this.responseData.data.data[0].openingDate : this.responseData.data.openingDate ;
            let dataTemp = this.responseData.data.data.map(res => {
              if(this.preBal == null){
                if(res.CalculateBalance){
                  this.preBal = (res.CalculateBalance == 0)?res.balance:res.CalculateBalance ;
                } else {
                  this.preBal = res.balance
                }
                this.OpeningBalace = res.balance;
                res.tempNewBalance = this.preBal;
              }else{
                let credit = (res.credit != null)?res.credit:0 ;
                let debit  = (res.debit != null )?res.debit:0;
                this.preBal = res.tempNewBalance = (this.preBal + credit) - debit;

              }
              return res ;
            });
            this.chipsTransactionData = dataTemp;
            $('.respo-scroll').addClass('displayBl');
          }
          this.spinner.hide();
          this.dtTrigger.next();
          callback({
            recordsTotal: this.responseData.data.totalCount,
            recordsFiltered: this.responseData.data.totalCount,
            data: []
          });
        });
      },

      columns: [ { data: '' },{ data: 'message' }, { data: 'debit' }, { data: 'credit' }, { data: 'balance' },{ data: 'type' }, { data: 'createdAt' }],
      columnDefs: [ { orderable: false, targets: [0] },{ orderable: false, targets: [1] }, { orderable: false, targets: [2] }, { orderable: false, targets: [3] }, { orderable: false, targets: [4] },  { orderable: false, targets: [5] }, { orderable: false, targets: [6] }]
    };

  }


  rerender(): void {
    $('.dataTable').dataTable().fnClearTable();
  }

  clearDate(e){
    if(e.target.value === ''){
      this.getAcSummaryReport()
    }
  }

  searchFilterData() {
    this.OpeningBalaceDate = null;
    this.rawNO = 0;
    if (this.fromDate){
      this.filter.startDate = (this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day);
    }
    if (this.toDate){
      this.filter.endDate = (this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day);
    }

    if(this.fromDate){
      this.fromDate = this.fromDate;
      this.toDate = this.toDate;
    }
    if(this.matchNameVaule){
      this.filter.matchName = this.matchNameVaule;
    }
    this.getAcSummaryReport();
    // this.getOpeningBalanceData();
    // this.matchListSerch();
  }

  clearFilter(){
    this.OpeningBalaceDate = null;
    this.rawNO = 0;
    this.disabled = true;
    this.sDate = '';
    this.eDate = '';
    this.filter.startDate = null;
    this.filter.endDate = null;
    this.fromDate =null;
    this.toDate =null;
    this.matchNameVaule = '';

    this.maxDate = {
      year: 2022,
      month: 3,
      day: 15
    };
    this.firstMaxdate = {
      year: 2022,
      month: 3,
      day: 15
    }

    let pre = moment('2022-03-15').format("YYYY-MM-DD");
    let endDate = moment('2022-03-15').subtract(6, 'd').format("YYYY-MM-DD");
    this.filter.startDate = endDate;
    this.filter.endDate = pre;

    let ePreShow = moment('2022-03-15').format("DD/MM/YYYY");
    let sPreShow = moment('2022-03-15').subtract(6, 'd').format("DD/MM/YYYY");
    this.sDate = sPreShow;
    this.eDate = ePreShow;

    this.filter = {
      id: this.utilityService.returnLocalStorageData('userId'),
      page: 1,
      limit: 10,
      startDate: this.filter.startDate,
      endDate: this.filter.endDate,
      matchName:this.matchNameVaule,
      type: 'GAME',
      checkBoxValue : true,
    };
    this.getAcSummaryReport();
    // this.getOpeningBalanceData();
    this.matchListSerch();
  }


  /**
   * @author kc
   * @date 30-04-2019
   * changed page based on current page
   * @param p
   */
  pageChange(p): void {
    this.filter.page = p.page;
    this.getAcSummaryReport();
  }

  transactionList(data){
    this.userTransactionRes = null;
    this.getAllTransaction(data);
    this.transactionModel.show();
  }
  closeTransactionModel(){
    this.transactionModel.hide();
    this.userTransactionRes = [];
  }

  getAllTransaction(data){
    this.selectedResult = data;
    if(data.marketData[0].marketType == 'Fancy'){
      this.betlistFind = {
        gameId : data.marketData[0].gameId,
        userId : data.userId,
        status: 'settled',
        marketType:data.marketData[0].marketType
      };
    } else if(data.marketData[0].marketType == 'Casino') {
      this.betlistFind = {
        gameId : data.transactionId,
        userId : data.userId,
        status: 'settled',
        marketType:data.marketData[0].marketType
      };
    } else {
      this.betlistFind = {
        gameId : data.marketData[0].marketId,
        userId : data.userId,
        status: 'settled',
        marketType:data.marketData[0].marketType
      };
    }
    console.log("this.betlistFind+++++++++++",this.betlistFind);
    this.userService.getAllTransactionByGame(this.betlistFind).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      this.userTransactionRes = response.data;
      console.log(this.selectedResult);
    });
  }
   /**
   * @author subhash
   * @date 21-08-2019
   * date bulding
   */
    onDateSelect(e) {
      if (e.year) {
        this.toDate = null;
        this.disabled = false;
        this.minDate = {
          year: e.year,
          month: e.month ,
          day: e.day + 1
        };
        const jsDate = new Date(e.year, e.month - 1, e.day + 1);
        let date = JSON.stringify(jsDate)
        date = date.slice(1,11)
        let finalDate = new Date(date);
        let val = new Date(this.filter.startDate)
        let endDate = new Date('2022-03-15')

        if(finalDate > val){
          if(+finalDate === +endDate){
            this.minDate = {
              year: 2022,
              month: 3 ,
              day: 15
            };
          }
          this.maxDate = {
            year: 2022,
            month: 3 ,
            day: 15
          }
          let pre = moment('2022-03-15').format("YYYY-MM-DD");
          this.filter.endDate = pre;
          let ePreShow = moment(this.filter.endDate).format("DD/MM/YYYY");
          this.eDate = ePreShow;
        }else{
          var d = new Date(jsDate);
          d.setDate(d.getDate() + 6);
          let neDate = JSON.stringify(d)
          neDate = neDate.slice(1,11)
          let ePreShow = moment(neDate).format("DD/MM/YYYY");
          this.eDate = ePreShow;
          let endDate = moment(neDate).format("YYYY-MM-DD");
          this.filter.endDate = endDate;
          var check = moment(this.filter.endDate, 'YYYY-MM-DD');
            var month = check.format('M');
            var day   = check.format('D');
            var year  = check.format('YYYY');
            this.maxDate = {
              year: Number(year),
              month: Number(month),
              day: Number(day)
            }
        }
      }
        else {
        this.disabled = true;
      }
    }



    download() {
      this.preBal = null;
      this.jsonData =[];
      let filter = {"draw":1,"columns":
      [{
        "data":"","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
        {"data":"createdAt","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
        {"data":"debit","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
        {"data":"credit","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
        {"data":"balance","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
        {"data":"transactionType","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},
        {"data":"message","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}
      }],"order":[{"column":0,"dir":""}],"start":0,"length":50,"search":{"value":"","regex":false},
      "data":{"id": this.filter.id,"page":1,"limit":10000000000,"startDate":this.filter.startDate,"endDate":this.filter.endDate,"matchName":this.filter.matchName,"type":this.filter.type}}


      let count = 0;
      let i = 0;
      let responseDatacsv;
      let chipsTransactionDatacsv;
      this.placebetService.getAcSummaryReport(filter).subscribe(response => {
        response = this.utilityService.gsk(response.auth);
        response = JSON.parse(response);
        responseDatacsv = response;
        if(responseDatacsv && responseDatacsv.status){
          this.OpeningBalace = this.preBal;
          let dataTemp = responseDatacsv.data.data.map(res => {
            i++;
            if(this.preBal == null){
              if(res.CalculateBalance){
                this.preBal = (res.CalculateBalance == 0)?res.balance:res.CalculateBalance ;
              } else {
                this.preBal = res.balance
              }
              this.OpeningBalace = res.balance;
              res.tempNewBalance = this.preBal;
            }else{
              let credit = (res.credit != null)?res.credit:0 ;
              let debit  = (res.debit != null )?res.debit:0;
              this.preBal = res.tempNewBalance = (this.preBal + credit) - debit;

            }
            return res ;
          });
          if(responseDatacsv.data.data.length == i){
            chipsTransactionDatacsv = dataTemp;
          }
        }

        if(chipsTransactionDatacsv){
          let dataTemps = chipsTransactionDatacsv.map(chipsData => {
            console.log(chipsData)
            let dataArr ={
              DESCRIPTION: (chipsData.type != 'GAME')? '[' + chipsData.message + ']' :
              (chipsData.type == 'GAME' && chipsData.marketData[0].marketType === 'Fancy') ? '[ Result :' + chipsData.marketData[0].result + '] ['+ chipsData.message + '] ['+ chipsData.marketData[0].match.name.substring(0,chipsData.marketData[0].match.name.indexOf('(')) + ']':
              (chipsData.type == 'GAME' && chipsData.marketData[0].marketType === 'Line')? '[ Result :' + chipsData.marketData[0].result + '] [' + chipsData.message + '] [' + chipsData.marketData[0].match.name.substring(0,chipsData.marketData[0].match.name.indexOf('(')) + ']' :
              (chipsData.type == 'GAME' && chipsData.marketData[0].marketType === 'Bookmaker')? '[ Result :' + chipsData.marketData[0].result.runnerName + '] [Bookmaker] ['+ chipsData.marketData[0].match.name.substring(0, chipsData.marketData[0].match.name.indexOf('(')) + ']' :
              (chipsData.type == 'GAME' && chipsData.marketData[0].marketType === 'Match Odds')? '[ Result :' + chipsData.marketData[0].result.runnerName + '] [Match Odds] ['+ chipsData.marketData[0].match.name.substring(0, chipsData.marketData[0].match.name.indexOf('(')) + ']' :
              (chipsData.type == 'GAME' && chipsData.marketData[0].marketType === 'Winner')? '[ Result :' + chipsData.marketData[0].result.runnerName + '] [Winner] ['+ chipsData.marketData[0].match.name.substring(0, chipsData.marketData[0].match.name.indexOf('(')) + ']' :
              (chipsData.type == 'GAME' && chipsData.marketData[0].marketType === 'Casino')? '[ Result :' + (chipsData.marketData[0].result).replace(/,/g, ' ') + ' ] [ '+ chipsData.message + ']  [Casino]' :
              (chipsData.type == 'GAME' && chipsData.marketData[0].marketType === 'casinoLive')  ? '[ Result :' + (chipsData.debit ? 'Loss' : 'Win') + ' ] [ '+ chipsData.message + ']  [casinoLive]' : '',

              DEBIT: (chipsData.debit) ? chipsData.debit : 0,
              CREDIT: (chipsData.credit)? chipsData.credit: 0,
              BALANCE: chipsData.tempNewBalance,
              TYPE: chipsData.type,
              'DATE & TIME': formatDate(chipsData.createdAt,'dd-MM-yyyy  h:mm:ss',this.locale),
            }
            count++;
            this.jsonData.push(dataArr);
          });
        }

        let headerKey = ['DESCRIPTION', 'DEBIT', 'CREDIT', 'BALANCE', 'TYPE', 'DATE & TIME'];
        let csvName = 'Account Statement';
      if(chipsTransactionDatacsv.length == count) {
        this.commonService.downloadFile(this.jsonData, headerKey,csvName);
      }
    }, error => {
      console.error('error in get ac summary report', error);
    })
  }
   /**
   * get all match list
   */
  matchListSerch() {
    let data = {
      startDate: this.filter.startDate,
      endDate:this.filter.endDate
    };
    this.marketService.getMatchListSerch(data).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      // response.data = _.uniq(response.data , 'id');
      response.data = _.uniqBy(response.data, function (e) {
        return e.id;
      });
      this.matchListSerchdata = response.data;
    }, error => {
      console.error('error');
    });
  }
}
