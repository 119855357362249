import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/internal/operators';
import {isUndefined} from 'util';
import * as env from '../globals/env';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  server_url: any = env.adminServer_url();
  office_url: any = env.office_url();


  constructor(private http: HttpClient) { }

  /**
   * @author TR
   * @date : 11/06
   *  getAlldefaultScript
   */

    getAlldefaultScript(data): Observable<any> {
    return this.http.post(this.server_url + 'user/portfolio/list', data)
      .pipe(tap(_ => this.log(`Get default script successfully`)));
  }

  /**
   * @author TR
   * @date : 11/06
   *  getAlldefaultScript
   */

  getSettleData(data): Observable<any> {
    return this.http.post(this.server_url + 'market/settle/list', data)
      .pipe(tap(_ => this.log(`Get settle list successfully`)));
  }

  /**
   * @author TR
   * @date : 11/06
   *  get round data result
   */

  getRoundData(data): Observable<any> {
    return this.http.post(this.server_url + 'market/settle/round', data)
      .pipe(tap(_ => this.log(`Get settle list successfully`)));
  }

  /**
   * @author TR
   * @date : 05/06
   *  getUserCurrency
   */

      getUserCurrency(data): Observable<any> {
    return this.http.get(this.server_url + 'currency/'+ data.userId)
      .pipe(tap(_ => this.log(`get Currency`)));

  }

  /**
   * @author TR
   * @date : 31/07
   *  getRestriction
   */

      getRestriction(): Observable<any> {
    return this.http.get(this.server_url + 'webHook/restriction/get')
      .pipe(tap(_ => this.log(`get market Rules`)));

  }

  /**
   * @author TR
   * @date : 05/06
   * user updateCurrency
   */


  updateCurrency(data) :Observable<any>{
    return this.http.put(this.server_url + 'currency/'+ data.userId , data)
      .pipe(tap(_ => this.log(` update currency successfully`)));
  }


  log(message) {
    console.log(message);
  }

}
