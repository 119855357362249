import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { AccountStatementComponent } from './account-statement/account-statement.component';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import {AuthGuard} from "../auth-gaurd/auth-guard.service";
import {OpenBetComponent} from './open-bet/open-bet.component';
import { ChipsSettingComponent } from './chips-setting/chips-setting.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {ExposerDetailsComponent} from './exposer-details/exposer-details.component';
import {RulesSettingComponent} from './rules-setting/rules-setting.component';
import { GenerateBillComponent } from './generate-bill/generate-bill.component';
import { HistoricalAcStatementComponent } from './historical-ac-statement/historical-ac-statement.component';
import {ProfileComponent} from './profile/profile.component';
import {MatchWiseAcStatementComponent} from './match-wise-ac-statement/match-wise-ac-statement.component';


const routes: Routes = [{
  path: 'reports',
  component: ReportsComponent,
  children: [
    {
      path: 'as-view',
      canActivate: [AuthGuard],
      component: AccountStatementComponent,
    },
    {
      path: 'historical-ac-statement',
      canActivate: [AuthGuard],
      component: HistoricalAcStatementComponent,
    },
    // {
    //   path: 'match-ac-statement',
    //   canActivate: [AuthGuard],
    //   component: MatchWiseAcStatementComponent,
    // },
    {
      path: 'pl-view',
      canActivate: [AuthGuard],
      component: ProfitLossComponent,
    },
    {
      path: 'bt-view',
      canActivate: [AuthGuard],
      component: OpenBetComponent,
    },{
      path: 'chips-setting',
      canActivate: [AuthGuard],
      component: ChipsSettingComponent,
    },{
      path: 'password-reset',
      canActivate: [AuthGuard],
      component: PasswordResetComponent,
    },{
      path: 'profile',
      canActivate: [AuthGuard],
      component: ProfileComponent,
    },
    {
      path: 'rules-setting',
      canActivate: [AuthGuard],
      component: RulesSettingComponent,
    },{
      path: 'exposer-details',
      canActivate: [AuthGuard],
      component: ExposerDetailsComponent,
    },{
      path: 'create-bill',
      canActivate: [AuthGuard],
      component: GenerateBillComponent,
    },
  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
