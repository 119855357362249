import {UtilityService} from '../globals/utilityService';

declare var require: any;
import {Injectable} from '@angular/core';
import {isUndefined} from "util";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from "rxjs/index";
import {Router} from '@angular/router';

import {tap} from 'rxjs/internal/operators';
var aes256 = require('aes256');

@Injectable()
export class DefaultRequestOptions implements HttpInterceptor {
  constructor(private router: Router,
              private utilityService: UtilityService) {}
  mycurrentRoute: any;
  intercept(options: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userId = localStorage.getItem('userId');
    let datauser = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
    if(datauser.userType == 'CLIENT'){

    }else{
      this.router.navigate(['/login']);
    }
    this.mycurrentRoute = this.router.url;
    let user;
    let reqHeader;
    if(userId){
      user = manipulation();
    }
    if (options.method !== 'GET') {
      if(options.headers.get('stock') !== null){
        //plain request
      }else{
        reqHeader = getEncKey(options.body);
        options =  options.clone({
          body: {
            Auth: reqHeader
          }
        });
      }
    }
    if (options.url && options.url.startsWith('https://maps.googleapis.com/')) {
      return next.handle(options);
    }

    if (options && options.headers) {
      if (options.headers.get('Content-Type') !== null) {
        return next.handle(options);
      }
      if (options.headers.get('Content-Type') === '') {
        options.headers.set('Content-Type', 'application/json');
      }
      const local = localStorage.getItem('token');
      const session = sessionStorage.getItem('token');
      if (!isUndefined(localStorage.getItem('token')) && (localStorage.getItem('token') !== null)) {
        options.headers.set('Authorization', localStorage.getItem('token'));
        options.headers.set('Identity', user);
        let clone ;
        if ( options.method !== 'GET') {
          clone = options.clone({ setHeaders: { Authorization: `${localStorage.getItem('token')}` , Identity: `${user}` } });
        } else {
          clone = options.clone({ setHeaders: { Authorization: `${localStorage.getItem('token')}` , Identity: `${user}` } });

        }
        return next.handle(clone).pipe( tap(() => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              } else {
                this.router.navigate(['/login']);
              }
            }
          }));
      }

    } else {
      if (!isUndefined(localStorage.getItem('token')) && localStorage.getItem('token') !== null) {

        options.headers.set('Authorization', localStorage.getItem('token'));
        options.headers.set('Identity', user);
        let clone ;
        if ( options.method !== 'GET') {
          clone = options.clone({ setHeaders: { Authorization: `${localStorage.getItem('token')}` , Identity: `${user}` } });
        } else {
          clone = options.clone({ setHeaders: { Authorization: `${localStorage.getItem('token')}` , Identity: `${user}` } });

        }        return next.handle(clone);
      }
    }

    return next.handle(options);
  }
}
function manipulation(){
  let userId = localStorage.getItem('userId');
  let item = userId.replace(/=/g, "cF");
  let item2 = item.replace(/F/g, "De6Gs");
  let user = reverseString(item2);
  return user;
}

function reverseString(str){
  var splitString = str.split(""); // var splitString = "hello".split("");
  var reverseArray = splitString.reverse();
  var joinArray = reverseArray.join("");
  return joinArray;
}
function dataRkEnc(key, data){
  let encrypted = aes256.encrypt(key, data);
  return encrypted;
};

function  getEncKey(resObj){
  let encKey = getRandomString(20);
  let appendEncKey = getRandomString(5);
  let finalString = appendEncKey + reverseString(encKey) + dataRkEnc(encKey, JSON.stringify(resObj));
  return finalString;
};
function  getRandomString(length){
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
  result += characters.charAt(Math.floor(Math.random() * charactersLength));
}
  return result;
};
