import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DreamCasinoRoutingModule } from './dream-casino-routing.module';
import { DreamCasinoComponent } from './dream-casino.component';
import { DreamCasinoViewComponent } from './dream-casino-view/dream-casino-view.component';
import {ModalModule} from 'ngx-bootstrap';
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule} from "@angular/forms";
import { IndianPokerViewComponent } from './indian-poker-view/indian-poker-view.component';
import { VirtualComponent } from './virtual/virtual.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LobbyCasinoComponent } from './lobby-casino/lobby-casino.component';

@NgModule({
  declarations: [DreamCasinoComponent, DreamCasinoViewComponent, IndianPokerViewComponent, VirtualComponent, LobbyCasinoComponent],
  imports: [
    DreamCasinoRoutingModule,
    SlickCarouselModule,
    BrowserModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    CommonModule
  ]
})
export class DreamCasinoModule { }
