import {Component, OnInit, ViewChild} from '@angular/core';
import { StudioService } from '../../services/studio.service';
import {UtilityService} from '../../globals/utilityService';
import {ActivatedRoute, Router,NavigationEnd} from '@angular/router';
import {ModalDirective} from 'ngx-bootstrap';
import * as $ from 'jquery';
import {MarketService} from '../../services/market.service';
declare let _: any;
@Component({
  selector: 'app-virtual',
  templateUrl: './virtual.component.html',
  styleUrls: ['./virtual.component.scss']
})
export class VirtualComponent implements OnInit {

  @ViewChild('instructionModal', { static: false }) instructionModal: ModalDirective;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studioService: StudioService,
    private utilityService: UtilityService,
    private marketService: MarketService,
  ) { }
  gameList;
  groupList;
  bannerImages: any;
  casinoImages: any;
  product = ['Kiron' ,'Edge Gaming', 'Golden Race'];
  gameLists;
  userId = this.utilityService.returnLocalStorageData('userId');
  gameIds = [7171,7173,7175,7177,7181,7187,7189,7191,10641,10643,12623,12331,12332,12333,6984];


  ngOnInit() {
    this.getCasinoList();
    this.getBannerImage();
  }
  closeModal(){
    this.instructionModal.hide();
  }
  getBannerImage(){
    this.marketService.getBannerImage().subscribe(response => {
      if (response.status) {
        this.bannerImages = _.filter(response.data , function (e) {
          if(e.type === 'Banner'){
            return e;
          }
        });
        this.casinoImages = _.filter(response.data , function (e) {
          if(e.type === 'Casino'){
            return e;
          }
        });
        setTimeout(function(){
          $('.carousel-indicators > li').first().addClass('active');
          $('#img0').addClass('active');
        }, 200);

      } else {
        // this.commonService.popToast('error','Error', 3000 , 'not found market rules.');
      }
    }, error => {
      // this.commonService.popToast('error', 'Error', 3000, 'not found.');
    });
  }
  getCasinoList(){
    let data = {
      product : this.product
    }
    this.studioService.getGameList(data).subscribe(response => {
      if(response.status == true){
        this.gameLists = response.data;
        this.gameList = response.data;
        this.groupList = [];
        // this.instructionModal.show();
        this.groupList = _.filter(this.gameList, function(p){
          let checktrue = _.includes([7171,7173,7175,7177,7181,7187,7189,7191,10641,10643,12623,12331,12332,12333,6984], p.game_id);
          if(checktrue === true){
            return p;
          }
        });
        //   this.groupList = _.filter(this.gameList)
        //   // Group the elements of Array based on `color` property
        //     .groupBy("product")
        //     // `key` is group's name (color), `value` is the array of objects
        //     .map((value, key) => ({ name: key, category: value }))
        //     .value();
        // }else{
        //   this.gameList = [];
      }
    });
  }
  onSelect(item){
    if(item === 'all'){
      this.gameList = this.gameLists;
    }else {
      this.gameList = item.category;
    }
  }

  gameUrl(data){
    this.router.navigateByUrl('/dc/game-lobby/' + data.game_id);
    // let game_id = data.game_id;
    // let params = {
    //   game_id : data.game_id,
    //   user : this.userId,
    //   platform : 'GPL_DESKTOP',
    //   lobby_url : "https://bettrader.live/dashboard",
    // }
    // this.studioService.getUrl(params).subscribe(response => {
    //   console.log(response);
    // });
  }


}
