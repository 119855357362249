import { Component, OnInit, Inject, Injectable } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import * as io from 'socket.io-client';
import { Observable} from 'rxjs';
import * as env from './env';
import {UtilityService} from './utilityService';
import {CommonService} from '../services/common.service';
declare let _: any;
declare var $: any;
@Injectable()

export class SocketServiceFancyRedis{
  // private url =  env.client_socket_redis();
  private url =  'https://fancy.getlivefeed.xyz/';
  //private url =  'localhost:6056';

  private socket;
  constructor(@Inject(DOCUMENT) private document: any,
              private commonService: CommonService,
              private router: Router,
              private location: Location,
              private utilityService: UtilityService
              ) {
                this.socket = io('https://stfancy.getlivefeed.xyz',{
                  autoConnect: true,
                  forceNew: false,
                  reconnection: true,
                  reconnectionDelay: 500,
                  reconnectionDelayMax: 1000,
                  reconnectionAttempts: Infinity,
                    transports: ['websocket','flashsocket','htmlfile','xhr-polling','jsonp-polling','polling']
                  }
                );

          this.socket.on('disconnect', () => {

          });


    this.socket.on('reconnect', () => {
      this.reconnections();
      let socket = this.socket;
      let roomIs = sessionStorage.getItem('lastJoinRoom');
      let room = JSON.parse(roomIs);
      if (roomIs !== null) {
        _.map(room, function(res) {
          socket.emit('join-room', res.toString());
        }, this);
      }
    });

    document.addEventListener("visibilitychange", function() {
    }, false);
  }



  //Join room function
  joinRoom(roomId){
    this.socket.emit('join-room', roomId);
  }


  //leave the room
  leaveRoom(roomId){
    // console.log(roomId);
    this.socket.emit('leave-room', roomId);
  }

  disconnect(){
    this.socket.disconnect(0);
  }

  connect(){
    this.socket.connect(0);
  }

  //this function use for getting bet from socket emit event

  //this function use for getting bet from socket emit event
  public fancyRate = () => {
    return Observable.create((observer) => {
      this.socket.on('fancy-auto-rate', (message) => {
        observer.next(message);
      });
    });
  }



  public reconnections = () => {
    return Observable.create((observer) => {
      this.socket.on('reconnect', (message) => {
        observer.next('reconnect');
      });
    });
  }
}
