// Modules
import {NgModule} from '@angular/core';
import {OnlynumberDirective} from './onlyNumber';
import {EllipsisPipe} from './ellipsis.pipe';
import {DisableRightClickDirective} from './right -click';
import {MatchValueDirective} from './match-value.directive';
import {AutofocusDirective} from "./autofocus.directive";
import {SafePipe} from './safePipe';
import {NoCommaPipe} from './commaRemove';
import {ThousandSuffixesPipe} from './thousandConvert';
import {dateFormatPipe} from './date.pipe';
import {FilterPipe} from './filter.pipe';
@NgModule({
  declarations: [
    OnlynumberDirective,
    EllipsisPipe,
    DisableRightClickDirective,
    MatchValueDirective,
    AutofocusDirective,
    SafePipe,
    NoCommaPipe,
    ThousandSuffixesPipe,
    FilterPipe,
    dateFormatPipe
  ],
  exports: [
    OnlynumberDirective,
    EllipsisPipe,
    DisableRightClickDirective,
    MatchValueDirective,
    AutofocusDirective,
    SafePipe,
    NoCommaPipe,
    ThousandSuffixesPipe,
    FilterPipe,
    dateFormatPipe
  ]
})
export class commonDerectivenModule{}
