import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {tap} from 'rxjs/internal/operators';
import {isUndefined} from 'util';
import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import * as env from '../globals/env';
import {UtilityService} from '../globals/utilityService';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private headers = new HttpHeaders({'Authorization' : 'Bearer ' + this.utilityService.returnLocalStorageData('token')});
  private toasterService: ToasterService;

  server_url: any = env.office_url();

  constructor(private http: HttpClient, private utilityService: UtilityService ,
            toasterService: ToasterService
        ) {
          this.toasterService = toasterService;
        }

  log(message) {
    console.log(message);
  }

  public config1 : ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-right'
  });

  popToast(type,title ,timeout, body ) {
    const toast: Toast = {
      type: type,
      title: title,
      timeout: timeout,
      body: body
    };

    this.toasterService.pop(toast);
  }

  getLocalStorage(){
      let obj = {
       userData : this.utilityService.returnLocalStorageData('userData'),
       userId : this.utilityService.returnLocalStorageData('userId')
     }
     return obj;
  }

  getRandomString(length){
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }

  downloadFile(data,headerKey ,csvName) {

    let csvData = this.ConvertToCSV(data, headerKey);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", csvName + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);

  }


  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'NO.,';

    for (let index in headerList) {
        row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
        let line = (i+1)+'';
        for (let index in headerList) {
           let head = headerList[index];

            line += ',' + array[i][head];
        }
        str += line + '\r\n';
    }
    return str;
  }




}
