'use strict';
import {isDevMode} from '@angular/core';


// This is url to get data from office panel JSON API
export function server_url() {
  if (isDevMode()) {
    return 'https://localhost:2021/api/v1/';
  } else {
     //return 'http://localhost:6261/api/v1/';
    return 'https://server.dgexch.com/api/v1/';
  }
}


// This is url to get data from white label panel JSON API
export function adminServer_url() {
  if (isDevMode()) {
    return 'https://localhost:2020/api/v1/';
  } else {
    //return 'http://localhost:6261/api/v1/';
    return 'https://server.dgexch.com/api/v1/';
  }
}

// This is socekt url to get data from office panel SOCKET
export function socket_url() {
  if (isDevMode()) {
    return 'ws://localhost:2021';
  } else {
    //return 'ws://localhost:2051';
    return 'https://socket.dgexch.com';
  }
}

// This is socekt url to get data from office panel SOCKET
export function client_socket_url() {
  if (isDevMode()) {
    return 'ws://localhost:2020';
  } else {
    //return 'ws://localhost:2050';
    return 'https://server.dgexch.com';
  }
}


// This is socekt url to get data from office panel SOCKET
export function client_socket_redis() {
  return 'https://sslbhav.rdsconn.com';
}

export function stockUsernamePrefix() {
  return '101';
}

// This is socekt url to get data from office panel SOCKET
export function studio_url() {
  if (isDevMode()) {
    return 'https://casino.top99.ml';
  } else {
    //return 'https://casino.top99.ml';
    //return 'https://apexdc.premiumgames.xyz';
    return 'https://smdcnew.premiumgames.xyz';
  }
}



// This is url to get data from office panel JSON API
export function office_url() {
  if (isDevMode()) {
    return 'https://localhost:2021/api/v1/';
  } else {
    // return 'http://localhost:2021/api/v1/';
    return 'https://socket.dgexch.com/api/v1/';
  }
}

export function constantKey() {
  return 'bqwOlSSbg9VLtQuMp3mB7OAWQQwrvj6V';
}

export function socketPrefix() {
  return 'dgexch-';
}

export function socketPrefix1() {
  return 'dgexch';
}

export function stockApi() {
  return 'https://api.bettraderfee.xyz/';
  //return 'https://devapi.bazaarconn.xyz/';
}


// import server ip
export function import_url() {
  return 'https://games555.ml';
}
// production
export function production() {
  return false;
}


// project config
export const PROJECT_NAME = 'dgexch';
export const PROJECT_CODE = '326';
export const PROJECT_LOGO_URL = 'assets/images/digi_logo.png';
export let EXPOSURE = 0;


