import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/internal/operators';
import {isUndefined} from 'util';
import * as env from '../globals/env';


@Injectable({
  providedIn: 'root'
})
export class FancyService {
  server_url: any = env.adminServer_url();
  office_url: any = env.office_url();

  constructor(private http: HttpClient) { }

  /**
   *@author Ravi Kadia
  * @date 07-02-2020
  * @param filter
  * @returns {Observable<any>}
  * get all fancy
**/

getAllFancy(data): Observable<any> {
  return this.http.post(this.server_url + 'fancy/get-all-market/Fancy', data)
    .pipe(tap());
}


getFancyByid(fancyId): Observable<any> {
  return this.http.get(this.office_url + 'market/get-latest-rate-fancy/'+fancyId)
    .pipe(tap());
}

  getExposer(data): Observable<any> {
    return this.http.post(this.server_url + 'placebet/get-match-fancy-expo', data)
      .pipe(tap());
  }

getFancyByRedis(data): Observable<any> {
  return this.http.post(this.server_url + 'fancy/get-fancy-redis', data)
    .pipe(tap());
}

log(message) {
  console.log(message);
}


}
