import {Component, OnInit, ViewChild} from '@angular/core';
import {MarketService} from "../services/market.service";
import {SocketService} from "../globals/socketService";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ModalDirective} from 'ngx-bootstrap';
import {UserIdleService} from 'angular-user-idle';
import {UtilityService} from '../globals/utilityService';
import {SocketServiceClient} from '../globals/socketServiceClient';
import {UserService} from "../services/user.service";
import { CommonService } from "../services/common.service";
import {ScoreCard} from '../globals/scoreCard';
import {SocketServiceRedisMarket} from '../globals/socketServiceRedisMarket';
import {SocketServiceRedis} from '../globals/socketServiceRedis';
import * as $ from 'jquery';
import * as env from '../globals/env';
declare let _: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  marketFilter = {
    page: 1,
    limit: 10,
    search: null,
    userId : this.utilityService.returnLocalStorageData('userId')
  };
  userId = this.utilityService.returnLocalStorageData('userId');
  userData = JSON.parse(this.utilityService.returnLocalStorageData('userData'));
  getAllMarkets :any;
  getAllMarketsSearch :any;
  searchDatas = [];
  counter: any;
  stockMarket: any;
  supportNumber: any;
  searchText: any;
  count = 0;
  getAll = [];
  isStockLogin : any;
  sidebarData  :any;
  cricketData;
  soccerData;
  tennisData;
  otherData;
  cricketCount;
  soccerCount;
  tennisCount;
  otherCount;

  constructor(
    private marketService: MarketService ,
    private utilityService: UtilityService ,
    private userIdle: UserIdleService ,
    private  socketService: SocketService,
    private  socketServiceClient: SocketServiceClient ,
    private socketServiceRedis: SocketServiceRedis,
    private socketServiceRedisMarket: SocketServiceRedisMarket,
    private scoreCard: ScoreCard,
    private  spinner: NgxSpinnerService ,
    private commonService: CommonService,
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.getBannerImage();
    this.getSidebarData();
    setTimeout(res =>{
      this.getMarketOdds();
      this.getMarketOddsItem();
    },200)

    // this.getLayoutSetting();
    this.socketService
      .changeFlag()
      .subscribe((response) => {
          if(response){
            setTimeout(res =>{
              this.getMarketOdds();
              this.getMarketOddsData();
            },2000)
          }
      });
      if (this.utilityService.returnLocalStorageData('stockLogin') == false) {
        setTimeout(() => {
          if(this.utilityService.returnLocalStorageData('stockLogin') == 'false'){
            this.isStockLogin = false;
          }else{
            this.isStockLogin = true;
          }
        },1000);
      }else{
        this.isStockLogin = true;
      }
    this.socketServiceClient
      .marketCreate()
      .subscribe((response) => {
          if(response){
           this.getMarketOdds();
            this.getMarketOddsData()
          }
      });

    this.socketServiceClient
      .updateSport()
      .subscribe((response) => {
        if(response){
          this.getMarketOdds();
          this.getMarketOddsData()
        }
      });

    this.socketServiceClient
      .updateTournament()
      .subscribe((response) => {
        if(response){
          this.getMarketOdds();
          this.getMarketOddsData()
        }
      });

    this.socketServiceClient
      .updateMatch()
      .subscribe((response) => {
        if(response){
          this.getMarketOdds();
          this.getMarketOddsData()

        }
      });

    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
        this.counter = count;
      this.utilityService.userLogout();
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['']);
      setTimeout(function(){ window.location.reload(); }, 30);
      }
    );

    // // Start watch when time is up.
    // this.userIdle.onTimeout().subscribe(() =>{
    //   this.utilityService.userLogout();
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   this.router.navigate(['']);
    //   setTimeout(function(){ window.location.reload(); }, 100);
    // });
  }

  /**
   * @author TR
   * @date : 26-02-2020
   * get all market
   */
  getMarketOdds() {
    let sidebar = localStorage.getItem("sidebarData");
    if(sidebar) {
      sidebar = JSON.parse(sidebar);
      this.getAllMarkets = sidebar;
      this.getAllMarketsSearch = sidebar;
      console.log('this.getAllMarkets', this.getAllMarkets);
      console.log('this.getAllMarketsSearch', this.getAllMarketsSearch);
      let cricketCnt;
      let cricket;
      let soccerCnt;
      let soccer;
      let tennis;
      let tennisCnt;
      let other;
      let otherCnt;
      
      
      _.map(this.getAllMarketsSearch, function (e) {
        if(e._id == 4){
           cricket = e;
           cricketCnt = e.doc.length;
        }else if(e._id == 1){
          tennis = e;
          tennisCnt = e.doc.length;
        }else if(e._id == 2){
          soccer = e;
          soccerCnt = e.doc.length;
        }else{
          other = e;
          otherCnt = e.doc.length;
        }
      })

      this.cricketCount = cricketCnt;
      this.cricketData = cricket;
      this.tennisCount = tennisCnt;
      this.tennisData = tennis;
      this.soccerCount = soccerCnt;
      this.soccerData = soccer;
      this.otherCount = otherCnt;
      this.otherData = other;
      

    }
    let reply = localStorage.getItem("dashboardData")
      // if(reply){
      //   reply = JSON.parse(reply);
      //   _.map(reply, function (e) {
      //     if(e._id == 4){
      //       console.log('asdas');
      //       this.cricketData = e;
      //       this.cricketCount= e.doc.length;
      //       console.log('cricketCount',this.cricketCount);
      //       localStorage.setItem('cricketData', JSON.stringify(e));
      //     } else if(e._id == 1){
      //       this.tennisData = e;
      //       this.tennisCount= e.doc.length;
      //       localStorage.setItem('tennisData', JSON.stringify(e));
      //     }else if(e._id == 2){
      //       console.log('e',e);
      //       this.soccerData = e;
      //       this.soccerCount= e.doc.length;
      //       localStorage.setItem('soccerData', JSON.stringify(e));
      //     }else{
      //       localStorage.setItem('otherSport', JSON.stringify(e));
      //     }
      //   })

      // }

  }

  getMarketOddsItem() {
    let reply = localStorage.getItem("dashboardData")
      if(reply){
        reply = JSON.parse(reply);
        reply =    _.filter(reply, function (e) {
          e.doc = _.filter(e.doc, function (i) {
            // i.data = _.filter(i.data, function (p) {
              if(i.isPlay == true){
                return i;
              }
            // })
            // if(i.data.length > 0){
            //   return i;
            // }

          })
          if(e.doc.length > 0) {
            return e;
          }
        })

        let gfg = _.sortBy(reply, ['doc[0].sportUrl.displayOrder']);
        localStorage.setItem('inplayMatch',JSON.stringify(gfg));

      }
  }

  getMarketOddsData() {
    this.marketService.getAllMarketForSideCreative(this.marketFilter, this.userId).subscribe(response => {
      //console.log(response)
      localStorage.setItem('sidebarData', JSON.stringify(response.data));

    });

  }

  /**
   * @author TR
   * @date : 26-02-2020
   * get all market
   */
  matchDataSearch() {
    this.marketService.getAllMarketForSidebar(this.marketFilter).subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      if(response){
        this.getAllMarkets = response.data;
      }
      // console.log(this.getAllMarkets);
      // this.getAllMarkets.data = this.getAllMarkets.data.map(data =>{
      //   data.doc = _.unique(data.doc, function (datas) {
      //       return datas.match.id;
      //     });
      //   return data;
      // })
    });
  }


  getLayoutSetting(){
    this.marketService.getLayoutSetting().subscribe(response => {
      if (response.status) {
        this.stockMarket = response.data.stockMarket;
      } else {
        // this.commonService.popToast('error','Error', 3000 , 'not found market rules.');
      }
    }, error => {
      this.commonService.popToast('error', 'Error', 3000, 'not found.');
    });
  }
  searchCustom(){

    let items= [];
    let items1= [];
    let src = this.searchText;
      if(src.length > 2){
          _.map(this.getAllMarketsSearch , function(e) {
        _.map(e.doc , function(s) {
          items = _.filter(s.data , function(ite) {
           return ite.name.toLowerCase().includes(src.toLowerCase());
          });
          if(items.length > 0){
            _.map(items , function (p) {
              items1.push(p);
            })

          }
        });
      });

    }
    this.searchDatas = items1;
      console.log(this.searchDatas)
  }
  selectMatch(id){
    this.searchText = '';
    $('.side-bar').removeClass('is-open');
    $('#srch').val('');
    this.searchDatas = [];
    this.router.navigateByUrl('/sports/game-view/' + id);
  }
  closeSideBar(){
    $('.side-bar').removeClass('is-open');
  }

  userLogout() {
    this.userService.logOut().subscribe(response => {
      response = this.utilityService.gsk(response.auth);
      response = JSON.parse(response);
      let checkbox = localStorage.getItem('checkbox');
      let userName = localStorage.getItem('username');
      let password = localStorage.getItem('password');
      this.commonService.popToast('success', 'Success', 1500, 'Logout successfully.');
      localStorage.clear();
      localStorage.setItem('checkbox' , checkbox);
      localStorage.setItem('username' , userName);
      localStorage.setItem('password' , password);
      let lastJoinRoom = sessionStorage.getItem("lastJoinRoom");
      $('#mainBody').css('overflow', 'visible');
      let userJoinRoom = this.utilityService.returnSessionStorageData('userId');
      this.socketService.disconnect();
      this.socketServiceClient.disconnect();
      this.socketServiceRedis.disconnect();
      this.socketServiceRedisMarket.disconnect();
      this.scoreCard.disconnect();
      const oldEventId = sessionStorage.getItem('casinoId');
      if(oldEventId){
        //this.socketcasinoRedis.leaveRoom('casino_' + oldEventId);
      }
      if (lastJoinRoom) {
        this.socketService.leaveRoom(lastJoinRoom);
      }
      if (userJoinRoom) {
        this.socketServiceClient.leaveRoom(userJoinRoom);
      }
      sessionStorage.clear();
      this.router.navigate(['']);
    }, error => {
      this.commonService.popToast('error', 'Error', 1500, error.message);
      console.error("error in logout");
    });
  }

  closeMenu(){
      $('.side-bar').removeClass('is-open');
      $('#mainBody').css('overflow', 'visible');

  }


  

  twoFAAuth(){
    console.log('twoFAAuth');
    console.log('this.userId++++',this.userId);
  }



  auraCasino(){
    $('.side-bar').removeClass('is-open');
    $('#mainBody').css('overflow', 'visible');
    let ite = localStorage.getItem('token');
    let wdh = $(window).innerWidth();
    let url;
    if(this.userId == "64ae340d589859629c1bc3b7"){
    if(wdh > 992){
     url = 'https://d2.fawk.app/#/splash-screen/'+ ite +'/'+9530;
    }else{
      url = 'https://m2.fawk.app/#/splash-screen/'+ ite +'/'+9530;
    }
      window.open(url, "_blank");
    }
  }

  getBannerImage(){
    let prefix = env.socketPrefix1();
    this.marketService.getBannerImage().subscribe(response => {
      if (response.status) {
        this.supportNumber = _.filter(response.data , function (e) {
          let items = _.includes(e.whitelableTypes , prefix);
          if(e.type === 'Support' && items){
            return e;
          }
        });

      } else {
        // this.commonService.popToast('error','Error', 3000 , 'not found market rules.');
      }
    }, error => {
      // this.commonService.popToast('error', 'Error', 3000, 'not found.');
    });
  }

  getSidebarData(){
    let filterObj = {
      page : 1,
      limit : 10
    }
    this.marketService.getSideBarData(filterObj,false).subscribe(response => {
      if(response.status == true){
        this.sidebarData = response.data
      }
    });
}

  liveSpport(){
  }

}
